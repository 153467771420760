export const filters: any[] = [
    {
        title: "General",
        filters: [
            {
                type: "search",
                title: "Organisation name",
                key: "name",
                id: "name"
            },
            {
                type: "select-or",
                title: "Operating Status",
                key: "operating_status.keyword",
                id: "operating_status.keyword",
                options: [
                    {key: "active", value: "y", title: "Active"},
                    {key: "inactive", value: "n", title: "Inactive"},
                    {key: "review", value: "c", title: "Under review"},
                ]
            },
            {
                type: "select-or",
                title: "Type",
                key: "type.keyword",
                id: "type.keyword",
                options: [
                    {key: "startup", value: "startup", title: "Startup"},
                    {key: "investor", value: "investor", title: "Investor"},
                    {key: "hub", value: "hub", title: "Hub"},
                ]
            },
            {
                type: "select-or",
                title: "Specifier",
                bucket: "organizations",
                key: "specifier.keyword",
                id: "specifier.keyword",
                options: [],
                dynamic: true,
            },
            {
                type: "range",
                title: "Founded between",
                key: "founded",
                id: "founded"
            }
        ]
    },
    {
        title: "AgBase",
        filters: [
            {
                type: "exists",
                title: "AgBase Specific",
                key: "agbase_category.name",
                id: "agbase_category.name",
                dynamic: false,
                bucket: 'organizations',
                options: []
            },
            {
                type: "select-or",
                title: "Category",
                key: "agbase_category.name.keyword",
                id: "agbase_category.name.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: []
            },
            {
                type: "select-or",
                title: "Value Chain",
                key: "agbase_value_chain.name.keyword",
                id: "agbase_value_chain.name.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: []
            },
            {
                type: "select-or",
                title: "Custom Visibility",
                key: "agbase_visibility.name.keyword",
                id: "agbase_visibility.name.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: []
            },
            {
                type: "select-or",
                title: "Regional HQs",
                key: "offices_hq_regional.country.name.keyword",
                id: "offices_hq_regional.country.name.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: []
            },
        ]
    },
    {
        title: "Sectors",
        filters: [
            {
                type: "select-or",
                title: "Sectors",
                key: "sectors.name.keyword",
                id: "sectors.name.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: []
            },
            {
                type: "select-or",
                title: "Sub-Sectors",
                key: "sub_sectors.name.keyword",
                id: "sub_sectors.name.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: []
            },
            {
                type: "select-or",
                title: "Technologies",
                key: "technologies.name.keyword",
                id: "technologies.name.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: []
            },
        ]
    },
    {
        title: "Geography",
        filters: [
            {
                type: "select-or",
                title: "Region",
                key: "ops_countries.region.keyword",
                id: "ops_countries.region.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: []
            },
            {
                type: "select-or",
                title: "Sub-Region",
                key: "ops_countries.sub_region.keyword",
                id: "ops_countries.sub_region.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: []
            },
            {
                type: "select-or",
                title: "Operating Countries",
                key: "ops_countries.name.keyword",
                id: "ops_countries.name.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: []
            },
        ]
    },
    {
        title: "Impact Metrics",
        filters: [
            {
                title: "SDG name",
                type: "select-or",
                key: "sdgs.name.keyword",
                id: "sdgs.name.keyword",
                dynamic: true,
                bucket: 'organizations',
                options: []
            },
            {
                title: "Impact metrics",
                type: "select-or",
                key: "attributes.organization:ResourceTag/Impact",
                id: "attributes.organization:ResourceTag/Impact",
                dynamic: true,
                bucket: 'organizations',
                options: []
            }
        ]
    },
    {
        title: "Funding",
        filters: [
            {
                type: "select-or",
                title: "Investor Name",
                key: "deals.investors.name.keyword",
                id: "deals.investors.name.keyword",
                dynamic: true,
                options: [],
                bucket: "organizations"
            },
            {
                title: "Funding stage",
                type: "select-or",
                key: "deals.stage",
                id: "deals.stage",
                dynamic: true,
                options: [],
                bucket: "organizations"
            },
            {
                title: "Doesn't have funding",
                type: "boolean",
                key: "no_funding",
                id: "no_funding"
            },
            {
                title: "Funding received",
                type: "range",
                key: "total_funding",
                id: "total_funding",
            },
            {
                type: "exists",
                title: "Funding information available",
                key: "deals.investors.name",
                id: "deals.investors.name"
            }
        ]
    }
];