import { useState } from "react"
import {useFlash} from "../../../context/FlashContext";
import {gql, useMutation} from "@apollo/client";
import {Button} from "@briterbridges/elements";
import {useNavigate} from "react-router";


const FILE_UPLOAD_MUTATION = gql`
    mutation ($file: Upload!,$path: String!) {
        singleUpload(file: $file,path: $path) {
            id
            uri
        }
    }
`;

const UPSERT_JOB = gql`
    mutation createjob($req: UpsertJobRequest!) {
        createJob(req: $req) {
            id
        }
    }
`

export const BulkUploadContainer = () => {

    const [selectedFile,setSelectedFile] = useState<any>()
    const [selectedType,setSelectedType] = useState("BulkCreateOrganizationCSV")
    const flash = useFlash()
    const navigate = useNavigate()

    const [upload] = useMutation(FILE_UPLOAD_MUTATION)
    const [upsertJob,{}] = useMutation(UPSERT_JOB)

    const onFileChange = (event: any) => {
        setSelectedFile(event.target.files[0])
    }


    const handleTypeChange = (event: any) => {
        setSelectedType(event.target.value)
    }

    const onFileUpload = () => {
        upload({
            variables: {
                file: selectedFile,
                path: "csv/bulkupload"
            }
        }).then((res: any) => {
            flash("file uploaded successfully","success")
            upsertJob({
                variables: {
                    req: {
                        type: selectedType,
                        payload: {"msg": res.data.singleUpload.uri}
                    }
                }
            }).then((res) => {
                console.log("res",res)
                flash("job created successfully","success")
                navigate("/approval")
            }).catch((err) => console.log("err",err))
        }).catch((err: any) => {
            console.log("err",err)
            flash("error", "CSV failed to upload")
        })
    }

    return (
        <section className="px-10">
            <div style={{paddingTop: "1rem",paddingBottom: "1rem"}}>
                <p style={{fontSize: "1rem",fontWeight: "bold"}}>Upload CSV to import entities to the platform</p>
            </div>
            <div style={{ gap: "2rem",display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", padding: "2rem", borderRadius: "4px", height: "300px", border: "2px dashed rgba(0,0,0,0.3)" }}>
                <div style={{ display: "flex", justifyContent: "center", gap: "0.5rem" }}>
                    <div>
                        <label>Choose type to upload :</label>
                        <select id={"entities"} onChange={handleTypeChange}>
                            <option selected={true} value={"BulkCreateOrganizationCSV"}>
								Organizations - (Create)
							</option>
                            <option value={"BulkUpdateOrganizationCSV"}>
                                Organizations - (Update)
                            </option>
                            <option value={"CreateEmployees"}>
                                Employees - (Create)
                            </option>
                            <option  value={"BulkCreateDealCSV"}>
                                Deals
                            </option>
                        </select>
                    </div>
                </div>
                <div>
                    <input type="file" onChange={onFileChange} />
                </div>
            </div>
            <div style={{ paddingTop: "1rem", display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "0.5rem" }}>
                <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
                    <Button color="secondary" onClick={() => console.log("cancel")}>Cancel</Button>
                    <Button color="primary" onClick={onFileUpload}>Upload</Button>
                </div>
            </div>
        </section>
    )
}
