import {memo, ReactChild, useEffect, useRef, useState} from 'react';
import { createPortal } from 'react-dom';

const DynamicPortal = ({ id, children, className } : { id: string, children: ReactChild, className?: string}) => {
    const el = useRef(document.getElementById(id) || document.createElement('div'));
    const [dynamic] = useState(!el.current.parentElement)
    useEffect(() => {
        if (dynamic) {
            el.current.id = id;
            el.current.className = className || ""
            document.body.appendChild(el.current);
        }
        return () => {
            if (dynamic && el.current.parentElement) {
                el.current.parentElement.removeChild(el.current);
            }
        }
    }, [id])
    return createPortal(children, el.current);
};
//@ts-ignore
export default memo(DynamicPortal);