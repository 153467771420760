import React from "react";
// eslint-disable-next-line
import ReactFC from "react-fusioncharts";
// eslint-disable-next-line
import FusionCharts from "fusioncharts";
// eslint-disable-next-line
import Chart from "fusioncharts/fusioncharts.charts";
import FusionTheme from 'fusioncharts/themes/fusioncharts.theme.carbon';
import {DocumentNode, TypedDocumentNode, useQuery} from "@apollo/client";
import _, {merge} from "lodash";

ReactFC.fcRoot(FusionCharts, Chart, FusionTheme);

interface ISimpleTimeChartGraphQL {
    config?: any
    query: DocumentNode | TypedDocumentNode;
    property?: string;
    queryMapper?: (data: any) => any;
    root: string;
    filters?: any;
    variables?: any;
}

export function SimpleTimeChartGraphQL({...props}: ISimpleTimeChartGraphQL) {
    const {query, config, variables, root, filters, property, queryMapper = (data: any) => data} = props
    const v = {
        "aggs": {
            ...variables,
            query: {
                filter: filters
            }
        }
    }
    const {data, loading, error, client, refetch} = useQuery(query, {
        variables: v,
        fetchPolicy: 'no-cache',
        pollInterval: 10000,
    })

    const rows = queryMapper(_.get(data, [root, property].join("."), []).map((c: any) => c));
    if (loading) {
        return <p>Loading..</p>
    }
    return <SimpleTimeChart data={rows} config={config}/>
}

export function SimpleTimeChart({data, config = {}}: { data: any, config?: any }) {
    // Create a JSON object to store the chart configurations
    const chartConfigs = {
        type: "splinearea", // The chart type
        renderAt: "chart-container-div",
        width: "100%", // Width of the chart
        height: "150", // Height of the chart
        dataFormat: "json", // Data type
        dataSource: {
            chart: {
                theme: "carbon",
                showhovereffect: "1",
                drawcrossline: "1",
                "crosslinealpha": "100",
                "plotcolorintooltip": "1",
                "crosslinecolor": "#808080",
                minimizeTendency: 1,
                yAxisMinValue: 0,
                baseFont: 'Manrope, sans-serif',
                yAxisPosition: 'left',
                showLimits: 1,
                chartLeftMargin: 0,
                chartRightMargin: 0,
                yAxisValuesPadding: 10,
                xAxisNamePadding: 0,
                labelPadding: 5,
                drawAnchors: 0,
                plotFillColor: "#C4C4C4",
                plotBorderColor: "#232323",
                plotBorderThickness: 0.5,
                borderThickness: 0,
                showPlotBorder: 1,
                xAxisNameFontColor: "#84818A",
                xAxisLineColor: "#232323",
                palettecolors: "#C4C4C4",
                usePlotGradientColor: 1,
                plotGradientColor: "#E4E4E4",
                plotFillAngle: 90,
                drawCrossLine: 1,
                drawCrossLineOnTop: 1,
                crossLineAlpha: 1,
                animation: 0,
                //
                showZeroPlaneValue: 0,
                divLineColor: '#C4C4C4',
                divLineAlpha: 50,
                showShadow: 0
            },
            // Chart Data - from step 2
            data: data
        }
    };
    return (
        <div>
            <ReactFC {...merge(chartConfigs, config)} />
        </div>
    )
}