import {useMutation, useQuery,gql} from "@apollo/client";
import {FETCH_COMPANY_BY_ID,DELETE_ORGANIZATION_BY_ID,DELETE_EMPLOYEE} from "./queries";
import {DELETE_DEAL} from "../deals/queries"
import {useParams} from "react-router";
import _, {get, sumBy} from "lodash";
import {format} from "date-fns";
import {Pillola} from "../../interface/Pillole";
import {HSection} from "../../interface/Typography";
import {Button} from "../../interface/Button/Button";
import {PencilIcon,TrashIcon} from "@heroicons/react/solid";
//@ts-ignore
import numeral from "numeral"
import {DataTable} from "../../interface/DataTable/DataTable";
import {Persona} from "../../interface/Persona";
import {Link, useNavigate} from "react-router-dom";
import { useFlash } from "../../context/FlashContext";

import {UpsertEmployeeFragment} from "./fragments/UpsertEmployeeFragment"

import {OrganizationMetadata} from "./OrganizationMetadata"
import {OrganizationPrimaryInfo} from "./OrganizationPrimaryInfo"
import {usePlatformContext} from "../../context/PlatformContext"

const MessageAction = ({message, entity,onClick}: { message: string, entity: string,onClick?: any}) => {
    return (
        <div className="bg-gray-50 border border-gray-300 p-2.5 rounded-md my-2.5 flex items-center">
            <p className="flex-grow">{message}</p>
            <Button title={`Add ${entity}`} variant="primary" size="sm" onClick={onClick}/>
        </div>
    )
}



export default function OrganizationContainer() {
    const params = useParams();
    const flash = useFlash();
    const navigate = useNavigate()

	const {addPane,removePaneByID} = usePlatformContext()

    const {data, loading} = useQuery(FETCH_COMPANY_BY_ID, { variables: { id: params.id }, fetchPolicy: "network-only"})
    const [deleteOrganization,{data: deleteOrgData,loading: deleteOrgLoading,error: deleteOrgError}] = useMutation(DELETE_ORGANIZATION_BY_ID)
	const [deleteEmployee] = useMutation(DELETE_EMPLOYEE)
	const [deleteDeal] = useMutation(DELETE_DEAL)

    const org = get(data, 'organization', {});

    const handleDeleteOrg = (orgId: string) => {
        deleteOrganization({
            variables: {
                id: orgId
            }
        }).then((res) => {
            flash("organization deleted successfully","success")
            navigate("/")
        }).catch((err) => {
            console.log("err",err)
            flash("failed to delete organization","error")
        })
    }

	const handleDeleteDeal = (dealId: string) => {
		deleteDeal({
			variables: { id: dealId },
			refetchQueries: ["organization"]
		}).then((res: any) => {
				flash("deal removed","success")
		}).catch((err: any) => {
				flash(`${err.message}`,"error")
		})
	}

	const handleDeleteEmployee = (empId: string) => {
		deleteEmployee({
			variables: {id: empId},
			refetchQueries: ["organization"],
		}).then((res) => {
			flash("employee removed","success")
		}).catch((err) => {flash(`${err.message}`,"error")})
		console.log("deleting employee")
	}

    const handleAddImpact = () => {
        navigate(`/organizations/directory/impact/${params.id}/edit`)
    }


    if(deleteOrgLoading) return <div className={"px-10 py-5 flex-grow"}><p>Deleting....</p></div>
    if(loading) return <div className={"px-10 py-5 flex-grow"}><p>Loading....</p></div>
    if (org.id === undefined) return <p>ERROR: Organization ID mismatch</p>
    return (
        <div className="flex flex-grow">
            <div className="px-10 py-5 flex-grow">
                <div className="flex flex-col gap-y-5">

                    <OrganizationPrimaryInfo 
                        org={org} 
                        onEdit={() => navigate(`/organizations/directory/orgs/${org.id}/edit`) } 
                        onDelete={() => handleDeleteOrg(org.id)}
                    />

                    <OrganizationMetadata org={org}/>

                    <div>
                        <HSection>{org.investments && org.investments.length} investments deployed (total disbursing {
                            org.investments && numeral(sumBy(org.investments, 'amount')).format(`($ 0.00 a)`)
                        })
                        </HSection>
                        {
                            org.investments && org.investments.length > 0 && (
                                <DataTable headers={[
                                    {key: "id", title: "ID", dataIndex: "id", render: (d: any) => d.id.slice(0, 8)},
                                    {
                                        key: "target",
                                        title: "Target",
                                        render: (d: any) => <Persona
                                            to={["/organizations/directory/orgs", d.target.id].join("/")} size="xs"
                                            text={d.target.name} imageURL={d.target.profile_image_url}/>
                                    },
                                    {key: "stage", title: "Stage", dataIndex: "stage"},
                                    {key: "date", title: "Date", render: (d: any) => format(new Date(d.date), 'PPP')},
                                    {key: "type", title: "Type", dataIndex: "type"},
                                    {
                                        key: "amount",
                                        title: "Amount",
                                        dataIndex: "amount",
                                        render: (d: any) => d.amount === 0 ? "Undisclosed" : d.amount.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                        })
                                    },
                                    {
                                        key: "investors",
                                        title: "Other Investors",
                                        render: (d: any) => {
                                            return (
                                                <div className="flex gap-2.5 whitespace-nowrap flex-wrap">
                                                    {
                                                        d.investors.filter((i: any) => i.id !== org?.id).map((i: any,idx: number) => {
                                                            return (
                                                                <Persona
                                                                    key={i.id}
                                                                    to={["/organizations/directory/orgs", i.id].join("/")}
                                                                    text={i.name} imageURL={i.profile_image_url} size="xs"/>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        key: "actions", title: "", render: (i: any) => {
                                            return (
												<div className="flex gap-1">
													<PencilIcon className="w-5 h-5 cursor-pointer" onClick={() => {
														navigate(`/deals/directory/deals/${i.id}/edit`)
													}}/>
													<TrashIcon className="text-red-700 w-5 h-5 cursor-pointer" 
														onClick={() => handleDeleteDeal(i.id)} />
												</div>
                                            )
                                        }
                                    }
                                ]} data={org.investments || []}/>
                            )
                        }
                    </div>
                    <div>
                        <div>
                            <HSection>{org.deals && org.deals.length} investments received (total
                                funding {org.deals && numeral(sumBy(org.deals, 'amount')).format(`($ 0.00 a)`)})
                            </HSection>
                            <MessageAction message={org.deals.length === 0 ? "No deals found" : `${org.deals.length} deals`} entity={"deals"} onClick={() => navigate("/create/deals")}/>
                        </div>
                        {
                            org.deals && org.deals.length > 0 && (
                                <DataTable headers={[
                                    {key: "id", title: "ID", dataIndex: "id", render: (d: any) => d.id.slice(0, 8)},
                                    {key: "stage", title: "Stage", dataIndex: "stage"},
                                    {key: "date", title: "Date", render: (d: any) => format(new Date(d.date), 'PPP')},
                                    {key: "type", title: "Type", dataIndex: "type"},
                                    {
                                        key: "amount",
                                        title: "Amount",
                                        dataIndex: "amount",
                                        render: (d: any) => d.amount === 0 ? "Undisclosed" : d.amount.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "USD"
                                        })
                                    },
                                    {
                                        key: "investors",
                                        title: "Other Investors",
                                        render: (d: any) => {
                                            return (
                                                <div className="flex gap-2.5 whitespace-nowrap flex-wrap">
                                                    {
                                                        d.investors.filter((i: any) => i.id !== org?.id).map((i: any) => {
                                                            return (
                                                                <Link
                                                                    id={i.id}
                                                                    to={["/organizations/directory/orgs", i.id].join("/")}>
                                                                    <Persona text={i.name} imageURL={i.profile_image_url}
                                                                             size="xs"/>
                                                                </Link>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            )
                                        }
                                    },
                                    {
                                        key: "actions", title: "", render: (i: any) => {
                                            const id = `edit-deals-${i.id}`
                                            return (
												<div className="flex gap-2">
													<PencilIcon className="w-5 h-5 cursor-pointer" onClick={() => {
														navigate(`/deals/directory/deals/${i.id}/edit`)
													}}/>
													<TrashIcon 
														className="text-red-700 w-5 h-5 cursor-pointer" 
														onClick={() => handleDeleteDeal(i.id)} />
												</div>
                                            )
                                        }
                                    }
                                ]} data={org.deals || []}/>
                            )
                        }
                    </div>
                </div>
                <div className="py-2.5">
                    <HSection>Team</HSection>
                    <MessageAction message={org.employees.length === 0 ? "No people in the team" : `${org.employees.length} people here`} entity="people" onClick={() => navigate(`/add/employee/${org.id}`)}/>
                    <div>
                        {
                            org.employees && org.employees.length > 0 && (
                                <DataTable headers={[
                                    {key: "id", title: "ID", dataIndex: "id", render: (emp: any) => emp.id.slice(0, 8)},
                                    {key: "role", title: "Role", dataIndex: "role"},
                                    {key: "person",title: "Person",dataIndex: "person",render: (emp: any) => {
                                        return <Persona size={"xs"} text={emp.person.first_name + " " + emp.person.other_names} />
                                    }},
                                    {
                                        key: "actions", title: "", dataIndex: "actions",
                                        render: (emp: any) => {
                                            return (
												<div className="flex gap-2">
													<PencilIcon className="w-5 h-5 cursor-pointer" onClick={() => {
														addPane({
															title: `Update Employee Information`,
															content: <UpsertEmployeeFragment id={emp.id} orgId={org.id}/>,
															id: emp.id,
															type: "edit"
														})
														//navigate(`/people/directory/person/${emp.id}/edit`)
													}} />

													<TrashIcon 
														className="text-red-700 w-5 h-5 cursor-pointer" 
														onClick={() => handleDeleteEmployee(emp.id)} />
												</div>
                                            )
                                        }
                                    },
                                ]} data={org.employees} />
                            )
                        }
                    </div>
                </div>
                <div className="py-2.5">
                    <HSection>Partnerships</HSection>
                    <MessageAction message="No partnership for this company" entity="partnership"/>
                </div>
                <div className="py-2.5">
                    <HSection>{"Impact"}</HSection>
                    <MessageAction message={org.impact ? `${buildImpacts(org.impact).length} impact variables here` : "No impact for this company"} entity="impact" onClick={handleAddImpact}/>
                    <div style={{display: "flex",gap: "0.5rem"}}>
                        {
                            buildImpacts(org.impact).map((it: any ) => (
                                <Pillola text={it.value} variant={"ghost"} />
                            ))
                        }
                    </div>
                </div>
                <div className="py-2.5">
                    <HSection>Finance & Operations</HSection>
                    <MessageAction message="No data for this company" entity="ops"/>
                </div>
                <div className="py-2.5">
                    <HSection>Assessments</HSection>
                    <MessageAction message="No assessment for this company" entity="assessments"/>
                </div>
                <div className="py-2.5">
                    <HSection>Docs</HSection>
                    <MessageAction message="No documents for this company" entity="docs"/>
                </div>
                <div className="py-2.5">
                    <HSection>Market</HSection>
                    <MessageAction message="No market analysis for this company" entity="markets"/>
                </div>
            </div>
        </div>
    )
}

const buildImpacts = (data : any) => {
    const valueMap: Record<string, string> = {
        "female_cofounder": "Female CoFounder",
        "assistive_tech": "Assistive Tech",
        "bop": "BoP",
        "elderly_people": "Elderly People",
        "employment_creation": "Employment Creation",
        "female_focused": "Female Focoused",
        "fifty_local": "Fifty Local",
        "informal_sector": "Informal Sector",
        "rural_focused" : "Rulal Focused",
        "youth_focused": "Youth Focused",
        "zero_emissions": "Zero Emissions"
    };

    const selectedImpacts = []
    for(const key in data) {
        if(Object.prototype.hasOwnProperty.call(data,key) && data[key] === true) {
            selectedImpacts.push({id: key,value: valueMap[key]})
        }
    }
    return selectedImpacts
}
