import React, {ReactNode, useRef, useState} from "react";
import {NavLink, useHref, useLinkClickHandler, useLocation} from "react-router-dom";
import classNames from "classnames";
import {useBaseLayoutContext} from "../../components/layouts/context/BaseLayoutContext";
import { useOnClickOutside } from 'usehooks-ts'

export function NavIconItem({children, isActive}: { children: ReactNode, isActive: boolean }): JSX.Element {
    const btnClass = [
        "flex w-16 h-16 text-bs-light-black justify-center align-middle border-l border-l-gray-100 hover:bg-gray-100 rounded",
        isActive ? "bg-gray-100" : ""
    ].join(" ");
    return (
        <button
            className={btnClass}>
            <div className="self-center">
                {children}
            </div>
            {
                isActive && (
                    <div className="absolute right-0 top-16 h-full max-w-3xl bg-gray-100 w-60">
                        <div className="p-5">
                            Portal right
                        </div>
                    </div>
                )
            }
        </button>
    )
}

interface ISubSideNav {
    children: ReactNode
    state: { isCollapsed: boolean, isHovering?: boolean }
}

export const SubSideNav = ({children, state}: ISubSideNav): JSX.Element => {
    const ref = useRef(null)
    const { setMenuAttached } = useBaseLayoutContext()

    const clsBlockMenu = [
        "bg-gray-50 mt-1 border-r border-r-gray-100 z-10",
        state.isCollapsed ? "hidden" : "fixed"
    ].join(" ")

    const clsMenuOuter = [
        "py-4 transition-all duration-[0ms] overflow-hidden",
        state.isCollapsed ? "w-5" : "w-80"
    ].join(" ");

    const clsMenuInner = [
        "transition-all duration-[0ms]",
        state.isCollapsed ? "hidden" : ""
    ].join(" ")

    const handleClickOutside = () => {
        setMenuAttached(true)
    }

    useOnClickOutside(ref, handleClickOutside)

    return (
        <div className={clsBlockMenu} style={{
            boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            borderBottomRightRadius: "1rem",
            borderTopRightRadius: "1rem"
        }}
             ref={ref}
             onMouseLeave={() => setMenuAttached(true)}
        >
            {/*<button className={clsBtnMenu} onClick={() => setState({...state, isCollapsed: !state.isCollapsed})}>*/}
            {/*    {*/}
            {/*        state.isCollapsed && (*/}
            {/*            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">*/}
            {/*                <path fillRule="evenodd"*/}
            {/*                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"*/}
            {/*                      clipRule="evenodd"/>*/}
            {/*            </svg>*/}
            {/*        )*/}
            {/*    }*/}
            {/*    {*/}
            {/*        !state.isCollapsed && (*/}
            {/*            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">*/}
            {/*                <path fillRule="evenodd"*/}
            {/*                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"*/}
            {/*                      clipRule="evenodd"/>*/}
            {/*            </svg>*/}
            {/*        )*/}
            {/*    }*/}
            {/*</button>*/}
            <nav className={clsMenuOuter}>
                <ul className={clsMenuInner}>
                    {children}
                </ul>
            </nav>
        </div>
    )
}

export function SubSideNavMenuLink({children, to}: { children: ReactNode, to: string }) {
    return (
        <li className="text-bs-light-black">
            <NavLink className="flex items-center w-full hover:bg-gray-100" to={to}>
                <span className="py-1.5 px-6">{children}</span>
            </NavLink>
        </li>
    )
}

// @ts-ignore
const SubSideMenuLink = React.forwardRef<HTMLLinkElement, React.HTMLProps>((
        {onClick, replace = false, state, target, children, to, ...rest}, ref
    ) => {
        const href = useHref(to);
        const handleClick = useLinkClickHandler(to, {replace, state, target});
        const {setMenuAttached} = useBaseLayoutContext()
        return (
            <a
                {...rest}
                href={href}
                onClick={(event) => {
                    setMenuAttached(true)
                    handleClick(event);
                    // onClick?.(event);
                    // if (!event.defaultPrevented) {
                    //     handleClick(event);
                    // }
                }}
                ref={ref}
                target={target}
            >{children}</a>
        );
    }
);


export function SubSideNavMenuItem({children, to}: { children: ReactNode, to: string }) {
    const location = useLocation();
    const {setMenuAttached} = useBaseLayoutContext()
    const cls = classNames({
        "pl-10 py-1.5 border-l-4 hover:bg-gray-100": true,
        "bg-bs-light-gray-100 border-l-4 border-l-bs-light-black": location.pathname.startsWith(to),
        "border-l-gray-50 ": !location.pathname.startsWith(to)
    })
    return (
        <li className={cls}>
            <SubSideMenuLink to={to} className="w-full block">
                {children}
            </SubSideMenuLink>
        </li>
    )
}

export interface ISubSideNavMenu {
    children?: ReactNode;
    menuLinkName: string;
    path?: string
}

export function SubSideNavMenu({children, menuLinkName, path}: ISubSideNavMenu) {
    const location = useLocation();
    const [state, setState] = useState({isVisible: path && location.pathname.startsWith(path)});
    const emptyChild = !React.isValidElement(children) && children === "";
    return (
        <li className="text-bs-light-black">
            <button className="flex items-center w-full hover:bg-gray-100"
                    onClick={() => setState({...state, isVisible: !state.isVisible})}>
                <span className="py-1.5 px-6">{menuLinkName}</span>
                <div className="flex flex-grow justify-end px-4">
                    {
                        state.isVisible && !emptyChild && (
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path fillRule="evenodd"
                                      d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
                                      clipRule="evenodd"/>
                            </svg>
                        )
                    }
                    {
                        !state.isVisible && !emptyChild && (
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path fillRule="evenodd"
                                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                      clipRule="evenodd"/>
                            </svg>
                        )
                    }
                </div>
            </button>
            {
                state.isVisible && (
                    <ul className="font-light">
                        {children}
                    </ul>
                )
            }
        </li>
    )
}