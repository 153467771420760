import {gql} from "@apollo/client";

export const FETCH_FACETS_BY_NAME = gql`
query facets($facets: FacetsRequest!) {
    facets(facets: $facets) {
        rows {
           key
           values {
             key
             count
           }
        }
        took
    }
}    
`