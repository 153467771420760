import { createContext, Dispatch, useContext, useReducer } from "react";

export const jobStateReducer = (state: any, action: any) => {
    switch (action.type) {
        case "ADD_INITIAL_JOBS":
            return {...state, jobs: action.payload};
        case "ADVANCE_NEXT":
            return state
        case "ADVANCE_PREV":
            return state
        case "SET_CURRENT_JOB":
            return {...state,currentJob: action.payload}
        case "REMOVE_CURRENT_JOB":
            return state
        case "UPDATE_JOB":
            return state
        case "FIND_JOB":
            // return state.jobs.find((job: any) => job.id === action.payload.id);
            return state
        case "DELETE_JOB":
            // return {...state,jobs: state.jobs.filter((job: any) => job.id !== action.payload.id)};
            return state
        case "SORT_JOBS":
            // const jobs = state.jobs
            // jobs.sort((a: any,b: any) => a.stage.localeCompare(b.stage))
            // return {...state,jobs: [...jobs]};
            return state
        default:
            return state;
    }
};

export interface JobStateProps {
    state: State;
    dispatch: Dispatch<any>;
}

export type State = {
    jobs: Job[]
    currentJob: any
}

export type Job = {
    id?: string;
    type?: string;
    jobs?: Job[];
    media_mention?: any;
    stage: string;
    source?: string;
};


// const initialJobs: any[] = [
//     {
//         id: "1",
//         type: "AnalyseArticleJob",
//         source: "localhost",
//         media_mention: {
//             content: "Hello World Content",
//             source: "https://localhost:8000/1",
//         },
//         jobs: [
//             { id: "10", type: "SingleOrganizationCreate", stage: "created" },
//             { id: "11", type: "SingleDealCreate", stage: "created" },
//         ],
//         stage: "validated",
//         created_at: new Date().toString(),
//     },
//     {
//         id: "2",
//         source: "localhost",
//         type: "AnalyseArticleJob",
//         stage: "created",
//         media_mention: {
//             content: "Hello World Content",
//             source: "https://localhost:8000/1",
//         },
//         jobs: [
//             { id: "15", type: "SingleOrganizationCreate", stage: "created" },
//             { id: "16", type: "SingleDealCreate", stage: "created" },
//         ],
//         created_at: new Date().toString(),
//     },
// ];


// const initialState: State = {
//     jobs: initialJobs,
//     currentJob: initialJobs[0]
// }

export const JobStateContext = createContext<JobStateProps>(
    {} as JobStateProps,
);
export const JobStateProvider = ({ children }: React.PropsWithChildren<{}>) => {
    const [state, dispatch] = useReducer(jobStateReducer, {jobs: [],currentJob: {}});
    return (
        <JobStateContext.Provider value={{ state, dispatch }}>
            {children}
        </JobStateContext.Provider>
    );
};

export const useJobContext = () => {
    return useContext(JobStateContext);
};
