import React from "react";
import {MDSection} from "../../../interface/Typography";
import classNames from "classnames";
import {PencilIcon} from "@heroicons/react/solid";
import {Pillola} from "../../../interface/Pillole";
import {DocumentIcon} from "@heroicons/react/outline";
import {Button} from "../../../interface/Button/Button";
import UpgradeDowngradeFreemiumFragment from "../fragments/UpgradeDowngradeFreemiumFragment";

// @ts-ignore
const Status = ({status}) => {
    const clsDot = classNames({
        'relative inline-flex rounded-full h-3 w-3 mr-2.5': true,
        'bg-green-500': status === 'active',
        'bg-gray-500': status === 'inactive'
    })
    const clsStatus = classNames({
        'uppercase text-sm py-2.5': true,
        'text-gray-500': status === 'inactive',
        'text-green-500': status === 'active'
    })
    return (
        <p className={clsStatus}>
            <span className={clsDot}></span>
            {status}
        </p>
    )
}

// @ts-ignore
const SubscriptionRow = ({plan}) => {
    return (
        <div className="w-full flex py-5">
            <div className="flex-grow">
                <p className="pb-2.5">
                    <span className="font-semibold text-xl">{plan.name}</span>
                    <span className="text-bs-light-gray-800 text-sm pl-5">({plan.tier})</span>
                </p>
                <p>
                    <span className="text-bs-light-gray-800">Subscription ID:</span>
                    <span className="font-semibold pl-5">{plan.subscription_id}</span>
                    <span className="inline-block pl-2.5"><PencilIcon
                        className="display-inline w-4 h-4 cursor-pointer"/></span>
                </p>
                <div className="flex flex-col py-2.5 text-sm">
                    <p className="flex-grow"><span
                        className="text-bs-light-gray-800">Last Billing Date:</span> {plan.last_billing.toISOString()}
                    </p>
                    <p className="flex-grow"><span
                        className="text-bs-light-gray-800">Next Billing Date:</span> {plan.next_billing.toISOString()}
                    </p>
                </div>
            </div>
            <div className="self-center">
                <div className="flex flex-row">
                    <p className="text-5xl">${plan.amount}</p>
                    <p className="pl-2 text-bs-light-gray-800">{plan.frequency}</p>
                </div>
                <Status status={plan.status}/>

            </div>
        </div>
    )
}

export function UserSubscriptionPane({user}: { user: any }) {
    return (
        <div className="p-10 gap-10">
            <div className="pb-10">
                <UpgradeDowngradeFreemiumFragment user={user}/>
            </div>
            <div className="opacity-25 grayscale">
                <MDSection title="Subscriptions" variant="success"
                           subtitle={`List of all the active subscriptions of ${user.first_name}.`}
                           actions={(
                               <Button title={"add subscription"} variant={"primary"} uppercase size="sm"/>
                           )}
                >
                    <SubscriptionRow
                        plan={{
                            name: "Startup - Plan",
                            amount: 10,
                            frequency: 'monthly',
                            status: 'active',
                            last_billing: new Date(),
                            next_billing: new Date(),
                            subscription_id: 'ca1078d7-cf80-11ec-bfdd-0242ac120005',
                            tier: 'STARTUP_POLICY'
                        }}/>
                    <SubscriptionRow
                        plan={{
                            name: "AgriTech Africa",
                            amount: 120,
                            frequency: 'yearly',
                            status: 'active',
                            last_billing: new Date(),
                            next_billing: new Date(),
                            subscription_id: 'c28ab241-5d49-43b0-a9aa-25f22ae7b7cb',
                            tier: 'CUSTOM_POLICY'
                        }}/>
                </MDSection>
                <MDSection
                    title={"Transaction History"}
                    subtitle={"List of transaction made through Stripe. All invoices and subscriptions are managed from Stripe."}
                >
                    <table className="border-collapse table-auto w-full text-sm border my-5">
                        <thead>
                        <tr>
                            <th className="border-b font-semibold p-2.5 bg-bs-light-gray-10">Status</th>
                            <th className="border-b font-semibold p-2.5 bg-bs-light-gray-10">ID</th>
                            <th className="border-b font-semibold p-2.5 bg-bs-light-gray-10 text-left">Issue
                                Date
                            </th>
                            <th className="border-b font-semibold p-2.5 bg-bs-light-gray-10 text-left">Payer</th>
                            <th className="border-b font-semibold p-2.5 bg-bs-light-gray-10 text-left">Amount</th>
                            <th className="border-b font-semibold p-2.5 bg-bs-light-gray-10 text-left">PDF</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            [
                                {
                                    id: 'ca1078d7-cf80-11ec-bfdd-0242ac120005',
                                    date: new Date(),
                                    amount: '10.00',
                                    currency: 'USD',
                                    payer: 'Edoardo Biraghi',
                                    payer_email: 'edoardo.biraghi@gmail.com'
                                },
                                {
                                    id: 'c28ab241-5d49-43b0-a9aa-25f22ae7b7cb',
                                    date: new Date(),
                                    amount: '120.00',
                                    currency: 'USD',
                                    payer: 'Briter Bridges LTD',
                                    payer_email: 'support@briterbridges.com'
                                },
                                {
                                    id: 'ca1078d7-cf80-11ec-bfdd-0242ac120005',
                                    date: new Date(),
                                    amount: '10.00',
                                    currency: 'USD',
                                    payer: 'Edoardo Biraghi',
                                    payer_email: 'edoardo.biraghi@gmail.com'
                                },
                                {
                                    id: 'ca1078d7-cf80-11ec-bfdd-0242ac120005',
                                    date: new Date(),
                                    amount: '10.00',
                                    currency: 'USD',
                                    payer: 'Edoardo Biraghi',
                                    payer_email: 'edoardo.biraghi@gmail.com'
                                },
                            ].map((invoice) => {
                                return (
                                    <tr>
                                        <td className="text-center p-2.5"><Pillola text={"paid"} variant={"positive"}/>
                                        </td>
                                        <td className="text-center p-2.5">{invoice.id.substring(0, 8)}</td>
                                        <td className="text-left p-2.5">{invoice.date.toISOString()}</td>
                                        <td className="text-left p-2.5 w-1/2">
                                            <p>{invoice.payer}</p>
                                            <p className="text-bs-light-gray-800">{invoice.payer_email}</p>
                                        </td>
                                        <td className="text-left p-2.5">{invoice.currency} {invoice.amount}</td>
                                        <td className="text-center p-2.5">
                                            <DocumentIcon className="w-6 h-6 text-red-500"/>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </table>
                </MDSection>
            </div>
        </div>
    )
}