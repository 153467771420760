import React from "react";
import {FilterTitle} from "./fragments/FilterTitle";
import FilterFreeSearch from "./FilterFreeSearch";
import FilterBoolean from "./FilterBoolean";
import {IDataContainerFilter, TDataContainerFilterCallback} from "../context/DataContainerContext";
import _ from "lodash";
import FilterRange from "./FilterRange";
import FilterSelect from "./FilterSelect";

type TFilterType = 'boolean' | 'range' | 'search' | 'select' | 'select-or' | 'select-and' | "exists" | "negate";

export interface IFilter {
    title: string;
    key: string;
    id: string;
    type: TFilterType;
    options?: { key: string, value: any, title: string }[];
    addFilter: TDataContainerFilterCallback;
    removeFilter: TDataContainerFilterCallback;
    defaultValues?: any;
    extras?: {
        sub_filters?: IDataContainerFilter[]
    }
}

export interface IFilterFactory {
    filter: IFilter;
    filtersActive: IDataContainerFilter[];
    filtersChanges: IDataContainerFilter[];
    addFilter: TDataContainerFilterCallback;
    removeFilter: TDataContainerFilterCallback;
}

export default function FilterFactory({...props}: IFilterFactory) {
    const {filter, addFilter, removeFilter, filtersActive} = props;
    switch (filter.type) {
        case "boolean":
        case "exists":
        case "negate":
            return (
                <FilterBoolean
                    {...filter}
                    key={filter.key}
                    addFilter={addFilter}
                    removeFilter={removeFilter}
                    defaultValues={_.find(filtersActive, {'key': filter.key})}
                />
            )
        case "range":
            return (
                <FilterRange
                    {...filter}
                    key={filter.key}
                    addFilter={addFilter}
                    removeFilter={removeFilter}
                    defaultValues={_.find(filtersActive, {'key': filter.key})}
                />
            )
        case "search":
            return (
                <FilterFreeSearch
                    {...filter}
                    key={filter.key}
                    addFilter={addFilter}
                    removeFilter={removeFilter}
                    defaultValues={_.find(filtersActive, {'key': filter.key})}
                />
            )
        case "select":
        case "select-or":
        case "select-and":
            return (
                <FilterSelect
                    {...filter}
                    title={filter.title}
                    key={filter.key}
                    addFilter={addFilter}
                    removeFilter={removeFilter}
                    defaultValues={_.find(filtersActive, {'key': filter.key})}
                />
            )
        default:
            return (
                <div className="py-1.5">
                    <FilterTitle title={filter.title}/>
                    <p className="text-sm">Not implemented {filter.type} </p>
                </div>
            )
    }
}