export const filters: any[] = [
    {
        title: "General",
        filters: [
            {
                type: "search",
                title: "Organisation name",
                key: "name",
                id: "name",
                bucket: "organizations",
                dynamic: true
            },
            {
                type: "select-or",
                title: "Operating Status",
                key: "operating_status.keyword",
                id: "operating_status.keyword",
                options: [
                    { key: "active", value: "y", title: "Active" },
                    { key: "inactive", value: "n", title: "Inactive" },
                    { key: "review", value: "c", title: "Under review" },
                ],
            },
            {
            	type: "select-or",
            	title: "Type",
            	key: "type.keyword",
            	id: "type.keyword",
            	options: [
            		{ key: "startup", value: "startup", title: "Startup" },
            		{ key: "investor", value: "investor", title: "Investor" },
            		{ key: "hub", value: "hub", title: "Hub" },
            	],
            },
            {
                type: "range",
                title: "Founded between",
                key: "founded",
                id: "founded",
                variant: "date",
            },
        ],
    },
    {
        title: "Industry Specifics",
        filters: [
            {
                type: "select-or",
                title: "Sectors",
                key: "sectors.name.keyword",
                id: "sectors.name.keyword",
                dynamic: true,
                bucket: "organizations",
                options: [],
            },
            {
                type: "select-or",
                title: "Sub-Sectors",
                key: "sub_sectors.name.keyword",
                id: "sub_sectors.name.keyword",
                dynamic: true,
                bucket: "organizations",
                options: [],
            },
            {
                type: "select-or",
                title: "Technologies",
                key: "technologies.name.keyword",
                id: "technologies.name.keyword",
                dynamic: true,
                bucket: "organizations",
                options: [],
            },
        ],
    },
    {
        title: "Geography",
        filters: [
            {
                type: "select-or",
                title: "Region",
                key: "ops_countries.region.keyword",
                id: "ops_countries.region.keyword",
                bucket: "organizations",
                dynamic: true,
                options: [],
            },
            {
                type: "select-or",
                title: "Sub-region",
                key: "ops_countries.sub_region.keyword",
                id: "ops_countries.sub_region.keyword",
                bucket: "organizations",
                dynamic: true,
                options: [],
            },
            {
                type: "select-or",
                title: "Operating Country",
                key: "ops_countries.name.keyword",
                id: "ops_countries.name.keyword",
                bucket: "organizations",
                dynamic: true,
                options: [],
            },
            {
                type: "select-or",
                title: "HQ Country",
                key: "offices.country.name.keyword",
                id: "offices.country.name.keyword",
                bucket: "organizations",
                dynamic: true,
                options: [],
            },
        ],
    },
    {
        title: "Impact Metrics",
        filters: [
            {
                title: "SDG name",
                type: "select-or",
                key: "sdgs.name.keyword",
                id: "sdgs.name.keyword",
                bucket: "organizations",
                dynamic: true,
                options: [],
            },
            {
                title: "Impact metrics",
                type: "select-or",
                key: "attributes.organization:ResourceTag/Impact",
                id: "attributes.organization:ResourceTag/Impact",
                dynamic: true,
                bucket: "organizations",
                options: [],
            },
        ],
    },
    {
        title: "Funding",
        filters: [
            {
                type: "select-or",
                title: "Investor Name",
                key: "deals.investors.name.keyword",
                id: "deals.investors.name.keyword",
                dynamic: true,
                options: [],
                bucket: "organizations",
            },
            {
                type: "select-or",
                title: "Organisation stage",
                key: "deals.stage.keyword",
                id: "deals.stage.keyword",
                dynamic: true,
                options: [],
                bucket: "organizations",
            },
            {
                title: "Funding received",
                type: "range",
                key: "total_funding",
                id: "total_funding",
            },
            {
                type: "exists",
                title: "Funding information available",
                key: "deals.investors.name",
                id: "deals.investors.name",
            },
            {
                type: "range",
                title: "Funding information not available",
                key: "no_funding",
                name: "no_funding",
                id: "deals_no",
                variant: "checkbox",
            },
            {
                type: "range",
                title: "Single investor",
                key: "single_investor",
                name: "single_investor",
                id: "investors_no",
                variant: "checkbox",
            },
            {
                type: "range",
                title: "Multiple investors",
                key: "multiple_investors",
                name: "multiple_investors",
                id: "investors_no",
                variant: "checkbox",
            },
        ],
    },
];
