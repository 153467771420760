import React from "react";
import {useDataContainerContext} from "../../interface/DataContainer/context/DataContainerContext";
import {DataGridGraphQL} from "../../interface/DataGrid/DataGridGraphQL";
import {FETCH_SECTORS} from "./queries";
import {Link} from "react-router-dom";
import {Persona} from "../../interface/Persona";
import {DotsVerticalIcon} from "@heroicons/react/outline";
import {ActionFilters} from "../../interface/ActionFilters";

export default function ImpactMetricsContainer() {
    const {filtersQuery} = useDataContainerContext();

    return (
        <section className="px-10">
            <div className="py-10">
                <ActionFilters bql={[{id: "name"}]} onlyBQL={true}/>
                <DataGridGraphQL
                    loading={false}
                    query={FETCH_SECTORS}
                    root="impacts"
                    property="data"
                    filters={{
                        "and": [
                            {
                                "expression": {
                                    "field": "sector.is_sub_sector",
                                    "eq": "false"
                                }
                            }
                        ]
                    }}
                    entityName="impacts"
                    headers={[
                        {
                            title: "Impact Name",
                            dataIndex: "name",
                            key: "name",
                            render: (value) => {
                                return (
                                    <Link to={['/organizations/directory/orgs', value.id].join("/")}>
                                        <Persona text={value.name} imageURL={value.profile_image_url}/>
                                    </Link>
                                )
                            }
                        },
                        {
                            title: "Companies",
                            dataIndex: "companies",
                            key: "companies"
                        },
                        {
                            title: "Last Update",
                            dataIndex: "updated_at",
                            key: "updated_at"
                        },
                        {
                            title: "",
                            dataIndex: "actions",
                            key: "actions",
                            render: (value) => {
                                return <DotsVerticalIcon
                                    className="w-5 h-5 text-bs-light-gray hover:text-bs-light-black hover:cursor-pointer"/>
                            }
                        }
                    ]}
                    data={[]}
                />
            </div>
        </section>
    )
}