import React, {useEffect} from "react"
import {useParams} from "react-router";
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import {FormProvider, useForm} from "react-hook-form";
import {get} from "lodash";
import {RHFCheckboxInput} from "../../interface/Form/Input/CheckBoxInput";
import {useFlash} from "../../context/FlashContext";
import {styled} from "../../interface/stiches.config";

const Column = styled("div", {
    flexGrow: 1, display: "flex", flexDirection: "column", gap: "1rem", justifyContent: "space-evenly"
})
const Row = styled("div", {
    width: "100%", display: "flex", gap: "2.5rem"
})
const H2Section = styled("h2", {
    textTransform: "uppercase", fontWeight: 600, fontSize: "90%"
})

const FETCH_IMPACT = gql`
    query queryOrganizationImpact($id: ID!) {
        organization(id: $id) {
            impact {
                female_cofounder
                assistive_tech
                rural_focused
                youth_focused
                female_focused
                fifty_local
                elderly_people
                zero_emissions
                informal_sector
                bop
                employment_creation
            }
        }
    }
`

const UPDATE_IMPACT = gql`
    mutation updateImpact($id: ID!,$input: UpsertImpactInput!) {
        updateImpact(id: $id,input: $input) {
            impact {
                id
            }
        }
    }
`

const CREATE_IMPACT = gql`
    mutation createImpact($input: UpsertImpactInput!) {
        createImpact(input: $input) {
            impact {
                id
            }
        }
    }
`

export const AddNewImpactContainer = () => {
    const {id} = useParams()
    const flash = useFlash()

    const [fetchImpact,{data: fetchImpactData,error: fetchImpactError,loading: fetchImpactLoading}] = useLazyQuery(FETCH_IMPACT,{variables: {id}})

    const [updateImpact,{data: updateImpactData,error: updateImpactError,loading: updateImpactLoading}] = useMutation(UPDATE_IMPACT)
    const [createImpact,{data: createImpactData,error: createImpactError,loading: createImpactLoading}] = useMutation(CREATE_IMPACT)

    const methods = useForm({
        shouldUnregister: true,
        reValidateMode: "onChange",
        values: get(fetchImpactData,"organization.impact",{})
    })

    const onSubmit = (payload: any) => {
        if(id) {
            updateImpact({
                variables: {
                    id: id,
                    input: payload
                }
            }).then((res) => {
                // todo: flash message here
                flash("impact updated successfully","success")
            }).catch( err => flash("failed to update impact","error") )
        } else {
            createImpact({
                variables: {
                    id: id,
                    input: payload
                }
            }).then((res) => {
                flash("impact added successfully","success")
            }).catch( err => flash("failed to add impact","error"))
        }
    }

    useEffect(() => {
        if(id) {
            fetchImpact()
        }
    },[id])

    console.log("data",fetchImpactData)
    if(fetchImpactLoading) return <p>Loading options...</p>
    return (
        <div className="w-full scroll-smooth">
            <div className="px-5">
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit((f) => {
                        onSubmit(f)
                    })}>
                        <div style={{width: "100%", display: "flex", flexDirection: "column", gap: "1.5rem"}}>
                            <Row style={{justifyContent: "center"}}>
                                <Column>
                                    <H2Section>{ id ? "Update" : "Create" }: { id ? get(fetchImpactData, 'deal.type') : "Deal" }</H2Section>
                                </Column>
                                <div>
                                    <input type="submit" className="bg-bs-light-primary px-2.5 py-0.5 rounded-md"/>
                                </div>
                            </Row>
                            <hr />
                            <Row>
                                <Column>
                                    <div style={{display: "flex",gap: "1rem",flexDirection: "column"}}>
                                        <RHFCheckboxInput {...methods.register("female_focused")} label="Female Focused"/>
                                        <RHFCheckboxInput {...methods.register("female_cofounder")} label="Female Co-Founder" />
                                        <RHFCheckboxInput {...methods.register("rural_focused")} label="Rural Focused" />
                                        <RHFCheckboxInput {...methods.register("informal_sector")} label="Informal Sector" />
                                        <RHFCheckboxInput {...methods.register("assistive_tech")} label="Assistive Tech" />
                                        <RHFCheckboxInput {...methods.register("youth_focused")} label="Youth Focused" />
                                        <RHFCheckboxInput {...methods.register("fifty_local")} label="Fifty Local" />
                                        <RHFCheckboxInput {...methods.register("elderly_people")} label="Elderly People"/>
                                        <RHFCheckboxInput {...methods.register("zero_emissions")} label="Zero Emissions" />
                                        <RHFCheckboxInput {...methods.register("bop")} label="BOP" />
                                        <RHFCheckboxInput {...methods.register("employment_creation")} label="Employment Creation" />
                                    </div>
                                </Column>
                            </Row>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    )
}
