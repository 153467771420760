import React, {useState} from "react";
import classNames from "classnames";
import {Button} from "./Button/Button";
import {styled} from "./stiches.config";

export const StyledEditableDropdown = styled('select',{
    width: "100%",
    padding: "12px 20px",
    margin: "8px 0",
    background: "transparent",
    boxSizing: "border-box"
})

type TPillsVariant = "positive" | "negative" | "neutral" | "ghost" | "success" | "default" | "cold";

type TPillsSize = "xs" | "sm" | "md" | "lg" | "xl";

interface IPills {
    text: string;
    value?: any
    property?: string
    options?: any[]
    renderText?: (value: string) => JSX.Element
    variant: TPillsVariant;
    size?: TPillsSize
}

export const Pillola = ({...props}: IPills) => {
    const {variant, text,size,value,options,property} = props;

    const cls = classNames({
        "self-center text-xs rounded-md uppercase": true,
        "px-2 py-1": size === "md" || size === undefined,
        "px-4 py-2": size === "lg",
        "px-1 py-0.5 text-[10px]": size === "sm",
        "text-black bg-bs-light-primary": variant === "positive",
        "text-white bg-red-400": variant === "negative",
        "bg-bs-light-gray-300": variant === "neutral",
        "text-bs-light-black": variant === "ghost",
        "text-white bg-green-400": variant === "success",
        "text-white bg-sky-700": variant === "cold",
        "text-white bg-neutral-900": variant === "default",
    });

    return (
        <div style={{ display: "flex", height: "100%", alignItems: "center"}}>
            <span className={cls}>{text}</span>
        </div>
    )
}

export interface IGroupPillole {
    title: string,
    data: { value: string, variant?: string }[],
    visible?: number
}

export const GroupPillole = ({title, data, visible = 12}: IGroupPillole) => {
    const [state, setState] = useState({
        isExpanded: false,
    });

    const needsExpansion = data && data.length >= 12
    const items = data ? data.length : 0

    return (
        <p className="flex gap-2.5 py-2 flex-wrap">
            <span>{title} ({items})</span>
            {
                data && data.slice(0, state.isExpanded || !needsExpansion ? items + 1 : 12).map((s: any) => {
                    return <Pillola key={s.value} text={s.value} variant={s.variant ?? "positive"}/>
                })
            }
            {
                !state.isExpanded && needsExpansion && (
                    <Button title={`show more`} variant="link" size="xs" onClick={(ev) => {
                        ev.preventDefault()
                        setState({...state, isExpanded: true})
                    }}/>
                )
            }
        </p>
    )
}
