import React from 'react';
import {Route, Routes, useLocation} from "react-router-dom";
import {Navigate} from "react-router"
import LoginContainer from "./containers/LoginContainer";
import {useAuth} from "./context/AuthContext";
import BaseLayout from "./components/layouts/BaseLayout";
import IAMUsersContainer from "./containers/users/IAMUsersContainer";
import OrganizationsContainer from "./containers/companies/OrganizationsContainer";
import IAMUserContainer from "./containers/users/IAMUserContainer";
import OrganizationContainer from "./containers/companies/OrganizationContainer";
import MetadataContainer from "./containers/companies/MetadataContainer";
import HomeDashboardContainer from "./containers/home/HomeDashboardContainer";
import DealsContainer from "./containers/deals/DealsContainer";
import OrganizationsDashboardsContainer from "./containers/companies/OrganizationsDashboardsContainer";
import InitiativesContainer from "./containers/initiatives/InitiativesContainer";
import UserContainer from "./containers/user/UserContainer";
import MediaMentionsOverviewContainer from "./containers/mmentions/MediaMentionsOverviewContainer";
import MediaMentionsListContainer from "./containers/mmentions/MediaMentionsContainer";
import LaundromatOverviewContainer from "./containers/laundromat/LaundromatOverviewContainer";
import PeopleContainer from "./containers/people/PeopleContainers";
import CreateNewOrganizationContainer from "./containers/create/CreateNewOrganizationContainer";
import CreateNewDealContainer from "./containers/create/CreateNewDealContainer";
import {AddNewImpactContainer} from "./containers/create/AddNewImpactContainer";
import {CreateNewPersonContainer} from "./containers/create/CreateNewPersonContainer";
import CrowdsourcingContainer from "./containers/crowdsourcing/CrowdsourcingContainer";
import JobsContainer from "./containers/jobs/JobsContainer";
import {ProcessJobContainer} from "./containers/jobs/ProcessJobContainer";
import {CreateDatapointValue} from "./containers/create/CreateDatapointValue";
import {CreateNewMediaContainer} from "./containers/create/CreateNewMediaContainer";
import { SearchAutomationContainer } from './containers/searchautomation/SearchAutomationContainer';
import {SearchContainer} from "./containers/search/SearchContainer";
import { MultiStepFormContainer } from './containers/create/MultiStepFormContainer';
import {PersonContainer} from "./containers/people/PersonContainer";
import {AddEmployeeContainer} from "./containers/create/AddEmployeeContainer";
import DataExportSyncContainer from "./containers/exports/DataExportSyncContainer";
import ResourcesContainer from "./containers/resources/ResourcesContainer";
import {CreateResourceUploadContainer, UpdateResourceContainer} from "./containers/create/CreateResourceUploadContainer";
import AgBaseReportingContainer from "./containers/reportings/AgBaseReportingContainer";
import {CreateResourceArticleContainer, UpdateResourceArticleContainer} from "./containers/create/CreateResourceArticleContainer";
import {CreateInitiativeContainer, UpdateInitiativeContainer} from "./containers/create/CreateInitiativeFragment";

function WIP(message = "") {
    const location = useLocation();
    return <div className="px-10 py-8"><p>Work in Progress - Path: {location.pathname} </p></div>;
}

function Application() {
    const {session} = useAuth();
    return (
        <>
            {
                session && session.user && session.user.id && session.id && (
                    <BaseLayout>
                        <Routes>
                            <Route path="/logout" exact element={<UserContainer/>}/>
                            <Route path="/" exact element={<HomeDashboardContainer/>}/>

                            <Route path="/datapoints/manage" element={<CreateDatapointValue />} />
                            <Route path="/stepper" element={<MultiStepFormContainer />} />
                            <Route path="/stepper/:id" element={<MultiStepFormContainer />} />

                            <Route path="/laundromat/overview" exact element={<LaundromatOverviewContainer/>}/>

                            <Route path="/crowdsourcing" exact element={<CrowdsourcingContainer />}/>
                            <Route path={"/approval"} exact element={<JobsContainer />} />
                            <Route path={"/approve/jobs/:id"} exact element={<ProcessJobContainer />} />

                            <Route path="/search" exact element={<SearchContainer />} />

                            <Route path="/search-automation" exact element={<WIP/>}/>
                            <Route path="/search-automation/crowd-sourcing" exact element={<WIP/>}/>
                            <Route path="/search-automation/scraping-backlog" exact element={<SearchAutomationContainer/>}/>
                            <Route path="/search-automation/scraping-backlog/process-job/:id" exact element={<ProcessJobContainer />} />
                            {/*<Route path="/search-automation/scraping-backlog/edit-org-job/:id" exact element={<EditOrgJobFragment />}/>*/}
                            {/*<Route path="/search-automation/scraping-backlog/edit-deal-job/:id" exact element={<EditDealJobFragment />}/>*/}
                            <Route path="/search-automation/crawlers" exact element={<WIP/>}/>
                            <Route path="/organizations/overview" exact element={<OrganizationsDashboardsContainer/>}/>
                            <Route path="/organizations/meta" exact element={<MetadataContainer/>}/>
                            <Route path="/organizations/directory" exact element={<OrganizationsContainer/>}/>
                            <Route path="/organizations/directory/orgs/:id" exact element={<OrganizationContainer/>}/>
                            <Route path="/organizations/directory/orgs/:id/edit" exact element={<CreateNewOrganizationContainer/>}/>
                            <Route path="/organizations/directory/impact/:id/edit" exact element={<AddNewImpactContainer/>}/>

                            <Route path="/create/orgs" exact element={<CreateNewOrganizationContainer />}/>
                            <Route path="/create/deals" exact element={<CreateNewDealContainer />}/>
                            <Route path="/create/person" exact element={<CreateNewPersonContainer />}/>
                            <Route path="/create/media" exact element={<CreateNewMediaContainer />} />
                            <Route path="/create/resource" exact element={<CreateResourceUploadContainer />} />
                            <Route path="/create/article" exact element={<CreateResourceArticleContainer />} />
                            <Route path="/create/initiative" exact element={<CreateInitiativeContainer />} />

                            <Route path="/add/employee/:id" exact element={<AddEmployeeContainer />}/>
                            <Route path="/add/employee/:id/edit/:empId" exact element={<AddEmployeeContainer />}/>

                            <Route path="/deals/directory" exact element={<DealsContainer/>}/>
                            <Route path="/deals/directory/deals/:id/edit" exact element={<CreateNewDealContainer />}/>

                            <Route path="/people/directory" exact element={<PeopleContainer/>}/>
                            <Route path="/people/directory/person/:id" exact element={<PersonContainer/>}/>
                            <Route path="/people/directory/person/:id/edit" exact element={<CreateNewPersonContainer/>}/>

                            <Route path="/initiatives" exact element={<InitiativesContainer/>}/>
                            <Route path="/initiatives/events" exact element={<InitiativesContainer/>}/>
                            <Route path="/initiatives/applications" exact element={<WIP/>}/>
                            <Route path="/initiatives/:id/edit" exact element={<UpdateInitiativeContainer />} />

                            <Route path="/media-mentions/overview" exact element={<MediaMentionsOverviewContainer/>}/>
                            <Route path="/media-mentions/directory" exact element={<MediaMentionsListContainer/>}/>

                            <Route path="/exports" exact element={<DataExportSyncContainer />} />

                            <Route path="/contents" exact element={<ResourcesContainer />} />
                            <Route path="/contents/articles/:id" exact element={<ResourcesContainer />} />
                            <Route path="/contents/articles/:id/edit" exact element={<UpdateResourceArticleContainer />} />
                            <Route path="/contents/resources/:id" exact element={<ResourcesContainer />} />
                            <Route path="/contents/resources/:id/edit" exact element={<UpdateResourceContainer />} />

                            <Route path="/settings" exact element={<WIP/>}/>
                            <Route path="/iam/users" exact element={<IAMUsersContainer/>}/>
                            <Route path="/iam/users/:id" exact element={<IAMUserContainer/>}/>
                            <Route path="/login" element={<Navigate to="/" replace/>}/>
                            <Route path="/reports/agbase" element={<AgBaseReportingContainer />} />
                        </Routes>
                    </BaseLayout>
                )
            }
            {
                !session.user && (
                    <Routes>
                        <Route path="/login" exact element={<LoginContainer/>}/>
                        <Route path="*" element={<Navigate to="/login" replace/>}/>
                    </Routes>
                )
            }
        </>
    );
}

export default Application;
