import React from "react"
import {
    DataContainerProvider,
    useDataContainerContext
} from "../../interface/DataContainer/context/DataContainerContext";
import {Link, useNavigate} from "react-router-dom";
import {format} from "date-fns";
import {DataGridGraphQL} from "../../interface/DataGrid/DataGridGraphQL";
import {FETCH_SEARCH} from "../../queries";
import {FiPlus, FiStar} from "react-icons/fi";
import classNames from "classnames";
import {get} from "lodash";

export default function ResourcesContainer() {
    return (
        <div className="w-full px-10 py-8">
            <div className="flex-grow">
                <DataContainerProvider filters={[]}>
                    <ResourcesDirectory/>
                </DataContainerProvider>
            </div>
        </div>
    )
}

const ResourcesDirectory = () => {
    const {filtersQuery} = useDataContainerContext();
    const navigate = useNavigate()
    return (
        <div className="flex flex-col w-full">
            <div className="flex flex-grow items-center gap-12">
                <div className="">
                    <p className="font-semibold">Content Management</p>
                </div>
                <div className="flex justify-end gap-4">
                    <button className="flex gap-1 items-center bg-bs-light-primary text-sm px-2.5 py-1 rounded-md"
                            onClick={(e) => {
                                e.preventDefault()
                                navigate("/create/resource")
                            }}><FiPlus/> New resource
                    </button>
                    <button className="flex gap-1 items-center bg-bs-light-primary text-sm px-2.5 py-1 rounded-md"
                            onClick={(e) => {
                                e.preventDefault()
                                navigate("/create/article")
                            }}><FiPlus/> New article
                    </button>
                </div>
            </div>
            <DataGridGraphQL
                loading={false}
                query={FETCH_SEARCH}
                rowHeight={"auto"}
                root="search"
                property="items"
                filters={filtersQuery}
                sort={[{key: "published_at", dir: "desc"}]}
                variables={{
                    "search": {
                        "bucket": "resources,articles",
                        "sort": [{key: "created_at", dir: "desc"}]
                    }
                }}
                entityName="contents"
                headers={[
                    {
                        title: "Title",
                        dataIndex: "title",
                        key: "title",
                        sortable: true,
                        sortingKey: 'title.keyword',
                        className: "w-full",
                        render: (value) => {
                            return (
                                <Link to={['/contents', value.index, value.id, 'edit'].join("/")}>
                                    <div className="flex gap-4 py-2 flex-nowrap">
                                        <p style={{backgroundImage: `url(${value.image_preview})`}}
                                          className="w-12 h-12 block bg-cover rounded-md shadow-md shrink-0 border border-gray-100 border-solid" />
                                        <p className="flex flex-col gap-1 w-auto">
                                        <span className="uppercase text-xs text-gray-400 flex gap-2 items-center">
                                            <span>{value.index}</span>
                                            {
                                                get(value, 'resource_type', []).map((v: any) => (
                                                    <span key={v.name}
                                                        className={classNames({
                                                            "text-black px-1 py-0.5 rounded-md": true,
                                                            "bg-orange-200": value.index === "resources",
                                                            "bg-blue-200": value.index === "articles",
                                                        })}>{v.name}</span>
                                                ))
                                            }
                                            {
                                                get(value, 'authors', [])?.map((v: any) => (
                                                    <span
                                                        key={v.display_name}
                                                        className={classNames({
                                                            "text-black px-1 py-0.5 rounded-md bg-gray-200": true,
                                                        })}>{v.display_name}</span>
                                                ))
                                            }
                                        </span>
                                            <span className="line-clamp-1 leading-2 max-w-[40rem] text-ellipsis">{value.title}</span>
                                        </p>
                                    </div>
                                </Link>
                            )
                        }
                    },
                    {
                        title: "Visibility",
                        dataIndex: "is_premium",
                        key: "is_premium",
                        className: "text-sm justify-center",
                        render: (value) => {
                            return value?.is_premium
                                ? <span className="bg-bs-light-primary flex gap-1 rounded-md py-0.5 items-center px-1"><FiStar /> Premium</span>
                                : "Free"
                        }
                    },
                    {
                        title: "Views",
                        dataIndex: "views",
                        key: "views",
                        className: "justify-center",
                        sortingKey: 'views',
                        render: (value) => {
                            return <span className="text-center">0</span>
                        }
                    },
                    {
                        title: "Published At",
                        dataIndex: "published_at",
                        className: "text-center justify-center text-sm",
                        key: "published_at",
                        sortingKey: 'published_at',
                        render: (value) => {
                            return value?.published_at && value?.status === 1
                                ? <span
                                    className="bg-green-200 px-2 py-0.5 text-sm rounded-md">{format(new Date(value?.published_at), 'P')}</span>
                                : (
                                    <span className="flex flex-col gap-1 justify-center">
                                        {
                                            value?.published_at === undefined && (
                                                <span
                                                    className="bg-gray-600 text-white uppercase px-2 py-0.5 text-sm rounded-md">
                                                    Draft
                                                </span>
                                            )
                                        }
                                        {
                                            value?.published_at && (
                                                <>
                                                     <span
                                                         className="bg-blue-800 text-white uppercase px-2 py-0.5 text-sm rounded-md">
                                                        soon
                                                    </span>
                                                    {format(new Date(value?.published_at), 'P')}
                                                </>
                                            )
                                        }
                                    </span>
                                )
                        }
                    },
                    {
                        title: "Last Update",
                        dataIndex: "updated_at",
                        key: "updated_at",
                        sortingKey: "updated_at",
                        className: "text-sm",
                        render: (value) => {
                            return format(new Date(value?.updated_at), 'Pp')
                        }
                    },
                    {
                        title: "Created At",
                        dataIndex: "created_at",
                        key: "created_at",
                        sortingKey: "created_at",
                        className: "text-sm",
                        render: (value) => {
                            return format(new Date(value?.created_at), 'Pp')
                        }
                    }
                ]}
                data={[]}
                rowsPerPage={100}
            />
        </div>
    )
}