import React from "react";
import classNames from "classnames";
import _ from "lodash";
import {isURL, isValidUrl} from "../utils/strings";

const socialsType = ["website", "linkedin", "youtube", "instagram", "twitter", "angellist", "facebook", "crunchbase"] as const;

type TSocialDirection = "horizontal" | "list";
type TSocialType = typeof socialsType[number];
type TSocialSize = "sm" | "md" | "xl";

interface ISocials {
    type: TSocialDirection,
    allow?: TSocialType[],
    size?: TSocialSize,
    hasLabel?: boolean,
    allowAll?: boolean,
    socials: { [key: string]: string },
    hideMissing?: boolean
}

interface ISocialLine {
    social: TSocialType,
    value: string,
    hasLabel: boolean,
    size?: TSocialSize
}

export function Socials({...props}: ISocials) {
    const {type, allow = [], socials = {}, allowAll, hasLabel = false, size = "md", hideMissing = true} = props
    const ss = allowAll || _.isEmpty(allow) ? socialsType : allow;
    const list = ss.map((id) => {
        return <SocialLine key={id} social={id} value={socials[id]} hasLabel={hasLabel} size={size}/>
    });

    const cls = classNames({
        "flex flex-grow": true,
        "flex-col gap-y-2.5": type === "list",
        "flex-row gap-2.5": type === "horizontal"
    },)
    return (
        <div className={cls}>
            {list}
        </div>
    )
}

export function SocialLine({social, value, hasLabel, size = "md"}: ISocialLine) {
    const clsContainer = classNames({
        "flex gap-x-1.5 items-center": true,
        "text-bs-light-gray-300": !value || value.length < 1,
        "text-bs-light-black cursor-pointer": value && value.length > 0,
    })

    const url = mountSocialURL(social,value)
    const [link, URL] = isValidUrl(url);

    return (
        <span className={clsContainer} title={value} onClick={() => {
            if (link) {
                // @ts-ignore
                window.open(mountSocialURL(social, value), '_blank').focus()
            }
        }}>
            <Icon type={social} size={size}/>
            <span className="text-sm font-semibold"
                  style={{display: hasLabel ? "inline-block" : "none"}}
            >
                {
                    value && value.length > 0 && (link && URL ? URL.hostname : value)
                }
            </span>
        </span>
    )
}

function mountSocialURL(type: string, value: string): string {
    switch (type) {
        case 'facebook':
            return ['https://facebook.com', value].join("/")
        case 'instagram':
            return ['https://instagram.com', value].join("/")
        case 'twitter':
            return ['https://twitter.com', value].join("/")
        case 'linkedin':
			if(value?.startsWith("https://linkedin.com")) {
				return value
			}
            return ['https://linkedin.com/company',value].join("/")
        default:
            return value
    }
}

const Icon = ({type, size = "md"}: { type: TSocialType, size?: TSocialSize }) => {
    const cls = classNames({
        "h-5 w-5": size === "sm",
        "h-6 w-6": size === "md",
        "h-10 w-10": size === "xl"
    });
    switch (type) {
        case 'instagram':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                     className={cls}>
                    <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                    <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                    <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                </svg>
            )
        case 'facebook':
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                     className={cls}>
                    <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                </svg>
            )
        case "youtube":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                     className={cls}>
                    <path
                        d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z"></path>
                    <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02"></polygon>
                </svg>
            )
        case "linkedin":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                     className={cls}>
                    <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                    <rect x="2" y="9" width="4" height="12"></rect>
                    <circle cx="4" cy="4" r="2"></circle>
                </svg>
            )
        case "twitter":
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                     className={cls}>
                    <path
                        d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                </svg>
            )
        default:
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                     className={cls}>
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="2" y1="12" x2="22" y2="12"></line>
                    <path
                        d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
                </svg>
            )
    }
}
