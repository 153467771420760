import React, {createContext, useContext, useEffect, useRef, useState} from "react";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/outline";
import {Button, TButton} from "./Button";
import {NavLink} from "react-router-dom";
import {useOnClickOutside} from "usehooks-ts";

export interface TDropDownButton extends TButton {
    children: React.ReactNode;
    icon?: JSX.Element;
}

export interface TDropDownItem {
    to: string;
    title: string;
    icon?: JSX.Element
}

export interface TDropDownItemButton {
    title: string;
    icon?: JSX.Element
    onClick: () => void
}

export interface TDropDownContext {
    ref: any;
    state: any;
    setButtonBoundriesRect: (rectButton: object) => void;
    setMenuBoundriesRect: (rectButton: object) => void;
}

const DropDownContext = createContext<TDropDownContext>({} as TDropDownContext);

export function DropDownButtonContext({children}: { children: React.ReactNode }) {
    const ref = useRef(null);
    const [state, setState] = useState({
        rectButton: {},
        rectMenu: {}
    });

    const setButtonBoundriesRect = (rectButton: object) => {
        setState({...state, rectButton})
    }
    const setMenuBoundriesRect = (rectMenu: object) => {
        setState({...state, rectMenu});
    }

    return (
        <DropDownContext.Provider
            value={{
                ref,
                state,
                setButtonBoundriesRect,
                setMenuBoundriesRect
            } as TDropDownContext}>
            {children}
        </DropDownContext.Provider>
    )
}

export const DropDownButtonContainer = ({children}: { children: React.ReactNode }) => {
    return (
        <div className="relative">
            {children}
        </div>
    )
}

export const DropDownMenuContainer = ({children, isOpen}: { children: React.ReactNode, isOpen: boolean }) => {
    const {ref, setMenuBoundriesRect, state} = useContext(DropDownContext);
    useEffect(() => {
        if (ref.current.getBoundingClientRect().width) {
            setMenuBoundriesRect(ref.current.getBoundingClientRect());
        }
    }, [ref.current, isOpen]);
    const cls = [
        "absolute border border-b-bs-light-border-light bg-white shadow-2xl z-10 px-4 py-2 rounded-md left-0 top-10",
        isOpen ? "block" : "hidden"
    ].join(" ")
    return (
        <div
            ref={ref}
            className={cls}
            style={{
                left: ["-", state.rectMenu?.width - state.rectButton?.width, "px"].join(""),
                top: [state.rectButton?.height + 5, "px"].join("")
            }}
        >
            {children}
        </div>
    )
}

export function DropDownDivider() {
    return (
        <hr className="mx-2"/>
    )
}

export function DropDownItemButton({onClick, title, icon}: TDropDownItemButton): JSX.Element {
    return (
        <p className="hover:underline cursor-pointer text-bs-light-black text-sm w-full rounded-md my-2 px-2 py-1 self-center whitespace-nowrap"
           onClick={onClick}>
            <span className="w-full flex block  items-center">
                {icon && (<span className="pr-2 self-center">{icon}</span>)}
                <span>{title}</span>
            </span>
        </p>
    )
}

export function DropDownItem({to, title, icon}: TDropDownItem): JSX.Element {
    return (
        <p className="hover:underline text-bs-light-black text-sm w-full rounded-md my-2 px-2 py-1 self-center whitespace-nowrap">
            <NavLink className="w-full flex block  items-center" to={to}>
                {icon && (<span className="pr-2 self-center">{icon}</span>)}
                <span>{title}</span>
            </NavLink>
        </p>
    )
}

export function DropDownButton({...props}: TDropDownButton) {
    return (
        <DropDownButtonContext>
            <DropDownButtonInner {...props} />
        </DropDownButtonContext>
    )
}

export function DropDownButtonInner({title, icon, children, size, variant, uppercase}: TDropDownButton) {
    const [state, setState] = useState({isOpen: false});
    const ref = useRef(null);
    const menuRef = useRef(null);
    const {setButtonBoundriesRect} = useContext(DropDownContext);
    useOnClickOutside(menuRef, () => setState({...state, isOpen: false}));
    useEffect(() => {
        if (ref != null) {
            // @ts-ignore
            setButtonBoundriesRect(ref.current.getBoundingClientRect());
        }
    }, [ref]);
    return (
        <DropDownButtonContainer>
            <div
                className="inline-block"
                ref={ref}
            >
                <Button
                    size={size}
                    uppercase={uppercase}
                    variant={variant}
                    title={title}
                    iconPrefix={icon}
                    iconSuffix={state.isOpen ? <ChevronUpIcon className="h-4 w-4"/> :
                        <ChevronDownIcon className="h-4 w-4"/>}
                    onClick={() => {
                        setState({
                            ...state,
                            isOpen: !state.isOpen
                        });
                    }}
                />
            </div>
            <DropDownMenuContainer isOpen={state.isOpen}>
                <div ref={menuRef}>
                    {children}
                </div>
            </DropDownMenuContainer>
        </DropDownButtonContainer>
    )
}