import React, {useEffect, useMemo, useRef, useState} from "react"
import {Controller, FormProvider, useForm} from "react-hook-form";
import {CheckboxInput, DateInput, TextInput} from "../../interface/Form/Input/Inputs";
import {MultiSelectComboBox} from "../../interface/Form/Select/Select";
import {forEach, get, has} from "lodash";
import {InputImage, InputPDF} from "../../interface/Form/Input";
import {gql, useMutation, useQuery} from "@apollo/client";
import {WYSIWYGAreaEditorInput} from "../../interface/Form/WYSIWYGAreaEditorInput";
import {useNavigate, useParams} from "react-router";
import {RESOURCE_UPLOAD_CREATE, RESOURCE_UPLOAD_GET_BY_ID, RESOURCE_UPLOAD_UPDATE} from "../../queries";
import slugify from "slugify";
import {FiAlertTriangle, FiPlus} from "react-icons/fi";
import {useFlash} from "../../context/FlashContext";
import {formatISO, isValid} from "date-fns";
import {createPortal} from "react-dom";
import {CreateResourceAuthorContainerWidget} from "./CreateResourceAuthorContainer";
import {CreateResourceTagsWidget} from "./CreateResourceTagsContainer";

const FETCH_DROPDOWN_OPTIONS_FORM = gql`
    query fetchDropDownOptions {
        sectors(limit: 10000) {
            data {
                id
                value: name
            }
        }
        getResourceTypes {
            value: name
            id
        }
        getResourceAuthors {
            value: display_name
            id
        }
        agbase_categories(limit: 10000) {
            data {
                id
                value: name
            }
        }
        agbase_visibility(limit: 10000) {
            data {
                id
                value: name
            }
        }
        agbase_valuechains(limit: 10000) {
            data {
                id
                value: name
            }
        }
        business_models(limit: 10000) {
            data {
                id
                value: name
            }
        }
        sub_sectors(limit: 10000) {
            data {
                id
                value: name
            }
        }
        countries(limit: 1000) {
            data {
                id
                value: name
            }
        }
        cities(limit: 1000) {
            data {
                id
                value: name
            }
        }
        sdgs(limit: 1000) {
            data {
                id
                value: name
            }
        }
        technologies(limit: 1000) {
            data {
                id
                value: name
            }
        }
        getResourceTags {
            id
            value: name
        }
    }
`

const ResourceContainerForm = ({resource = {}, onSubmit, onSubmitLoading = false}: any) => {
    const {data: opts, loading: optsLoading, refetch} = useQuery(FETCH_DROPDOWN_OPTIONS_FORM)
    const [isEditingSlug, setIsEditingSlug] = useState<boolean>(false)
    const [isAdding, setIsAdding] = useState<boolean>(false)
    const [isAddingTags, setIsAddingTags] = useState<boolean>(false)
    const methods = useForm({
        defaultValues: useMemo(() => {
            return {
                image_preview: "",
                ...resource
            };
        }, [resource])
    })
    const containerRef = useRef(null)
    useEffect(() => {
        if (has(resource, "id")) {
            methods.reset(resource);
        }
    }, [resource]);

    React.useEffect(() => {
        const subscription = methods.watch((value, {name, type}) => {
            if (name === "title" && type === "change" && get(value, 'id', undefined) === undefined) {
                methods.setValue("slug", slugify(methods.watch("title") ?? "", {
                    trim: true, lower: true, strict: true
                }))
            }
        });
        return () => subscription.unsubscribe();
    }, [methods.watch]);

    const slug = methods.watch("slug")
    const status = methods.watch("status")
    const errors = get(methods, 'formState.errors')
    console.log(errors)
    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                {
                   errors && (
                       Object.keys(errors).map((k) => {
                           return <p
                               className="bg-red-100 px-2 py-0.5 flex gap-2 items-center my-1 text-sm text-red-800 rounded-md border-solid border border-red-500">
                               <FiAlertTriangle /> {get(errors, `${k}.message`, "")}
                           </p>
                       })
                    )
                }
                <div className="flex items-center gap-4 flex-grow pb-4">
                    <div className="w-2/3">
                        {
                            resource.id && (
                                <p className="grow uppercase font-medium inline-flex flex-col">
                                    <span>{resource.title}</span>
                                </p>
                            )
                        }
                        {
                            !resource?.id && (
                                <p className="grow uppercase font-medium text-lg">Create a new resource</p>
                            )
                        }
                    </div>
                    <div className="flex justify-end w-1/3 gap-8">
                        {/*{*/}
                        {/*    !resource.id && (*/}
                        {/*        <button*/}
                        {/*            type="submit"*/}
                        {/*            disabled={onSubmitLoading}*/}
                        {/*            className="rounded-md text-bs-light-black border border-bs-light-gray font-medium px-4 py-0.5">*/}
                        {/*            Save in draft*/}
                        {/*        </button>*/}
                        {/*    )*/}
                        {/*}*/}
                        <button
                            type="submit"
                            disabled={onSubmitLoading}
                            className="rounded-md bg-bs-light-primary border border-bs-light-border-primary px-4 py-0.5">
                            {resource.id ? "Update" : "Publish"}
                        </button>
                    </div>
                </div>
                <div className="flex gap-12">
                    <div className="flex gap-4 flex-col py-4 grow w-2/3">
                        <TextInput
                            placeholder={"Enter the title of the resource"}
                            type={"title"}
                            register={methods.register}
                            required={true}
                            name={"title"}
                            label={""}/>
                        {
                            !isEditingSlug && (
                                <p>
                                    <span className="text-sm px-1 rounded-md">URL</span> <span
                                    className="text-gray-500">ex: https://briterbridges.com/</span>
                                    <span className="font-medium">{slug}</span>
                                    <button type="button"
                                            className="mx-4 px-2 rounded-md py-0.5 text-xs bg-bs-light-primary"
                                            onClick={(ev) => {
                                                ev.preventDefault()
                                                setIsEditingSlug(true)
                                            }}
                                    >edit
                                    </button>
                                    <button type="button"
                                            className="mx-4 px-2 rounded-md py-0.5 text-xs bg-bs-light-black text-white"
                                            onClick={(ev) => {
                                                if (confirm("Are you sure you want to override the URL?")) {
                                                    methods.setValue("slug", slugify(methods.watch("title") ?? "", {
                                                        trim: true, lower: true, strict: true
                                                    }))
                                                }
                                            }}
                                    >regenerate
                                    </button>
                                </p>
                            )
                        }
                        <TextInput
                            type={"hidden"}
                            register={methods.register}
                            name={"status"}
                        />
                        <TextInput
                            placeholder={"Enter the title of the resource"}
                            type={isEditingSlug ? "text" : "hidden"}
                            register={methods.register}
                            name={"slug"}
                            required={true}
                            label={"URL Slug"}/>
                        <div className="flex flex-grow items-end gap-4 relative" ref={containerRef}>
                            <div className="flex-grow">
                                <MultiSelectComboBox
                                    {...methods.register("authors")}
                                    name={"authors"}
                                    label={"Author(s)"} control={methods.control}
                                    options={get(opts, "getResourceAuthors", [])}
                                />
                            </div>
                            <button
                                className="py-3 bg-bs-light-primary border border-bs-light-border-primary font-semibold border-solid px-4 flex gap-2 items-center rounded-md"
                                type="button"
                                onClick={(ev) => {
                                    ev.preventDefault()
                                    setIsAdding(true)
                                }}
                            >
                                <FiPlus/>
                            </button>
                            {
                                isAdding && (
                                    //@ts-ignore
                                    <Portal>
                                        <CreateResourceAuthorContainerWidget
                                            onSuccess={() => {
                                                setIsAdding(false)
                                                refetch()
                                            }}
                                            onCancel={() => setIsAdding(false)}
                                        />
                                    </Portal>
                                )
                            }
                        </div>
                        <div className="flex flex-grow items-end gap-4 relative">
                            <div className="flex-grow">
                                <MultiSelectComboBox
                                    {...methods.register("tags")}
                                    name={"tags"}
                                    label={"Tags"} control={methods.control}
                                    options={get(opts, "getResourceTags", [])}/>
                            </div>
                            <button
                                className="py-3 bg-bs-light-primary border border-bs-light-border-primary font-semibold border-solid px-4 flex gap-2 items-center rounded-md"
                                type="button"
                                onClick={(ev) => {
                                    ev.preventDefault()
                                    setIsAddingTags(true)
                                }}
                            >
                                <FiPlus/>
                            </button>
                            {
                                isAddingTags && (
                                    //@ts-ignore
                                    <Portal>
                                        <CreateResourceTagsWidget
                                            onSuccess={() => {
                                                setIsAddingTags(false)
                                                refetch()
                                            }}
                                            onCancel={() => setIsAddingTags(false)}
                                        />
                                    </Portal>
                                )
                            }
                        </div>
                        <div className="flex flex-col gap-1">
                            <label className="pb-1 text-sm text-bs-light-black">
                                Description (SEO friendly)
                            </label>
                            <Controller
                                name="description"
                                control={methods.control}
                                render={({field}) => {
                                    return (
                                        <WYSIWYGAreaEditorInput
                                            value={field.value}
                                            onChange={field.onChange}
                                            classNameContentEditable="border border-solid border-gray-300"
                                        />
                                    );
                                }}
                            />
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                            <MultiSelectComboBox
                                {...methods.register("agbase_category")}
                                name={"agbase_category"}
                                label={"AgBase Category"} control={methods.control}
                                options={get(opts, "agbase_categories.data", [])}/>

                            <MultiSelectComboBox
                                {...methods.register("agbase_value_chain")}
                                name={"agbase_value_chain"}
                                label={"AgBase Value Chain"} control={methods.control}
                                options={get(opts, "agbase_valuechains.data", [])}/>

                            <MultiSelectComboBox
                                {...methods.register("agbase_visibility")}
                                name={"agbase_visibility"}
                                label={"AgBase Visibility"} control={methods.control}
                                options={get(opts, "agbase_visibility.data", [])}/>

                            <MultiSelectComboBox
                                {...methods.register("sectors")} name={"sectors"}
                                label={"Sectors"} control={methods.control}
                                options={get(opts, "sectors.data", [])}/>

                            <MultiSelectComboBox
                                {...methods.register("sub_sectors")} name={"sub_sectors"}
                                label={"Sub Sectors"} control={methods.control}
                                options={get(opts, "sub_sectors.data", [])}/>

                            <MultiSelectComboBox
                                {...methods.register("technologies")} name={"technologies"}
                                label={"Technologies"} control={methods.control}
                                options={get(opts, "technologies.data", [])}/>

                            <MultiSelectComboBox
                                {...methods.register("ops_countries")}
                                name={"ops_countries"}
                                label={"Operating Countries"} control={methods.control}
                                options={get(opts, "countries.data", [])}/>
                            <MultiSelectComboBox
                                {...methods.register("sdgs")}
                                name={"sdgs"}
                                label={"SDGs"} control={methods.control}
                                options={get(opts, "sdgs.data", [])}/>

                        </div>
                    </div>
                    <div className="flex flex-col grow w-1/3 gap-4">
                        {
                            isValid(new Date(methods.watch("published_at"))) && status === 1 && (
                                <>
                                    <div className="flex flex-col gap-1">
                                        <p className="text-sm">Status: <span
                                            className="font-medium bg-green-300 rounded-md px-2 py-0.5">Published</span></p>
                                        <p className="text-sm">Published: <span
                                            className="font-medium">{formatISO(new Date(methods.getValues("published_at")))}</span>
                                        </p>
                                    </div>
                                    <hr/>
                                </>
                            )
                        }
                        {
                            status !== 1 && (
                                <DateInput
                                    type={"date"}
                                    register={methods.register}
                                    name={"published_at"}
                                    label={"Publishing Date"}
                                    methods={methods}
                                />
                            )
                        }
                        <CheckboxInput
                            register={methods.register}
                            label={"Only premium users"}
                            name={"is_premium"}
                        />
                        <CheckboxInput
                            register={methods.register}
                            label={"Spotlight (top of lists)"}
                            name={"is_spotlight"}
                        />
                        <MultiSelectComboBox
                            {...methods.register("resource_type", { required: {value: true, message: "Resource type is mandatory."} })}
                            name={"resource_type"}
                            label={"Resource Type"} control={methods.control}
                            options={get(opts, "getResourceTypes", [])}/>
                        <InputPDF
                            label="Resource (PDF)"
                            preview={false}
                            getValues={methods.getValues}
                            setValue={(v: string) => methods.setValue("path", v)}
                            {...methods.register("path", {
                                required: {value: false, message: "PDF is required."}
                            })}
                            name="path"
                            path="files/reports"
                            type={"file"} required={true}
                        />
                        <InputImage
                            label="Image Preview (JPEG/PNG)"
                            getValues={methods.getValues}
                            path="images/reports"
                            setValue={(v: string) => methods.setValue("image_preview", v)}
                            {...methods.register("image_preview", {
                                required: {value: true, message: "Image preview is mandatory."}
                            })}
                            name="image_preview"
                            type={"file"} required={true}
                        />
                    </div>
                </div>
            </form>
        </FormProvider>
    )
}

export const UpdateResourceContainer = () => {

    const params = useParams()
    const flash = useFlash()
    const {data, loading, error} = useQuery(RESOURCE_UPLOAD_GET_BY_ID, {
        variables: {
            id: params.id
        },
        onError: err => flash(err.message, "error"),
        fetchPolicy: "network-only"
    })
    const [updateByID, {data: dataUpdate, loading: dataLoading, error: dataError}] = useMutation(RESOURCE_UPLOAD_UPDATE)

    const onSubmit = (data: any) => {
        delete data?.id
        delete data?.history
        data.status = 1

        data.published_at = formatISO(new Date(data.published_at))
        const remaps = [
            'resource_type', 'tags', 'authors', 'sectors', 'sub_sectors', 'technologies', 'ops_countries',
            'sdgs', 'agbase_category', 'agbase_visibility', 'agbase_value_chain'
        ]
        forEach(remaps, (remap: any) => {
            if (get(data, remap, []) != null) {
                data[remap] = get(data, remap, []).map((t: any) => t.id)
            }
        })

        updateByID({
            variables: {
                id: params.id,
                resource: data
            }
        })
            .then(r => {
                flash("updated successfully", "success")
            })
            .catch((err) => flash(err.message, "error"))
    }

    if (loading) {
        return (
            <div className="w-full px-10 py-8">
                <div className="flex-grow">
                    <p>Loading...</p>
                </div>
            </div>
        )
    }

    return (
        <div className="w-full px-10 py-8">
            <div className="flex-grow">
                <ResourceContainerForm resource={get(data, 'getResourceUploadByID', {})} onSubmit={onSubmit}/>
            </div>
        </div>
    )
}

export const CreateResourceUploadContainer = () => {

    const [createResourceUpload, {error, loading}] = useMutation(RESOURCE_UPLOAD_CREATE)
    const flash = useFlash()
    const navigate = useNavigate()

    const onSubmit = (data: any) => {
        delete data?.id
        delete data?.history
        data.status = 1

        data.published_at = formatISO(new Date(data.published_at))
        const remaps = [
            'resource_type', 'tags', 'authors', 'sectors', 'sub_sectors', 'technologies', 'ops_countries',
            'sdgs', 'agbase_category', 'agbase_visibility', 'agbase_value_chain'
        ]
        forEach(remaps, (remap: any) => {
            if (get(data, remap, []) != null) {
                data[remap] = get(data, remap, []).map((t: any) => t.id)
            }
        })
        // data.resource_type = get(data, 'resource_type', []).map((t: any) => t.id)
        // data.authors = get(data, 'authors', []).map((t: any) => t.id)
        // data.sectors = get(data, 'sectors', []).map((t: any) => t.id)
        // data.sub_sectors = get(data, 'sub_sectors', []).map((t: any) => t.id)

        createResourceUpload({
            variables: {
                resource: {...data}
            }
        }).then(r => {
            flash(`${data.title} created successfully`, "success")
            setTimeout(() => {
                navigate("/contents")
            }, 500)
        })
    }

    return (
        <div className="w-full px-10 py-8">
            <div className="flex-grow">
                {
                    error && (
                        <p>{error.message}</p>
                    )
                }
                {
                    !loading && (
                        <ResourceContainerForm onSubmit={onSubmit} onSubmitLoading={loading}/>
                    )
                }
            </div>
        </div>
    )
}

export function Portal({children}: any) : React.ReactNode {
    return createPortal(
        <div
            className="fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none bg-opacity-75 bg-white">
            <div
                className="pointer-events-none relative w-auto min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px]">
                <div
                    className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-4 outline-none p-8 shadow-md">
                    {children}
                </div>
            </div>
        </div>,
        document.body
    );
}