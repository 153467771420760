import React, {useState} from "react";
import { HiMiniBuildingOffice, HiPencil, HiPlus, HiOutlineXMark } from "react-icons/hi2"
import {get } from "lodash";
import {useFieldArray, useForm, useFormContext} from "react-hook-form";
import {ComboBoxGQL} from "../Select/ComboBox";
import {FETCH_CITIES, FETCH_COUNTRIES, FETCH_SUBDIVISIONS} from "../../../queries";

interface InputLocationPropsGQL {
    id?: string;
    address?: string
    location?: LocationProps;
    onSave: (data: any) => void
    onCancel: () => void;
    onRemove: (id?: string) => void;
}

export const InputSingleLocation = () => {

}

interface LocationProps {
    country?: { id: string; name: string; }
    city?: { id?: string; name: string }
    address?: string
    sub_division?: { id: string; name: string }
    is_hq_regional?: boolean
    is_hq_primary?: boolean
    is_office_regional?: boolean
}

interface InputLocationGraphQLProps {
    type: "single" | "multiple"
    locations?: LocationProps[]
    label?: string
    name: string
    register?: any;
    onSelect?: (data: any) => void;
    onRemove?: (data: any) => void;
}

const LocationRow = ({...props}: LocationProps) => {
    const {
        country, sub_division,
        city,
        address,
        is_hq_primary,
        is_office_regional,
        is_hq_regional
    } = props
    return (
        <div className="flex items-center gap-2 grow">
            <div className="flex items-center gap-4 grow">
                <p className="flex items-center bg-[#ffdb8080] px-2 py-1.5 rounded-md gap-1">
                    {
                        get(city, 'name', undefined) && (
                            <span>
                                {get(city, 'name', undefined)}{ ", "}
                            </span>
                        )
                    }
                    {
                        get(country, 'name', undefined) && (
                            <span>
                                 {get(country, 'name', undefined)}
                            </span>
                        )
                    }
                </p>
                {
                    address && (
                        <p>{address}</p>
                    )
                }
                {
                    is_office_regional && (
                        <span className="rounded-md bg-yellow-200 px-2 py-1.5 flex items-center justify-center">
                        <HiMiniBuildingOffice className="text-yello-800"/> Office
                    </span>
                    )
                }
                {
                    is_hq_primary && (
                        <span className="rounded-md px-2 py-1.5 bg-red-200 flex items-center justify-center">
                            <HiMiniBuildingOffice className="text-red-800"/> Global HQ
                        </span>
                    )
                }
                {
                    is_hq_regional && (
                        <span className="rounded-md px-2 py-1.5 bg-blue-200 flex items-center justify-center">
                            <HiMiniBuildingOffice className="text-blue-800"/> Regional HQ
                        </span>
                    )
                }
            </div>
        </div>
    )
}

const InputLocationEdit = ({ ...props } : any) => {
    const { location, onSave, onRemove, forceIsEditing } = props
    const [isEditing, setIsEditing] = useState(forceIsEditing)
    return isEditing || get(location, 'country.id', undefined) === undefined
        ? <InputLocationGQL location={location}
                         onCancel={() => setIsEditing(false)}
                         onSave={(data) => {
                             onSave(data)
                             setIsEditing(false)
                         }}
                            onRemove={() => {
                             onRemove()
                             setIsEditing(false)
                         }}
        />
        : (
            <div className="flex items-center gap-2">
                <LocationRow city={location?.city} country={location?.country}
                             address={location?.address} is_hq_primary={location?.is_hq_primary}
                             is_hq_regional={location?.is_hq_regional}
                             is_office_regional={location?.is_office_regional}
                />
                <HiPencil onClick={(ev) => {
                    ev.preventDefault()
                    setIsEditing(true)
                }} className="cursor-pointer hover:bg-gray-100 rounded-md h-6 w-6 p-1"/>
            </div>
        )
}

export const InputLocationGraphQL = ({...props}: InputLocationGraphQLProps) => {
    const { label, type} = props
    const { control } = useFormContext()
    const { fields, update, append, remove } = useFieldArray({
        control,
        name: "offices"
    })

    return (
        <div className="flex flex-col">
            {label !== undefined && (<p className="text-sm pb-2.5">{label}</p>)}
            <div className="flex flex-col gap-2">
            {
                fields?.map((location, index) => {
                    return <InputLocationEdit key={index} location={location} onSave={(data: any) => {
                        const city = get(data, 'city', undefined)
                        const country = get(data, 'country', undefined)
                        const subdiv = get(data, 'subdivision', undefined)

                        update(index, {
                            ...data,
                            city: city ? { id: city.id, name: city.name } : undefined,
                            country: country ? { id: country.id, name: country.name } : undefined,
                        })
                    }} onRemove={() => {
                        remove(index)
                    }}  />
                })
            }
            {
                type === "multiple" && (
                    <div className="pt-2">
                        <button type="button" className="flex bg-bs-light-black text-bs-light-white px-2 py-0.5 rounded-md gap-2 items-center text-sm"
                            onClick={(ev) => {
                                ev.preventDefault()
                                append({})
                            }}
                        >
                            Add location <HiPlus />
                        </button>
                    </div>
                )
            }
            </div>
        </div>
    )
}

export const InputLocationGQL = ({...props}: InputLocationPropsGQL) => {
    const { onCancel, onSave, location, onRemove } = props
    const {
        register,
        handleSubmit,
        setValue,
        getValues
    } = useForm<LocationProps>({
        defaultValues: {
            ...location,
            is_office_regional: get(location, 'is_office_regional', false),
            is_hq_regional: get(location, 'is_hq_regional', false),
            is_hq_primary: get(location, 'is_hq_primary', false)
        }
    })
    const onSubmit = (data: any) => {
        if(get(data, 'country.id', undefined) === undefined) {
            alert("Country is mandatory")
        }
        onSave(data)
    }
    return <form className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
            <label className="text-bs-light-gray-800 text-sm">
                Address (ex. Riverside Drive 45)
            </label>
            <input type="text"
                   className="border border-gray-300 rounded-md py-1.5 px-2 outline-bs-light-border-primary"
                   {...register("address")}
            />
        </div>
        <div className="flex gap-2">
            <div className="flex grow flex-col gap-2">
                <ComboBoxGQL
                    gql={{
                        query: FETCH_COUNTRIES,
                        options: {
                            fetchPolicy: "network-only"
                        }
                    }}
                    onSelect={(data) => {
                        if(data) {
                            setValue("country", {
                                id: get(data, 'key', undefined),
                                name: get(data, 'value', undefined)
                            })
                        } else {
                            setValue("country", undefined)
                        }
                    }}
                    getter={(data: any) => get(data, 'countries.data', [])}
                    //@ts-ignore
                    value={getValues("country") ? { key: getValues("country").id, value: getValues("country").name } : undefined }
                    placeholder="Select..."
                    label="Country (ex. Kenya, Nigeria, etc.)"
                />
            </div>
            <div className="flex grow flex-col gap-2">
                <ComboBoxGQL
                    gql={{
                        query: FETCH_CITIES,
                        options: {
                            fetchPolicy: "network-only"
                        }
                    }}
                    creation={true}
                    getter={(data: any) => get(data, 'cities.data', [])}
                    onSelect={(data) => {
                        setValue("city", {
                            id: get(data, 'key', undefined),
                            name: get(data, 'value', undefined),
                        })
                    }}
                    //@ts-ignore
                    value={getValues("city") ? { key: getValues("city").id, value: getValues("city").name } : undefined }
                    placeholder="Select..."
                    label="City (ex. Nairobi, Abuja, etc.)"
                />
            </div>
            <div className="flex grow flex-col gap-2">
                <ComboBoxGQL
                    gql={{
                        query: FETCH_SUBDIVISIONS,
                        options: {
                            fetchPolicy: "network-only"
                        }
                    }}
                    onSelect={(data) => setValue("sub_division", data)}
                    getter={(data: any) => get(data, 'subdivisions.data', [])
                        .map((s: any) => ({ key: s.id, value: `${s.name} (${s.iso_31662})`}))
                    }
                    placeholder="Select..."
                    label="State/Province (ex. Nairobi, KE-30)"
                />
            </div>
        </div>
        <div className="flex gap-2">
            <div className="flex items-center gap-2">
                <input type="checkbox" {...register("is_office_regional") } />
                <label className="text-bs-light-gray-800 text-sm">
                    Regional Office
                </label>
            </div>
            <div className="flex items-center gap-2">
                <input type="checkbox" {...register("is_hq_regional") } />
                <label className="text-bs-light-gray-800 text-sm">
                    Regional Headquarter
                </label>
            </div>
            <div className="flex items-center gap-2">
                <input type="checkbox" {...register("is_hq_primary")} />
                <label className="text-bs-light-gray-800 text-sm">
                    Global Headquarter
                </label>
            </div>
        </div>
        <div className="flex gap-8">
            <div className="grow flex gap-8">
                <button type="button" onClick={handleSubmit(onSubmit)}
                        className="bg-bs-light-border-primary px-4 py-1 rounded-md">Save</button>
                <button type="button" className="text-gray-500" onClick={onCancel}>Cancel</button>
            </div>
            <button type="button"
                    className="flex gap-2 items-center text-red-800 font-semibold"
                    onClick={() => onRemove("")}
            >Delete <HiOutlineXMark /></button>
        </div>
    </form>
}