import SectorsContainer from "./SectorsContainer";
import React from "react";
import {Tabs} from "../../interface/Tabs/Tabs";
import SubSectorsContainer from "./SubSectorsContainer";
import TechnologiesContainer from "./TechnologiesContainer";
import ImpactMetricsContainer from "./ImpactMetricsContainer";
import SDGContainer from "./SDGContainer";
import GeographiesContainer from "./GeographiesContainer";
import {DataContainerProvider} from "../../interface/DataContainer/context/DataContainerContext";

export default function MetadataContainer() {
    const tabs = {
        "sectors": {
            title: "Sectors",
            key: 'sectors',
            pane: <SectorsContainer/>
        },
        "sub-sectors": {
            title: "Sub Sectors",
            key: 'sub-sectors',
            pane: <SubSectorsContainer/>
        },
        "technologies": {
            title: "Technologies",
            key: 'technologies',
            pane: <TechnologiesContainer/>
        },
        "geographies": {
            title: "Geographies",
            key: 'geographies',
            pane: <GeographiesContainer/>
        },
        "impact": {
            title: 'Impact Metrics',
            key: 'impact',
            pane: <ImpactMetricsContainer/>
        },
        "sdgs": {
            title: 'SDGs',
            key: 'sdgs',
            pane: <SDGContainer/>
        }
    }
    return (
        <DataContainerProvider filters={[
            {
                title: "General",
                filters: [
                    {
                        type: "search",
                        title: "Metadata name",
                        key: "name",
                        id: "name"
                    }
                ]
            }
        ]}>
            <div className="flex-grow flex flex-row">
                <div className="flex-grow">
                    <Tabs
                        index={'sectors'}
                        tabs={tabs}
                    />
                </div>
            </div>
        </DataContainerProvider>
    )
}