import {FilterTitle} from "./fragments/FilterTitle";
import React, {useEffect, useState} from "react";
import {IFilter} from "./FilterFactory";
import _ from "lodash";

interface IFilterBoolean extends IFilter {
}

export default function FilterBoolean({...props}: IFilterBoolean) {
    const {addFilter, removeFilter, title, type, id, defaultValues} = props
    const value = _.get(defaultValues, 'value', false);
    const [checked, setChecked] = useState(value);

    useEffect(() => {
        setChecked(value);
    }, [value]);

    useEffect(() => {
        if (checked) {
            addFilter({type, key: id, value: true})
        } else {
            removeFilter({type, key: id, value: false})
        }
    }, [checked]);

    return (
        <div
            className="text-sm text-bs-light-black flex items-center py-1">
            <input
                type="checkbox"
                onChange={(ev) => {
                    setChecked(ev.target.checked)
                }}
                checked={checked}
                className="accent-black w-5 h-5"
            />

            <FilterTitle title={title} overrideStyle={"px-2 pt-0"}/>
        </div>
    )
}