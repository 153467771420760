export const LastUpdatedFields = () => {
    // TODO: check the last update of the organisation

    // Step 1: Write a query to fetch organizations and sort by last update

    // Step 2: Display organizations within a certain period range

    // Step 3: Edit a single organization

    return (
        <h1>Last Updated Fields</h1>
    )
}
