import React from 'react';
// @ts-ignore
import ReactDOM from 'react-dom/client';

import './styles/briter.css'

import reportWebVitals from './reportWebVitals';
import Application from "./application";

import 'core-js/es/array/includes';
import 'core-js/es/array/fill';
import 'core-js/es/string/includes';
import 'core-js/es/string/trim';
import 'core-js/es/object/values';

import {BrowserRouter} from "react-router-dom";
import {ApolloProvider} from "@apollo/client";
import {client} from "./networking/graphql";
import {AuthProvider} from "./context/AuthContext";
import {PlatformProvider} from "./context/PlatformContext";
import FlashProvider from './context/FlashContext';
import { JobStateProvider } from './containers/jobs/context/JobStateContext';

import {pdfjs} from "react-pdf";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <FlashProvider>
                <ApolloProvider client={client}>
                    <AuthProvider>
                        <PlatformProvider>
                            <JobStateProvider>
                                <Application/>
                            </JobStateProvider>
                        </PlatformProvider>
                    </AuthProvider>
                </ApolloProvider>
            </FlashProvider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
