import React, {useRef, useState} from "react"
import classNames from "classnames";
import {PlusCircleIcon, XIcon} from "@heroicons/react/outline";
import {useOnClickOutside} from "usehooks-ts";
import {gql, useLazyQuery} from "@apollo/client";
import {get} from "lodash";
import {Button} from "../Button/Button";
import {upsert} from "../../utils/arrays";

interface ILocationInput {
    options?: any
    values?: any
    label: string
    required: boolean
    errors?: any
    type: string
    name: string
    onChange: (ev: any) => void
    multiple?: boolean
    loading?: boolean
    maxLocations?: number
}

interface ICityInput {
    id: undefined | string
    name: string
}

const QUERY_LOC_CITY = gql`
query ($filters: FiltersInput) {
    cities(limit: 20, filters: $filters){
        data {
            id
            name
            sub_division {
                id
                name
                type
                iso_31662
                country {
                    id
                    name
                }
            }
        }
    }
}
`
const QUERY_LOC_SUBDIVISION = gql`
query ($filters: FiltersInput) {
    subdivisions(limit: 10, filters: $filters){
        data {
            id
            name
            type
            iso_31662
            country {
                id
                name
            }
        }
    }
}
`

interface IMultipleLocationData {
    city: any
    sub_division: any
    address: string
}

function formatAddress({...props}: IMultipleLocationData) {
    return (
        <span>
            {props.address.length > 0 && `${props.address},`} {get(props,"city.name","")} - {get(props,"city.subdivision.name","")} {get(props,"city.subdivision.country.name","")}
        </span>
    )
}

export const MultipleLocationInput = React.forwardRef(({...props}: ILocationInput, ref) => {
    const {label, onChange, values = [],loading} = props

    const [state, setState] = useState({
        locations: values,
        isAddingMore: false
    })

    if (values === undefined) {
        return <p>Loading...</p>
    }
    return (
        <div className="">
            {label !== undefined && (
                <p className="font-semibold text-sm uppercase pb-2.5">{label}</p>)}
            <div className="flex flex-col gap-2.5 pb-2.5">
                {
                    values.map((loc: IMultipleLocationData, index: number) => {
                        return (
                            <p key={index} className="flex gap-1.5">
                                <button onClick={(ev) => {
                                    ev.preventDefault()
                                    const locs = values
                                    locs.splice(index, 1)
                                    // setState({...state, locations: locs})
                                    onChange(locs)
                                }}><XIcon className="w-3 h-3"/></button>
                                {formatAddress(loc)}
                            </p>
                        )
                    })
                }
            </div>
            {
                (state.isAddingMore || values.length < 1) && (
                    <LocationInput
                        label={''}
                        required={false}
                        type={'text'}
                        name={''}
                        onChange={(data: IMultipleLocationData) => {
                            const locations = upsert(values, data, 'city.id')
                            //@ts-ignore
                            setState({...state, isAddingMore: false})
                            onChange(locations)
                        }}
                    />
                )
            }
            {
                values.length > 0 && !state.isAddingMore && (
                    <Button
                        title="Add more" variant="secondary" size="xs"
                        onClick={(ev) => {
                            ev.preventDefault()
                            setState({...state, isAddingMore: true})
                        }}
                    />
                )
            }
        </div>
    )
})

const defaultLocationState = {
    isCreating: false,
    isSearchingCity: false,
    isSelectingSubDivision: false,
    cityQuery: '',
    cityValue: {},
    subDivisionQuery: '',
    subDivisionValue: {},
    subDivisionOptions: [],
    addressValue: '',
    addressQuery: ''
}

export const LocationInput = React.forwardRef(({...props}: ILocationInput, ref) => {
    const {label, required, errors, type, name, onChange, multiple = true, maxLocations = 3} = props
    const cls = classNames({
        "border-b border-bs-light-gray-200 focus:border-bs-light-gray-800 outline-0 py-1 w-full bg-transparent": true,
        "border-red-500": errors !== undefined
    })

    const refCityDropdown = useRef(null)
    const refSubDivisionDropdown = useRef(null)
    const handleClickOutsideDropdown = () => {
        setState({...state, isSearchingCity: false})
    }
    const handleClickOutsideSubDivision = () => {
        setState({...state, isSelectingSubDivision: false, subDivisionQuery: ''})
    }

    useOnClickOutside(refCityDropdown, handleClickOutsideDropdown)
    useOnClickOutside(refSubDivisionDropdown, handleClickOutsideSubDivision)

    const [searchCity, {data: cityOptions}] = useLazyQuery(QUERY_LOC_CITY, {fetchPolicy: "no-cache"})
    const [searchSubDivision, {data: subDivisionOptions}] = useLazyQuery(QUERY_LOC_SUBDIVISION, {fetchPolicy: "no-cache"})

    const [state, setState] = useState(defaultLocationState);

    const onChangeCity = (ev: any) => {
        if (state.cityQuery.length > 2) {
            setState({
                ...state,
                isSearchingCity: true,
                cityQuery: ev.target.value,
                cityValue: {},
                subDivisionValue: {},
                subDivisionQuery: '',
                isCreating: false
            })
            searchCity({
                variables: {
                    filters: {
                        and: [
                            {
                                expression: {
                                    field: "city.name",
                                    "like": ev.target.value
                                }
                            }

                        ]
                    }
                }
            })
        } else {
            setState({
                ...state,
                cityQuery: ev.target.value,
                isSearchingCity: false,
                isCreating: false,
                cityValue: {},
                subDivisionValue: {},
                subDivisionQuery: ''
            })
        }
    }

    const onChangeSubDivision = (ev: any) => {
        if (state.subDivisionQuery.length > 2) {
            setState({
                ...state, isSelectingSubDivision: true, subDivisionQuery: ev.target.value
            })
            searchSubDivision({
                variables: {
                    filters: {
                        and: [
                            {
                                expression: {
                                    field: "sub_division.name",
                                    "like": ev.target.value
                                }
                            }

                        ]
                    }
                }
            })
        } else {
            setState({
                ...state, isSelectingSubDivision: false, subDivisionQuery: ev.target.value, subDivisionValue: {}
            })
        }
    }

    const cityQuery = get(state.cityValue, 'id', false)
        ? [
            get(state.cityValue, 'name', ''),
            get(state.cityValue, 'sub_division.name', ''),
            get(state.cityValue, 'sub_division.iso_31662', '')
        ].join(", ") : state.cityQuery

    const subDivisionQuery = get(state.subDivisionValue, 'id', false) && !state.isCreating
        ? [
            get(state.subDivisionValue, 'name', ''),
            get(state.subDivisionValue, 'type', ''),
            get(state.subDivisionValue, 'country.name', ''),
            get(state.subDivisionValue, 'iso_31662', '')
        ].join(", ")
        : state.subDivisionQuery

    return (
        <div className="">
            {label && label.length > 0 && (<p className="font-semibold text-sm uppercase pb-2.5">{label}</p>)}
            <div className="border p-2.5 rounded-md bg-gray-50">
                <div className="pb-2.5 relative">
                    <label className="text-bs-light-gray-800 text-sm">
                        City (ex. Nairobi, Abuja, etc.) {required && (<span className="text-red-800">*</span>)}
                    </label>
                    <input type="text"
                           name="city"
                           value={cityQuery}
                           autoComplete="off"
                           onChange={onChangeCity}
                           className={cls}
                           onFocus={() => {
                           }}
                    />
                    {
                        state.isSearchingCity && !get(state.cityValue, 'id', null) && (
                            <div className="absolute bg-gray-100 border border-t-0 z-10 w-full p-2.5 flex flex-col"
                                 ref={refCityDropdown}>
                                {get(cityOptions, 'cities.data', []).map((c: any) => {
                                    return <button
                                        key={c.id}
                                        className="pb-2 text-left"
                                        onClick={(ev) => {
                                            ev.preventDefault()
                                            setState({
                                                ...state, cityValue: c, subDivisionValue: get(c, 'sub_division')
                                            })
                                        }}
                                    >{c.name} ({get(c, 'sub_division.name')}, {get(c, 'sub_division.iso_31662')})</button>
                                })}
                                <button className="flex items-center gap-1.5" type="button"
                                        onClick={() => {
                                            if (state.cityQuery.length > 0) {
                                                setState({
                                                    ...state,
                                                    isCreating: true,
                                                    isSearchingCity: false,
                                                    cityValue: {
                                                        name: state.cityQuery
                                                    }
                                                })
                                            }
                                        }}>
                                    <PlusCircleIcon className="w-5 h-5"/>
                                    <p className="text-bs-light">Create a new city</p>
                                </button>
                            </div>
                        )
                    }
                </div>
                <div className="pb-2.5 relative">
                    <label className="text-bs-light-gray-800 text-sm">
                        Sub-Divisions (ex. Nairobi, KE-30) {required && (<span className="text-red-800">*</span>)}
                    </label>
                    <input
                        type="text"
                        name="subdivision"
                        className={cls}
                        disabled={!state.isCreating}
                        autoComplete="off"
                        value={subDivisionQuery}
                        onChange={onChangeSubDivision}
                    />
                    {
                        state.isSelectingSubDivision && state.isCreating && !get(state.subDivisionValue, 'id', null) && (
                            <div className="absolute bg-gray-100 border border-t-0 z-10 w-full p-2.5 flex flex-col"
                                 ref={refSubDivisionDropdown}>
                                {get(subDivisionOptions, 'subdivisions.data', []).map((c: any) => {
                                    return <button
                                        key={c.id}
                                        className="pb-2 text-left"
                                        onClick={(ev) => {
                                            ev.preventDefault()
                                            setState({
                                                ...state, subDivisionValue: c, isCreating: false
                                            })
                                        }}
                                    >{c.name} - {get(c, 'iso_31662', '')} ({get(c, 'country.name')})</button>
                                })}
                            </div>
                        )
                    }
                </div>
                <div className="pb-2.5">
                    <label className="text-bs-light-gray-800 text-sm">
                        Address {required && (<span className="text-red-800">*</span>)}
                    </label>
                    <textarea className={cls}
                              value={state.addressQuery}
                              onChange={(ev) => setState({...state, addressQuery: ev.target.value})}
                    />
                </div>
                <div className="flex gap-2.5">
                    {
                        get(state.cityValue, 'name', false) && get(state.subDivisionValue, 'name', true) && (
                            <Button
                                size="sm" variant="secondary" title="Save address"
                                onClick={(ev) => {
                                    ev.preventDefault()
                                    onChange({
                                        city: state.cityValue,
                                        sub_division: state.subDivisionValue,
                                        address: state.addressQuery
                                    })
                                    setState(defaultLocationState)
                                }}
                            />
                        )
                    }
                    {
                        (get(state.cityValue, 'name', false) || get(state.subDivisionValue, 'name', false)) && (
                            <Button
                                size="sm" variant="link" title="Reset"
                                onClick={(ev) => {
                                    ev.preventDefault()
                                    setState(defaultLocationState)
                                }}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    )
})
