import {useEffect} from "react"
import { Controller, FormProvider, useForm } from "react-hook-form";
import { DatePicker, Input } from "../../../interface/Form/Input";
import Select from "react-select";
import { get } from "lodash";
import { useQuery,useMutation } from "@apollo/client";
import { FETCH_DEALS_DROPDOWN_OPTIONS_FORM,UPDATE_JOB,PROCESS_JOB,GET_JOB } from "../../../queries/";
import { SelectComboBox } from "../../../interface/Form/Select/Select";
import { useFlash } from "../../../context/FlashContext";
import { useNavigate } from "react-router";

export interface DealJobFormProps {
	job: any;
	onProcessJob: any;
	handleFormState?: any
}

export const DealJobForm = ({ ...props }: DealJobFormProps) => {
	const { job, handleFormState} = props;

	const flash = useFlash()
	const navigate = useNavigate()

	const { data: opts } = useQuery(FETCH_DEALS_DROPDOWN_OPTIONS_FORM)
    const [upsertJob] = useMutation(UPDATE_JOB);
    const [processJob] = useMutation(PROCESS_JOB);

	const onSubmit = (data: any) => {
		// TODO(nate): check that it works even if the fields are not required
		const normalized = {...data,stage: data.stage ? data.stage.id : "",type: data.type ? data.type.id : ""}
		upsertJob({
			variables: {
				id: get(job,"id"),
				req: {
					payload: {
						"msg": {...normalized}
					}
				}
			},
			refetchQueries: [GET_JOB,"getJobByID"],
		}).then((res: any) => {
			console.log("upsertRes",res)
			processJob({
				variables: {id: get(job,"id")}
			}).then((res: any) => {
				console.log("processRes",res)
				flash("job updated and processed successfully","success")
			}).catch((err: any) => {
				console.log("processErr",err)
				flash(`${err.message}`,"error")
				navigate(-1)
			})
		}).catch((err) => flash(`${err.message}`,"error"))
	};

	return (
		<div>
			<DealForm 
				job={job}
				onSubmit={onSubmit} 
				options={opts} 
				handleFormState={handleFormState} />
		</div>
	);
};

export interface DealFormProps {
	job: any
	onSubmit: any
	options: any
	handleFormState?: any
}

export const DealForm = ({ ...props }: DealFormProps) => {

	const {job,onSubmit,handleFormState} = props

	const handleSubmit = (data: any) => {
		onSubmit(data)
	}

	const methods = useForm({
		values: get(job,"payload.msg")
	})

	const deal_stage_options = [
		"Series B", "Series C", "Accelerator", "Angel Round", "Pre-Seed",
		"IPO", "Seed", "Acquired", "ICO", "Series D", "Series E", "Pre-Series A",
		"Unspecified", "Pre-Series B", "Series A", "Pre-Series C", "Incubator",
		"Merger"
	].map((v) => ({
		id: v.replace(/\s+/g, "-").toUpperCase(),
		value: v,
	}))

	const deal_type_options = [
		"Awards", "Bond", "Convertible", "Crowdfunding", "Debt", "Acquisition",
		"Grant", "Hybrid", "In-Kind assistance", "Merger", "Equity", "Non-Equity Assistance",
		"Unspecified", "Other",
	].map((v) => ({
		id: v.replace(/\s+/g, "-").toUpperCase(),
		value: v,
	}))


	useEffect(() => {
		handleFormState && handleFormState(methods.formState.isDirty)
	},[methods.formState.isDirty])

	return (
		<FormProvider {...methods}>
			<form
				onSubmit={methods.handleSubmit(handleSubmit)}
				id="edit-job-form"
			>
				<div className="mb-4 w-full">
					<SelectComboBox {...methods.register("target")}
						name={"target"}
						label={"Target Organization"}
						control={methods.control}
						required={true}
						options={get(props.options, "organizations.data", [])}
					/>
				</div>
				<div className="mb-4 w-full">
					<Input
						errors={methods.formState.errors}
						outlined={true}
						label="Amount Raised"
						type={"number"}
						placeholder={"$500"}
						{...methods.register("amount")}
					/>
				</div>
				<div className="mb-4 w-full flex gap-2">
					<div className="w-full">
						<SelectComboBox {...methods.register("stage")}
							name={"stage"}
							label={"Deal Stage"}
							control={methods.control}
							options={deal_stage_options}
						/>
					</div>
					<div className="w-full">
						<SelectComboBox {...methods.register("type")}
							name={"type"}
							label={"Deal Type"}
							control={methods.control}
							options={deal_type_options}
						/>
					</div>
				</div>

				<div className="mb-4">
					<DatePicker
						type="date"
						outlined={true}
						required={true}
						label="Start Date"
						setValue={methods.setValue}
						getValues={methods.getValues}
						{...methods.register("date")}
					/>
				</div>

				<div className="mb-4 w-full">
					<label
						htmlFor="visitors"
						className="block mb-2 mt-2 text-sm font-medium text-gray-900 border-2 border-rose-500 dark:text-gray-900"
					>
						Investors
					</label>

					<Controller
						render={({
							field: {
								onChange,
								onBlur,
								value,
								name,
								ref,
							},
							fieldState: {
								invalid,
								isTouched,
								isDirty,
								error,
							},
							formState,
						}) => {
							const options = get(
								props.options,
								"organizations.data",
								[],
							);
							return (
								<Select
									styles={{
										option: (
											base,
											state,
										) => ({
											...base,
											backgroundColor:
												state.isSelected
													? "#FFDB80FF"
													: "white",
											color: state.isSelected
												? "black"
												: "black",
										}),
										menu: (
											base,
											props,
										) => ({
											...base,
											width: "max-content",
										}),
										multiValueLabel: (
											base,
											props,
										) => ({
											...base,
											align: "center",
											backgroundColor: "#FFDB80FF",
											color: "black",
											"&:hover": {
												backgroundColor:
													"rgba(252,186,122,0.68)",
												borderColor:
													"red",
											},
										}),
										multiValueRemove: (
											base,
											props,
										) => ({
											...base,
											backgroundColor: "#FFDB80FF",
											color: "black",
										}),
									}}
									getOptionLabel={(
										option: any,
									) => option.value}
									getOptionValue={(option) =>
										option.id
									}
									options={options}
									onBlur={onBlur}
									defaultValue={value}
									onChange={onChange}
									isMulti={true}
									value={value}
									ref={ref}
								/>
							);
						}}
						name="investors"
						control={methods.control}
						rules={{ required: false }}
					/>
				</div>
			</form>
		</FormProvider>
	)
}
