import { useDataContainerContext } from "../../../interface/DataContainer/context/DataContainerContext";
import { useEffect, useState } from "react";
import { IDataGridHeader } from "../../../interface/DataGrid/DataGridTypes";
import { Persona } from "../../../interface/Persona";
import { Pillola } from "../../../interface/Pillole";
import { ActionsFilterMissingDatapoint } from "../../../interface/ActionFilters";
import {Button} from "../../../interface/Button/Button"
import { DataGridGraphQL, IDataGridGraphQLSort } from "../../../interface/DataGrid/DataGridGraphQL";
import { FETCH_COMPANIES } from "../../companies/queries";

export const MissingDatapointsContainer = () => {
    const { addFilter, removeFilter, filtersActive, applyFilters, filtersQuery, clearAllFilters, isDirty } = useDataContainerContext()
    const [activeField, setActiveField] = useState("")

    const [sorting,setSorting] = useState<IDataGridGraphQLSort[]>([{ key: "updated_at", dir: "desc" }])

    const allHeaders: IDataGridHeader[] = [
        {
            title: "Company Name",
            dataIndex: "name",
            sortable: true,
            sortingKey: "name.keyword",
            key: "name",
            type: "text",
            render: (value: any) => {
                return (
                    <div style={{display: "flex",height: "100%", width: "100%",alignItems: "center"}}>
                        <Persona
                            text={value.name}
                            size="xs"
                            value={value}
                            imageURL={value.profile_image_url}
                        />
                    </div>
                )
            }
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            type: "select",
            sortable: true,
            sortingKey: "type.keyword",
            render: (value: any) => {
                return (
                    <>
                        <Pillola text={value.type} variant={"ghost"} value={value} options={[]} property={"type"} />
                    </>
                )
            }
        },
        {
            title: "SubSectors",
            dataIndex: "sub_sectors",
            key: "sub_sectors",
            sortable: true,
            sortingKey: "sub_sectors.name.keyword",
            type: "multi_select",
        },
        {
            title: "Sectors",
            dataIndex: "sectors",
            key: "sectors",
            sortable: true,
            sortingKey: "sectors.name.keyword",
            type: "multi_select",
        },
        {
            title: "Technologies",
            dataIndex: "technologies",
            key: "technologies",
            sortable: true,
            sortingKey: "technologies.name.keyword",
            type: "multi_select",
        },
        {
            title: "Employees",
            dataIndex: "employees",
            key: "employees",
            type: "multi_select",
        }
    ]

    const [columns, setColumns] = useState<any>([...allHeaders])

    useEffect(() => {
        if (filtersActive.length > 0 && filtersActive.length < 2) {
            const f = filtersActive[0]
            console.log("field", f)
            setActiveField(f.key.split('.')[0])
        } else {
            setActiveField("")
        }

    }, [filtersActive])

    useEffect(() => {
        isDirty && applyFilters()
    }, [filtersActive])


    const handleSort = (sort: any) => {
        // todo(nate) : handle all the sort values
        setSorting([sort])
    }

    // todo: add headers here so you can add/remove as you select the missing field
    const handleChangeColumns = (col: any) => {
        let found = false
        for (let i = 0; i < columns.length; i++) {
            if (columns[i].key === col.key) {
                found = true;
                break
            }
        }

        if (found) {
            // remove from the columns
            setColumns(columns.filter((it: IDataGridHeader) => it.key !== col.key))
        } else {
            setColumns([...columns, col])
        }
    }

    return (
        <section className="px-6">
            <div className="flex flex-grow gap-5 items-center self-end justify-center">

                <ActionsFilterMissingDatapoint
                    addFilter={addFilter}
                    removeFilter={removeFilter}
                    bucket={"organizations"}
                    type="boolean"
                    title="title"
                    id="id"
                    active={activeField}
                    defaultValues={filtersActive}
                />

                <Button variant={"none"} title={"Clear All"} size="xs" onClick={clearAllFilters} />

            </div>
            <div>
                <DataGridGraphQL
                    loading={false}
                    query={FETCH_COMPANIES}
                    root="search"
                    property="items"
                    filters={filtersQuery}
                    sort={sorting}
                    sortBy={handleSort}
                    variables={{
                        "search": {
                            "bucket": "organizations",
                            "query": {
                                "bool": [
                                    {
                                        "must_not": { "exists": { "field": "specifier" } }
                                    }
                                ]
                            }
                        },
                        fetchPolicy: 'network-only',
                        // pollInterval: 1000,
                    }}
                    entityName="organizations"
                    headers={columns}
                    allHeaders={allHeaders}
                    onColumnsChange={handleChangeColumns}
                    rowHeight={50}
                    data={[]}
                />
            </div>
        </section>
    )
}
