import React from "react";
import {gql, useMutation} from "@apollo/client";
import {Button} from "../../../interface/Button/Button";
import Message from "../../../interface/Message/Message";

interface IUpgradeDowngradeFreemium {
    user: any;
}

const MUTATION = gql`
    mutation upgradeDowngradeFreemium($user_id: ID!, $flag: Boolean!) {
        upgradeDowngradeFreemium(user_id: $user_id, flag: $flag) {
            user {
                first_name
                other_names
                is_freemium
            }
        }
    }
`

export default function UpgradeDowngradeFreemiumFragment({...props}: IUpgradeDowngradeFreemium) {
    const {user} = props
    const text = user.is_freemium ? <strong>freemium</strong> : <strong>paying</strong>
    const [setFlag, {data, loading, error}] = useMutation(MUTATION, {
        variables: {
            user_id: user.id,
            flag: !user.is_freemium
        },
        refetchQueries: [
            'user'
        ]
    })
    return (
        <>
            <div className="flex items-center gap-10">
                <p>{user.first_name} {user.other_names} is currently {text} user</p>
                <Button size="sm" variant={user.is_freemium ? "primary" : "secondary"}
                        title={user.is_freemium ? "Upgrade" : "Downgrade"}
                        onClick={setFlag}
                />
            </div>
            {
                (loading || error || data) && (
                    <div className="py-5">
                        {
                            loading && (<Message size="md" variant="neutral">Saving the configuration...</Message>)
                        }
                        {
                            error && (
                                <Message autoHide expiration={5000} size={"md"}
                                         variant={"error"}>Error {error.message}</Message>)
                        }
                        {
                            data && (
                                <Message autoHide expiration={5000} size={"md"} variant={"success"}>
                                    User correctly {!user.is_freemium ? "upgraded to paying" : "downgraded to freemium"}
                                </Message>
                            )
                        }
                    </div>
                )
            }

        </>
    )
}