import React, {useState} from "react"
import {Button} from "../../interface/Button/Button";
import {useFlash} from "../../context/FlashContext";
import {gql, useMutation} from "@apollo/client";

export const CREATE_COUNTRY = gql`
    mutation createCountry($input: CountryInput) {
        createCountry(input: $input) {
            id
        }
    }
`

export const CREATE_SECTOR = gql`
    mutation createSector($input: InputSector!) {
        createSector(input: $input) {
            id
            name
        }
    }
`


export const CREATE_SUB_SECTOR = gql`
    mutation createSubSector($input: InputSubSector!) {
        createSubSector(input: $input) {
            id
            name
        }
    }
`


export const CREATE_TECHNOLOGY = gql`
    mutation createTechnology($input: InputTechnology!) {
        createTechnology(input: $input) {
            id
            name
        }
    }
`

export const CreateDatapointValue = () => {
    const [countryName,setCountryName] = useState("")
    const [countryCode,setCountryCode] = useState("")

    const [sectors,setSectors] = useState("")
    const [subSectors,setSubSectors] = useState("")
    const [technologies,setTechnologies] = useState("")

    const [currDatapoint,setCurrDatapoint] = useState("sectors")
    const flash = useFlash()

    const [createCountry,{data: countryData,loading: countryLoading,error: countryError}] = useMutation(CREATE_COUNTRY)
    const [createSector,{data : sectorData,loading : sectorLoading,error: sectorError}] = useMutation(CREATE_SECTOR)
    const [createSubSector,{data : subSectorData,loading : subSectorLoading,error: subSectorError}] = useMutation(CREATE_SUB_SECTOR)
    const [createTechnology,{data : technologyData,loading : technologyLoading,error: technologyError}] = useMutation(CREATE_TECHNOLOGY)

    // todo : write a mutation query to add country to database
    const onSubmit = () => {
        createCountry({variables: {
            input: {
            name: countryName,
                code: countryCode
            }
        }}).then((res) => {
            console.log("res",res)
            flash("country created successfully","success")
        }).catch((err) => {
            console.log("err",err)
            flash(`${err.message}`,"error")
        })
    }

    const handleAddSectors = () => {
        const arr = sectors.split("\n")
        for(let i = 0; i < arr.length; i++) {
            createSector({
                variables: {
                    input: {
                        name: arr[i],
                    }
                }
            }) .then((res) => {
                console.log("res",res)
                flash("sector created successfully","success")
            }) .catch((err) => {
                flash(`${err.message}`,"error")
                console.log("err",err)
            })
        }
    }

    const handleAddSubSectors = (e: any) => {
        const arr = subSectors.split("\n")
        console.log(subSectors)
        for(let i = 0; i < arr.length; i++) {
            createSubSector({
                variables: {
                    input: {
                        name: arr[i],
                    }
                }
            }).then((res) => {
                console.log("res",res)
                flash("subsector added successfully","success")
            }).catch((err) => {
                console.log("err",err)
                flash(`${err.message}`,"error")
            })
        }
    }

    const handleAddTechnologies = () => {
        console.log("adding technologies")
        const arr = technologies.split("\n")
        for(let i = 0; i < arr.length; i++) {
            createTechnology({
                variables: {
                    input: {
                        name: arr[i],
                    }
                }
            }).then((res) => {
                console.log("res",res)
                flash("technology added successfully","success")
            }).catch((err) => {
                console.log("err",err)
                flash(`${err.message}`,"error")
            })
        }
    }

    const handleTypeChange = (e: any) => {
        setCurrDatapoint(e.target.value)
    }

    return (
        <div style={{
            paddingLeft: "4rem",
            paddingRight: "4rem",
            paddingTop: "4rem",
            width: "700px",
            margin: "50px auto",
            position: "relative",
        }}>
            <h1 className={"text-lg py-2 mb-1.5"}>Create Datapoint - ({currDatapoint})</h1>
            <div className={"mb-1.5"}>
                <select onChange={handleTypeChange}>
                    <option selected>Choose a datapoint</option>
                    {/*<option value="country">Country</option>*/}
                    <option value="sectors">Sectors</option>
                    <option value="subsectors">SubSectors</option>
                    <option value="technologies">Technologies</option>
                </select>
            </div>
            {((type : any) => {
                switch (type) {
                    case "country": {
                        return (
                            <div>
                                <label>Country name</label>
                                <input className={"px-4 py-2 rounded-md border-2 border-gray-200 block w-full mb-2 "}
                                       onChange={(e) => setCountryName(e.target.value)} placeholder={"Kenya"}/>

                                <label>Country code</label>
                                <input className={"px-4 py-2 rounded-md border-2 border-gray-200 block w-full mb-2 "}
                                       onChange={(e) => setCountryCode(e.target.value)} placeholder={"KE"}/>
                                <Button title={"Create"} onClick={onSubmit} variant={"primary"}/>
                            </div>

                        )
                    }
                    case "sectors": {
                        return (
                            <div>
                                <textarea onChange={(e) => setSectors(e.target.value)} placeholder={"Add sectors"} rows={5} className="px-4 py-2 rounded-md border-2 border-gray-200 block w-full mb-1.5"></textarea>
                                <Button title={"Create"} onClick={handleAddSectors} variant={"primary"}/>
                            </div>
                        )
                    }
                    case "subsectors": {
                        return (
                            <div>
                                <textarea
                                    onChange={(e) => setSubSectors(e.target.value)}
                                    placeholder={"Add "}
                                    rows={5}
                                    className="px-4 py-2 rounded-md border-2 border-gray-200 block w-full mb-1.5"
                                />
                                <Button title={"Create"} onClick={handleAddSubSectors} variant={"primary"}/>
                            </div>
                        )
                    }

                    case "technologies":
                        return (
                            <div>
                                <textarea onChange={(e) => setTechnologies(e.target.value)} placeholder={"Add technologies"} rows={5} className="px-4 py-2 rounded-md border-2 border-gray-200 block w-full mb-1.5"></textarea>
                                <Button title={"Create"} onClick={handleAddTechnologies} variant={"primary"}/>
                            </div>
                        )
                }
            })(currDatapoint)}
        </div>
    )
}