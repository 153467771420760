import React, {useEffect, useMemo, useState} from "react"
import {Controller, useForm} from "react-hook-form";
import {DatePicker, Input, InputImage, InputSelect, Textarea} from "../../interface/Form/Input";
import {Button} from "../../interface/Button/Button";
import {get} from "lodash";
import {gql, useMutation, useQuery} from "@apollo/client";
import {format, formatRFC3339, isValid} from "date-fns";
import Message from "../../interface/Message/Message";
import {MultipleLocationInput} from "../../interface/Form/InputLocation";
import {
    FETCH_INITIATIVE_DROPDOWN_OPTIONS,
    FETCH_INITIATIVE,
    UPDATE_INITIATIVE,
    CREATE_INITIATIVE,
    DELETE_INITIATIVE
} from "../../queries/"
import {useNavigate, useParams} from "react-router";
import {useFlash} from "../../context/FlashContext";
import {RESOURCE_ARTICLE_BY_ID, RESOURCE_ARTICLE_UPDATE} from "../../queries";

interface TInitiative {
    name?: string
    description?: string
    body?: string
}

interface IModalFragment {
    id?: string;
    onSuccess?: (value?: any) => void;
    onClose?: (value?: any) => void;
}

const mapLocationsToGraphQL = (locations: any) => {
    return locations.map((o: any) => {
        return {
            id: get(o, 'id', null),
            address: get(o, 'address', ''),
            city: {
                id: get(o, 'city.id', null),
                name: get(o, 'city.name', null)
            },
            sub_division: {
                id: get(o, 'sub_division.id', null)
            }
        }
    })
}

export const CreateInitiativeContainer = () => {
    const [state, setState] = useState({
        hasError: false,
        errorMessage: '',
        hasSuccess: false
    });
    const navigate = useNavigate()
    const [create, {data: created, loading: cLoading, error: cError}] = useMutation(CREATE_INITIATIVE, {
        errorPolicy: "all"
    })
    const onSubmit = (data: any) => {
        const test = {
            ...data,
            organizersID: get(data, 'organizers', []).map((o: any) => o.key || o.id),
            sponsorsID: get(data, 'sponsors', []).map((o: any) => o.key || o.id),
            sectorsID: get(data, 'sectors', []).map((o: any) => o.key || o.id),
            organizationsSpeakersID: get(data, 'organizations_speakers', []).map((o: any) => o.key || o.id),
            countriesID: get(data, 'countries_interests', []).map((o: any) => o.key || o.id),
            subregionsID: get(data, 'subregions_interests', []).map((o: any) => o.key || o.id),
            initiativeFormatsID: get(data, 'formats', []).map((o: any) => o.key || o.id),
            locations: mapLocationsToGraphQL(get(data, 'locations', [])),
            start_at: formatRFC3339(new Date(data.start_at)),
            end_at: formatRFC3339(new Date(data.end_at)),
        }
        delete test.id
        //@ts-ignore
        delete test.formats
        delete test.countries_interests
        delete test.subregions_interests
        delete test.organizers
        delete test.sponsors
        delete test.sectors
        delete test.organizations_speakers
        console.log("remapping", data, test);

            create({variables: {input: test}})
                .then((res) => {
                    setState({...state, hasSuccess: true})
                    navigate("/initiatives/events")
                }).catch((err) => {
                setState({...state, hasError: true, errorMessage: err.message})
            })

    };
    return (
        <div className="w-full px-10 py-8">
            <div className="flex-grow">
                {state.hasSuccess && (
                    <Message variant={"success"} size={"md"} autoHide={true} expiration={10000}
                             absolute={true}
                             onClose={() => setState({...state, hasSuccess: false})}>
                        Success
                    </Message>
                )}
                {state.hasError && (
                    <Message variant={"error"} size={"md"} autoHide={true} expiration={10000}
                             absolute={true}
                             onClose={() => setState({...state, hasError: false})}>
                        (error) {typeof state.errorMessage === "string" ? state.errorMessage : JSON.stringify(state.errorMessage)}
                    </Message>
                )}
                <InitiativeFormFragment onSubmit={onSubmit}/>
            </div>
        </div>
    )
}
export const UpdateInitiativeContainer = () => {
    const params = useParams()
    const flash = useFlash()
    const {data, loading, error, refetch} = useQuery(FETCH_INITIATIVE, {
        variables: {
            id: params.id
        },
        onError: err => flash(err.message, "error"),
        fetchPolicy: "network-only"
    })
    const [update] = useMutation(UPDATE_INITIATIVE)
    const [state, setState] = useState({
        hasError: false,
        errorMessage: '',
        hasSuccess: false,
        isSending: false
    });
    const onSubmit = (data: any) => {
        const test = {
            ...data,
            organizersID: get(data, 'organizers', []).map((o: any) => o.key || o.id),
            sponsorsID: get(data, 'sponsors', []).map((o: any) => o.key || o.id),
            sectorsID: get(data, 'sectors', []).map((o: any) => o.key || o.id),
            organizationsSpeakersID: get(data, 'organizations_speakers', []).map((o: any) => o.key || o.id),
            countriesID: get(data, 'countries_interests', []).map((o: any) => o.key || o.id),
            subregionsID: get(data, 'subregions_interests', []).map((o: any) => o.key || o.id),
            initiativeFormatsID: get(data, 'formats', []).map((o: any) => o.key || o.id),
            agbaseVisibiltyID: get(data, 'agbase_visibility', []).map((o: any) => o.key || o.id),
            agbaseCategoriesID: get(data, 'agbase_category', []).map((o: any) => o.key || o.id),
            agbaseValueChainsID: get(data, 'agbase_value_chain', []).map((o: any) => o.key || o.id),
            locations: mapLocationsToGraphQL(get(data, 'locations', [])),
            start_at: formatRFC3339(new Date(data.start_at)),
            end_at: formatRFC3339(new Date(data.end_at)),
        }
        delete test.id
        //@ts-ignore
        delete test.formats
        delete test.countries_interests
        delete test.agbase_visibility
        delete test.agbase_value_chain
        delete test.agbase_category
        delete test.subregions_interests
        delete test.organizers
        delete test.sponsors
        delete test.sectors
        delete test.organizations_speakers
        console.log("remapping", data, test);

        setState({...state, isSending: true})
        update({variables: {id: params.id, input: test}})
            .then((res) => {
                setState({...state, hasSuccess: true})
                refetch()
                flash(`${data.title} successfully updated`, "success")
                setState({...state, isSending: false})
            }).catch((err) => {
            setState({...state, hasError: true, errorMessage: err.message})
            flash(`${data.title} not updated: ${err.message}`, "error")
            setState({...state, isSending: false})
        })
    };
    return (
        <div className="w-full px-10 py-8">
            <div className="flex-grow">
                {
                    !loading && (
                        <InitiativeFormFragment
                            initiative={get(data, 'initiative', {})}
                            onSubmitLoading={state.isSending}
                            onSubmit={onSubmit}
                        />
                    )
                }
            </div>
        </div>
    )
}

export const InitiativeFormFragment = ({initiative = {}, onSubmit, onSubmitLoading = false}: any) => {

    const methods = useForm({
        defaultValues: useMemo(() => {
            return {
                ...initiative
            };
        }, [initiative])
    })

    const [remove, {data: removed, loading: rLoading, error: rError}] = useMutation(DELETE_INITIATIVE, {
        errorPolicy: "all",
        refetchQueries: ['search']
    })

    const {data: opts, loading: optsLoading} = useQuery(FETCH_INITIATIVE_DROPDOWN_OPTIONS)

    const agvisibility = get(opts, 'agbase_visibility.data', []).map((s: any) => ({
        title: s.name,
        value: s.value,
        key: s.id,
        id: s.id
    }))

    const agvaluechains = get(opts, 'agbase_valuechains.data', []).map((s: any) => ({
        title: s.name,
        value: s.value,
        key: s.id,
        id: s.id
    }))

    const agcategories = get(opts, 'agbase_categories.data', []).map((s: any) => ({
        title: s.name,
        value: s.value,
        key: s.id,
        id: s.id
    }))

    const subregions = get(opts, 'sub_regions.data', []).map((s: any) => ({
        title: s.name,
        value: s.value,
        key: s.id,
        id: s.id
    }))

    const sectors = get(opts, 'sectors.data', []).map((s: any) => ({
        title: s.name,
        value: s.value,
        key: s.id,
        id: s.id
    }))
    const organizations = get(opts, 'organizations.data', []).map((s: any) => ({
        title: s.name,
        value: s.value,
        key: s.id,
        id: s.id
    }))
    const formats = get(opts, 'initiativeFormats.data', []).map((s: any) => ({
        title: s.name,
        value: s.value,
        key: s.id,
        id: s.id
    }))

    const countries = get(opts, 'countries.data', []).map((s: any) => ({
        title: s.name,
        value: s.value,
        key: s.id,
        id: s.id
    }))

    // @ts-ignore
    return (
        <div className="flex flex-col w-full h-full">

            <div className="p-5 flex-grow">
                <form onSubmit={methods.handleSubmit(onSubmit)} className="flex gap-16">
                    <div className="flex flex-col w-2/3 gap-5">
                        <Input
                            label="Event Name"
                            type={"text"}
                            {...methods.register("title", {
                                required: {value: true, message: "Field is required"},
                                minLength: {value: 2, message: "At least 2 characters"}
                            })}
                            description="Input the title of the event."
                            required={true} errors={get(methods.formState.errors, 'name')}
                        />
                        <Input
                            label="URL/Link to event registration"
                            type={"text"}
                            {...methods.register("uri", {
                                required: {value: true, message: "Field is required"},
                                minLength: {value: 2, message: "At least 2 characters"}
                            })}
                            description="URL of the event, or registration link."
                            required={true} errors={get(methods.formState.errors, 'uri')}
                        />
                        <Textarea
                            label="Abstract / Excerpt / Brief description (max 1 paragraph)"
                            type={"text"}
                            {...methods.register("description", {
                                required: {value: true, message: "Field is required"},
                                minLength: {value: 2, message: "At least 2 characters"}
                            })}
                            required={true} errors={get(methods.formState.errors, 'description')}/>
                        <Controller
                            control={methods.control}
                            render={({field}) => {
                                return (
                                    <MultipleLocationInput
                                        onChange={(data) => {
                                            console.log("locations", data)
                                            methods.setValue("locations", data)
                                        }}
                                        type={"string"}
                                        name={"locations"}
                                        label={"Locations"}
                                        values={field.value}
                                        required={false}
                                    />
                                )
                            }}
                            name={"locations"}
                        />
                        <div className="flex w-full gap-5">
                            <Controller
                                name="sectors"
                                control={methods.control}
                                render={({field}) => {
                                    return (
                                        <InputSelect
                                            description="Sectors relevant to this event"
                                            label="Sectors of Interest"
                                            loading={optsLoading}
                                            options={{options: sectors}}
                                            type="multiple"
                                            value={field.value}
                                            onChange={(value: any, options: any) => {
                                                methods.setValue("sectors", options)
                                            }}
                                            getValues={methods.getValues}
                                            setValue={methods.setValue}
                                        />
                                    )
                                }}
                            />
                            <Controller
                                name="type"
                                control={methods.control}
                                render={({field}) => {
                                    return (
                                        <InputSelect
                                            getValues={methods.getValues}
                                            setValue={methods.setValue}
                                            value={field.value}
                                            required={true}
                                            options={{
                                                options: [
                                                    {id: 'IN-PERSON', key: 'IN-PERSON', value: 'IN-PERSON'},
                                                    {id: 'ONLINE', key: 'ONLINE', value: 'ONLINE'},
                                                    {id: 'CFA', key: 'CFA', value: 'CFA'},
                                                ]
                                            }}
                                            onChange={(value: any, options: any) => {
                                                methods.setValue("type", value)
                                            }}
                                            name={'type'}
                                            label="Type of Event"
                                            description="Type of event"
                                            type="single"
                                        />
                                    )
                                }}
                            />
                            <Controller
                                name="formats"
                                control={methods.control}
                                render={({field}) => {
                                    return (
                                        <InputSelect
                                            description="Format of the event (ex. conference, roundtable, networking event, etc.)"
                                            label="Format"
                                            loading={optsLoading}
                                            options={{options: formats}}
                                            type="multiple"
                                            value={field.value}
                                            onChange={(value: any, options: any) => {
                                                methods.setValue("formats", options)
                                            }}
                                            getValues={methods.getValues}
                                            setValue={methods.setValue}
                                        />
                                    )
                                }}
                            />
                        </div>
                        <Textarea
                            type={"textarea"}
                            label="Event description / Body of text (no limit)"
                            {...methods.register("body", {
                                required: {value: true, message: "Field is required"},
                                minLength: {value: 2, message: "At least 2 characters"}
                            })}
                            required={true} errors={get(methods.formState.errors, 'body')}/>
                    </div>
                    <div className="flex flex-col w-1/3 gap-5">
                        <InputImage
                            label="Cover/Banner (image, aspect ratio 8/3)"
                            getValues={methods.getValues}
                            setValue={(v: string) => methods.setValue("banner", v)}
                            {...methods.register("banner", {
                                required: {value: false, message: "Banner/cover is required."}
                            })}
                            description="Briter Platform is optimised for 8/3 aspect ratio event covers"
                            type={"image"} required={true} errors={get(methods.formState.errors, "banner")}
                        />
                        <div className="flex gap-5">
                            <Controller
                                name="start_at"
                                control={methods.control}
                                render={({ field }) => {
                                    return (
                                        <input {...field}
                                               value={field.value ? format(new Date(field.value), "yyyy-MM-dd") : undefined}
                                               onChange={(e) => {
                                                   if (!isValid(new Date(e.target.value))) {
                                                       field.onChange(e.target.value)
                                                   } else {
                                                       field.onChange(format(new Date(e.target.value), "yyyy-MM-dd"))
                                                   }
                                               }}
                                               type="date"
                                        />)
                                }}
                            />
                            <Controller
                                name="end_at"
                                control={methods.control}
                                render={({ field }) => {
                                    return (
                                        <input {...field}
                                               value={field.value ? format(new Date(field.value), "yyyy-MM-dd") : undefined}
                                               onChange={(e) => {
                                                   if (!isValid(new Date(e.target.value))) {
                                                       field.onChange(e.target.value)
                                                   } else {
                                                       field.onChange(format(new Date(e.target.value), "yyyy-MM-dd"))
                                                   }
                                               }}
                                               type="date"
                                        />)
                                }}
                            />

                            {/*<Controller*/}
                            {/*    name="end_at"*/}
                            {/*    control={methods.control}*/}
                            {/*    render={({field}: any) => {*/}
                            {/*        return (*/}
                            {/*            <input*/}
                            {/*                type="datetime-local"*/}
                            {/*            />*/}
                            {/*        )*/}
                            {/*    }}*/}
                            {/*/>*/}
                            {/*<Controller*/}
                            {/*    name={"start_at"}*/}
                            {/*    control={control}*/}
                            {/*    render={({field}) => {*/}
                            {/*        return (*/}
                            {/*            <DatePicker*/}
                            {/*                type={"datetime-local"}*/}
                            {/*                label={`Start Date (${format(new Date(), 'O')})`}*/}
                            {/*                description={`Time is set on your time zone`}*/}
                            {/*                value={field.value}*/}
                            {/*                getValues={getValues}*/}
                            {/*                required={true} errors={get(errors, "start_at")}*/}
                            {/*                onChange={(ev: any) => {*/}
                            {/*                    try {*/}
                            {/*                        const value = formatRFC3339(new Date(ev.target.value))*/}
                            {/*                        setValue("start_at", value)*/}
                            {/*                    } catch (e) {*/}
                            {/*                        console.log(e)*/}
                            {/*                    }*/}
                            {/*                }}*/}
                            {/*            />*/}
                            {/*        )*/}
                            {/*    }}*/}
                            {/*/>*/}
                            {/*<Controller*/}
                            {/*    name={"end_at"}*/}
                            {/*    control={control}*/}
                            {/*    render={({field}) => {*/}
                            {/*        return (*/}
                            {/*            <DatePicker*/}
                            {/*                type={"datetime-local"}*/}
                            {/*                label={`Finish Date (${format(new Date(), 'O')})`}*/}
                            {/*                value={field.value}*/}
                            {/*                getValues={getValues}*/}
                            {/*                required={true} errors={get(errors, "end_at")}*/}
                            {/*                onChange={(ev: any) => {*/}
                            {/*                    try {*/}
                            {/*                        const value = formatRFC3339(new Date(ev.target.value))*/}
                            {/*                        setValue("end_at", value)*/}
                            {/*                    } catch (e) {*/}
                            {/*                        console.log(e)*/}
                            {/*                    }*/}
                            {/*                }}*/}
                            {/*            />*/}
                            {/*        )*/}
                            {/*    }}*/}
                            {/*/>*/}
                        </div>
                        <Controller
                            name="organizers"
                            control={methods.control}
                            render={({field}) => {
                                return (
                                    <InputSelect
                                        description="Company organising the event"
                                        label="Organisers"
                                        loading={optsLoading}
                                        options={{options: organizations}}
                                        type="multiple"
                                        value={field.value}
                                        onChange={(value: any, options: any) => {
                                            methods.setValue("organizers", options)
                                        }}
                                        getValues={methods.getValues}
                                        setValue={methods.setValue}
                                    />
                                )
                            }}
                        />
                        <Controller
                            name="sponsors"
                            control={methods.control}
                            render={({field}) => {
                                return (
                                    <InputSelect
                                        description="Companies sponsoring the event"
                                        label="Sponsors"
                                        loading={optsLoading}
                                        options={{options: organizations}}
                                        type="multiple"
                                        value={field.value}
                                        onChange={(value: any, options: any) => {
                                            methods.setValue("sponsors", options)
                                        }}
                                        getValues={methods.getValues}
                                        setValue={methods.setValue}
                                    />
                                )
                            }}
                        />
                        <Controller
                            name="organizations_speakers"
                            control={methods.control}
                            render={({field}) => {
                                return (
                                    <InputSelect
                                        description="Companies speaking at the event"
                                        label="Speakers"
                                        loading={optsLoading}
                                        options={{options: organizations}}
                                        type="multiple"
                                        value={field.value}
                                        onChange={(value: any, options: any) => {
                                            methods.setValue("organizations_speakers", options)
                                        }}
                                        getValues={methods.getValues}
                                        setValue={methods.setValue}
                                    />
                                )
                            }}
                        />
                        <Controller
                            name="subregions_interests"
                            control={methods.control}
                            render={({field}) => {
                                return (
                                    <InputSelect
                                        getValues={methods.getValues}
                                        setValue={methods.setValue}
                                        value={field.value}
                                        onChange={(value: any, options: any) => {
                                            methods.setValue("subregions_interests", options)
                                        }}
                                        loading={optsLoading}
                                        options={{options: subregions}}
                                        name="subregions_interests"
                                        label="Regions of Interest"
                                        description="Regions this event has relevance"
                                        type="multiple"
                                    />
                                )
                            }}
                        />
                        <Controller
                            name="countries_interests"
                            control={methods.control}
                            render={({field}) => {
                                return (
                                    <InputSelect
                                        getValues={methods.getValues}
                                        setValue={methods.setValue}
                                        value={field.value}
                                        onChange={(value: any, options: any) => {
                                            methods.setValue("countries_interests", options)
                                        }}
                                        loading={optsLoading}
                                        options={{options: countries}}
                                        name="countries_interests"
                                        label="Countries of Interest"
                                        description="Countries this event has relevance"
                                        type="multiple"
                                    />
                                )
                            }}
                        />
                        <Controller
                            name="agbase_visibility"
                            control={methods.control}
                            render={({field}) => {
                                return (
                                    <InputSelect
                                        getValues={methods.getValues}
                                        setValue={methods.setValue}
                                        value={field.value}
                                        onChange={(value: any, options: any) => {
                                            methods.setValue("agbase_visibility", options)
                                        }}
                                        loading={optsLoading}
                                        options={{options: agvisibility}}
                                        name="agbase_visibility"
                                        label="AgBase Visibility"
                                        description="AgBase Visibility"
                                        type="multiple"
                                    />
                                )
                            }}
                        />
                        <Controller
                            name="agbase_value_chain"
                            control={methods.control}
                            render={({field}) => {
                                return (
                                    <InputSelect
                                        getValues={methods.getValues}
                                        setValue={methods.setValue}
                                        value={field.value}
                                        onChange={(value: any, options: any) => {
                                            methods.setValue("agbase_value_chain", options)
                                        }}
                                        loading={optsLoading}
                                        options={{options: agvaluechains}}
                                        name="agbase_value_chain"
                                        label="AgBase Value Chain"
                                        description="AgBase Value Chain"
                                        type="multiple"
                                    />
                                )
                            }}
                        />
                        <Controller
                            name="agbase_category"
                            control={methods.control}
                            render={({field}) => {
                                return (
                                    <InputSelect
                                        getValues={methods.getValues}
                                        setValue={methods.setValue}
                                        value={field.value}
                                        onChange={(value: any, options: any) => {
                                            methods.setValue("agbase_category", options)
                                        }}
                                        loading={optsLoading}
                                        options={{options: agcategories}}
                                        name="agbase_category"
                                        label="AgBase Category"
                                        description="AgBase Category"
                                        type="multiple"
                                    />
                                )
                            }}
                        />
                    </div>
                </form>
            </div>
            <div className="p-5 flex border-t-bs-light-gray-100 border-t-2">
                <div className="flex-grow flex">
                    {(
                        <>
                            <Button disabled={onSubmitLoading}
                                    title={onSubmitLoading ? "Saving..." : "Save"}
                                    size="sm"
                                    variant="primary"
                                    onClick={methods.handleSubmit(onSubmit)}
                            />
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
