import React from "react";
import {DataContainerProvider} from "../../interface/DataContainer/context/DataContainerContext";
import {Tabs} from "../../interface/Tabs/Tabs";
import OverviewDashboardPane from "./dashboards/OverviewDashboardPane";
import {filters} from "./filters";

export default function OrganizationsDashboardsContainer() {
    const nav = {
        "overview": {
            title: 'Overview',
            key: 'overview',
            pane: <OverviewDashboardPane type="Overview"/>
        },
        "companies": {
            title: 'Companies',
            key: 'companies',
            pane: <OverviewDashboardPane type="Companies"/>
        },
        "investors": {
            title: 'Investors',
            key: 'investors',
            pane: <OverviewDashboardPane type="Investors"/>
        },
        "hubs": {
            title: 'Hubs',
            key: 'hubs',
            pane: <OverviewDashboardPane type="Hubs - Accelerators"/>
        },
        "ngos": {
            title: 'NGOs',
            key: 'ngos',
            pane: <OverviewDashboardPane type="NGOs - Charity"/>
        },
    }

    return (
        <DataContainerProvider filters={filters}>
            <div className="flex-grow">
                <Tabs
                    index={'overview'}
                    tabs={nav}
                />
            </div>
        </DataContainerProvider>
    )
}