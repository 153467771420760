import React, { useEffect, useRef, useState } from "react";
import _, { get, sortBy } from "lodash";
import { DocumentNode, TypedDocumentNode, useQuery } from "@apollo/client";
import { Button } from "../Button/Button";
import { useOnClickOutside } from "usehooks-ts";
import { DataGridPagination } from "../DataGrid/DataGridPagination";

interface IDataTable {
    headers: any;
    data: any;
    emptyMessage?: string;
    hideIfEmpty?: boolean;
    onChangeRowsPerPage?: (number: any) => void;
    isSelectable?: boolean;
    selectedRows?: any[],
    onSelectRow?: (row: any) => void;
    onSelectAllRows?: () => void;
}

interface IDataTableGraphQL extends IDataTable {
    query: DocumentNode | TypedDocumentNode;
    queryDataResultMapper?: (data: any) => any;
    variables?: any;
    property?: string;
    root: string;
    filters?: any
    sort?: any
    showMaxRows?: number;
    emptyMessage?: string;
    pollInterval?: number;
}

export function DataTableAggsGraphQL({ ...props }: IDataTableGraphQL) {
    const {
        headers,
        variables,
        query,
        root,
        property,
        filters,
        showMaxRows = 10,
        queryDataResultMapper = (data: any) => data
    } = props;
    const [state, setState] = useState({
        isShowingAll: false
    });
    const v = {
        "aggs": {
            ...variables,
            query: {
                filter: filters
            }
        }
    }
    const { data } = useQuery(query, {
        pollInterval: 60000,
        variables: v
    });
    const rows = _.get(data, [root, property].join("."), []).map((c: any) => c);
    const ref = useRef(null)
    useOnClickOutside(ref, function () {
        setState({ ...state, isShowingAll: false })
    })
    return (
        <div>
            <DataTable headers={headers} data={_.slice(rows, 0, showMaxRows)} />
            <div className="flex items-center justify-end">{rows.length > showMaxRows ?
                <Button title={`SHOW ALL ${rows.length} RESULTS`} variant="link" size="xs"
                    onClick={() => setState({ ...state, isShowingAll: true })} /> : ""}</div>
            {state.isShowingAll && (
                <div className="w-full h-full bg-white bg-opacity-80 top-0 left-0 absolute overflow-y-auto">
                    <div
                        className="2xl:container  2xl:mx-auto py-20 px-4 flex justify-center items-center drop-shadow-2xl">
                        <div
                            ref={ref}
                            className="w-full md:w-full bg-white relative flex flex-col justify-center items-center bg-white p-10">
                            <DataTable headers={headers} data={rows} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export function DataTableGraphQL({ ...props }: IDataTableGraphQL) {
    const {
        headers, variables, query, sort, root, property, filters,
        emptyMessage = "No data to display", pollInterval = 0, onChangeRowsPerPage
    } = props;

    // todo : create pagination state
    const [state, setState] = useState({
        offset: 0,
        limit: 5,
        page: 1,
    })


    const v = {
        ...variables,
        // limit: state.limit,
        // offset: state.offset,
        [root]: {
            ...get(variables, root, {}),
            query: {
                filter: filters
            },
            filters: []
        }
    }

    const { data, refetch } = useQuery(query, {
        pollInterval: pollInterval,
        variables: v,
        fetchPolicy: "network-only",
    });
    const rows = _.get(data, [root, property].join("."), []).map((c: any) => c);

    useEffect(() => {
        refetch()
    }, [state.limit, state.offset, state.limit, state.page, filters])

    return (
        <DataTable
            headers={headers}
            data={rows}
            emptyMessage={emptyMessage}
            onChangeRowsPerPage={onChangeRowsPerPage}
        />
    )
}

export function DataTable({ ...props }: IDataTable) {
    const { headers, data, emptyMessage, onChangeRowsPerPage, isSelectable, selectedRows, onSelectAllRows, onSelectRow } = props

    const isRowSelected = (row: any) => selectedRows ? selectedRows.includes(row) : false;
    const isTableSelected = () => selectedRows ? selectedRows.length === data.length && selectedRows.length !== 0 : false;

    return (
        <div>
            <table className="border-collapse table-auto w-full text-sm border my-2.5 align-text-top align-top">
                <thead>
                    <tr>
                        {isSelectable && (
                            <th className="border-b font-semibold p-2.5 bg-bs-light-gray-10 text-left" style={{ width: "20px" }}>
                                <input type="checkbox" onChange={onSelectAllRows} checked={isTableSelected()}/>
                            </th>
                        )}
                        {
                            headers.map((h: any) => {
                                return (
                                    <th key={h.dataIndex} onDoubleClick={() => console.log("test", "sort in reverse")}
                                        className={["border-b font-semibold p-2.5 bg-bs-light-gray-10 text-left", h.thClassName].join(" ")}
                                    >{h.title}</th>
                                )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        data && data.length == 0 && (
                            <tr>
                                <td colSpan={headers.length} style={{
                                    textAlign: "center", padding: "1rem", verticalAlign: "top", whiteSpace: "nowrap",
                                    fontWeight: 500, fontSize: "100%", textTransform: "uppercase"
                                }}>{emptyMessage}</td>
                            </tr>
                        )
                    }
                    {
                        data.map((d: any, i: number) => {
                            return (
                                <tr key={i}>
                                    {isSelectable && (
                                        <td className="text-left p-2.5 align-top whitespace-nowrap" style={{ width: "20px" }}>
                                            <input type="checkbox" checked={isRowSelected(d)} onChange={() => onSelectRow && onSelectRow(d)}/>
                                        </td>
                                    )}
                                    {
                                        headers.map((h: any, index: number) => {
                                            return (
                                                <td key={[index, h.dataIndex].join("-")}
                                                    className="text-left p-2.5 align-top whitespace-nowrap">
                                                    {
                                                        h.render ? h.render(d) : get(d, h.dataIndex)
                                                    }
                                                </td>
                                            )
                                        })
                                    }
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            <div>
                <div style={{ paddingLeft: "1rem", width: "100%" }}>
                    <DataGridPagination
                        current={0}
                        max={0}
                        onChangeRowsPerPage={onChangeRowsPerPage}
                        onClick={(value: any) => {
                            console.log("pagination", value)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}