import React, {useRef, useState} from "react";
import {useBaseLayoutContext} from "../context/BaseLayoutContext";
import {useOnClickOutside} from "usehooks-ts";
import {gql, useQuery} from "@apollo/client";
import {get} from "lodash";
import {createSearchParams, Link, useNavigate} from "react-router-dom";
import {Persona} from "../../../interface/Persona";

export const QUERY_SEARCH_ANYWHERE = gql`
query search($search: SearchRequest!) {
    search(search: $search) {
        items
        total
        took
        cursor
    }
}
`

const SearchAnywhereContainer = ({input, onClick}: { input: string, onClick: any }) => {

    const generateLink = (item: any, index: string) => {
        switch (index) {
            case "organizations":
                return (
                    <p className="py-1.5">
                        <Link to={["organizations/directory/orgs", item.id].join("/")}
                              onClick={() => {
                                  onClick()
                              }}>
                            <Persona text={`${item.name} (${item.index}) (${item.enabled ? "active" : "inactive"})`} imageURL={item.profile_image_url} size="xs"/>
                        </Link>
                    </p>
                )
        }
        return <p>{item.name} ({item.index})</p>
    }

    const {data} = useQuery(QUERY_SEARCH_ANYWHERE, {
        variables: {
            search: {
                bucket: '_all',
                size: 25,
                query: {
                    must: [
                        {
                            simple_query_string : {
                                query: input.replace(" ", ""),
                                fields: ["name"]
                            }
                        }
                        // {query_string: {query: `*${input}*`, default_field: 'name.keyword'}}
                    ]
                }
            }
        }
    })

    return (
        <div>
            {
                get(data, 'search.items', []).map((i: any) => generateLink(i, i.index))
            }
        </div>
    )
}

export default function MenuSearchAnywhere() {
    const {isSearchingAnywhere} = useBaseLayoutContext();
    const divRef = useRef(null);
    const navigate = useNavigate();
    useOnClickOutside(divRef, () => {
        setState({
            ...state, query: ''
        })
    })
    const [state, setState] = useState({
        query: ''
    });
    return (
        <form className="flex-grow max-w-lg" onSubmit={(ev) => {
            ev.preventDefault()
            navigate({
                pathname: '/search',
                search: createSearchParams({ qs: state.query }).toString()
            });
            setState({...state, query: ''})
        }}>
            <div className="relative">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                    <button type="submit"
                            className="p-1 text-gray-400 focus:text-bs-light-black focus:outline-none focus:shadow-outline">
                      <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                           strokeWidth="2"
                           viewBox="0 0 24 24" className="w-4 h-4"><path
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    </button>
                  </span>
                <input type="text"
                       className="rounded-lg outline-0 focus:bg-white placeholder-gray-300 bg-gray-500 border-0 px-4 py-1 w-full pl-12 border-black border"
                       placeholder="Search anything, anywhere"
                       value={state.query}
                       onChange={(ev) => setState({...state, query: ev.target.value})}
                    // ref={input => isSearchingAnywhere && input && input.focus()}
                />
                {
                    isSearchingAnywhere && state.query.length > 0 && (
                        <div className="search w-full bg-white absolute z-20 top-10 shadow-xl overflow-y-scroll max-h-[50vh]"
                             ref={divRef}>
                            <div className="py-5">
                                <div className="px-5 pb-2.5">
                                    <p className="pt-0 text-xl font-light">
                                        <span>Searching:</span>
                                        <span className="font-normal ml-5">{state.query}</span></p>
                                </div>
                                <div className="px-5">
                                    <SearchAnywhereContainer input={state.query} onClick={() => {
                                        setState({...state, query: ''})
                                    }}/>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </form>
    )
}