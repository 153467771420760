import React from "react";
import {Tabs} from "../../interface/Tabs/Tabs";
import {DataGridGraphQL} from "../../interface/DataGrid/DataGridGraphQL";
import {Persona} from "../../interface/Persona";
import {DropDownButton, DropDownItem} from "../../interface/Button/DropDownButton";
import {PencilAltIcon} from "@heroicons/react/outline";
import {gql} from "@apollo/client";
import {
    DataContainerProvider,
    useDataContainerContext
} from "../../interface/DataContainer/context/DataContainerContext";
import {Link} from "react-router-dom";
import UsersActivityStreamPane from "./panes/UsersActivityStreamPane";
import {DataTableGraphQL} from "../../interface/DataTable/DataTable";
import {Pillola} from "../../interface/Pillole";
import {FETCH_SEARCH} from "../../queries";
import {MDSection} from "../../interface/Typography";

const filters = [
    {
        title: "General",
        filters: [
            {
                type: "search",
                title: "Search by First Name",
                key: "first_name",
                id: "first_name"
            },
            {
                type: "search",
                title: "Search by Other Names",
                key: "other_names",
                id: "other_names"
            },
            {
                type: "search",
                title: "Search by email",
                key: "email",
                id: "email"
            },
            {
                type: "negate",
                title: "Has a subscription?",
                key: "is_freemium",
                id: "is_freemium"
            },
            {
                type: "range",
                title: "Last Login",
                key: "last_login",
                id: "last_login"
            }
        ]
    },
    {
        title: "Geography",
        filters: []
    },
    {
        title: "Subscriptions",
        filters: [
            {
                type: "select",
                title: "Subscription Type",
                key: "user.subs",
                id: "user.subs"
            },
        ]
    }
];

const FETCH_USERS = gql`
query search($search: SearchRequest!) {
    search(search: $search) {
        items
        total
        took
        cursor
    }
}
`;

export default function IAMUsersContainer() {
    const nav = {
        "overview": {
            title: 'Overview',
            key: 'overview',
            pane: (
                <DataContainerProvider filters={filters}>
                    <div className="max-w-full flex-grow">
                        <IAMUsersContainerList/>
                    </div>
                </DataContainerProvider>
            )
        },
        // "preferences": {
        //     title: 'User Preferences',
        //     key: 'preferences',
        //     pane: <IAMUserContainerActivityStream/>
        // },
        "activity-stream": {
            title: 'Activity Stream',
            key: 'activity-stream',
            pane: (
                <DataContainerProvider key="activity-stream" filters={[
                    {
                        title: "General",
                        filters: [
                            {
                                type: "select-or",
                                title: "Name of the User",
                                key: "context.subject.name.keyword",
                                id: "context.subject.name.keyword",
                                dynamic: true,
                                options: [],
                                bucket: "activities"
                            },
                            {
                                type: "select-or",
                                title: "Source (select)",
                                key: "source.keyword",
                                id: "source.keyword",
                                dynamic: true,
                                options: [],
                                bucket: "activities"
                            },
                            {
                                type: "range",
                                title: "Time range",
                                key: "time",
                                id: "time",
                            }
                        ]
                    },
                    {
                        title: "Advance",
                        filters: [
                            {
                                type: "search",
                                title: "Source (query)",
                                key: "source.keyword",
                                id: "source.keyword"
                            },
                        ]
                    }
                ]}>
                    <div className="max-w-full flex-grow">
                        <UsersActivityStreamPane />
                    </div>
                </DataContainerProvider>
            )
        },
        // "subscriptions": {
        //     title: 'Subscriptions',
        //     key: 'subscriptions',
        //     pane: <IAMUserContainerActivityStream/>
        // }
    }
    return (
            <div className="max-w-full flex-grow">
                <Tabs
                    index={'overview'}
                    tabs={nav}
                />
            </div>
    )
}

export function IAMUserContainerActivityStream() {
    return (
        <section className="px-10">
            <MDSection
                title={"Latest Activities"}
                subtitle={`Last update ${(new Date()).toISOString()}`}
            >
                <DataTableGraphQL
                    headers={[
                        {dataIndex: "time", title: "Detection Time"},
                        {
                            dataIndex: "subject_name",
                            title: "User",
                            render: (value: any) => {
                                if (value.subject.__typename == "User") {
                                    return (
                                        <>
                                            <p>{[value.subject.first_name, value.subject.other_names].join(" ")}</p>
                                            <p className="text-xs text-bs-light-gray-800">{value.subject.email}</p>
                                        </>
                                    )
                                }
                                return JSON.stringify(value.subject)
                            }
                        },
                        {dataIndex: "source", title: "Source"},
                        {
                            dataIndex: "category",
                            title: "Category",
                            render: (v: any) => <Pillola text={v.category} variant="ghost"/>
                        },
                        {
                            dataIndex: "object.name",
                            title: "Object",
                            render: (v: any) => {
                                return (
                                    <>
                                        {v.object?.name}
                                        <span
                                            className="text-xs text-bs-light-gray-800 pl-1.5">
                                                ({v.object?.id?.substring(0, 8)})
                                            </span>
                                    </>
                                )
                            }
                        },
                        {
                            dataIndex: "action",
                            title: "Action",
                            render: (v: any) => <Pillola text={v.action} variant={"neutral"}/>
                        },
                        {dataIndex: "id", title: "ID", render: (value: any) => value.id.substring(0, 8)},
                    ]}
                    root="search"
                    property="items"
                    query={FETCH_SEARCH}
                    variables={{
                        search: {
                            bucket: "activities",
                            size: 100,
                            sort: [{key: "time", dir: "desc"}]
                        }
                    }}
                    data={[]}
                />
            </MDSection>
        </section>
    )
}

export function IAMUsersContainerList() {
    const {filtersQuery, activeSort, sortBy} = useDataContainerContext();

    return (
        <section className="p-4">
            <div>
                <DataGridGraphQL
                    loading={false}
                    query={FETCH_USERS}
                    variables={{
                        "search": {
                            "bucket": "users",
                            "sort": [{key: "first_name.keyword", dir: "asc"}]
                        }
                    }}
                    root="search"
                    property="items"
                    sortBy={sortBy}
                    sort={activeSort}
                    filters={filtersQuery}
                    entityName="users"
                    headers={[
                        {
                            title: "ID",
                            dataIndex: 'id',
                            key: 'id',
                            render: (value) => {
                                return value.id?.split("-")[0]
                            }
                        },
                        {
                            title: "Name",
                            dataIndex: 'first_name',
                            key: 'first_name',
                            sortable: true,
                            sortingKey: 'first_name.keyword',
                            render: (value) => {
                                const name = [value.first_name, value.other_names].join(" ")
                                return (
                                    <Link to={['/iam/users', value.id].join("/")}>
                                        <Persona size="xs" text={name} imageURL={value.profile_image_url}/>
                                    </Link>
                                )
                            }
                        },
                        {
                            title: "Email",
                            dataIndex: "email",
                            key: 'email',
                            sortable: true,
                            sortingKey: 'email.keyword'
                        },
                        {
                            title: "Last Login",
                            dataIndex: 'last_login',
                            key: 'last_login',
                            sortable: true,
                            sortingKey: 'last_login'
                        },
                        {
                            title: "Organization",
                            dataIndex: "organization",
                            key: 'organization',
                            sortable: true
                        },
                        {
                            title: "Subscription",
                            dataIndex: "subscription",
                            key: "subscription"
                        },
                        {
                            title: "Actions",
                            dataIndex: '',
                            key: 'actions',
                            render: (value) => {
                                return (
                                    <>
                                        <DropDownButton title={"actions"} uppercase size="xs"
                                                        variant="primary">
                                            <DropDownItem to={"/"} title={`Remove ${value.first_name}`}
                                                          icon={<PencilAltIcon className={"w-4 h-4"}/>}/>
                                        </DropDownButton>
                                    </>
                                )
                            }
                        }
                    ]}
                    data={[]}
                    options={{
                        isSelectable: true,
                        isSearchingEverywhere: false
                    }}
                />
            </div>
        </section>
    )
}