import React from "react";
import {MDSection} from "../../../interface/Typography";
import {StatsGraphQL} from "../../../interface/Stats";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import ReactFusioncharts from "react-fusioncharts";
import theme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import map from "fusioncharts/fusioncharts.maps"
import world from "fusioncharts/maps/fusioncharts.world";
import {isEmpty, random, range} from "lodash";
import {FETCH_AGGREGATION_STAT} from "../../../queries";
import {useDataContainerContext} from "../../../interface/DataContainer/context/DataContainerContext";
//@ts-ignore
import numeral from "numeral";
import BarChartGraphQL from "../../../components/fragments/charts/BarChartGraphQL";

ReactFusioncharts.fcRoot(FusionCharts, charts, theme, map, world);

const chartMMIT = {
    type: "msspline",
    width: "100%",
    height: 300,
    dataFormat: 'JSON',
    dataSource: {
        chart: {
            xAxisname: "Months",
            theme: "fusion",
            palettecolors: "4c4636,fcbc43,fcda7e",
            showhovereffect: "1",
            drawcrossline: "1",
        },
        categories: [
            {
                category: [
                    {label: "Jan"}, {label: "Feb"}, {label: "Mar"}, {label: "Apr"},
                    {label: "May"}, {label: "Jun"}, {label: "Jul"}, {label: "Aug"},
                    {label: "Sep"}, {label: "Oct"}, {label: "Nov"}, {label: "Dec"}
                ]
            }
        ],
        dataset: [
            {
                seriesName: "2022",
                data: range(0, 12).map((t) => ({value: random(10000000, 1000000000)}))
            },
            {
                seriesName: "2021",
                data: range(0, 12).map((t) => ({value: random(10000000, 1000000000)}))
            },
            {
                seriesName: "2020",
                data: range(0, 12).map((t) => ({value: random(10000000, 1000000000)}))
            }
        ]
    }
}

const chartMap = {
    type: "world",
    width: "100%",
    height: "300",
    dataFormat: "json",
    dataSource: {
        chart: {
            theme: "fusion",
        },
        colorrange: {
            gradient: "0",
            color: [
                {
                    maxvalue: "4",
                    displayvalue: "2-4",
                    code: "#efde9a"
                },
                {
                    maxvalue: "6",
                    displayvalue: "4-6",
                    code: "#efde50"
                },
                {
                    maxvalue: "8",
                    displayvalue: "6-8",
                    code: "#e5de35"
                },
                {
                    maxvalue: "10",
                    displayvalue: "8-10",
                    code: "#c6de28"
                },
                {
                    maxvalue: "11",
                    displayvalue: "No data available",
                    code: "#ffdeee"
                }
            ]
        },
        data: [
            {
                data: [
                    {
                        id: "021",
                        value: "11",
                        tooltext: "No data available"
                    },
                    {
                        id: "002",
                        value: "11",
                        tooltext: "No data available"
                    },
                ]
            }]
    }
}

const chartHorizontal = {
    type: "scrollbar2d",
    height: 250,
    width: "100%"
}

const chartCountry = {
    ...chartHorizontal,
    dataSource: {
        chart: {
            yaxisname: "# of companies",
            theme: "fusion",
            palettecolors: "4c4636",
        },
        categories: [
            {
                category: [
                    {label: "Nigeria"}, {label: "South Africa"}, {label: "Kenya"}, {label: "Egypt"},
                    {label: "Ghana"}, {label: "Uganda"}, {label: "Tunisia"}, {label: "Ethiopia"}
                ]
            }
        ],
        dataset: [
            {data: range(0, 10).map((m) => ({value: (10 - m + 1) * 2 * random(100, 110)}))}
        ]
    }

}

const chartProduct = {
    ...chartHorizontal,
    dataSource: {
        chart: {
            yaxisname: "# of companies",
            theme: "fusion",
            palettecolors: "4c4636",
        },
        categories: [
            {
                category: [
                    {label: "Payments"}, {label: "EdTech"}, {label: "Online Retailer"}, {label: "JobTech"},
                    {label: "Solar Energy"}, {label: "Online Marketplace"}, {label: "Saas"}, {label: "Delivery"}
                ]
            }
        ],
        dataset: [
            {data: range(0, 10).map((m) => ({value: (10 - m + 1) * 2 * random(40, 45)}))}
        ]
    }
}

const chartSector = {
    dataSource: {
        chart: {
            yaxisname: "# of companies",
            theme: "fusion",
            palettecolors: "4c4636",
        },
        categories: [
            {
                category: [
                    {label: "Education"}, {label: "Health"}, {label: "Logistics"}, {label: "Mobility"}, {label: "Water Access"},
                    {label: "CleanTech"}, {label: "FinTech & DFS"}, {label: "Green Economy"}, {label: "On-Demand Services"}
                ]
            }
        ],
        dataset: [
            {data: range(0, 10).map((m) => ({value: (10 - m + 1) * 2 * random(40, 50)}))}
        ]
    }
}

export default function OverviewDashboardPane({type}: { type: string }) {
    const {filtersQuery} = useDataContainerContext();
    return (
        <div className="p-10">
            {
                !isEmpty(filtersQuery) && (
                    <p className="text-sm">Active filters: {JSON.stringify(filtersQuery)}</p>
                )
            }
            <MDSection title={type}>
                <div className="flex gap-16 w-full">
                    <StatsGraphQL
                        query={FETCH_AGGREGATION_STAT}
                        property={"count_organizations"}
                        title={"Operating companies"}
                        variables={{
                            aggs: {
                                bucket: "organizations",
                                query: {filter: filtersQuery},
                                list: [
                                    {
                                        key: "count_organizations",
                                        type: "value_count",
                                        params: {
                                            field: "name.keyword"
                                        }
                                    }
                                ]
                            }
                        }}
                    />
                    <StatsGraphQL
                        query={FETCH_AGGREGATION_STAT}
                        property={"count_sectors"}
                        title={"Sectors"}
                        variables={{
                            aggs: {
                                bucket: "organizations",
                                query: {filter: filtersQuery},
                                list: [
                                    {
                                        key: "count_sectors",
                                        type: "cardinality",
                                        params: {
                                            field: "sectors.id.keyword"
                                        }
                                    }
                                ]
                            }
                        }}
                    />
                    <StatsGraphQL
                        query={FETCH_AGGREGATION_STAT}
                        property={"count_sectors"}
                        title={"Products"}
                        variables={{
                            aggs: {
                                bucket: "organizations",
                                query: {filter: filtersQuery},
                                list: [
                                    {
                                        key: "count_sectors",
                                        type: "cardinality",
                                        params: {
                                            field: "sectors.id.keyword"
                                        }
                                    }
                                ]
                            }
                        }}
                    />
                    <StatsGraphQL
                        query={FETCH_AGGREGATION_STAT}
                        property={"sum_deals_amount"}
                        title={"Funding captured"}
                        render={(v: any) => numeral(v).format('($ 0.00 a)')}
                        variables={{
                            aggs: {
                                bucket: "organizations",
                                query: {filter: filtersQuery},
                                list: [
                                    {
                                        key: "sum_deals_amount",
                                        type: "sum",
                                        params: {
                                            field: "deals.amount"
                                        }
                                    }
                                ]
                            }
                        }}
                    />
                </div>
            </MDSection>
            <div className="flex flex-grow gap-10">
                <div className="w-3/5">
                    <MDSection title="Month-over-Month Investment Tracker">
                        <ReactFusioncharts {...chartMMIT} />
                    </MDSection>
                </div>
                <div className="w-2/5">
                    <MDSection title={`${type} Map`}>
                        <ReactFusioncharts {...chartMap} />
                    </MDSection>
                </div>
            </div>
            <div className="flex flex-grow gap-10">
                <div className="w-1/3">
                    <MDSection title={`${type} by Country`}>
                        <ReactFusioncharts {...chartCountry} />
                    </MDSection>
                </div>
                <div className="w-1/3">
                    <MDSection title={`${type} by Sectors`}>
                        <BarChartGraphQL config={chartHorizontal} dataSource={chartSector}/>
                    </MDSection>
                </div>
                <div className="w-1/3">
                    <MDSection title={`${type} by Products`}>
                        <ReactFusioncharts {...chartProduct} />
                    </MDSection>
                </div>
            </div>
        </div>
    )
}