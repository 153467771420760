import React from "react";
import {MDSection} from "../../../interface/Typography";
import {eachDayOfInterval, format} from "date-fns";
import {SimpleTimeChart} from "../../../interface/Charts/SimpleTimeChart";
import {ActionFilters} from "../../../interface/ActionFilters";
import {DataTableGraphQL} from "../../../interface/DataTable/DataTable";
import {FETCH_USER_ACTIVITIES} from "../queries";
import {Pillola} from "../../../interface/Pillole";

const data = eachDayOfInterval({
    start: new Date('06-01-2022'), end: new Date()
}).map((s) => ({label: format(s, 'Y-M-d'), value: Math.floor(Math.random() * 10), showValue: 0, color: "#666666"}));

export function UserAnalyticsPane({user}: { user: any }) {
    return (
        <div className="p-10">
            <div className="flex flex-col gap-10 flex-grow">
                <ActionFilters bql={[
                    {id: "source"},
                    {id: "action"},
                    {id: "category"},
                    {id: "time"}
                ]}/>

                <SimpleTimeChart data={data} config={{
                    type: "column2d",
                    height: "250",
                    dataSource: {
                        chart: {
                            theme: "carbon",
                            animation: 0,
                            baseFont: 'Manrope, sans-serif',
                        },
                        data: data
                    }
                }}/>
                <MDSection
                    title={"Activity Log"}
                    subtitle="List of all the actions users perform on the platform, with source"
                >
                    <DataTableGraphQL
                        headers={[
                            {dataIndex: "time", title: "Detection Time"},
                            {
                                dataIndex: "subject.name",
                                title: "User",
                                render: (value: any) => {
                                    if (value.subject.__typename == "User") {
                                        return (
                                            <>
                                                <p>{[value.subject.first_name, value.subject.other_names].join(" ")}</p>
                                                <p className="text-xs text-bs-light-gray-800">{value.subject.email}</p>
                                            </>
                                        )
                                    }
                                    return value.subject.name
                                }
                            },
                            {dataIndex: "source", title: "Source"},
                            {
                                dataIndex: "category",
                                title: "Category",
                                render: (v: any) => <Pillola text={v.category} variant="ghost"/>
                            },
                            {
                                dataIndex: "object.name",
                                title: "Object ID",
                                render: (v: any) => {
                                    return (
                                        <>
                                            {v.object.name}
                                            <span
                                                className="text-xs text-bs-light-gray-800 pl-1.5">
                                                ({v.object.id.substring(0, 8)})
                                            </span>
                                        </>
                                    )
                                }
                            },
                            {
                                dataIndex: "action",
                                title: "Action",
                                render: (v: any) => <Pillola text={v.action} variant={"neutral"}/>
                            },
                            {dataIndex: "id", title: "ID", render: (value: any) => value.id.substring(0, 8)},
                        ]}
                        root="activitiesByUser"
                        property="data"
                        query={FETCH_USER_ACTIVITIES}
                        variables={{
                            id: user.id
                        }}
                        data={[]}
                    />
                </MDSection>
            </div>
        </div>
    )
}