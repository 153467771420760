import {styled} from "../stiches.config";

export const StyledElementContainer = styled("div", {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    variants: {
        inline: {
            true: {
                flexDirection: "row",
                alignItems: "flex-end"
            },
            false: {
                flexDirection: "cols"
            }
        }
    }
})
export const StyledLabel = styled("label", {
    fontSize: "90%",
    fontWeight: 500,
    color: "rgb(132, 129, 138)",
    variants: {
        inline: {
            true: { },
            false: { }
        }
    }
})
export const StyledInput = styled("input", {
    border: "1px solid #aaa",
    borderRadius: "3px",
    padding: "0.5rem 1rem",
    flexGrow: "1",
    outline: "none",
    variants: {
        inline: {
            true: {
                flexGrow: 1
            },
            false: {}
        }
    },
    "&:focus": {
        border: "1px solid #000",
    },
    "&[type='text']": {
        flexGrow: 1,
    }
})
export const StyledTextarea = styled("textarea", {
    display: "block",
    flexGrow: 1,
    border: "1px solid #aaa",
    padding: "0.25rem 1rem",
    outline: "none",
    "&:focus": {
        border: "1px solid #000",
    }
})