import { get } from "lodash";
import {JOB_QUERY} from "../../../queries/";
import {useQuery} from "@apollo/client";
import {DataTable} from "../../../interface/DataTable/DataTable";

interface CreateOrganizationsContainerProps {
    id: string
    value: any;
}

export const CreateOrganizationsContainer = ({ ...props }: CreateOrganizationsContainerProps) => {
    const { id,value } = props;

    const {loading,data,error} = useQuery(JOB_QUERY,{variables: {id: id}})

    const headers = [
        {key: "id",dataIndex: "id",title: "ID"}
    ]

    return (
        <div className="flex gap-4 w-full">
            <div className="w-full">
                <DataTable headers={headers} data={get(data,"getJobByID.jobs",[])} />
            </div>
        </div>
    );
};
