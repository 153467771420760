import React, {useState} from "react";
import {gql, useQuery} from "@apollo/client";
import {FETCH_COMPANY_BY_ID} from "../queries";
import {get} from "lodash";
import {FormLoader} from "../../../interface/Loaders/SkeletonLoaders";
import {EditCompanyFormInput} from "./EditCompanyFormInput";
import {decorateWithOptions, FormBuilder, replaceWithProperty} from "../../../interface/Form/FormBuilder";

const FETCH_DROPDOWN_OPTIONS_FORM = gql`
query fetchDropDownOptions {
    sectors(limit: 10000) {
        data {
            id
            value: name
        }
    }
    sub_sectors(limit: 10000) {
        data {
            id
            value: name
        }
    }
    agbase_categories(limit: 10000) {
        data {
            id
            value: name
        }
    }
    agbase_valuechains(limit: 10000) {
        data {
            id
            value: name
        }
    }
    countries(limit: 1000) {
        data {
            id
            value: name
        }
    }
    sdgs(limit: 1000) {
        data {
           id
           value: name
        }
    }
    technologies(limit: 1000) {
        data {
            id
            value: name
        }
    }
}
`

export default function EditCompanyFragment({id, onCancel}: { id?: string, onCancel?: any }) {
    const [json, setJSON] = useState(JSON.stringify({}))
    const {data, loading} = useQuery(FETCH_COMPANY_BY_ID, {
        variables: {
            id: id
        }
    })

    const {data: opts, loading: optsLoading} = useQuery(FETCH_DROPDOWN_OPTIONS_FORM)

    const onSubmit = (data: any) => {
        data = replaceWithProperty(data, "sectors_ids", "sectors")
        data = replaceWithProperty(data, "sub_sectors_ids", "sub_sectors")
        data = replaceWithProperty(data, "technologies_ids", "technologies")
        setJSON(JSON.stringify(data, undefined, 2))
    }

    const options = decorateWithOptions([
        {dataPath: '[1].sets[0].options.values', valuesPath: 'sectors.data', data: opts},
        {dataPath: '[1].sets[1].options.values', valuesPath: 'sub_sectors.data', data: opts},
        {dataPath: '[1].sets[2].options.values', valuesPath: 'technologies.data', data: opts},
        {dataPath: '[6].sets[0].options.values', valuesPath: 'sdgs.data', data: opts}
    ], EditCompanyFormInput)


    return loading || optsLoading ? <div className="p-10"><FormLoader/></div> : (
        <div className="w-full">
            <FormBuilder
                isSending={false}
                onCancel={onCancel}
                onSubmit={onSubmit} options={options} data={get(data, 'organization', {})}
            />
            {
                json && (
                    <pre>{json}</pre>
                )
            }
        </div>
    )
}