import {gql} from "@apollo/client";

export const DELETE_DEAL = gql`
    mutation deleteDeal($id: ID!) {
        deleteDeal(id: $id) {
            is_successful
        }
    }
`

export const FETCH_COMPANY_BY_ID = gql`
query organization($id: ID) {
  organization(id: $id) {
     name
     profile_image_url
     type
     created_at
     updated_at
     deals {
        id
        date
        equity
        type
        investors {
            id
            name
        }
     }
     sectors {
       id
       value: name
     }
     socials {
        website
        facebook
        instagram
        youtube
        linkedin
        angellist
    }
  }
}
`
export const FETCH_DEALS = gql`
query fetchDeals($search: SearchRequest!) {
    search(search: $search) {
        items
        total
        took
        cursor
    }
}
`

export const FETCH_DEALS_PG = gql`
query deals($filters: FiltersInput, $offset: Int, $limit: Int) {
    deals (offset: $offset, limit: $limit, filters: $filters) {
        data {        
            id
            equity
            currency_code
            date
            amount
            stage
            investors {
                id
                name
                profile_image_url
            }
            target {
                id
                name
                profile_image_url
                sectors {
                  id
                  value: name
                }
            }
            type
        }
        count
        pages
        current
    }
}
`

export const FETCH_SECTORS = gql`
query sectors($filters: FiltersInput, $offset: Int, $limit: Int) {
    sectors (offset: $offset, limit: $limit, filters: $filters) {
        data {
            id
            name
            enabled
            updated_at
            created_at
        }
        count
        pages
        current
    }
}
`

export const FETCH_SUBSECTORS = gql`
query sectors($filters: FiltersInput, $offset: Int, $limit: Int) {
    sectors (offset: $offset, limit: $limit, filters: $filters) {
        data {
            id
            name
            enabled
            updated_at
            created_at
        }
        count
        pages
        current
    }
}
`
