import { formatDistance } from "date-fns";
import { get } from "lodash";
import { Pillola } from "../../interface/Pillole";
import { Button } from "../../interface/Button/Button";
import { useNavigate } from "react-router";
import format from "../../interface/utils/date/format";
import { MDSection } from "../../interface/Typography";
import { DataTable, } from "../../interface/DataTable/DataTable";
import { FETCH_PENDING_JOBS } from "../../queries/";
import { useQuery } from "@apollo/client";
import { useJobContext } from "../jobs/context/JobStateContext";
import { useEffect } from "react";
import { Tabs } from "../../interface/Tabs/Tabs";
import { ArrowRightIcon } from "@heroicons/react/solid";

export const SearchAutomationContainer = () => {
    const nav = {
        pending: {
            title: "Pending Jobs",
            key: "pending",
            pane: <PendingJobsContainer />,
        },
        list: {
            title: "Approved/Rejected Jobs",
            key: "list",
            pane: <ProcessedJobsContainer />,
        },
    };
    return (
        <div className="flex-grow flex flex-row">
            <div className="flex-grow">
                <Tabs index={"pending"} tabs={nav} />
            </div>
        </div>
    );
}

const PendingJobsContainer = () => {
    const navigate = useNavigate();

    const { state, dispatch } = useJobContext()

    const { data,loading,error } = useQuery(FETCH_PENDING_JOBS, {
        variables: {
            limit: 5,
            offset: 0,
            sort: { field: "created_at", order: "desc" },
            filters: {
                and: [
                    {
                        expression: {
                            field: "job.type",
                            eq: "AnalyseArticleJob",
                        },
                    },
                ],
            },
        },
        pollInterval: 500,
    });


    useEffect(() => {
        if(data) {
            dispatch({type: "ADD_INITIAL_JOBS",payload: get(data,"jobs.data",[])})
        }
    },[data])

    const pendingHeaders = [
        {
            key: "id",
            title: "ID",
            dataIndex: "id",
            render: (job: any) => job.id.slice(0, 8),
        },
        {
            key: "created_at",
            title: "Created At",
            dataIndex: "created_at",
            render: (job: any) => (
                <span>
                    {formatDistance(new Date(job.created_at), new Date(), {
                        addSuffix: true,
                    })}{" "}
                    ({format(new Date(job.created_at), "Y-MM-dd")})
                </span>
            ),
        },
        {
            key: "media_mention",
            title: "Source",
            dataIndex: "media_mention",
            render: (job: any) => {
                return <p>{get(job, "media_mention.source", "")}</p>;
            },
        },
        // {
        //     key: "media_mention",
        //     title: "Link",
        //     dataIndex: "media_mention",
        //     render: (job: any) => {
        //         return (
        //             <a href={get(job, "media_mention.uri", "")} target="_blank">
        //                 {get(job, "media_mention.uri", "")}
        //             </a>
        //         );
        //     },
        // },
        {
            key: "type",
            title: "Type",
            dataIndex: "type",
            render: (job: any) => {
                return (
                    <div>
                        {job.type === "BulkCreateOrganizationCSV" && (
                            <p>BulkOrganizations </p>
                        )}
                        {job.type === "SingleOrganizationCreate" && (
                            <p>
                                Organization -{" "}
                                {get(job, "payload.msg.name", {})}
                            </p>
                        )}
                        {job.type === "AnalyseArticleJob" && (
                            <p>
                                Deals - (
                                {
                                    get(job, "jobs", []).filter(
                                        (it: any) =>
                                            it.type !== "SingleDealCreate",
                                    ).length
                                }
                                ) Organizations - (
                                {
                                    get(job, "jobs", []).filter(
                                        (it: any) =>
                                            it.type === "SingleDealCreate",
                                    ).length
                                }
                                )
                            </p>
                        )}
                    </div>
                );
            },
        },
        {
            key: "stage",
            title: "Stage",
            dataIndex: "stage",
            render: (job: any) => {
                return <Pillola text={job.stage} variant="default" size="md"/>
            }
        },
        {
            key: "actions",
            title: "Actions",
            dataIndex: "actions",
            render: (job: any) => {
                return (
                    <div style={{ display: "block", position: "relative" }}>
                        <div style={{ display: "flex", gap: "1rem" }}>
                            <Button
                                iconSuffix={<ArrowRightIcon width={16} height={16}/>}
                                title="View"
                                variant="primary"
                                size="sm"
                                onClick={() => {
                                    navigate(
                                        `/search-automation/scraping-backlog/process-job/${job.id}`,
                                    );
                                }}
                            />
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <div className="w-full p-5">
            <MDSection title="Pending jobs">
                <DataTable
                    data={state.jobs}
                    headers={pendingHeaders}
                />
            </MDSection>
        </div>
    );
};

const ProcessedJobsContainer = () => {
    return (
        <div>
            <h1>Approved / Rejected Scraped Jobs</h1>
        </div>
    )
}
