import React from "react";
import {MDSection} from "../../../interface/Typography";
import {DataTable} from "../../../interface/DataTable/DataTable";
import {BadgeCheckIcon, ExclamationCircleIcon} from "@heroicons/react/outline";
import {Pillola} from "../../../interface/Pillole";
import {Button} from "../../../interface/Button/Button";

const headers = [
    {dataIndex: "action", title: "Action", thClassName: "w-1/4"},
    {
        dataIndex: "allow",
        title: "Permitted",
        render: (v: any) => v.allow
            ? <BadgeCheckIcon className="w-6 h-6 text-green-700"/>
            : <ExclamationCircleIcon className="w-6 h-6 text-red-800"/>
    },
    {
        dataIndex: "conditions",
        title: "Conditions",
        render: (v: any) => v.conditions.length < 1
            ? <p>No conditions applied</p>
            : <RenderConditions conditions={v.conditions}/>
    },
]

const RenderConditions = ({conditions}: { conditions: any }) => {
    return (
        <ul>
            {
                conditions.map((c: any) => {
                    return <li className="py-1">{c.key}: {c.value} <Pillola text={c.policy} variant="neutral"/></li>
                })
            }
        </ul>
    )
}

const conditions = [
    {key: "entity:ResourceTag/Competitors", value: "Novek", policy: "STARTUP_POLICY"},
    {key: "entity:ResourceTag/Geographies", value: "Kenya, Uganda, Tanzania, Rwanda", policy: "AGRITECH_EAST_AFRICA"},
    {key: "entity:ResourceTag/SectorName", value: "AgriTech", policy: "AGRITECH_EAST_AFRICA"}
]

export function UserPolicyPermsPane({user}: { user: any }) {
    return (
        <div className="p-10">
            <MDSection
                title={"Policies assigned"}
                subtitle={`List of all the policies assigned to ${user.first_name}. Remember, ${user.first_name} won't be allowed to perform any action that is not listed here.`}
                actions={(
                    <Button title={"edit policies"} variant={"primary"} uppercase size="sm"/>
                )}
            >
                <div className="py-5">
                    {
                        ["STARTUP_POLICY", "AGRITECH_EAST_AFRICA"].map((p: string) =>
                            <span className="mr-5"><Pillola text={p} variant={"positive"}/></span>
                        )
                    }
                </div>
            </MDSection>
            <MDSection title={"Organizations"}
                       subtitle="List of permissions for the entity Organization (Startup, NGO, Investor, etc)">
                <DataTable headers={headers} data={[
                    {
                        action: "List Organizations",
                        allow: true,
                        conditions: conditions
                    },
                    {
                        action: "View Organizations",
                        allow: true,
                        conditions: conditions
                    },
                    {
                        action: "Claim Organizations",
                        allow: true,
                        conditions: []
                    },
                ]}/>
            </MDSection>
            <MDSection title={"Deals"} subtitle="List of permissions for the entity Deals">
                <DataTable headers={headers} data={[
                    {
                        action: "List Deals",
                        allow: true,
                        conditions: conditions
                    },
                    {
                        action: "View Deals",
                        allow: true,
                        conditions: conditions
                    }
                ]}/>
            </MDSection>
            <MDSection title={"Initiatives"}>
                <div
                    className="my-2.5 px-5 py-2 text-sm text-yellow-700 bg-yellow-50 rounded-md border-yellow-700 border">
                    <p>{user.first_name} doesn't have any permissions assigned on <strong>Initiative</strong>.</p>
                </div>
            </MDSection>
            <MDSection title={"People"}>
                <div
                    className="my-2.5 px-5 py-2 text-sm text-yellow-700 bg-yellow-50 rounded-md border-yellow-700 border">
                    <p>{user.first_name} doesn't have any permissions assigned on <strong>People</strong>.</p>
                </div>
            </MDSection>
            <MDSection title={"Media Mentions"}>
                <div
                    className="my-2.5 px-5 py-2 text-sm text-yellow-700 bg-yellow-50 rounded-md border-yellow-700 border">
                    <p>{user.first_name} doesn't have any permissions assigned on <strong>Media Mentions</strong>.</p>
                </div>
            </MDSection>
            <MDSection title={"Jobs"}>
                <div
                    className="my-2.5 px-5 py-2 text-sm text-yellow-700 bg-yellow-50 rounded-md border-yellow-700 border">
                    <p>{user.first_name} doesn't have any permissions assigned on <strong>Jobs</strong>.</p>
                </div>
            </MDSection>
        </div>
    )
}