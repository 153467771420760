import {useParams,useNavigate} from "react-router";
import {Controller, FormProvider, useForm} from "react-hook-form";
import Select from "react-select";
import {get} from "lodash";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {useEffect} from "react";
import {DateInput} from "../../interface/Form/Input/DateInput";
import {format, parseISO} from "date-fns";
import {useFlash} from "../../context/FlashContext";
import { CheckboxInput } from "../../interface/Form/Input/Inputs";

// todo : create new mutation to replace create and update deal to upsert deal
import {DEALS_BY_ID,FETCH_DEALS_DROPDOWN_OPTIONS_FORM,MUTATION_UPDATE_DEAL,MUTATION_CREATE_DEAL} from "../../queries/"
import { SingleComboBoxInput } from "../../interface/Form/Input/ComboBoxInput";
import {styled} from "../../interface/stiches.config";


export default function CreateNewDealContainer() {
    const { id } = useParams();
    const flash = useFlash();
    const navigate = useNavigate();

    const [fetchDeals, {data,loading, error}] = useLazyQuery(DEALS_BY_ID, {variables: {id}})
    const {data: opts, loading: optsLoading} = useQuery(FETCH_DEALS_DROPDOWN_OPTIONS_FORM)
    const [update, {error: updateError, loading: updateLoading}] = useMutation(MUTATION_UPDATE_DEAL)
    const [create, {error: createError, loading: createLoading}] = useMutation(MUTATION_CREATE_DEAL)

    const methods = useForm({
        shouldUnregister: true,
        reValidateMode: "onChange",
        values: get(data, "getDealByID", {}),
        defaultValues: get(data,"getDealByID",{})
    });

    const watchUndisclosed = methods.watch("undisclosed")

    const watchInvestorUndisclosed = methods.watch("undisclosed_investor")

    const onSubmit = (payload: any) => {
        const normalized = {
            ...payload,
            target: payload.target.id,
            investors: payload.investors ? payload.investors.map((it: any) => it.id) : [],
            amount: payload.amount,
            type: payload.type.value,
            stage: payload.stage.value,
            pre_money: payload.pre_money,
            date: format(new Date(new Date(parseISO(payload.date)).toISOString()),"yyy-MM-dd"),
            equity: payload.equity,
        }

        delete normalized.undisclosed_investor

        if(id) {
            update({
                variables: {
                    id: id,
                    req: {
                        ...normalized
                    }
                }
            }).then((res) => {
                flash("deal updated successfully","success")
                navigate(`/organizations/directory/orgs/${normalized.target}`)
            }).catch((err) => {
                flash("failed to update deal please try again","error")
            })
        } else {
            create({
                variables: {
                    req: normalized
                }
            }).then((res) => {
                flash("deal created successfully","success")
                navigate(`/organizations/directory/orgs/${normalized.target}`)
            }).catch((err) => {
                flash("failed to create deal","error")
            })
        }
    }

    useEffect(() => {
        if(id) {
            fetchDeals()
        }
    },[id])

    useEffect(() => {
        if(watchInvestorUndisclosed) {
            methods.unregister("investors")
        } else {
            methods.register("investors")
        }
    },[methods.register,methods.unregister,watchInvestorUndisclosed])

    useEffect(() => {
        if(watchUndisclosed) {
            methods.unregister("amount")
        } else {
            methods.register("amount")
        }
    },[methods.register,methods.unregister,watchUndisclosed])

    return (
        <div className="w-full scroll-smooth">
            <div className="px-5">
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit((d) => {
                        onSubmit(d)
                    })}>
                        <div style={{display: "flex",width: "100%",gap: "1.5rem",flexDirection: "column"}}>
                            <Row style={{justifyContent: "center"}}>
                                <Column>
                                    <H2Section>{ id ? "Update" : "Create" }: { id ? get(data, 'deal.type') : "Deal" }</H2Section>
                                </Column>
                                <div>
                                    <button type="submit" className="text-gray-900 bg-bs-light-primary hover:bg-bs-light-border-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-bs-light-primary dark:hover:bg-bs-light-border-primary dark:focus:ring-blue-800">Submit</button>
                                </div>
                            </Row>
                            <hr />
                            <div className="w-full">
                                    <div>
                                        <label htmlFor="visitors"
                                               className="block mb-2 text-sm font-medium text-gray-900 border-2 border-rose-500 dark:text-gray-900">Target Organization</label>
                                        <Controller
                                            render={({
                                                 field: {onChange,onBlur,value,name,ref},
                                                fieldState: {invalid, isTouched, isDirty, error },
                                                formState
                                            }) => {
                                                const options = get(opts,"organizations.data",[])
                                                // @ts-ignore
                                                return <Select
                                                    styles={{
                                                        // @ts-ignore
                                                        option: (base,state) => ({
                                                            ...base,
                                                            backgroundColor: state.isSelected ? "#FFDB80FF"  : "white",
                                                            color: state.isSelected ? "black"  : "black",
                                                        }),
                                                        menu: (base, props) => ({
                                                            ...base,
                                                            width: "max-content",
                                                        }),
                                                        multiValueLabel: (base,props) => ({
                                                            ...base,
                                                            backgroundColor: "#FFDB80FF",
                                                            color: "black",
                                                            "&:hover": {
                                                                backgroundColor: "rgba(252,186,122,0.68)",
                                                                borderColor: "red"
                                                            }
                                                        }),
                                                        multiValueRemove: (base,props) => ({...base,backgroundColor: "#FFDB80FF",color: "black"})
                                                    }}
                                                    getOptionLabel={(option: any) => option.value}
                                                    getOptionValue={option => option.id}
                                                    options={options}
                                                    onBlur={onBlur}
                                                    defaultValue={value}
                                                    onChange={onChange}
                                                    value={value}
                                                    ref={ref} />
                                            }}
                                            name="target"
                                            control={methods.control}
                                            rules={{ required: true }}
                                        />
                                    </div>
                                    <div className="mb-4 mt-4">
                                        <CheckboxInput register={methods.register} label={"Undisclosed Investor"} name={"undisclosed_investor"} />
                                    </div>
                                    {!watchInvestorUndisclosed ? 
                                    <div className="mb-2">
                                        <label htmlFor="visitors"
                                               className="block mb-2 mt-2 text-sm font-medium text-gray-900 border-2 border-rose-500 dark:text-gray-900">Investors</label>

                                        <Controller
                                            render={({
                                                         field: {onChange,onBlur,value,name,ref},
                                                         fieldState: {invalid, isTouched, isDirty, error },
                                                         formState
                                                     }) => {
                                                const options = get(opts,"organizations.data",[])
                                                return <Select
                                                    styles={{
                                                        option: (base,state) => ({
                                                            ...base,
                                                            backgroundColor: state.isSelected ? "#FFDB80FF"  : "white",
                                                            color: state.isSelected ? "black"  : "black",
                                                        }),
                                                        menu: (base, props) => ({
                                                            ...base,
                                                            width: "max-content",
                                                        }),
                                                        multiValueLabel: (base,props) => ({
                                                            ...base,
                                                            backgroundColor: "#FFDB80FF",
                                                            color: "black",
                                                            "&:hover": {
                                                                backgroundColor: "rgba(252,186,122,0.68)",
                                                                borderColor: "red"
                                                            }
                                                        }),
                                                        multiValueRemove: (base,props) => ({...base,backgroundColor: "#FFDB80FF",color: "black"})
                                                    }}
                                                    getOptionLabel={(option: any) => option.value}
                                                    getOptionValue={option => option.id}
                                                    options={options}
                                                    onBlur={onBlur}
                                                    defaultValue={value}
                                                    onChange={onChange}
                                                    isMulti={true}
                                                    value={value}
                                                    ref={ref} />
                                            }}
                                            name="investors"
                                            control={methods.control}
                                            rules={{ required: false }}
                                        />
                                    </div>
                                    :
                                    null
                                    }
                                <div className="mb-4 mt-4">
                                    <CheckboxInput register={methods.register} label={"Undisclosed Deal Amount"} name={"undisclosed"} />
                                </div>
                                <Column>
                                    {!watchUndisclosed ? 
                                        <div>
                                            <label htmlFor="amount"
                                                   className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-900">Amount Raised</label>
                                            <Controller
                                                render={({
                                                             field: {onChange,onBlur,value,name,ref},
                                                             fieldState: {invalid, isTouched, isDirty, error },
                                                             formState
                                                         }) => {
                                                    return (
                                                        <input type="number" id="age"
                                                               {...methods.register("amount")}
                                                               className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                                               placeholder="500" required
                                                               onChange={onChange}
                                                               value={methods.getValues("amount")} />
                                                    )
                                                }}
                                                name={"amount"}
                                            />
                                        </div>
                                        :
                                        null
                                    }

                                    <div>
                                        <label htmlFor=""
                                               className="block mb-2 text-sm font-medium text-gray-900 border-2 border-rose-500 dark:text-gray-900">Deal Type</label>
                                        <Controller
                                            render={({
                                                         field: {onChange,onBlur,value,name,ref},
                                                         fieldState: {invalid, isTouched, isDirty, error },
                                                         formState
                                                     }) => {

                                                const options = [
                                                    {id: "award-or-price", value: "Award or Prize"},
                                                    {id: "bond", value: "Bond"},
                                                    {id: "convertible", value: "Convertible"},
                                                    {id: "crowdfunding", value: "Crowdfunding"},
                                                    {id: "debt", value: "Debt"},
                                                    {id: "acquisition", value: "Acquisition"},
                                                    {id: "grant", value: "Grant"},
                                                    {id: "hybrid", value: "Hybrid"},
                                                    {id: "in-kind-assistance", value: "In-Kind Assistance"},
                                                    {id: "merger", value: "Merger"},
                                                    {id: "equity",value: "Equity"},
                                                    {id: "non-equity-assistance", value: "Non-Equity assistance"},
                                                    {id: "other", value: "Other"},
                                                ]
                                                // @ts-ignore
                                                return <Select
                                                    styles={{
                                                        // @ts-ignore
                                                        option: (base,state) => ({
                                                            ...base,
                                                            backgroundColor: state.isSelected ? "#FFDB80FF"  : "white",
                                                            color: state.isSelected ? "black"  : "black",
                                                        }),
                                                        menu: (base, props) => ({
                                                            ...base,
                                                            width: "max-content",
                                                        }),
                                                        multiValueLabel: (base,props) => ({
                                                            ...base,
                                                            backgroundColor: "#FFDB80FF",
                                                            color: "black",
                                                            "&:hover": {
                                                                backgroundColor: "rgba(252,186,122,0.68)",
                                                                borderColor: "red"
                                                            }
                                                        }),
                                                        multiValueRemove: (base,props) => ({...base,backgroundColor: "#FFDB80FF",color: "black"})
                                                    }}
                                                    getOptionLabel={(option: any) => option.value}
                                                    getOptionValue={option => option.id}
                                                    options={options}
                                                    onBlur={onBlur}
                                                    defaultValue={value}
                                                    onChange={onChange}
                                                    value={options.find((opt) => opt.id === value)}
                                                    ref={ref} />
                                            }}
                                            name="type"
                                            control={methods.control}
                                            rules={{ required: true }}
                                        />
                                    </div>


                                    <div>
                                        <label htmlFor="visitors"
                                               className="block mb-2 text-sm font-medium text-gray-900 border-2 border-rose-500 dark:text-gray-900">Deal Stage</label>
                                        <Controller
                                            render={({
                                                         field: {onChange,onBlur,value,name,ref},
                                                         fieldState: {invalid, isTouched, isDirty, error },
                                                         formState
                                                     }) => {
                                                const options = [
                                                        "Series B", "Series C", "Accelerator", "Angel Round", "Pre-Seed", "IPO", "Seed", "Acquired",
                                                        "ICO", "Series D", "Series E", "Pre-Series A", "Unspecified", "Pre-Series B", "Series A",
                                                        "Pre-Series C", "Incubator","Merger"
                                                    ].map((v) => ({
                                                        id: v.replace(/\s+/g, '-').toUpperCase(),
                                                        value: v
                                                    }))
                                                // @ts-ignore
                                                return <Select
                                                    styles={{
                                                        // @ts-ignore
                                                        option: (base,state) => ({
                                                            ...base,
                                                            backgroundColor: state.isSelected ? "#FFDB80FF"  : "white",
                                                            color: state.isSelected ? "black"  : "black",
                                                        }),
                                                        menu: (base, props) => ({
                                                            ...base,
                                                            width: "max-content",
                                                        }),
                                                        multiValueLabel: (base,props) => ({
                                                            ...base,
                                                            backgroundColor: "#FFDB80FF",
                                                            color: "black",
                                                            "&:hover": {
                                                                backgroundColor: "rgba(252,186,122,0.68)",
                                                                borderColor: "red"
                                                            }
                                                        }),
                                                        multiValueRemove: (base,props) => ({...base,backgroundColor: "#FFDB80FF",color: "black"})
                                                    }}
                                                    getOptionLabel={(option: any) => option.value}
                                                    getOptionValue={(option: any) => option.id}
                                                    options={options ? options : []}
                                                    onBlur={onBlur}
                                                    defaultValue={options.find((opt: any) => opt.id === value)}
                                                    onChange={onChange}
                                                    value={options.find((opt: any) => opt.id === value)}
                                                    ref={ref} />
                                            }}
                                            name="stage"
                                            control={methods.control}
                                            rules={{ required: true }}
                                        />
                                    </div>
                                    <DateInput
                                        required
                                        register={methods.register}
                                        label={"Date"}
                                        type={"date"}
                                        formatLayout={"yyyy-MM-dd"}
                                        getValues={methods.getValues}
                                        name={"date"}
                                        setValue={methods.setValue}
                                    />
                                </Column>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    )
}
const Column = styled("div", {
    flexGrow: 1, display: "flex", flexDirection: "column", gap: "1rem", justifyContent: "space-evenly"
})
const Row = styled("div", {
    width: "100%", display: "flex", gap: "2.5rem"
})
const H2Section = styled("h2", {
    textTransform: "uppercase", fontWeight: 600, fontSize: "90%"
})
