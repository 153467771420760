import React from "react";
import classNames from "classnames";

export const TextInput = ({
                              label,
                              register,
                              name,
                              required,
                              type,
                              ...rest
                          }: any & {}) => {
    return (
        <div className={classNames({
            "flex flex-col gap-1": true,
            "hidden": type === "hidden",
        })}>
            {
                type != "hidden" && (
                    <label className="pb-1 text-sm text-bs-light-black">
                        {label}
                    </label>
                )
            }
            <input
                {...rest}
                type={type}
                {...register(name, { required: { value: required ?? false, message: `${name} is required` } })}
                className="focus:border-teal block w-full rounded-sm border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-0"
            />
        </div>
    );
};

export const TextAreaInput = ({
                                  label,
                                  register,
                                  name,
                                  required,
                                  rows = 4,
                                  ...rest
                              }: any & {}) => {
    return (
        <div className="flex flex-col gap-1">
            <label className="pb-1 text-sm text-bs-light-black">
                {label}
            </label>
            <textarea
                rows={rows}
                {...rest}
                {...register(name)}
                className="block w-full rounded-sm border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 dark:border-gray-300 dark:bg-gray-50 dark:text-gray-900 dark:focus:border-blue-500 dark:focus:ring-blue-500"
            />
        </div>
    );
};

export const PasswordInput = ({
                                  label,
                                  register,
                                  name,
                                  required,
                              }: {} & any) => {
    return (
        <div>
            <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-900">
                {label}
            </label>
            <input
                type="password"
                id="password"
                {...register(name)}
                className="block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:border-blue-500 focus:ring-blue-500 "
                required
            />
        </div>
    );
};

export const CheckboxInput = ({
                                  label,
                                  register,
                                  name,
                                  required,
                              }: any & {}) => {
    return (
        <div className="flex items-start">
            <div className="flex h-5 items-center">
                <input
                    id="remember"
                    {...register(name, {required})}
                    type="checkbox"
                    value=""
                    className="focus:ring-3 h-4 w-4 rounded border border-gray-300 focus:ring-blue-300"
                />
            </div>
            <label className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-900">
                {label}
            </label>
        </div>
    );
};

export const DateInput = ({
                              label,
                              register,
                              name,
                              required,
                              type,
                              ...rest
                          }: any & {}) => {
    return (
        <div className={classNames({
            "flex flex-col gap-1": true,
            "hidden": type === "hidden",
        })}>
            {
                type != "hidden" && (
                    <label className="pb-1 text-sm text-bs-light-black">
                        {label}
                    </label>
                )
            }
            <input
                {...rest}
                type={type}
                {...register(name, {
                    valueAsDate: true
                })}
                className="focus:border-teal block w-full rounded-sm border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-0"
                required={required}
            />
        </div>
    );
};
