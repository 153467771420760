import React from "react"
import {styled} from "../../stiches.config";
import {useFormContext, UseFormRegister, useWatch} from "react-hook-form";
import {StyledElementContainer, StyledInput, StyledLabel} from "../StyledElements";
import {method} from "lodash";

export interface CheckBoxInputProps {
    onChange: any
    checked: boolean
}

export const RHFCheckboxInput = React.forwardRef<HTMLInputElement,
    { label: string } & ReturnType<UseFormRegister<any>>
>(({name, label}, ref) => {
        const {setValue, getValues} = useFormContext()

    const initialState = getValues(name) ? getValues(name) : false

    useWatch()

    return (
            <StyledElementContainer inline={true}>
                <StyledInput
                    style={{flexGrow: 0}}
                    type="checkbox"
                    name={name}
                    checked={initialState}
                    onChange={(e) => {
                        setValue(name,!initialState)
                        console.log("test",e.target.checked)
                    }}
                />
                <StyledLabel>{label}</StyledLabel>
            </StyledElementContainer>
        );
    }
);
