import React, {useEffect, useState} from "react";
import {TabsContextProvider} from "./context/TabsContext";
import classNames from "classnames";
import {useSearchParams} from "react-router-dom";

export interface ITabPane {
}

export interface ITabContext {
    tabs: ITabPane[];
    prefixCls: string;
}

// export const TabContext = createContext<ITabContext>(null);

export function TabPaneList() {

}

export function TabPane({children, label, id}: { children: React.ReactNode, label: string, id: string }) {
    return (
        <p>
            {children}
        </p>
    )
}


export function TabNavList() {
    return (
        <li className="mr-2">
            <a href="#"
               className="inline-flex p-4 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group">
                <svg
                    className="mr-2 w-5 h-5 text-gray-400 group-hover:text-gray-500 dark:text-gray-500 dark:group-hover:text-gray-300"
                    fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                          clipRule="evenodd"></path>
                </svg>
                Profile
            </a>
        </li>
    )
}

export interface ITab {
    title: string;
    pane: JSX.Element;
    key: string;
}

// @ts-ignore
export function Tabs({tabs, index}) {
    const [state, setState] = useState({activeIndex: index});
    const [searchParams, setSearchParams] = useSearchParams()
    useEffect(() => {
        if (searchParams.get("t")) {
            setState({activeIndex: searchParams.get("t")})
        } else {
            searchParams.set("t", index)
            setSearchParams(searchParams)
        }
    }, [])
    return (
        <TabsContextProvider>
            <div style={{
                padding: "0 0 0 0", marginBottom: "0rem",
                // backgroundColor: "#232323",
                // boxShadow: "rgba(0, 0, 0, 0.1) 0px 2px 4px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px"
                borderBottom: "1px solid #f0f0f0"
            }} className="border-b border-solid shadow-sm">
                <ul className="flex flex-wrap -mb-px font-light text-center text-sm py-1">
                    {
                        Object.keys(tabs).map((key, index) => {
                            const clses = classNames({
                                "inline-flex py-1.5 rounded-t-lg active group hover:underline": true,
                                "border-b-2 border-b-2 border-bs-light-black underline": tabs[key].key === state.activeIndex,
                            });
                            return (
                                <li className="mx-7 first:ml-5" key={key} onClick={(ev) => {
                                    ev.preventDefault();
                                    setState({activeIndex: tabs[key].key})
                                    searchParams.set("t", tabs[key].key)
                                    setSearchParams(searchParams)
                                }}>
                                    <a href="#"
                                       className={clses}
                                       aria-current="page">
                                        {tabs[key].title}
                                    </a>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div>
                {tabs[state.activeIndex].pane}
            </div>
        </TabsContextProvider>
    )
}