import React, {useEffect, useState} from "react";
import {IFilter} from "./FilterFactory";
import Select, {Option} from 'rc-select';
import {FilterTitle} from "./fragments/FilterTitle";
import "./select.scss"
import {CheckIcon, XIcon} from "@heroicons/react/outline";
import {get, isEmpty} from "lodash";
import {useLazyQuery} from "@apollo/client";
import {FETCH_FACETS_BY_NAME} from "../queries";

interface IFilterSelect extends IFilter {
    dynamic?: boolean;
    bucket?: string;
}

export default function FilterSelect({...props}: IFilterSelect) {
    const {
        options,
        title,
        addFilter,
        id,
        type,
        defaultValues, removeFilter,
        dynamic,
        bucket,
        extras = undefined
    } = props
    const value = get(defaultValues, 'value', []);
    const [values, setValues] = useState<String | String[]>([]);
    const [opts, setOpts] = useState(options || []);

    const [fetch, {loading, data, called}] = useLazyQuery(FETCH_FACETS_BY_NAME, {
        variables: {
            facets: {
                bucket: bucket,
                list: [id]
            }
        }
    });

    useEffect(() => {
        const def = get(defaultValues, 'value', value);
        if (!isEmpty(def)) {
            setValues(def)
        } else {
            setValues([])
        }

        if (dynamic) {
            fetch();
        }
    }, [defaultValues]);

    useEffect(() => {
        if (called && !loading && data) {
            const o = get(data, 'facets.rows[0].values', []).map((op: any) => {
                return {key: op.key, value: op.key, title: `${op.key} (${op.count})`}
            });
            setOpts(o)
        }
    }, [data, called, loading])
    return (
        <div className="w-full">
            <FilterTitle title={title}/>
            <Select
                mode="multiple"
                prefixCls="rs"
                maxTagCount={2}
                maxTagTextLength={8}
                loading={loading}
                value={values}
                notFoundContent={<span className="p-2.5">Loading..</span>}
                tokenSeparators={[' ', ',']}
                menuItemSelectedIcon={<CheckIcon className="w-4 h-4"/>}
                clearIcon={<XIcon className="w-4 h-4"/>}
                removeIcon={<XIcon className="w-3 h-3"/>}
                onChange={(value, option) => {
                    setValues(value)
                    if (isEmpty(value)) {
                        removeFilter({
                            type, key: id, value: []
                        })
                    } else {
                        addFilter({type, key: id, value: value, sub_filters: extras?.sub_filters})
                    }
                }}
            >
                {
                    opts.map((i) => (
                        <Option key={i.value} value={i.value}>{i.title}</Option>
                    ))
                }
            </Select>
        </div>
    )
}