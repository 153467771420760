import React, {useState} from "react";
import {BadgeCheckIcon, ExclamationCircleIcon, ExclamationIcon} from "@heroicons/react/outline";
import {FaChevronDown, FaChevronUp} from "react-icons/fa";

type TSectionVariant = "warning" | "success" | "neutral" | "error";

interface ISectionBase {
    title: string;
    subtitle?: string;
    badge?: string;
    actions?: React.ReactNode,
    variant?: TSectionVariant;
    children?: React.ReactNode;
    isCollapsible?: boolean;
    defaultCollapsed?: boolean;
}

export const HSection = ({children}: { children: React.ReactNode }) => {
    return (
        <h2 className="text-xl text-bs-light-black font-light uppercase">{children}</h2>
    )
}

export const MDSection = ({...props}: ISectionBase) => {
    const {
        title, children, variant, badge,
        subtitle, actions, isCollapsible = false, defaultCollapsed = false
    } = props
    const [state, setState] = useState({
        isCollapsible: isCollapsible, isCollapsed: defaultCollapsed
    });

    const isVisible = (isCollapsible : boolean, isCollapsed: boolean) : boolean => {
        return isCollapsible ? !isCollapsed : true;
    }

    return (
        <div className="pb-4 w-full">
            <div className="flex items-center">
                <div className="flex-grow">
                    <div className="flex flex-row items-center gap-2.5 pb-1">
                        <p className="uppercase font-semibold" style={{display: "flex", alignItems: "center", gap: "1rem"}}>
                            {title} {
                                state.isCollapsible && (
                                    <button onClick={() => setState({ ...state, isCollapsed: !state.isCollapsed})}>
                                        {state.isCollapsible && state.isCollapsed && <FaChevronDown />}
                                        {state.isCollapsible && !state.isCollapsed && <FaChevronUp /> }
                                    </button>
                            ) }
                        </p>
                        {renderIconVariant(variant)}
                        {badge && (
                            <p
                                className="bg-bs-light-gray-800 uppercase text-bs-light-gray-50 py-1 px-2 rounded-md text-sm"
                            >{badge}
                            </p>
                        )}
                    </div>
                    {subtitle && (<p className="text-bs-light-gray text-sm">{subtitle}</p>)}
                </div>
                <div>
                    {actions}
                </div>
            </div>
            <div style={{width: "100%", display: isVisible(state.isCollapsible, state.isCollapsed) ? "block" : "none"}}>
                {children}
            </div>
        </div>
    )
}


function renderIconVariant(variant?: TSectionVariant): JSX.Element {
    switch (variant) {
        case "warning":
            return <ExclamationIcon className="w-6 h-6 text-yellow-500"/>
        case "success":
            return <BadgeCheckIcon className="w-6 h-6 text-green-700"/>
        case "error":
            return <ExclamationCircleIcon className="w-6 h-6 text-red-500"/>
        default:
            return <></>
    }
}