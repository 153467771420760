import { createContext, useContext, useReducer, useState } from "react"

type Action =
    | {type: "ADD_PRIMARY_INFORMATION", payload: any}
    | {type: "ADD_CONTACT_INFORMATION",payload: any}
    | {type: "ADD_IMPACT_INFORMATION",payload: any}
    | {type: "ADD_EMPLOYEE_INFORMATION",payload: any}
    | {type: "UPDATE_INDEX",index: string}

export const formStateReducer = (state: FormState,action: Action) : FormState => {
    switch(action.type) {
        case "ADD_PRIMARY_INFORMATION":
            return {
                ...state,
                organization: {
                    ...state.organization,
                    ...action.payload
                }
            }

        case "UPDATE_INDEX":
            return {
                ...state,
                index: action.index
            }
        default:
            return state
    }
}

const formData: FormState = {
    organization: {
        id: "1c1bac06-e90a-4560-acfc-b61f8a0c4c2f",
        name: "Paismo", 
        type: "startup",
        enabled: true,
        operating_status: "y",
        ownership_structure: "",
        incorporation_number: "",
        description: "",
        primary_email_contact: "",
        primary_phone_number: "",
    },
    index: "primary-information"
}

export interface FormState {
    organization: any
    index: any
}

interface FormStateContextProps {
    state: any
    dispatch: any
}

export const FormStateContext = createContext<FormStateContextProps>({} as FormStateContextProps)
export const FormStateProvider = ({children} : React.PropsWithChildren<{}>) => {

    const [state,dispatch] = useReducer(formStateReducer,formData)

    return (
        <FormStateContext.Provider value={{state,dispatch}}>
            {children}
        </FormStateContext.Provider>
    )
}

export const useFormContext = () => {
    return useContext(FormStateContext)
}