import { useParams } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import _, { get } from "lodash";
import { GET_JOB, PROCESS_JOB } from "../../queries/";
import { useFlash } from "../../context/FlashContext";
import { useNavigate } from "react-router-dom";
import { CreateOrganizationsContainer } from "./bulk/CreateOrganizationsContainer";
import { ProcessOrgJobContainer} from "./fragments/ProcessOrgJobContainer";
import { InitiativeJobForm } from "./forms/InitiativeJobForm";
import { ProcessArticleJobForm } from "./forms/ProcessArticleJobContainer";
import { DealJobForm } from "./forms/DealJobForm";
import { Button } from "../../interface/Button/Button";
import classNames from "classnames";
import { useState } from "react";
import {CreateEmployeesContainer} from "./bulk/CreateEmployeesContainer";

export const ProcessJobContainer = () => {
    const { id } = useParams();

    const { data, loading } = useQuery(GET_JOB, { variables: { id: id } });
    const [processJob] = useMutation(PROCESS_JOB);

    const allJobs = get(data, "getJobByID.jobs", []);
    const [jobIdx, setJobIdx] = useState(0);

	const [formState,setFormState] = useState({
		"org_update": false,
		"org_create": false,
		"deal_create": false,
		"deal_update": false,
	})

	const handleOrgCreateFormState = (state: boolean) => {
		setFormState({...formState,org_create: state})
	}

	const handleOrgUpdateFormState = (state: boolean) => {
		setFormState({...formState,org_update: state})
	}

	const handleDealUpdateFormState = (state: boolean) => {
		setFormState({...formState,deal_update: state})
	}

	const handleDealCreateFormState = (state: boolean) => {
		setFormState({...formState,deal_create: state})
	}


    const flash = useFlash();
    const navigate = useNavigate();

    const handleProcessJob = (id: string) => {
        processJob({
            variables: {
                id: id,
            },
            refetchQueries: [GET_JOB, "GetJobByID"],
        })
			.then((res) => {
                if ( _.get(res, "data.processJobById.stage", "") === "completed") {
                    navigate("/approval");
                }
                flash("job processed successfully", "success");
            }).catch((err) => {
                flash(`${err.message}`, "error");
            });
    };

    const handleNextJob = () => {
        if (jobIdx > allJobs.length - 1) {
            setJobIdx(0);
        } else {
            const nextIdx = jobIdx + (1 % allJobs.length);
            setJobIdx(nextIdx);
        }
    };

    const handlePrevJob = () => {
        if (jobIdx < 0) {
            setJobIdx(allJobs.length - 1);
        } else {
            const prevIdx = (jobIdx + allJobs.length - 1) % allJobs.length;
            setJobIdx(prevIdx);
        }
    };

    const clsButton = classNames({
        "flex justify-center rounded border": true,
        "text-sm px-4 py-1": true,
        "bg-bs-light-black text-bs-light-white border-bs-light-black": true,
    });

    if (loading) return <p>Loading....</p>;

    return (
		<div
			style={{
				width: "100%",
				display: "grid",
				gridTemplateColumns: "repeat(12,minmax(50px,auto))",
				gridTemplateRows:
					"[row-start] 40px [row-content] auto [row-end] 50px",
			}}
		>
			<div className="col-span-12 h-full">
				<div className="flex w-full justify-between gap-1 p-4">
					<Button
						title={"Go Back"}
						variant="primary"
						size="sm"
						onClick={() => navigate(-1)}
					/>
					<div className="flex gap-2">
						<Button
							title={"Prev"}
							size="sm"
							onClick={handlePrevJob}
						/>
						<Button
							title={"Next"}
							size="sm"
							variant="secondary"
							onClick={handleNextJob}
						/>
					</div>
				</div>
			</div>

			<div className="col-span-12 h-full w-full">
				<div
					className="grid grid-cols-5"
					style={{ gridTemplateColumns: "repeat(5,20%)" }}
				>
					<div className="col-span-5 w-full p-6">
						{((job: any) => {
							const type = get(job, "type");
							switch (type) {
								case "BulkCreateOrganizationCSV":
									return (
										<CreateOrganizationsContainer value={job} id={job.id}/>
									);
								case "BulkUpdateOrganizationCSV":
									return (
										<CreateOrganizationsContainer value={job} id={job.id}/>
									);
								case "AnalyseArticleJob":
									return (
										<ProcessArticleJobForm
											job={job}
											onProcessJob={handleProcessJob}
										/>
									);
								case "SingleOrganizationUpdate":
									return (
										<ProcessOrgJobContainer
											job={job}
											onProcessJob={handleProcessJob}
											handleFormState={handleOrgUpdateFormState}
										/>
									);
								case "SingleOrganizationCreate":
									return (
										<ProcessOrgJobContainer
											job={job}
											onProcessJob={handleProcessJob}
											handleFormState={handleOrgCreateFormState}
										/>
									);
								case "SingleInitiativeCreate":
									return (
										<InitiativeJobForm
											job={job}
											onProcessJob={handleProcessJob}
										/>
									);
								case "SingleDealUpdate":
									return (
										<DealJobForm
											job={job}
											handleFormState={handleDealUpdateFormState}
											onProcessJob={handleProcessJob}
										/>
									)
								case "SingleDealCreate":
									return (
										<DealJobForm
											job={job}
											handleFormState={handleDealCreateFormState}
											onProcessJob={handleProcessJob}
										/>
									);
								case "CreateEmployees":
									return (
										<CreateEmployeesContainer id={get(data,"getJobByID.id")}  />
									)
								default:
									return (
										<pre>
											{JSON.stringify(job, null, "\t")}
										</pre>
									);
							}
						})(get(data,"getJobByID.type") === "AnalyseArticleJob" && allJobs.length > 0 ?  allJobs[jobIdx] : get(data,"getJobByID"))}
					</div>
				</div>
			</div>
			<div className="col-span-12 h-full w-full text-center">
			</div>
		</div>
    );
};

export default ProcessJobContainer;
