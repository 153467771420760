import React, {useEffect} from 'react'
import {useForm} from "react-hook-form";
import {TextInput} from "../../interface/Form/Input/Inputs";
import {get} from "lodash";
import slugify from "slugify";
import {gql, useMutation} from "@apollo/client";
import {useFlash} from "../../context/FlashContext";

export const CreateResourceTagsWidget = ({onSuccess, onCancel}: any) => {
    const flash = useFlash();
    const [create, {data, loading, error}] = useMutation(gql`
        mutation createResourceTags($tag: UpsertResourceTagRequest!) {
            createResourceTags(tag: $tag) {
                id
                name
                slug
            }
        }
    `)

    return <UpsertResourceTagForm onSubmit={(data: any) => {
        create({
            variables: {
                tag: data
            }
        }).then((res) => {
            flash(`tag "${data.name}" created`, "success")
            onSuccess()
        }).catch((err) => {
            flash(`tag creation has encounter an error: ${err.message}`, "error")
        })
    }} onCancel={onCancel}/>
}

export const UpsertResourceTagForm = ({onSubmit, onCancel}: any) => {
    const {
        handleSubmit,
        register,
        watch,
        setValue
    } = useForm()

    useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (name === "name" && type === "change" && get(value, 'id', undefined) === undefined) {
                setValue("slug", slugify(watch("name") ?? "", {
                    trim: true, lower: true, strict: true
                }))
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <TextInput
                placeholder={"Enter the title of the tag"}
                type={"text"}
                register={register}
                name={"name"}
                label={"Name"}
            />
            <TextInput
                placeholder={"Machine readable text"}
                type={"text"}
                register={register}
                name={"slug"}
                label={"Machine name"}
            />
            <div>
                <button type="button"
                        className="rounded-md bg-bs-light-primary border text-sm border-bs-light-border-primary px-4 py-0.5"
                        onClick={(ev) => {
                            ev.preventDefault()
                            handleSubmit(onSubmit)()
                        }}>Create
                </button>
                <button type="button"
                        className="mx-8 text-sm px-4 py-0.5 border rounded-md"
                        onClick={(ev) => {
                            ev.preventDefault()
                            onCancel()
                        }}
                >
                    Cancel
                </button>
            </div>
        </form>
    )
}