import React from "react";
import FusionCharts from "fusioncharts";
import charts from "fusioncharts/fusioncharts.charts";
import theme from 'fusioncharts/themes/fusioncharts.theme.fusion';
import ReactFusioncharts from "react-fusioncharts";

// Resolves charts dependancy
charts(FusionCharts);
theme(FusionCharts)

const d = {
    chart: {
        yaxisname: "# of companies",
        theme: "fusion",
        palettecolors: "4c4636",
    },
    data: [
        {
            label: "Venezuela",
            value: "290"
        },
        {
            label: "Saudi",
            value: "260"
        },
        {
            label: "Canada",
            value: "180"
        },
        {
            label: "Iran",
            value: "140"
        },
        {
            label: "Russia",
            value: "115"
        },
        {
            label: "UAE",
            value: "100"
        },
        {
            label: "US",
            value: "30"
        },
        {
            label: "China",
            value: "300"
        }
    ]
};


export default function BarChartGraphQL({...props}) {
    const {dataSource, config} = props
    const chart = {
        type: "bar2d",
        height: "250px",
        width: "100%",
        dataFormat: "JSON",
        dataSource: d
    }
    return (
        <ReactFusioncharts {...chart} />
    )
}