import React from "react";
import {ArrowsExpandIcon, ChevronDownIcon, XIcon} from "@heroicons/react/outline";

interface IComposePaneTitle {
    title: string;
    type: string;
    pane: IComposePane;
    isMinimized: boolean;
    onClose: any;
    onMinimize: () => void;
    onMaximize: (pane: IComposePane) => void
}

const ComposePaneTitle = ({title, type, pane, onClose, onMinimize, onMaximize, isMinimized}: IComposePaneTitle) => {
    return (
        <div
            className="pt-2 pb-3 w-full bg-bs-light-black rounded-t-md flex items-center text-bs-light-gray-300 px-5">
            <div className="flex-grow">
                <p><span
                    className="uppercase text-xs font-semibold mr-2.5 px-2 py-0.5 rounded-full bg-bs-light-gray-100 text-bs-light-black">
                            {type}</span> {title}
                </p>
            </div>
            <div className="flex gap-2.5 ml-4">
                {
                    false && isMinimized && (
                        <ArrowsExpandIcon className="w-5 h-5 cursor-pointer hover:bg-gray-500 hover:text-white"
                                          onClick={() => {
                                              onMaximize(pane)
                                          }}/>
                    )
                }
                {
                    false && !isMinimized && (
                        <ChevronDownIcon className="w-5 h-5 cursor-pointer" onClick={() => {
                            onMinimize()
                        }}/>
                    )
                }
                <XIcon className="w-5 h-5 cursor-pointer" onClick={() => {
                    onClose(pane);
                }}/>
            </div>
        </div>
    )
}

export function ComposeSystem({...props}) {
    const {compose} = props;
    return (
        <p>Se</p>
    )
}

export interface IComposePane {
    id: string;
    title: string;
    type: string;
    content: React.ReactNode;
    onSuccess?: (pane: IComposePane) => void;
    onClose?: (pane: IComposePane) => void;
    onSubmit?: () => void;
}

export function ComposePane({
                                pane,
                                onClose,
                                onMinimize,
                                onMaximize,
                                isMinimized
                            }: { pane: IComposePane, onClose: any, onMinimize: any, onMaximize: any, isMinimized: boolean }) {
    const {type, title, content} = pane;

    if (isMinimized) {
        return (
            <div className="max-w-[400px]">
                <ComposePaneTitle title={title} type={type} isMinimized={isMinimized} pane={pane} onClose={onClose}
                                  onMaximize={onMaximize}
                                  onMinimize={onMinimize}/>
            </div>
        )
    }

    return (
        <div className="fixed inset-0 w-full h-screen bg-black z-20 bg-opacity-50">
            <div
                className="fixed bg-white inset-0 mx-auto my-12 z-30 w-[95%] drop-shadow-2xl rounded-md flex flex-col"
            >
                <ComposePaneTitle title={title} type={type} pane={pane} onClose={onClose} onMaximize={onMaximize}
                                  onMinimize={onMinimize} isMinimized={isMinimized}/>
                <div className="flex-grow flex min-h-0 overflow-y-auto">
                    {content}
                </div>
            </div>
        </div>
    )
}