import React from "react";
import {useDataContainerContext} from "../../interface/DataContainer/context/DataContainerContext";
import {DataGridGraphQL} from "../../interface/DataGrid/DataGridGraphQL";
import {Link} from "react-router-dom";
import {Persona} from "../../interface/Persona";
import {DotsVerticalIcon} from "@heroicons/react/outline";
import {gql} from "@apollo/client";

export default function GeographiesContainer() {
    const {filtersQuery} = useDataContainerContext();

    return (
        <section className="px-10">
            <DataGridGraphQL
                loading={false}
                query={gql`
                        query searchGeographies($search: SearchRequest!) {
                            search(search: $search) {
                                items
                                total
                                took
                                cursor
                            }
                        }
                        `}
                variables={{
                    search: {
                        bucket: 'countries',
                        sort: [
                            {key: "name.keyword", dir: "desc"}
                        ]
                    }
                }}
                root="search"
                property="items"
                filters={filtersQuery}
                entityName="countries"
                headers={[
                    {
                        title: "Geography",
                        dataIndex: "name",
                        key: "name",
                        render: (value) => {
                            return (
                                <Link to={['/organizations/directory/orgs', value.id].join("/")}>
                                    <Persona text={value.name} imageURL={value.profile_image_url}/>
                                </Link>
                            )
                        }
                    },
                    {
                        title: "Companies",
                        dataIndex: "count",
                        key: "count"
                    },
                    {
                        title: "Last Update",
                        dataIndex: "updated_at",
                        key: "updated_at"
                    },
                    {
                        title: "",
                        dataIndex: "actions",
                        key: "actions",
                        render: (value) => {
                            return <DotsVerticalIcon
                                className="w-5 h-5 text-bs-light-gray hover:text-bs-light-black hover:cursor-pointer"/>
                        }
                    }
                ]}
                data={[]}
            />
        </section>
    )
}