import React from "react";

interface IDataGridPagination {
    current: number;
    max: number;
    minSteps?: number;
    rowsPerPage?: number;
    onClick: (value: any) => void;
    onChangeRowsPerPage?: (value: number) => void;
}

interface IDataGridPaginationResultsPerPage {
    current?: number;
    onChange?: (value: number) => void
}

function pagination(c: number, m: number) {
    const current = c,
        last = m,
        delta = 2,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [];
    let l;

    for (let i = 1; i <= last; i++) {
        if (i == 1 || i == last || i >= left && i < right) {
            range.push(i);
        }
    }

    for (let i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push('...');
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
}


export const ResultsPerPage = ({...props}: IDataGridPaginationResultsPerPage) => {
    const {current, onChange} = props;
    const options = [5, 10, 25, 50, 100, 250, 500];
    return (
        <select defaultValue={current}
                className="bg-bs-light-gray-100 outline-0 border border-solid rounded-md"
                style={{zIndex: 10}}
                //@ts-ignore
                onChange={(ev) => onChange(ev.target.value)}
        >
            {
                options.map((o) => (
                    <option key={o} value={o}>{o}</option>
                ))
            }
        </select>
    )
}

/**
 *
 * @todo Fix the pagination (current is buggy, as well as the chevrons)
 *
 * @param props
 * @constructor
 */
export function DataGridPagination({...props}: IDataGridPagination) {
    const {current, max, rowsPerPage, onClick, onChangeRowsPerPage} = props
    const pages = pagination(current, max);

    // if (current !== 1) {
    //     pages.push(<ChevronLeftIcon className={"h-5 w-5"}/>);
    //     pages.push(1)
    // }
    // pages.push(current)
    // if (current !== max) {
    //     pages.push(max);
    //     pages.push(<ChevronRightIcon className={"h-5 w-5"}/>);
    // }

    return (
        <div role="complementary" className="flex py-4 align-center z-10">
            <div className="flex-grow text-bs-light-gray">
                <p>Show <ResultsPerPage current={rowsPerPage} onChange={onChangeRowsPerPage}/> results per page</p>
            </div>
            <div>
                <ul className="flex">
                    {
                        pages.map((item: any) => {
                            console.log(item, current);
                            const cls = [
                                "mx-1 rounded-md hover:text-bs-light-black hover:bg-bs-light-gray-50 hover:text-bs-light-black",
                                current === (item) ? "bg-bs-light-gray-100 text-bs-light-black" : "text-bs-light-gray"
                            ].join(" ")
                            return (
                                <li className={cls} key={item}>
                                    <button className="text-sm px-4 py-2" onClick={() => onClick(item)}>{item}</button>
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        </div>
    )
}
