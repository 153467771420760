import React from "react";
import {MDSection} from "../../interface/Typography";
import {StatsGroup} from "../../interface/Stats";
import {SimpleTimeChartGraphQL} from "../../interface/Charts/SimpleTimeChart";
import {differenceInMinutes, format, formatDistance, formatISO, parseISO, sub} from "date-fns";
import {Pillola} from "../../interface/Pillole";
import {DataTableAggsGraphQL, DataTableGraphQL} from "../../interface/DataTable/DataTable";
import {FETCH_AGGREGATION_STAT, FETCH_SEARCH} from "../../queries";
import {ActionFiltersBoolean, ActionFiltersDateRange, ActionFiltersRefresh} from "../../interface/ActionFilters";
import _, {find, get} from "lodash";
import 'status-indicator/styles.css'
import {
    DataContainerProvider,
    useDataContainerContext
} from "../../interface/DataContainer/context/DataContainerContext";
import {gql, useQuery} from "@apollo/client";

export default function HomeDashboardContainer() {
    return (
        <div className="px-0 py-2.5 w-full">
            <div className="flex-grow">
                <DataContainerProvider filters={[]} defaultFilters={[
                    {
                        "type": "range",
                        "key": "created_at",
                        "value": {
                            "gte": format(sub(new Date(), { months: 1 }), "yyyy-MM-dd"),
                            "lte": format(new Date(), "yyyy-MM-dd")
                        }
                    },
                    {
                        "type": "search-fulltext",
                        "key": "source",
                        "value": "intelligence"
                    }
                ]}>
                    <HomeDashboardWidgets/>
                </DataContainerProvider>
            </div>
        </div>
    )
}

function StatsGroupOverviewQL({ gte, lte } : { gte?: any, lte?: any}) {
    const {data: orgs, error, loading} = useQuery(gql`
        query getAggregationOverviewOrgsStats($aggs: AggregationRequest!) {
            aggregate(aggregations: $aggs) {
                aggregations
                took
            }
        }
    `, {
        variables: {
            aggs: {
                bucket: "organizations",
                list: [
                    {
                        key: "orgs",
                        type: "terms",
                        params: {
                            field: "type.keyword"
                        }
                    }
                ]
            }
        }
    })

    const {data: pOrgsData, error : pOrgsError, loading: pOrgsLoading} = useQuery(gql`
        query getAggregationOverviewOrgsStats($aggs: AggregationRequest!) {
            aggregate(aggregations: $aggs) {
                aggregations
                took
            }
        }
    `, {
        variables: {
            aggs: {
                query: {
                    filter: [
                        {
                            range: {
                                created_at: {
                                    lte: format(new Date(lte), "Y-MM-dd"),
                                    gte: format(new Date(gte), "Y-MM-dd")

                                }
                            }
                        }
                    ]
                },
                bucket: "organizations",
                list: [
                    {
                        key: "orgs",
                        type: "terms",
                        params: {
                            field: "type.keyword"
                        }
                    }
                ]
            }
        }
    })

    const {data: docs} = useQuery(gql`
        query getAggregationOverviewDocsStats($aggs: AggregationRequest!) {
            aggregate(aggregations: $aggs) {
                aggregations
                took
            }
        }
    `, {
        variables: {
            aggs: {
                bucket: "deals,people,users,media_mentions",
                list: [
                    {
                        key: "docs",
                        type: "terms",
                        params: {
                            field: "_index"
                        }
                    }
                ]
            }
        }
    })

    const {data: pDocsData} = useQuery(gql`
        query getAggregationOverviewDocsStats($aggs: AggregationRequest!) {
            aggregate(aggregations: $aggs) {
                aggregations
                took
            }
        }
    `, {
        variables: {
            aggs: {
                bucket: "deals,people,users,media_mentions",
                query: {
                    filter: [
                        {
                            range: {
                                created_at: {
                                    lte: format(new Date(lte), "Y-MM-dd"),
                                    gte: format(new Date(gte), "Y-MM-dd")
                                }
                            }
                        }
                    ]
                },
                list: [
                    {
                        key: "docs",
                        type: "terms",
                        params: {
                            field: "_index"
                        }
                    }
                ]
            }
        }
    })


    function getValueByKey(data: any, dataPath: string, key: string) {
        const path = get(data, dataPath, [])
        const bucket = find(path, {key})
        return bucket?.doc_count ?? 0
    }

    // console.log(pDocsData, docs)

    return (
        <StatsGroup
            stats={{
                options: {
                    stretchLast: true
                },
                stats: [
                    {
                        title: 'Startups',
                        value: getValueByKey(orgs, "aggregate.aggregations.orgs.buckets", "startup"),
                        type: 'CARD',
                        variation: getValueByKey(pOrgsData, "aggregate.aggregations.orgs.buckets", "startup"),
                    },
                    {
                        title: 'Investors',
                        value: getValueByKey(orgs, "aggregate.aggregations.orgs.buckets", "investor"),
                        type: 'CARD',
                        variation: getValueByKey(pOrgsData, "aggregate.aggregations.orgs.buckets", "investor"),
                    },
                    {
                        title: 'Hubs',
                        value: getValueByKey(orgs, "aggregate.aggregations.orgs.buckets", "hub"),
                        type: 'CARD',
                        variation: getValueByKey(pOrgsData, "aggregate.aggregations.orgs.buckets", "hub"),
                    },
                    {
                        title: 'NGO/Charity',
                        value: getValueByKey(orgs, "aggregate.aggregations.orgs.buckets", "ngo_charity"),
                        type: 'CARD',
                        variation: getValueByKey(pOrgsData, "aggregate.aggregations.orgs.buckets", "ngo_charity"),
                    },
                    {
                        title: 'Deals',
                        value: getValueByKey(docs, "aggregate.aggregations.docs.buckets", "deals"),
                        type: 'CARD',
                        variation: getValueByKey(pDocsData, "aggregate.aggregations.docs.buckets", "deals"),
                    },
                    {
                        title: 'People',
                        value: getValueByKey(docs, "aggregate.aggregations.docs.buckets", "people"),
                        type: 'CARD',
                        variation: getValueByKey(pDocsData, "aggregate.aggregations.docs.buckets", "people"),
                    },
                    {
                        title: 'Media Mentions',
                        value: getValueByKey(docs, "aggregate.aggregations.docs.buckets", "media_mentions"),
                        type: 'CARD',
                        variation: getValueByKey(pDocsData, "aggregate.aggregations.docs.buckets", "media_mentions"),
                    },
                    {
                        title: 'Users',
                        value: getValueByKey(docs, "aggregate.aggregations.docs.buckets", "users"),
                        type: 'CARD',
                        variation: getValueByKey(pDocsData, "aggregate.aggregations.docs.buckets", "users"),
                    },
                ]
            }}
        />
    )
}

function HomeDashboardWidgets() {
    const {
        addFilter,
        removeFilter,
        filtersActive,
        applyFilters,
        filtersQuery,
        clearAllFilters,
        isDirty
    } = useDataContainerContext()

    const from = _.get(_.find(filtersActive, {key: 'created_at'}), 'value.gte', format(new Date(), 'yyyy-MM-dd'))
    const to = _.get(_.find(filtersActive, {key: 'created_at'}), 'value.lte', format(new Date(), 'yyyy-MM-dd'))
    console.log(filtersActive)
    return (
        <div className="px-5 py-2.5 w-full">
            <div className="flex-grow">
                <MDSection title={`Briter Database Stats`}
                           subtitle={`Displaying data from ${from} to ${to}`}
                           actions={(
                               <div className="flex flex-grow gap-5 items-center self-end justify-end">

                                   <ActionFiltersBoolean
                                       type={"search-fulltext"} addFilter={addFilter} removeFilter={removeFilter}
                                       title={"Show Briter Intelligence"} id={"source"}
                                       defaultValues={_.find(filtersActive, {key: 'source', value: 'intelligence'})}
                                       value={"intelligence"}
                                   />
                                   <ActionFiltersBoolean
                                       type={"search-fulltext"} addFilter={addFilter} removeFilter={removeFilter}
                                       title={"Show AgBase Platform"} id={"source"}
                                       defaultValues={_.find(filtersActive, {key: 'source', value: 'agbase'})}
                                       value={"agbase"}
                                   />
                                   <ActionFiltersDateRange
                                       type="range"
                                       addFilter={addFilter}
                                       removeFilter={removeFilter}
                                       defaultValues={_.find(filtersActive, {key: 'created_at'})}
                                       id={"created_at"}
                                       title={""}
                                   />
                                   <ActionFiltersRefresh
                                       onClick={applyFilters}
                                       text={isDirty ? "Apply" : "Refresh"}
                                   />
                               </div>
                           )}
                >
                    <div className="py-5">
                        <StatsGroupOverviewQL lte={to} gte={from} />
                    </div>
                </MDSection>
                <div className="flex gap-5">
                    <div className="w-2/3">
                        <MDSection title={"User activities per hour"}>
                            <SimpleTimeChartGraphQL
                                query={FETCH_AGGREGATION_STAT}
                                root={"aggregate.aggregations"}
                                property={"activities_hours_col2d.buckets"}
                                filters={filtersQuery}
                                variables={{
                                    "bucket": "activities",
                                    "list": [
                                        {
                                            "key": "activities_hours_col2d",
                                            "type": "date_histogram",
                                            "params": {
                                                "field": "time",
                                                "calendar_interval": "day"
                                            }
                                        }
                                    ]
                                }}
                                queryMapper={(data: any) => {
                                    return data.map((datum: any) => ({
                                        label: format(parseISO(datum.key_as_string), 'Y-M-d H:mm'),
                                        value: datum.doc_count,
                                        showValue: 0,
                                        color: '#666666'
                                    }))
                                }}
                                config={{
                                    type: "splinearea",
                                    height: "275",
                                }}/>
                        </MDSection>
                    </div>
                    <div className="w-1/3">
                        <MDSection title={"Top Users on the platform"}>
                            <DataTableAggsGraphQL
                                headers={[
                                    {
                                        dataIndex: "key", title: "User",
                                        render: (e: any) => {
                                            const diff = differenceInMinutes(
                                                new Date(),
                                                parseISO(_.get(e, "n_one_activity.hits.hits[0]._source.created_at", ''))
                                            )
                                            return (
                                                <div className="flex gap-2.5 items-center">
                                                    <span className="text-sm">
                                                    {diff <= 5 && (
                                                        // @ts-ignore
                                                        <status-indicator
                                                            positive pulse
                                                            style={{height: "7.5px", width: "7.5px"}}
                                                        />
                                                    )}
                                                        {diff > 5 && diff <= 10 && (
                                                            // @ts-ignore
                                                            <status-indicator
                                                                style={{height: "7.5px", width: "7.5px"}}
                                                            />
                                                        )}
                                                        {diff > 10 && (
                                                            // @ts-ignore
                                                            <status-indicator
                                                                negative
                                                                style={{height: "7.5px", width: "7.5px"}}
                                                            />
                                                        )}
                                                    </span>
                                                    <span>{_.get(e, "key", "unknown")}</span>
                                                </div>
                                            )
                                        }
                                    },
                                    {dataIndex: "doc_count", title: "Queries"},
                                    {
                                        dataIndex: "created_at",
                                        render: (e: any) => {
                                            return formatDistance(
                                                parseISO(_.get(e, "n_one_activity.hits.hits[0]._source.created_at", '')),
                                                new Date(),
                                                {addSuffix: true}
                                            )
                                        }
                                    }
                                ]}
                                query={FETCH_AGGREGATION_STAT}
                                root={"aggregate.aggregations"}
                                property={"activities_by_user.buckets"}
                                filters={filtersQuery}
                                showMaxRows={5}
                                variables={{
                                    "bucket": "activities",
                                    "list": [
                                        {
                                            "key": "activities_by_user",
                                            "type": "terms",
                                            "params": {
                                                "field": "context.subject.name.keyword",
                                                "size": 1000,
                                                "order": {"last_activity": "desc"},
                                            },
                                            "extra": {
                                                "aggs": {
                                                    "n_one_activity": {
                                                        "top_hits": {
                                                            "size": 1,
                                                            "sort": [{"created_at": {"order": "desc"}}],
                                                            "_source": {
                                                                "includes": ["context", "created_at"]
                                                            }
                                                        }
                                                    },
                                                    "last_activity": {
                                                        "max": {
                                                            "field": "created_at"
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    ]
                                }}
                                data={[]}
                            />
                        </MDSection>
                    </div>
                </div>
                <MDSection
                    title={"Latest 25 Activities"}
                >
                    <DataTableGraphQL
                        headers={[
                            {
                                dataIndex: "time", title: "Detection Time",
                                render: (value: any) => {
                                    return formatDistance(new Date(value.created_at), new Date(), { addSuffix: true })
                                }
                            },
                            {
                                dataIndex: "subject_name",
                                title: "User",
                                render: (value: any) => {
                                    if (value.context?.subject?.type == "user") {
                                        return (
                                            <>
                                                <p>{value.context?.subject?.name}</p>
                                                <p className="text-xs text-bs-light-gray-800">{value.context?.subject?.id}</p>
                                            </>
                                        )
                                    }
                                    return JSON.stringify(value?.context?.subject)
                                }
                            },
                            {
                                dataIndex: "source", title: "Source",
                                render: (v: any) => {
                                    try {
                                        const u = (new URL(v.source))
                                        return u.hostname
                                    } catch (e) {
                                        return v.source
                                    }
                                }
                            },
                            {
                                dataIndex: "category",
                                title: "Category",
                                render: (v: any) => <Pillola text={v.category} variant="ghost"/>
                            },
                            {
                                dataIndex: "object_qname",
                                title: "Object",
                                render: (v: any) => {
                                    return (
                                        <div style={{display: "flex", gap: "0.5rem", width: "100%"}}>
                                                <Pillola text={v.context?.bucket} variant="neutral" />
                                            {
                                                v.context?.object?.name && (
                                                    <Pillola text={v.context?.object?.name} variant="positive" />
                                                )
                                            }

                                            {v.context?.bucket && (
                                                <TagDisplayer filter={v.context?.query?.filter} />
                                            )}
                                            {
                                                v.context?.object?.id && (
                                                    <span
                                                        className="text-xs text-bs-light-gray-800 pl-1.5">
                                                ({v.context?.object?.id?.substring(0, 8)})
                                            </span>
                                                )
                                            }
                                        </div>
                                    )
                                }
                            },
                            {
                                dataIndex: "action",
                                title: "Action",
                                render: (v: any) => <Pillola text={v.action} variant={"neutral"}/>
                            },
                            {dataIndex: "id", title: "ID", render: (value: any) => value.id.substring(0, 8)},
                        ]}
                        root="search"
                        property="items"
                        query={FETCH_SEARCH}
                        variables={{
                            search: {
                                bucket: "activities",
                                size: 25,
                                sort: [{key: "time", dir: "desc"}],
                            }
                        }}
                        filters={filtersQuery}
                        data={[]}
                    />
                </MDSection>
            </div>
        </div>
    )
}

export const TagDisplayer = ({ filter }: { filter: any }) => {
    return filter?.map((f: any) => {
        return Object?.keys(f).map((key) => {
            return Object?.keys(f[key]).map((type) => {
                if (_.isArray(f[key][type])) {
                    return (
                        f[key][type].map((value: any) => <Pillola text={value} variant="positive" />)
                    )
                }
                if (_.isObject(f[key][type])) {
                    return Object?.keys(f[key][type]).map((value) => <Pillola text={f[key][type][value]} variant="positive" />)
                }
            })
        })
    })
}