import React, {useEffect} from "react"
import {useParams} from "react-router";
import {gql, useLazyQuery, useQuery} from "@apollo/client";
import {get} from "lodash";


const  QUERY_FETCH_PERSON = gql`
    query fetchPerson($id: String!) {
        person(id: $id) {
            id
            first_name
            other_names
            gender
        }
    }
`

export const PersonContainer = () => {
    const {id} = useParams()

    const [fetchPerson,{data}] = useLazyQuery(QUERY_FETCH_PERSON,{variables: {id: id}})

    useEffect(() => {
        if(id) {
            fetchPerson().then((res) => {
                console.log("res",res)
            }).catch((err) => {
                console.log("err",err)
            })
        }
    },[id])

    return (
        <div style={{padding: "2rem"}}>
            <p>
                Name : {get(data,"person.first_name") + " " + get(data,"person.other_names")}
            </p>
            <p>
                Gender : {get(data,"person.gender")}
            </p>
        </div>
    )
}
