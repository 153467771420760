import React, {useState} from "react";
import {decorateWithOptions, FormBuilder} from "../../interface/Form/FormBuilder";
import {gql, useMutation, useQuery} from "@apollo/client";
import {get} from "lodash";
import {formatRFC3339} from "date-fns";
import Message from "../../interface/Message/Message";

const orgsInvolvedForm = [
    {
        type: "select",
        label: "Target Company",
        name: "target",
        required: true,
        register: {
            required: {value: false, message: "Target company is required."}
        },
        options: {}
    }, {
        type: "select",
        label: "Investors",
        name: "investors",
        required: false,
        options: {
            mode: "multiple",
            maxTagTextLength: 24,
            maxTagCount: 128
        }
    }
]
const dealMetadataFrom = [
    {
        type: "date",
        label: "Date",
        name: "date",
        required: true,
        options: {
            type: "date",
            formatLayout: "yyyy-MM-dd"
        }
    },
    {
        type: "text",
        label: "Amount",
        name: "amount",
        required: false,
        options: {
            type: "number",
            format: "currency",
            currency: "USD"
        }
    }, {
        type: "select",
        label: "Stage",
        name: "stage",
        required: false,
        options: {}
    }, {
        type: "select",
        label: "Type",
        name: "type",
        required: false,
        options: {}
    }, {
        type: "text",
        label: "Pre-Money Valuation",
        name: "pre_money",
        required: false,
        options: {}
    }, {
        type: "text",
        label: "Equity Sold",
        name: "equity",
        required: false,
        options: {}
    }
]
const form = [
    {
        type: "fieldset",
        direction: "horizontal",
        sets: [{
            type: "fieldset",
            title: "Organizations Involved",
            direction: "vertical",
            options: {
                style: {
                    width: "70%",
                    maxWidth: "70%",
                }
            },
            sets: orgsInvolvedForm
        }, {
            type: "fieldset",
            title: "Deal Metadata",
            direction: "vertical",
            sets: dealMetadataFrom
        }]
    }
]

const MUTATION_UPDATE_DEAL = gql`
mutation updateDeals($id: ID!, $req: UpsertDealRequest!) {
    updateDeal(id: $id, req: $req) {
        id
    }
}
`

const MUTATION_CREATE_DEAL = gql`
mutation createDeal($req: UpsertDealRequest!) {
    createDeal(req: $req) {
        id
    }
}
`

const FETCH_DROPDOWN_OPTIONS_FORM = gql`
query getOrganizations {
    organizations(limit: 15000) {
        data {
            id
            value: name
        }
    }
}
`
const DEALS_BY_ID = gql`
query getDealByID($id: ID!) {
  getDealByID(id: $id) {
     id
     target {
        id
        name
        value: name
     }
     investors {
        id
        name
        value: name
     }
     amount
     date
     currency_code
     type
     equity
     stage
  }
}
`

export default function UpsertDealsFragment({id, onCancel}: { id?: string, onCancel: any }) {
    const {data, loading, error} = useQuery(DEALS_BY_ID, {variables: {id}})
    const {data: opts, loading: optsLoading} = useQuery(FETCH_DROPDOWN_OPTIONS_FORM)
    const [update, {error: updateError, loading: updateLoading}] = useMutation(MUTATION_UPDATE_DEAL)
    const [create, {error: createError, loading: createLoading}] = useMutation(MUTATION_CREATE_DEAL)
    const [isSending, setIsSending] = useState(false)

    const options = decorateWithOptions([
        {dataPath: '[0].sets[0].sets[0].options.values', valuesPath: 'organizations.data', data: opts},
        {dataPath: '[0].sets[0].sets[1].options.values', valuesPath: 'organizations.data', data: opts},
    ], form)

    const onSubmit = (data: any) => {
        console.log("data", data)
        // transform data before submitting
        data["investors_id"] = data.investors.map((i: any) => i.id ?? i)
        delete data["investors"]

        data["target_id"] = data.target?.id ?? data.target
        delete data["target"]

        data["date"] = formatRFC3339(new Date(data["date"]))

        // submitting the function
        setIsSending(true)
        console.log("id", id)
        if (id != null) {
            update({
                variables: {
                    id, req: data
                }, refetchQueries: ['search']
            }).then((v) => console.log(v))
                .finally(() => setIsSending(false))
        } else {
            create({
                variables: {
                    req: data
                }, refetchQueries: ['search']
            }).then((v) => console.log(v))
                .finally(() => setIsSending(false))
        }
    }
    return (
        <div className="w-full">
            {
                (createError || updateError) && <Message variant={"error"}
                                                         size={"md"}>{createError?.message}{updateError?.message}</Message>
            }
            {
                loading && <p className="px-5 py-2.5">Loading deal data...</p>
            }
            {
                optsLoading && <p className="px-5 py-2.5">Loading dropdown data...</p>
            }
            {
                !loading && !optsLoading && (
                    <FormBuilder onSubmit={onSubmit} options={options}
                                 onCancel={onCancel}
                                 data={get(data, "getDealByID", {})}
                                 toc={false} isSending={isSending}
                                 debug={false}
                    />
                )
            }
        </div>
    )
}