import React, {useEffect} from "react"
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import {get} from "lodash";
import {FormBuilder} from "../../interface/Form/FormBuilder";

interface IUpsertTechnology {
    id?: string
    onCancel: any
}

export default function UpsertTechnology({id, onCancel}: IUpsertTechnology) {
    const [fetch, {data, loading}] = useLazyQuery(gql`
                query getTechnology($id: ID!) {
                    technology(id: $id) {
                        id
                        name
                        created_at
                        updated_at
                    }
                }
            `)
    const [update, {}] = useMutation(gql`
                mutation updateTechnology($id: ID!, $input: InputTechnology!) {
                    updateTechnology(id: $id, input: $input) {
                        id
                        name
                        created_at
                        updated_at
                    }
                }
            `)
    const [create, {}] = useMutation(gql`
                mutation createTechnology($input: InputTechnology!) {
                    createTechnology(input: $input) {
                        id
                        name
                        created_at
                        updated_at
                    }
                }
            `)

    useEffect(() => {
        if (id != undefined) {
            fetch({variables: {id}}).then(r => console.log(r))
        }
    }, [id])
    if (id != undefined && loading) {
        return <p>Loading...</p>
    }

    const onSubmit = (data: any) => {
        if (id != undefined) {
            update({variables: {id, input: data}})
        } else {
            create({variables: {input: data}})
        }
    }
    return (
        <div style={{width: "100%"}}>
            <FormBuilder onSubmit={onSubmit}
                         options={[
                             {
                                 type: "text",
                                 label: "Name",
                                 name: "name",
                                 required: true,
                                 register: {
                                     required: {value: false, message: "Name is required."}
                                 },
                             }
                         ]} data={get(data, "technology", {})} isSending={false}
            />
        </div>
    )
}