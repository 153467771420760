import React, {useEffect, useState} from "react"
import {styled} from "../../interface/stiches.config";
import {FormProvider, useForm} from "react-hook-form";
import {useFlash} from "../../context/FlashContext"
import {TextInput} from "../../interface/Form/Input/Inputs";
import {Button} from "../../interface/Button/Button";
import {gql, useSubscription} from "@apollo/client";

export const MEDIA_MENTION_SUBSCRIPTION = gql`
    subscription mediaMention {
        mediaMention {
            uri
            last_modified
        }
    }
`

export const CreateNewMediaContainer = () => {

    const methods = useForm({})
    const flash = useFlash()

    const [accumulatedData, setAccumulatedData] = useState([]);
    const {data, loading} = useSubscription(MEDIA_MENTION_SUBSCRIPTION,{
        onData: ({data}) => {
            console.log("data",data.data)
            setAccumulatedData(data.data)
        }
    })

    const onCreateMedia = (data: any) => {
        console.log("payload",data)
        flash("create media mention","success")
    }

    useEffect(() => {
        console.log("data",accumulatedData)
    },[accumulatedData])

    return (
        <StyleCreateMediaContainer>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onCreateMedia)}>
                    <div style={{marginBottom: "1rem"}}>
                        <TextInput
                            placeholder="https://example.com/media"
                            label={"URL"}
                            register={methods.register} name={"name"} />
                    </div>
                    <div>
                        <Button title={"Create"} type={"submit"} variant={"primary"}/>
                    </div>

                </form>
            </FormProvider>
        </StyleCreateMediaContainer>
    )
}

export const StyleCreateMediaContainer = styled("div",{
    paddingLeft: "4rem",
    paddingRight: "4rem",
    paddingTop: "2rem",
    width: "100%"
})