
import {baseTheme} from "./theme/BaseTheme";
import {LexicalComposer} from "@lexical/react/LexicalComposer";
import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin";
import {ContentEditable} from "@lexical/react/LexicalContentEditable";
import {HistoryPlugin} from "@lexical/react/LexicalHistoryPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
//@ts-ignore
import {HeadingNode, QuoteNode} from "@lexical/rich-text";
//@ts-ignore
import {TableCellNode, TableNode, TableRowNode} from "@lexical/table";
//@ts-ignore
import {ListItemNode, ListNode} from "@lexical/list";
//@ts-ignore
import {CodeHighlightNode, CodeNode} from "@lexical/code";
//@ts-ignore
import {AutoLinkNode, LinkNode} from "@lexical/link";
import {LinkPlugin} from "@lexical/react/LexicalLinkPlugin";
import {ListPlugin} from "@lexical/react/LexicalListPlugin";
import {MarkdownShortcutPlugin} from "@lexical/react/LexicalMarkdownShortcutPlugin";
//@ts-ignore
import {TRANSFORMERS} from "@lexical/markdown";

import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import {OnChangePlugin} from "@lexical/react/LexicalOnChangePlugin";
import {TabIndentationPlugin} from "@lexical/react/LexicalTabIndentationPlugin";
import "./style.css"
import {useFlash} from "../../../context/FlashContext";

// @ts-ignore
const editorConfig = {
    // The editor theme
    theme: baseTheme,

    // Handling of errors during update
    //@ts-ignore
    onError(error) {
        throw error;
    },
    // Any custom nodes go here
    nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode,
    ]
};

export function WYSIWYGAreaEditorInput({value, onChange, classNameContentEditable = ""}: any) {
    const flash = useFlash()
    return (
        <LexicalComposer
            initialConfig={{
                ...editorConfig,
                editorState: value,
                namespace: "",
                onError: (err: any) => {
                    flash(err.message, "error")
                }
            }}>
            <div className="editor-container h-full">
                <ToolbarPlugin/>
                <div className="editor-inner h-full">
                    <RichTextPlugin
                        contentEditable={
                            <ContentEditable
                                className={`min-h-[200px] outline-1 outline-bs-light-gray-100 p-2 ${classNameContentEditable}`}
                                content={value}
                            />
                        }
                        placeholder={<></>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />
                    <OnChangePlugin onChange={(editorState, editor) => {
                        onChange(JSON.stringify(editorState.toJSON()))
                    }}/>
                    <HistoryPlugin/>
                    <ListPlugin/>
                    <TabIndentationPlugin/>
                    <LinkPlugin/>
                    <AutoLinkPlugin/>
                    <MarkdownShortcutPlugin transformers={TRANSFORMERS}/>
                </div>
            </div>
        </LexicalComposer>
    );
}