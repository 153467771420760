import React, {useEffect, useState} from "react"
import {Controller, useForm} from "react-hook-form";
import {DatePicker, Input, InputImage, InputSelect, Textarea} from "../../../interface/Form/Input";
import {Button} from "../../../interface/Button/Button";
import {get} from "lodash";
import {gql, useMutation, useQuery} from "@apollo/client";
import {format, formatRFC3339} from "date-fns";
import Message from "../../../interface/Message/Message";
import {MultipleLocationInput} from "../../../interface/Form/InputLocation";
import {
    FETCH_INITIATIVE_DROPDOWN_OPTIONS,
    FETCH_INITIATIVE,
    UPDATE_INITIATIVE,
    CREATE_INITIATIVE,
    DELETE_INITIATIVE
} from "../../../queries/"

interface TInitiative {
    name?: string
    description?: string
    body?: string
}

interface IModalFragment {
    id?: string;
    onSuccess?: (value?: any) => void;
    onClose?: (value?: any) => void;
}

const mapLocationsToGraphQL = (locations: any) => {
    return locations.map((o: any) => {
        return {
            id: get(o, 'id', null),
            address: get(o, 'address', ''),
            city: {
                id: get(o, 'city.id', null),
                name: get(o, 'city.name', null)
            },
            sub_division: {
                id: get(o, 'sub_division.id', null)
            }
        }
    })
}

export default function EditInitiativeFragment({...props}: IModalFragment) {
    const {id = undefined, onSuccess, onClose} = props;
    const {
        register,
        handleSubmit,
        reset,
        setValue,
        control,
        getValues,
        formState: {errors, isDirty}
    } = useForm({});
    const {data, loading, refetch} = useQuery(FETCH_INITIATIVE, {
        variables: {
            id: id
        }
    })

    const [state, setState] = useState({
        hasError: false,
        errorMessage: '',
        hasSuccess: false
    });

    const [update, {data: updated, loading: upsLoading, error: upsError}] = useMutation(UPDATE_INITIATIVE, {})
    const [create, {data: created, loading: cLoading, error: cError}] = useMutation(CREATE_INITIATIVE, {
        errorPolicy: "all"
    })
    const [remove, {data: removed, loading: rLoading, error: rError}] = useMutation(DELETE_INITIATIVE, {
        errorPolicy: "all",
        refetchQueries: ['search']
    })

    const {data: opts, loading: optsLoading} = useQuery(FETCH_INITIATIVE_DROPDOWN_OPTIONS)

    useEffect(() => {
        reset(get(data, 'initiative', {}))
    }, [data])

    const onSubmit = (data: any) => {
        const test = {
            ...data,
            organizersID: get(data, 'organizers', []).map((o: any) => o.key || o.id),
            sponsorsID: get(data, 'sponsors', []).map((o: any) => o.key || o.id),
            sectorsID: get(data, 'sectors', []).map((o: any) => o.key || o.id),
            organizationsSpeakersID: get(data, 'organizations_speakers', []).map((o: any) => o.key || o.id),
            countriesID: get(data, 'countries_interests', []).map((o: any) => o.key || o.id),
            initiativeFormatsID: get(data, 'formats', []).map((o: any) => o.key || o.id),
            locations: mapLocationsToGraphQL(get(data, 'locations', []))
        }
        delete test.id
        delete test.formats
        delete test.countries_interests
        delete test.organizers
        delete test.sponsors
        delete test.sectors
        delete test.organizations_speakers
        console.log("remapping", data, test);
        if (id) {
            update({variables: {id: id, input: test}})
                .then((res) => {
                    if (onSuccess) onSuccess(res)
                    setState({...state, hasSuccess: true})
                    refetch()
                }).catch((err) => {
                setState({...state, hasError: true, errorMessage: err.message})
            })
        } else {
            create({variables: {input: test}})
                .then((res) => {
                    if (onSuccess) onSuccess(res)
                    setState({...state, hasSuccess: true})
                    refetch()
                }).catch((err) => {
                setState({...state, hasError: true, errorMessage: err.message})
            })
        }

    };

    if (loading) {
        return <p className="p-5">Loading...</p>
    }

    const sectors = get(opts, 'sectors.data', []).map((s: any) => ({
        title: s.name,
        value: s.value,
        key: s.id,
        id: s.id
    }))
    const organizations = get(opts, 'organizations.data', []).map((s: any) => ({
        title: s.name,
        value: s.value,
        key: s.id,
        id: s.id
    }))
    const formats = get(opts, 'initiativeFormats.data', []).map((s: any) => ({
        title: s.name,
        value: s.value,
        key: s.id,
        id: s.id
    }))

    const countries = get(opts, 'countries.data', []).map((s: any) => ({
        title: s.name,
        value: s.value,
        key: s.id,
        id: s.id
    }))

    // @ts-ignore
    return (
        <div className="flex flex-col w-full h-full">
            {state.hasSuccess && (
                <Message variant={"success"} size={"md"} autoHide={true} expiration={10000}
                         absolute={true}
                         onClose={() => setState({...state, hasSuccess: false})}>
                    Success
                </Message>
            )}
            {state.hasError && (
                <Message variant={"error"} size={"md"} autoHide={true} expiration={10000}
                         absolute={true}
                         onClose={() => setState({...state, hasError: false})}>
                    (error) {typeof state.errorMessage === "string" ? state.errorMessage : JSON.stringify(state.errorMessage)}
                </Message>
            )}
            <div className="p-5 flex-grow">
                <form onSubmit={handleSubmit(onSubmit)} className="flex gap-16">
                    <div className="flex flex-col w-2/3 gap-5">
                        <Input
                            label="Event Name"
                            type={"text"}
                            {...register("title", {
                                required: {value: true, message: "Field is required"},
                                minLength: {value: 2, message: "At least 2 characters"}
                            })}
                            description="Input the title of the event."
                            required={true} errors={get(errors, 'name')}
                        />
                        <Input
                            label="URL/Link to event registration"
                            type={"text"}
                            {...register("uri", {
                                required: {value: true, message: "Field is required"},
                                minLength: {value: 2, message: "At least 2 characters"}
                            })}
                            description="URL of the event, or registration link."
                            required={true} errors={get(errors, 'uri')}
                        />
                        <Textarea
                            label="Description"
                            type={"text"}
                            {...register("description", {
                                required: {value: true, message: "Field is required"},
                                minLength: {value: 2, message: "At least 2 characters"}
                            })}
                            required={true} errors={get(errors, 'description')}/>
                        <Controller
                            control={control}
                            render={({field}) => {
                                return (
                                    <MultipleLocationInput
                                        onChange={(data) => {
                                            console.log("locations", data)
                                            setValue("locations", data)
                                        }}
                                        type={"string"}
                                        name={"locations"}
                                        label={"Locations"}
                                        values={field.value}
                                        required={false}
                                    />
                                )
                            }}
                            name={"locations"}
                        />
                        <div className="flex w-full gap-5">
                            <Controller
                                name="sectors"
                                control={control}
                                render={({field}) => {
                                    return (
                                        <InputSelect
                                            description="Sectors relevant to this event"
                                            label="Sectors of Interest"
                                            loading={optsLoading}
                                            options={{options: sectors}}
                                            type="multiple"
                                            value={field.value}
                                            onChange={(value: any, options: any) => {
                                                setValue("sectors", options)
                                            }}
                                            getValues={getValues}
                                            setValue={setValue}
                                        />
                                    )
                                }}
                            />
                            <Controller
                                name="type"
                                control={control}
                                render={({field}) => {
                                    return (
                                        <InputSelect
                                            getValues={getValues}
                                            setValue={setValue}
                                            value={field.value}
                                            required={true}
                                            options={{
                                                options: [
                                                    {id: 'IN-PERSON', key: 'IN-PERSON', value: 'IN-PERSON'},
                                                    {id: 'ONLINE', key: 'ONLINE', value: 'ONLINE'},
                                                    {id: 'CFA', key: 'CFA', value: 'CFA'},
                                                ]
                                            }}
                                            onChange={(value: any, options: any) => {
                                                setValue("type", value)
                                            }}
                                            name={'type'}
                                            label="Type of Event"
                                            description="Type of event"
                                            type="single"
                                        />
                                    )
                                }}
                            />
                            <Controller
                                name="formats"
                                control={control}
                                render={({field}) => {
                                    return (
                                        <InputSelect
                                            description="Format of the event (ex. conference, roundtable, networking event, etc.)"
                                            label="Format"
                                            loading={optsLoading}
                                            options={{options: formats}}
                                            type="multiple"
                                            value={field.value}
                                            onChange={(value: any, options: any) => {
                                                setValue("formats", options)
                                            }}
                                            getValues={getValues}
                                            setValue={setValue}
                                        />
                                    )
                                }}
                            />
                        </div>
                        <Textarea
                            type={"textarea"}
                            label="Body"
                            {...register("body", {
                                required: {value: true, message: "Field is required"},
                                minLength: {value: 2, message: "At least 2 characters"}
                            })}
                            required={true} errors={get(errors, 'body')}/>
                    </div>
                    <div className="flex flex-col w-1/3 gap-5">
                        <InputImage
                            label="Cover/Banner (image, aspect ratio 8/3)"
                            getValues={getValues}
                            setValue={(v: string) => setValue("banner", v)}
                            {...register("banner", {
                                required: {value: false, message: "Banner/cover is required."}
                            })}
                            description="Briter Platform is optimised for 8/3 aspect ratio event covers"
                            type={"image"} required={true} errors={get(errors, "banner")}
                        />
                        <div className="flex gap-5">
                            <Controller
                                name="start_at"
                                control={control}
                                render={({ field }: any) => {
                                    return (
                                        <input
                                            type="datetime-local"
                                        />
                                    )
                                }}
                            />
                            <Controller
                                name="end_at"
                                control={control}
                                render={({ field }: any) => {
                                    return (
                                        <input
                                            type="datetime-local"
                                        />
                                    )
                                }}
                            />
                            {/*<Controller*/}
                            {/*    name={"start_at"}*/}
                            {/*    control={control}*/}
                            {/*    render={({field}) => {*/}
                            {/*        return (*/}
                            {/*            <DatePicker*/}
                            {/*                type={"datetime-local"}*/}
                            {/*                label={`Start Date (${format(new Date(), 'O')})`}*/}
                            {/*                description={`Time is set on your time zone`}*/}
                            {/*                value={field.value}*/}
                            {/*                getValues={getValues}*/}
                            {/*                required={true} errors={get(errors, "start_at")}*/}
                            {/*                onChange={(ev: any) => {*/}
                            {/*                    try {*/}
                            {/*                        const value = formatRFC3339(new Date(ev.target.value))*/}
                            {/*                        setValue("start_at", value)*/}
                            {/*                    } catch (e) {*/}
                            {/*                        console.log(e)*/}
                            {/*                    }*/}
                            {/*                }}*/}
                            {/*            />*/}
                            {/*        )*/}
                            {/*    }}*/}
                            {/*/>*/}
                            {/*<Controller*/}
                            {/*    name={"end_at"}*/}
                            {/*    control={control}*/}
                            {/*    render={({field}) => {*/}
                            {/*        return (*/}
                            {/*            <DatePicker*/}
                            {/*                type={"datetime-local"}*/}
                            {/*                label={`Finish Date (${format(new Date(), 'O')})`}*/}
                            {/*                value={field.value}*/}
                            {/*                getValues={getValues}*/}
                            {/*                required={true} errors={get(errors, "end_at")}*/}
                            {/*                onChange={(ev: any) => {*/}
                            {/*                    try {*/}
                            {/*                        const value = formatRFC3339(new Date(ev.target.value))*/}
                            {/*                        setValue("end_at", value)*/}
                            {/*                    } catch (e) {*/}
                            {/*                        console.log(e)*/}
                            {/*                    }*/}
                            {/*                }}*/}
                            {/*            />*/}
                            {/*        )*/}
                            {/*    }}*/}
                            {/*/>*/}
                        </div>
                        <Controller
                            name="organizers"
                            control={control}
                            render={({field}) => {
                                return (
                                    <InputSelect
                                        description="Company organising the event"
                                        label="Organisers"
                                        loading={optsLoading}
                                        options={{options: organizations}}
                                        type="multiple"
                                        value={field.value}
                                        onChange={(value: any, options: any) => {
                                            setValue("organizers", options)
                                        }}
                                        getValues={getValues}
                                        setValue={setValue}
                                    />
                                )
                            }}
                        />
                        <Controller
                            name="sponsors"
                            control={control}
                            render={({field}) => {
                                return (
                                    <InputSelect
                                        description="Companies sponsoring the event"
                                        label="Sponsors"
                                        loading={optsLoading}
                                        options={{options: organizations}}
                                        type="multiple"
                                        value={field.value}
                                        onChange={(value: any, options: any) => {
                                            setValue("sponsors", options)
                                        }}
                                        getValues={getValues}
                                        setValue={setValue}
                                    />
                                )
                            }}
                        />
                        <Controller
                            name="organizations_speakers"
                            control={control}
                            render={({field}) => {
                                return (
                                    <InputSelect
                                        description="Companies speaking at the event"
                                        label="Speakers"
                                        loading={optsLoading}
                                        options={{options: organizations}}
                                        type="multiple"
                                        value={field.value}
                                        onChange={(value: any, options: any) => {
                                            setValue("organizations_speakers", options)
                                        }}
                                        getValues={getValues}
                                        setValue={setValue}
                                    />
                                )
                            }}
                        />
                        <Controller
                            name="countries_interests"
                            control={control}
                            render={({field}) => {
                                return (
                                    <InputSelect
                                        getValues={getValues}
                                        setValue={setValue}
                                        value={field.value}
                                        onChange={(value: any, options: any) => {
                                            setValue("countries_interests", options)
                                        }}
                                        loading={optsLoading}
                                        options={{options: countries}}
                                        name="countries_interests"
                                        label="Countries of Interest"
                                        description="Countries this event has relevance"
                                        type="multiple"
                                    />
                                )
                            }}
                        />
                    </div>
                </form>
            </div>
            <div className="p-5 flex border-t-bs-light-gray-100 border-t-2">
                <div className="flex-grow flex">
                    {(
                        <>
                            <Button title="Save" size="sm" variant="primary" onClick={handleSubmit(onSubmit)}/>
                            <Button title="Discard" size="sm" variant="link" onClick={() => onClose && onClose()}/>
                        </>
                    )}
                </div>
                <div>
                    {
                        id && (
                            <Button title="Delete" size="sm" variant="danger"
                                    onClick={(ev) => {
                                        ev.preventDefault()
                                        remove({variables: {id: id}})
                                            .then((res) => {
                                                if (onSuccess) {
                                                    onSuccess(res)
                                                }
                                                if (onClose) {
                                                    onClose()
                                                }
                                            }).catch((err) => {
                                            setState({...state, hasError: true, errorMessage: err})
                                        })
                                    }}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    )
}
