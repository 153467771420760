import {createStitches} from '@stitches/react';
//@ts-ignore
import {reset} from "stitches-reset";

const {
    styled,
    globalCss,
    createTheme,
} = createStitches({
    ...reset,
    theme: {
        colors: {
            gray400: 'gainsboro',
            gray500: 'lightgray',
            default: "#ffdb80",
        },
    }
});

const globalStyles = globalCss({
    ...reset,
    "@import": ["https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&display=swap"],
    html: {
        height: "100%",
    },
    "h1, h2, h3, h4, h5, h6, p, blockquote, a, abbr, acronym, address, big, cite, code, del, dfn, em": {
        fontFamily: "Manrope, sans-serif",
    },
    body: {
        fontFamily: "Manrope, sans-serif",
        fontSize: "0.9vw",
        fontWeight: 400,
        height: "100%",
        a: {
            color: "#151515",
            fontWeight: 500,
            textDecoration: "none"
        },
        "div#root": {
            height: "100%",
        }
    }
});

globalStyles()

export {styled, createTheme}
export const darkTheme = createTheme({
    colors: {
        hiContrast: 'hsl(206,2%,93%)',
        loContrast: 'hsl(206,8%,8%)',

        gray100: 'hsl(206,8%,12%)',
        gray200: 'hsl(206,7%,14%)',
        gray300: 'hsl(206,7%,15%)',
        gray400: 'hsl(206,7%,24%)',
        gray500: 'hsl(206,7%,30%)',
        gray600: 'hsl(206,5%,53%)',
    },
    space: {},
    fonts: {},
});
