import { forwardRef } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

export const MultiSelectComboBox = forwardRef(
    ({ name, control, options, label, isMulti = true }: any, ref) => {
        return (
            <div className="flex flex-col gap-1">
                <label className="pb-1 text-sm text-bs-light-black">
                    {label}
                </label>
                <Controller
                    name={name}
                    control={control}
                    render={({field}) => {
                        return (
                            <Select
                                {...field}
                                styles={{
                                    // @ts-ignore
                                    control: (base) => ({
                                        ...base,
                                        background: "rgb(249 250 251)",
                                    }),
                                    option: (base, state) => ({
                                        ...base,
                                        backgroundColor: state.isSelected
                                            ? "#FFDB80FF"
                                            : "white",
                                        color: state.isSelected
                                            ? "black"
                                            : "black",
                                    }),
                                    multiValueLabel: (base, props) => ({
                                        ...base,
                                        backgroundColor: "#FFDB80FF",
                                        color: "black",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(252,186,122,0.68)",
                                            borderColor: "red",
                                        },
                                    }),
                                    multiValueRemove: (base, props) => ({
                                        ...base,
                                        backgroundColor: "#FFDB80FF",
                                        color: "black",
                                    }),
                                }}
                                options={options}
                                isMulti={isMulti}
                                getOptionLabel={(option) =>
                                    option.value || option.name
                                }
                                getOptionValue={(option) => option.id}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 2.5,
                                })}
                            />
                        );
                    }}
                />
            </div>
        );
    },
);

export const SelectComboBox = forwardRef(
    ({ name, control, options, label }: any, ref) => {
        return (
            <div>
				<div role="label" className="text-sm text-bs-light-gray-800" style={{marginBottom: "0.25rem"}}>
					{label}
				</div>
                <Controller
                    name={name}
                    control={control}
                    render={({ field }) => {
                        return (
                            <Select
                                {...field}
                                theme={(theme) => ({
                                    ...theme,
                                    borderRadius: 2.5,
                                    colors: {
                                        ...theme.colors,
                                    },
                                })}
                                styles={{
                                    option: (base, state) => ({
                                        ...base,
                                        backgroundColor: state.isSelected
                                            ? "#FFDB80FF"
                                            : "white",
                                        color: state.isSelected
                                            ? "black"
                                            : "black",
                                    }),
                                    multiValueLabel: (base, props) => ({
                                        ...base,
                                        backgroundColor: "#FFDB80FF",
                                        color: "black",
                                        "&:hover": {
                                            backgroundColor:
                                                "rgba(252,186,122,0.68)",
                                            borderColor: "red",
                                        },
                                    }),
                                    multiValueRemove: (base, props) => ({
                                        ...base,
                                        backgroundColor: "#FFDB80FF",
                                        color: "black",
                                    }),
                                }}
                                value={options.find((opt: any) =>
                                    name === "number_employees"
                                        ? opt.value === field.value
                                        : opt.id === field.value,
                                )}
                                options={options}
                                isMulti={false}
                                getOptionLabel={(option) =>
                                    option.value || option.name
                                }
                                getOptionValue={(option) => option.id}
                            />
                        );
                    }}
                />
            </div>
        );
    },
);

export const CreateableMultiSelectComboBox = ({
    name,
    control,
    options,
    label,
    loading,
    onCreate,
}: {} & any) => {
    return (
        <div>
            <label className="mb-2 block text-sm font-medium text-gray-900 dark:text-gray-900">
                {label}
            </label>
            <Controller
                control={control}
                render={({ field }) => {
                    return (
                        <CreatableSelect
                            {...field}
                            styles={{
                                // @ts-ignore
                                option: (base, state) => ({
                                    ...base,
                                    backgroundColor: state.isSelected
                                        ? "#FFDB80FF"
                                        : "white",
                                    color: state.isSelected ? "black" : "black",
                                }),
                                multiValueLabel: (base, props) => ({
                                    ...base,
                                    backgroundColor: "#FFDB80FF",
                                    color: "black",
                                    "&:hover": {
                                        backgroundColor:
                                            "rgba(252,186,122,0.68)",
                                        borderColor: "red",
                                    },
                                }),
                                multiValueRemove: (base, props) => ({
                                    ...base,
                                    backgroundColor: "#FFDB80FF",
                                    color: "black",
                                }),
                            }}
                            isClearable
                            isLoading={loading}
                            onCreateOption={onCreate}
                            options={options}
                            defaultValue={field.value}
                            getOptionLabel={(option) =>
                                option.value || option.name
                            }
                            getOptionValue={(option) => option.id}
                        />
                    );
                }}
                name={name}
            />
        </div>
    );
};

// export function Select({options}: { options: any[] }) {
//     const {
//         isOpen,
//         selectedItem,
//         getToggleButtonProps,
//         getLabelProps,
//         getMenuProps,
//         highlightedIndex,
//         getItemProps,
//     } = useSelect({
//         items: options
//     });
//     return (
//         <div className="py-1.5 relative">
//             <div className="w-full flex flex-col text-sm">
//                 <button
//                     aria-label="toggle menu"
//                     className="py-2 bg-transparent border-b border-b-bs-light-border-light flex justify-between"
//                     type="button"
//                     {...getToggleButtonProps()}
//                 >
//                     <span>{selectedItem ? selectedItem.title : '-'}</span>
//                     <span className="px-2">{isOpen ? <ChevronUpIcon className="w-4 h-4"/> :
//                         <ChevronDownIcon className="w-4 h-4"/>}</span>
//                 </button>
//             </div>
//             <ul
//                 {...getMenuProps()}
//                 className="absolute w-full bg-white shadow-md max-h-80 overflow-scroll"
//             >
//                 {isOpen &&
//                     options.map((item, index) => (
//                         <li
//                             className={classNames({
//                                 'bg-gray-300 cursor-pointer': highlightedIndex === index,
//                                 'font-bold': selectedItem === item,
//                                 'py-1.5 px-4 shadow-sm flex flex-col text-sm': true,
//                             })}
//                             key={`${item.value}${index}`}
//                             {...getItemProps({item, index})}
//                         >
//                             <span>{item.title}</span>
//                         </li>
//                     ))}
//             </ul>
//         </div>
//     )
// }
