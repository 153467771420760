import React, {useEffect, useRef, useState} from "react";
import classNames from "classnames";
import {useOnClickOutside} from "usehooks-ts";
import {usePlatformContext} from "../../../context/PlatformContext";
import {
    ClipboardListIcon,
    CurrencyDollarIcon,
    IdentificationIcon,
    OfficeBuildingIcon,
    PlusCircleIcon,
    UserCircleIcon
} from "@heroicons/react/outline";
import EditInitiativeFragment from "../../../containers/initiatives/fragments/EditInitiativeFragment";
import UpsertDealsFragment from "../../../containers/fragments/UpsertDealsFragment";
import EditCompanyFragment from "../../../containers/companies/fragments/EditCompanyFragment";
import {useNavigate} from "react-router-dom";

export const MenuCreator = () => {
    const [state, setState] = useState({
        isOpen: false,
        left: 0
    })

    const onNavigate = (path: string) => {
        navigate(path)
        setState({...state,isOpen: false})
    }
    const navigate = useNavigate()
    const clsButton = classNames({
        "text-bs-light-white outline-0  border-0 items-center justify-center flex gap-2": true,
    });
    const clsMenuOuter = classNames({
        "absolute bg-bs-light-gray-50 p-10 gap-10 flex rounded-md z-20 my-5 left-0 drop-shadow-2xl shadow-md flex gap-2.5": true,
        "border border-bs-light-gray-500": true,
        "invisible": !state.isOpen
    })
    const menuRef = useRef(null);
    useOnClickOutside(menuRef, () => setState({...state, isOpen: false}));
    const {bindKeys, addPane, removePaneByID} = usePlatformContext();

    const createNewOrganization = () => {
        const id = `create-organization-${Math.random()}`
        addPane({
            content: <EditCompanyFragment onCancel={() => removePaneByID(id)}/>,
            id,
            title: "Create (organizations)",
            type: "create"
        })
        setState({...state, isOpen: false})
    }

    const createNewDeal = () => {
        const id = `create-deal-${Math.random()}`
        addPane({
            content: <UpsertDealsFragment onCancel={() => removePaneByID(id)}/>,
            id,
            title: "Create (deals)",
            type: "create"
        })
        setState({...state, isOpen: false})
    }

    const createInitiative = () => {
        const id = `create-initiative-${Math.random()}`
        addPane({
            content: <EditInitiativeFragment onClose={() => removePaneByID(id)}/>,
            id: id,
            title: "Create (initiatives)",
            type: "create"
        })
        setState({
            ...state, isOpen: false
        });
    }

    useEffect(() => {
        // Set current position, 17 is 35px of button divided by 2 (in order to have a perfect center)
        setState({
            // @ts-ignore
            ...state, left: -(menuRef?.current?.offsetWidth / 2 - 17)
        });

        bindKeys({CREATE_NEW: {sequence: "command+a", name: "Create a new resource"}}, {
            CREATE_NEW: (event: any) => {
                event.preventDefault();
                setState({
                    ...state, isOpen: !state.isOpen
                });
            }
        })
        bindKeys({CREATE_NEW_DEAL: {sequence: "command+a 1", name: "Create a new deal"}}, {
            CREATE_NEW_DEAL: (event: any) => {
                event.preventDefault();
                createNewDeal();
            }
        })
        bindKeys({CREATE_NEW_COMPANY: {sequence: "command+a 2", name: "Create a new deal"}}, {
            CREATE_NEW_COMPANY: (event: any) => {
                event.preventDefault();
                createNewOrganization();
            }
        })
        bindKeys({CREATE_NEW_EVENT: {sequence: "command+a 3", name: "Create a new initiative"}}, {
            CREATE_NEW_EVENT: (event: any) => {
                event.preventDefault();
                createInitiative();
            }
        })
    }, [menuRef.current])

    // @ts-ignore
    return (
        <div className="relative">
            <button
                className={clsButton}
                onClick={() => setState({...state, isOpen: true})}
            >
                <PlusCircleIcon className="h-5 w-5"/>
                <span className="text-xs text-gray-400">[⌘ C]</span>
                {
                    state.isOpen && (
                        <svg
                            className="absolute text-bs-light-black h-4 w-full left-0 top-full"
                            x="0px" y="0px"
                            viewBox="0 0 255 255"
                            xmlSpace="preserve">
                            <polygon className="fill-current" points="0,0 127.5,127.5 255,0"/>
                        </svg>
                    )
                }
            </button>
            <div
                ref={menuRef}
                className={clsMenuOuter}
                style={{left: state.left}}
            >
                <MenuCreatorIcon title="Deal" hotkey="1" onClick={() => onNavigate("create/deals")}
                                 icon={<CurrencyDollarIcon strokeWidth={"1"} className="w-10 h-10"/>}/>
                <MenuCreatorIcon title="Company" hotkey="2" onClick={() => onNavigate("/create/orgs")}
                                 icon={<OfficeBuildingIcon strokeWidth={"1"} className="w-10 h-10"/>}/>
                <MenuCreatorIcon title="User" hotkey="3"
                                 icon={<IdentificationIcon strokeWidth={"1"} className="w-10 h-10"/>}/>
                <MenuCreatorIcon title="Initiative" hotkey="4" onClick={() => onNavigate("/create/initiative")}
                                 icon={<ClipboardListIcon strokeWidth={"1"} className="w-10 h-10"/>}/>
                <MenuCreatorIcon title="Person" hotkey="5"
                                 onClick={() => onNavigate("/create/person")}
                                 icon={<UserCircleIcon strokeWidth={"1"} className="w-10 h-10"/>}/>
                <MenuCreatorIcon title="Media" hotkey="6"
                                 onClick={() => onNavigate("/create/media")}
                                 icon={<ClipboardListIcon strokeWidth={"1"} className="w-10 h-10"/>}/>
            </div>
        </div>
    )
}

interface IMenuCreatorIcon {
    title: string;
    hotkey: string;
    icon: JSX.Element,
    onClick?: () => void;
}

const MenuCreatorIcon = ({title, hotkey, icon, onClick}: IMenuCreatorIcon) => {
    return (
        <div
            className="p-5 border w-32 h-32 flex items-center flex-col relative hover:cursor-pointer hover:bg-bs-light-gray-100"
            onClick={onClick}
        >
            <span
                className="absolute border-dotted bg-opacity-80 border-white border right-1 top-1 text-xs rounded-md bg-bs-light-black text-white py-0.5 px-2">{hotkey}</span>
            <div className="relative w-16 h-16 items-center justify-center flex">
                {icon}
            </div>
            <p className="text-center text-bs-light-gray-800 uppercase font-semibold">
                <span>{title}</span>
            </p>
        </div>
    )
}