import {gql} from "@apollo/client";

export const FETCH_USER_ACTIVITIES_STATS = gql`
query getActivityStats($filters: FiltersInput) {
    getActivityStats(filters: $filters) {
        stats {
            title: label
            value
        }
    }
}
`

export const FETCH_USER_ACTIVITIES = gql`
query activitiesByUser($id: ID!, $filters: FiltersInput, $offset: Int, $limit: Int) {
    activitiesByUser (id: $id, filters: $filters, offset: $offset, limit: $limit) {
        data {        
            id
            time
            action
            category
            subject {
                ... on Platform {
                    id
                    name
                }
                ... on User {
                    id
                    first_name
                    other_names
                    email
                }
                ... on Organization {
                    id
                    name
                }
            }
            object {
                ... on Platform {
                    id
                    name
                }
                ... on User {
                    id
                    name: first_name
                    email
                }
                ... on Organization {
                    id
                    name
                }
            }
        }
        count
        pages
        current
    }
}
`;

export const FETCH_USERS_ACTIVITIES = gql`
query activities($filters: FiltersInput, $offset: Int, $limit: Int) {
    activities (filters: $filters, offset: $offset, limit: $limit) {
        data {        
            id
            time
            action
            category
            subject {
                ... on Platform {
                    id
                    name
                }
                ... on User {
                    id
                    first_name
                    other_names
                    email
                }
                ... on Organization {
                    id
                    name
                }
            }
            object {
                ... on Platform {
                    id
                    name
                }
                ... on User {
                    id
                    name: first_name
                    email
                }
                ... on Organization {
                    id
                    name
                }
            }
        }
        count
        pages
        current
    }
}
`;