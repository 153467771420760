import { FormProvider, useForm } from "react-hook-form"
import { TextAreaInput, TextInput } from "../../interface/Form/Input/Inputs"
import { Tabs } from "../../interface/Tabs/Tabs"
import { SelectComboBox } from "../../interface/Form/Select/Select"
import { FormStateContext, FormStateProvider, formStateReducer, useFormContext } from "./FormStateContext"
import { Button } from "../../interface/Button/Button"
import { useEffect, useReducer, useState } from "react"
import { useParams } from "react-router"
import { useLazyQuery } from "@apollo/client"
import { GET_ORG } from "../companies/queries"
import { get } from "lodash"

export const MultiStepFormContainer = () => {

    const {id} = useParams()

    const [fetchOrganization, { data, loading }] = useLazyQuery(GET_ORG, {
        variables: {
            id: id
        }
    })


    useEffect(() => {
        if(id) {
            fetchOrganization()
                .then((res) => {
                    console.log("res",res)
                }).catch((err) => {
                    console.log("err",err)
                })
        }
    },[id])

    const [state,dispatch] = useReducer(formStateReducer,{organization: get(data,"organization",{name: "test"}),index: "primary-information"})

    return (
        <FormStateContext.Provider value={{state,dispatch}}>
            <MultiStepForm />
        </FormStateContext.Provider>
    )
}

export interface MultiStepFormProps {
    organization?: any
}

export const MultiStepForm = ({...props} : MultiStepFormProps) => {
    const { state,dispatch } = useFormContext()
    const [index,setIndex] = useState(state.index)

    const steps = {
        "primary-information": {
            title: 'Primary Information',
            key: 'primary-information',
            pane: <PrimaryInformationForm />
        },
        "impact-information": {
            title: 'Impact Information',
            key: 'impact-information',
            pane: <ImpactInformationForm />
        },
    }

    useEffect(() => {
        console.log(state.index)
        setIndex(state.index)
    },[state.index])

    return (
        <div className="flex-grow flex flex-row">
            <div className="flex-grow">
                    <Tabs
                        index={index}
                        tabs={steps}
                    />
            </div>
        </div>
    )
}

export const PrimaryInformationForm = () => {
    const { state, dispatch } = useFormContext()
    const methods = useForm({values: state.organization})

    const onSubmit = (data: any) => {
        console.log("submitting",data)
        dispatch({type: "ADD_PRIMARY_INFORMATION", payload: data})
        dispatch({type: "UPDATE_INDEX", index: "impact-information"})
    }

    return (
        <div style={{ padding: "1.5rem" }}>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="grid md:grid-cols-1 gap-8 lg:grid-cols-1">
                        <div className="mb-6 w-full">
                            <TextInput
                                placeholder="Enter name"
                                data-testid="form-field-name"
                                label={"Company Name"}
                                register={methods.register} name={"name"} />
                        </div>

                        <div className="mb-6">
                            <TextAreaInput
                                placeholder="Enter the description"
                                data-testid="form-field-description"
                                label={"Description"}
                                register={methods.register}
                                name={"description"} />
                        </div>

                        <div className="mb-6">
                            <TextInput
                                placeholder={"Primary Email"}
                                type={"email"}
                                register={methods.register}
                                name={"primary_email_contact"}
                                label={"Primary Email"} />
                        </div>

                        <div className="mb-6">
                            <SelectComboBox {...methods.register("type")} name={"type"} label={"Category"} control={methods.control} options={[
                                { id: "startup", value: "Startup" },
                                { id: "investor", value: "Investor" },
                                { id: "ngo_charity", value: "NGO/Charity" },
                                { id: "hub", value: "Hub/Accelerator" }
                            ]} />
                        </div>
                    </div>

                    <div style={{display: "flex",gap: "2rem"}}>
                        <Button variant="secondary" title={"Prev"} onClick={() => {console.log("handlePrev")}}/>

                        <input value={"Next"} type={"submit"} className="bg-bs-light-primary text-bs-light-black border-bs-light-primary" style={{paddingLeft: "1rem",paddingRight: "1rem",borderRadius: "0.25rem"}}/>
                    </div>
                </form>
            </FormProvider>

        </div>
    )
}

export const ImpactInformationForm = () => {
    const methods = useForm({})

    const onSubmit = (data: any) => {
        console.log("impact_information", data)
    }

    return (
        <div style={{ padding: "1.5rem" }}>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <div className="mb-6 w-full">
                        <TextInput
                            placeholder="Enter email"
                            data-testid="form-field-name"
                            label={"Company Name"}
                            register={methods.register} name={"name"} />
                    </div>
                </form>
            </FormProvider>
        </div>
    )
}