import React from "react";
import {useForm} from "react-hook-form";
import {StyledElementContainer, StyledInput, StyledLabel, StyledTextarea} from "../../../interface/Form/StyledElements";
import {Button} from "../../../interface/Button/Button";
import {useMutation, useQuery} from "@apollo/client";
import {FETCH_CLAIM_BY_ID, MUTATE_CLAIM_BY_ID} from "../../../queries/Claims";
import {get} from "lodash";
import {ArrowRightIcon} from "@heroicons/react/solid";
import {Pillola} from "../../../interface/Pillole";
import {Persona} from "../../../interface/Persona";

interface IClaimApproveRejectFragment {
    id: string;
    action: "APPROVE" | "REJECT" | "REQUEST"
}

export const ClaimApproveRejectFragment = ({...props}: IClaimApproveRejectFragment) => {
    const {id, action} = props
    const {handleSubmit, register} = useForm({
        defaultValues: {
            status: action === "APPROVE" ? 1 : action === "REJECT" ? -1 : 0,
            status_reason: ""
        }
    })
    const {data, loading, refetch} = useQuery(FETCH_CLAIM_BY_ID, {
        variables: {
            id: id
        }
    })

    const [updateClaim, {loading: updateLoading, error: updateError}] = useMutation(MUTATE_CLAIM_BY_ID)

    const onSubmit = (data: any) => {
        updateClaim({ variables: { id: id, reason: data.status_reason, status: data.status }})
            .then((res) => {
                refetch()
            })
    }

    if (loading) {
        return <p>Loading claim...</p>
    }

    const getPillolaByInteger = (status: number) => {
        switch (status) {
            case -1:
                return <Pillola text="Rejected" variant="negative" />
            case 0:
                return <Pillola text="Pending" variant="neutral" />
            case 1:
                return <Pillola text="Approved" variant="success" />
            default:
                return <Pillola text="ERR" variant="neutral" />
        }
    }

    const organization = get(data, 'claim.organization', {})
    const user = get(data, 'claim.user', {})

    const activeClaims = get(data, 'claim.user.claims', []) || []
    const activeClaimers = get(data, 'claim.organization.claimers', []) || []

    return (
        <div style={{padding: "1rem 1rem", width: "100%"}}>
            <h1 style={{fontWeight: 500, fontSize: "0.9rem", color: "#232323", display: "flex", gap: "1rem", alignItems: "center"}}>
                {getPillolaByInteger(data?.claim?.status)} REF: {id}
            </h1>
            <div style={{display: "flex", width: "100%"}}>
                <div style={{display: "flex", width: "50%", flexDirection: "column"}}>
                    <div style={{display: "flex", width: "100%", padding: "2rem", alignItems: "center", gap: "2rem"}}>
                        <Persona text={`${user.first_name} ${user.other_names}`} imageURL={user.profile_image_url}/>
                        <ArrowRightIcon style={{width: "1rem", height: "1rem"}}/>
                        <Persona text={organization.name} imageURL={organization.profile_image_url}/>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} style={{width: "100%"}}>
                        <StyledInput hidden {...register("status")} />
                        <StyledElementContainer>
                            <StyledLabel>Reason <span style={{ color: "red"}}>*</span></StyledLabel>
                            <StyledTextarea {...register("status_reason", { required: true })} />
                        </StyledElementContainer>
                        <div style={{padding: "1rem 0"}}>
                            {
                                action === "APPROVE" && data?.claim?.status !== 1 && (
                                    <Button title="Approve" type="submit" variant="primary" size="sm"/>
                                )
                            }
                            {
                                action === "REJECT" && data?.claim?.status !== -1 && (
                                    <Button title="Reject" type="submit" variant="danger" size="sm"/>
                                )
                            }
                        </div>
                    </form>
                </div>
                <div style={{width: "20%"}}>
                    <div style={{padding: "0 2rem"}}>
                        <p style={{fontWeight: 500, fontSize: "1rem"}}>{user.first_name} already claims:</p>
                        <ul style={{marginBottom: "1rem", marginTop: "0.5rem"}}>
                            {activeClaims.filter((claim: any) => claim.status === 1).map((claim: any) => (
                                <li key={claim.id} style={{paddingLeft: "1rem"}}>
                                    <Persona
                                        text={get(claim, 'organization.name')}
                                        imageURL={get(claim, 'organization.profile_image_url')}
                                        size="xs"
                                    />
                                </li>
                            ))}
                        </ul>
                        <p style={{fontWeight: 500, fontSize: "1rem"}}>{user.first_name} wants also to claim:</p>
                        <ul style={{marginBottom: "1rem", marginTop: "0.5rem"}}>
                            {activeClaims.filter((claim: any) => claim.status === 0 && claim.id !== id).map((claim: any) => (
                                <li key={claim.id} style={{paddingLeft: "1rem"}}>
                                    <Persona
                                        text={get(claim, 'organization.name')}
                                        imageURL={get(claim, 'organization.profile_image_url')}
                                        size="xs"
                                    />
                                </li>
                            ))}
                        </ul>
                        <p style={{fontWeight: 500, fontSize: "1rem"}}>{user.first_name} has been rejected:</p>
                        <ul style={{marginBottom: "1rem", marginTop: "0.5rem"}}>
                            {activeClaims.filter((claim: any) => claim.status === -1 && claim.id !== id).map((claim: any) => (
                                <li key={claim.id} style={{paddingLeft: "1rem"}}>
                                    <Persona
                                        text={get(claim, 'organization.name')}
                                        imageURL={get(claim, 'organization.profile_image_url')}
                                        size="xs"
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div style={{width: "30%"}}>
                    <div style={{padding: "0 2rem"}}>
                        <p style={{fontWeight: 500, fontSize: "1rem"}}>{organization.name} has been claimed by:</p>
                        <ul style={{marginBottom: "1rem", marginTop: "0.5rem"}}>
                            {activeClaimers.filter((claim: any) => claim.status === 1).map((claim: any) => (
                                <li key={claim.id} style={{paddingLeft: "1rem"}}>
                                    <Persona
                                        text={get(claim, 'user.first_name')}
                                        imageURL={get(claim, 'user.profile_image_url')}
                                        size="xs"
                                    />
                                </li>
                            ))}
                            {
                                activeClaimers.filter((claim: any) => claim.status === 1).length < 1 && (
                                    <li style={{paddingLeft: "1rem"}}>
                                        Nobody has claimed it
                                    </li>
                                )
                            }
                        </ul>
                        <p style={{fontWeight: 500, fontSize: "1rem"}}>{organization.name} has pending claims by:</p>
                        <ul style={{marginBottom: "1rem", marginTop: "0.5rem"}}>
                            {activeClaimers.filter((claim: any) => claim.status === 0 && claim.id !== id).map((claim: any) => (
                                <li key={claim.id} style={{paddingLeft: "1rem"}}>
                                    <Persona
                                        text={get(claim, 'organization.name')}
                                        imageURL={get(claim, 'organization.profile_image_url')}
                                        size="xs"
                                    />
                                </li>
                            ))}
                            {
                                activeClaimers.filter((claim: any) => claim.status === 0).length < 1 && (
                                    <li style={{paddingLeft: "1rem"}}>
                                        Nobody else has requested to claim it
                                    </li>
                                )
                            }
                        </ul>
                        <p style={{fontWeight: 500, fontSize: "1rem"}}>{organization.name} has rejected claims by:</p>
                        <ul style={{marginBottom: "1rem", marginTop: "0.5rem"}}>
                            {activeClaimers.filter((claim: any) => claim.status === -1 && claim.id !== id).map((claim: any) => (
                                <li key={claim.id} style={{paddingLeft: "1rem"}}>
                                    <Persona
                                        text={get(claim, 'organization.name')}
                                        imageURL={get(claim, 'organization.profile_image_url')}
                                        size="xs"
                                    />
                                </li>
                            ))}
                            {
                                activeClaimers.filter((claim: any) => claim.status === -1).length < 1 && (
                                    <li style={{paddingLeft: "1rem"}}>
                                        Nobody else has been rejected
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
};