import React, {useEffect, useState} from "react";
import classNames from "classnames";
import {XIcon} from "@heroicons/react/outline";

type TMessageVariant = "success" | "warning" | "neutral" | "error";
type TMessageSize = "xs" | "sm" | "md" | "lg" | "xl" | "2xl";

interface IMessage {
    variant: TMessageVariant;
    size: TMessageSize;
    children: React.ReactNode;
    expiration?: number;
    autoHide?: boolean;
    onClose?: () => void;
    absolute?: boolean;
}

export default function Message({...props}: IMessage) {
    const {children, variant, expiration = 3000, autoHide = false, onClose, absolute = false} = props;
    const [state, setState] = useState({
        visible: true
    });
    useEffect(() => {
        if (autoHide) {
            const timeout = setTimeout(() => {
                if (onClose) onClose()
                setState({...state, visible: false})
            }, expiration)

            return () => {
                clearTimeout(timeout)
            }
        }
    }, [autoHide])

    if (!state.visible) {
        return null;
    }

    const cls = classNames({
        "w-full py-2.5 px-5 z-10 flex align-center": true,
        "absolute": absolute,
        "bg-green-500 text-white": variant === "success",
        "bg-red-500 text-white": variant === "error"
    })

    return (
        <div className={cls}>
            <div className="flex-grow">
                <p>{children}</p>
            </div>
            {autoHide && (
                <span className="font-bold cursor-pointer bg-white bg-opacity-25 rounded-md" onClick={() => {
                    if (onClose) onClose()
                    setState({...state, visible: false})
                }}>
                    <XIcon className="w-5 h-5"/>
                </span>
            )}
        </div>
    )
}