import React, {ReactNode, useRef, useState} from "react";

export interface IDropdownPopup {
    text: string;
    children: ReactNode
}
export const DropdownPopup = ({...props}: IDropdownPopup) => {
    const { text , children} = props
    const [isVisible, setIsVisible] = useState(false)
    const buttonRef = useRef(null)
    return (
        <div className="relative flex">
            <button ref={buttonRef} onClick={(ev) => {
                ev.preventDefault()
                setIsVisible(!isVisible)
            }}>{text}</button>
            {
                isVisible && (
                    <div className="absolute bg-white rounded-sm top-0 px-4 py-4 z-10 shadow-md" style={{
                        //@ts-ignore
                        left: buttonRef?.current?.getBoundingClientRect().width + 10,
                        //@ts-ignore
                        top: -buttonRef?.current?.getBoundingClientRect().height
                    }}>
                        {children}
                    </div>
                )
            }
        </div>
    )
}