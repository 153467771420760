import React from "react";
import {Link, useSearchParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {QUERY_SEARCH_ANYWHERE} from "../../components/layouts/fragments/MenuSearchAnywhere";
import {get} from "lodash";
import {Persona} from "../../interface/Persona";
import {Pillola} from "../../interface/Pillole";
import {Spinner} from "../../interface/Spinner";

const generateLink = (item: any, index: string) => {
    switch (index) {
        case "organizations":
            return (
                <div className="py-1.5" key={item.id}>
                    <Link to={["/organizations/directory/orgs", item.id].join("/")}>
                        <Persona text={`${item.name} (${item.index}) (${item.enabled ? "active" : "inactive"})`} imageURL={item.profile_image_url} size="xs"/>
                    </Link>
                </div>
            )
    }
    return <div key={item.id}>{item.name} ({item.index})</div>
}

export const SearchContainer = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const {data, loading} = useQuery(QUERY_SEARCH_ANYWHERE, {
        variables: {
            search: {
                bucket: '_all',
                size: 1000,
                query: {
                    must: [
                        // {
                        //     "more_like_this" : {
                        //         "fields" : ["name", "title", "description"],
                        //         "like" : searchParams.get("qs"),
                        //         "min_term_freq" : 1,
                        //         "max_query_terms" : 12
                        //     }
                        // }
                        {
                            simple_query_string : {
                                query: searchParams.get('qs')?.replace(" ", ""),
                                fields: ["name"],
                                default_operator: "OR"
                            }
                        }
                        // {query_string: {query: `*${input}*`, default_field: 'name.keyword'}}
                    ]
                }
            }
        }
    })
    return (
        <section className="px-10">
            <h1 className="text-2xl pt-10 pb-5 flex items-center gap-2">
                <span className="font-light">Searching:</span>
                <span className="font-medium">{searchParams.get("qs")}</span>
                {
                    !loading && (
                        <Pillola variant="ghost" text={`(${get(data, 'search.items', []).length} results)`} />
                    )
                }
            </h1>
            {
                loading && (
                    <Spinner message="Searching..." />
                )
            }
            {
                get(data, 'search.items', []).map((i: any) => generateLink(i, i.index))
            }
        </section>
    )
}