import React, {createContext, ReactNode, useContext, useState} from "react";

export interface LayoutContext {
    isMenuAttached: boolean
    isSearchingAnywhere: boolean
    setMenuAttached: (value: boolean) => void
    setSearchAnywhereVisible: (value: boolean) => void
}

const LayoutContext = createContext<LayoutContext>(
    {} as LayoutContext
);

export function BaseLayoutProvider({children}: { children: ReactNode }): JSX.Element {
    const [state, setLayoutState] = useState({
        isSearchingAnywhere: true,
        isMenuFloating: false,
        isMenuAttached: true
    });

    const setMenuAttached = (value: boolean) => {
        setLayoutState({...state, isMenuAttached: value});
    }
    const setSearchAnywhereVisible = (value: boolean) => setLayoutState({...state, isSearchingAnywhere: value});

    return (
        <LayoutContext.Provider value={{...state, setMenuAttached, setSearchAnywhereVisible}}>
            {children}
        </LayoutContext.Provider>
    )
}

export function useBaseLayoutContext() {
    return useContext(LayoutContext);
}