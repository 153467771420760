const profileInfoSetLeft = [
    {
        type: "text",
        label: "Organization Name",
        required: true,
        name: "name",
        register: {
            required: {value: true, message: "Field is required"},
            minLength: {value: 2, message: "At least 2 characters"}
        }
    },
    {
        type: "checkbox",
        label: "Enabled",
        name: "enabled",
        required: true,
        options: {
            values: [
                {id: true, value: "Enabled", key: "true"},
                {id: false, value: "Disabled", key: "false"},
            ]
        }
    },
    {
        type: "select",
        label: "Operating Status",
        name: "operating_status",
        required: true,
        register: {
            required: {value: true, message: "Operating status is required"},
        },
        options: {
            values: [
                {id: "y", value: "Active", key: "y"},
                {id: "n", value: "Shut Down", key: "n"},
                {id: "c", value: "Under Review", key: "c"},
            ]
        }
    },
    {
        type: "select",
        label: "Category",
        name: "type",
        required: true,
        register: {
            required: {value: false, message: "Type is required."}
        },
        options: {
            values: [
                {id: "startup", value: "Startup", key: "startup"},
                {id: "ngo_charity", value: "NGO/Charity", key: "ngo_charity"},
                {id: "investor", value: "Investor", key: "investor"},
                {id: "hub", value: "Hub", key: "hub"},
            ]
        }
    },
    {
        type: "select",
        label: "Ownership Structure",
        name: "ownership_structure",
        required: false,
        options: {
            values: [
                {id: "private", value: "Private Company"},
                {id: "acquired", value: "Acquired"},
                {id: "public", value: "Public Company"},
                {id: "other", value: "Other"}
            ]
        }
    },
    {
        type: "date",
        label: "Founding Year",
        name: "founding_year",
        required: true
    },
    {
        type: "select",
        label: "Business Model",
        name: "business_model",
        required: true,
        options: {}
    },
    {
        type: "select",
        label: "Employees #",
        name: "employees",
        required: false,
        options: {}
    },
    {
        type: "select",
        label: "Stage",
        name: "stage",
        required: false,
        options: {}
    },
    {
        type: "text",
        label: "Incorporation No.",
        name: "inc_no",
        required: false,
        options: {}
    },
    {
        type: "select",
        label: "Legal Registration #",
        name: "legal_registration",
        required: false,
        options: {}
    },
    {
        type: "text",
        label: "Reference",
        name: "reference",
        required: false,
        options: {}
    },
    {
        type: "text",
        label: "Primary Email",
        name: "primary_email",
        required: false,
        options: {}
    }
]
//website
//         facebook
//         instagram
//         youtube
//         linkedin
//         angellist
const profileInfoCenter = []
const profileInfoSetRight = [
    {
        type: "image",
        label: "Profile Image",
        name: "profile_image_url",
        required: true,
        register: {
            required: {value: false, message: "Profile Image is required."}
        }
    },
    {
        type: "textarea",
        label: "Description",
        required: true,
        name: "description",
        register: {
            required: {value: true, message: "Field is required"},
            minLength: {value: 2, message: "At least 2 characters"}
        }
    },
]

export const EditCompanyFormInput = [
    {
        type: "fieldset",
        direction: "horizontal",
        title: "Profile Information",
        sets: [
            {
                type: "fieldset",
                direction: "vertical",
                sets: profileInfoSetLeft
            },
            // {
            //     type: "fieldset",
            //     direction: "vertical",
            //     sets: profileInfoCenter
            // },
            {
                type: "fieldset",
                direction: "vertical",
                sets: profileInfoSetRight
            }
        ]
    },
    {
        type: "fieldset",
        direction: "vertical",
        title: "Industry Specifics Metadata",
        sets: [
            {
                type: "select",
                label: "Sectors",
                name: "sectors",
                options: {
                    maxTagTextLength: 24,
                    maxTagCount: 128,
                    mode: "multiple"
                }
            },
            {
                type: "select",
                label: "Products",
                name: "sub_sectors",
                options: {
                    maxTagTextLength: 24,
                    maxTagCount: 128,
                    mode: "multiple"
                }
            },
            {
                type: "select",
                label: "Technologies",
                name: "technologies",
                options: {
                    maxTagTextLength: 24,
                    maxTagCount: 128,
                    mode: "multiple"
                }
            }
        ]
    },
    {
        type: "fieldset",
        direction: "vertical",
        title: "Socials, digital presence",
        sets: [
            {
                type: "text",
                label: "Website",
                name: "socials.website"
            },
            {
                type: "text",
                label: "Facebook",
                name: "socials.facebook"
            },
            {
                type: "text",
                label: "Instagram",
                name: "socials.instagram"
            },
            {
                type: "text",
                label: "YouTube",
                name: "socials.youtube"
            },
            {
                type: "text",
                label: "LinkedIn",
                name: "socials.linkedin"
            },
            {
                type: "text",
                label: "AngelList",
                name: "socials.angellist"
            }
        ]
    },
    {
        type: "fieldset",
        title: "HQs, Operating countries",
        direction: "vertical",
        sets: []
    },
    {
        type: "fieldset",
        direction: "vertical",
        title: "Deals, Funding, Investment",
        sets: []
    },
    {
        type: "fieldset",
        direction: "vertical",
        title: "Team",
        sets: []
    },
    {
        type: "fieldset",
        direction: "vertical",
        title: "Impact",
        sets: [
            {
                type: "select",
                label: "SDGs",
                name: "sdgs",
                required: false,
                options: {
                    mode: "multiple"
                }
            }
        ]
    },
    {
        type: "fieldset",
        direction: "vertical",
        title: "Media Mentions",
        sets: []
    }
]