import {GroupPillole, Pillola} from "../../interface/Pillole";
import {HSection, MDSection} from "../../interface/Typography";

export interface OrganizatioNMetadataProps {
  org: any
}
export const OrganizationMetadata = ({...props} : OrganizatioNMetadataProps) => {

  const {org} = props


  // todo(nate) : handle nullable offices
  const buildHQCountries = (data : any) => {
      const countries : any = []

      // const countries = data.map((it: any) => {
      //     return {
      //         value: it.city?.subdivision?.country?.name,
      //         variant: "positive"
      //     }
      // })

      return countries
  }

  return (
    <div className="flex w-full gap-5 py-5">
        <div className="w-2/5">
            <MDSection title="Industry Specific Metadata">
                <GroupPillole title="Sectors" data={org.sectors}/>
                <GroupPillole title="Products" data={org.sub_sectors}/>
                <GroupPillole title="Technologies" data={org.technologies}/>
                <GroupPillole title="SDGs" data={org.sdgs}/>
            </MDSection>
        </div>
        <div className="w-2/5">
            <MDSection title="Headquarters & Offices">
                {
                    org.offices && org.offices.map((office: any,idx: number) => {
                        return (
                            <div className="flex gap-y-2 flex-col" id={`${org.id}-${idx}`} key={`${org.id}-${idx}`}>
                                <p>
                                    {office.address} {!office.address && (
                                    <Pillola text="No address provided" variant={"negative"}/>
                                )}
                                </p>
                                <p className="flex gap-5 flex-row">
                                    {[office?.city?.name, office?.city?.subdivision?.country?.name].join(", ")}
                                    {
                                        office?.is_hq_primary
                                            ? <Pillola text={"Primary HQ"} variant={"success"}/>
                                            : ""
                                    }
                                </p>

                            </div>
                        )
                    })
                }
            </MDSection>
            <MDSection title="Country of Operations">
                <GroupPillole title="Countries" data={org.ops_countries}/>
            </MDSection>
            <MDSection title="HQ countries">
                <GroupPillole title="HQ Countries" data={buildHQCountries(org.offices)}/>
            </MDSection>
            <MDSection title="Cities of Operations">
                <GroupPillole title="Cities" data={org.ops_cities}/>
            </MDSection>
        </div>
        <div className="w-1/5">

        </div>
    </div>
  )
}
