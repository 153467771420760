import {useState,useEffect} from "react"
import {useForm,FormProvider} from "react-hook-form"
import {get} from "lodash"
import {useNavigate} from "react-router"
import {gql,useLazyQuery,useMutation} from "@apollo/client"

import {DatePicker} from "../../../interface/Form/Input"
import {InputText} from "../../../interface/Form/Input/TextInput";
import {SingleComboBoxInput} from "../../../interface/Form/Input/ComboBoxInput";
import {CheckboxInput} from "../../../interface/Form/Input/Inputs";
import { useFlash } from "../../../context/FlashContext";

export const MUTATION_UPSERT_EMPLOYEE = gql`
    mutation upsertEmployee($id: ID!,$input: UpsertEmployeeInput!) {
        upsertEmployee(id: $id,input: $input) {
            id
        }
    }
`

export const MUTATION_UPSERT_PERSON = gql`
	mutation upsertPerson($id: String,$input: UpsertPersonInput!) {
		upsertPerson(id: $id,input: $input) {
			id
			first_name
			other_names
		}
	}
`

export const MUTATION_UPSERT_EXPERIENCE = gql`
	mutation upsertPersonExperience($id: String,$input: UpsertPersonExperienceInput!) {
		upsertPersonExperience(id: $id,input: $input) {
			id
		}
	}
`

export const QUERY_FETCH_EMPLOYEE = gql`
	query getPersonExperience($id: String!) {
		personExperience(id: $id) {
			id
			start_date
			end_date
			role
			person {
				id
				first_name
				other_names
				email
				gender
				ethnicity
				education_level
				education_degree
				education_university
				socials {
					facebook
					instagram	
					website
					linkedin
					twitter
				}
			}
		}
	}
`

export interface UpsertEmployeeFragmentProps {
	orgId: string
	id?: string
}

export const UpsertEmployeeFragment = ({...props} : UpsertEmployeeFragmentProps) => {
	const {orgId,id} = props

    const [currentlyWorking,setCurrentlyWorking] = useState(false)

    const flash = useFlash()

	const [upsertPerson,{data: upsertPersonData,loading: upsertPersonLoading}] = useMutation(MUTATION_UPSERT_PERSON)
	const [upsertExperience,{data: upsertExperienceData,loading: upsertExperienceLoading}] = useMutation(MUTATION_UPSERT_EXPERIENCE)

    //const [upsertEmployee,{data: upsertEmployeeData,loading: upsertEmployeeLoading}] = useMutation(MUTATION_UPSERT_EMPLOYEE)
    const [fetchEmployee, { data, loading }] = useLazyQuery(QUERY_FETCH_EMPLOYEE, { variables: { id: id } })

    useEffect(() => {
        if(id) {
            fetchEmployee().then((res) => console.log("res",res)).catch((err) =>
                console.log("err",err)
            )
        }
    }, []);

	const methods = useForm({
		values: get(data,"personExperience",{})
	})

    const watchCurrentRole = methods.watch("current")

	const onSubmit = (data: any) => {
		const person = {
			...data.person,
			gender: data.person.gender ? data.person.gender.id : "",
			ethnicity: data.person.ethnicity ? data.person.ethnicity.id : "",
		}

		const experience = {...data}

		delete experience.person
		delete experience.current

		currentlyWorking && delete experience.end_date
		

		console.log("person",person)
		upsertPerson({variables: {
			id: person.id ? person.id : null,
			input: {
				...person
			}
		}}).then((res: any) => {
				console.log("res",res)
				upsertExperience({variables: {
					id: experience.id ? experience.id : null,
					input: {
						...experience,
						person: get(res,"data.upsertPerson.id",person.id),
						target: orgId,
					}
				}}).then((res: any) => {
						console.log("res",res)
						flash(id ? "employee updated" : "employee created","success")
				}).catch((err: any) => {
						console.log("err",err)
						flash(`${err.message}`,"error")
				})
		}).catch((err: any) => {
				console.log("err",err)
				flash(`${err.message}`,"error")
		})
	}



    useEffect(() => {
        if(watchCurrentRole) {
            setCurrentlyWorking(true)
        } else {
            setCurrentlyWorking(false)
        }
    },[methods.register,methods.unregister,watchCurrentRole])

	return (
        <div className={"p-8 w-full"}>
            <p className="text-2xl font-bold pb-2">{id ? "Add" : "Update"} Employee to organization</p>
            <p className="text-sm md:text-lg xs:text-2xl sm:text-xl pb-4">Adding employee to organization {get(data, "organization.name")}</p>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit((e) => {
                    onSubmit(e)
                } )}>
                    <div className="grid md:grid-cols-1 gap-4 lg:grid-cols-3">
                        <div className={"w-full"}>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"First Name"}
                                    register={methods.register}
                                    required={true}
                                    name={"person.first_name"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                            <div className="pb-2">
                                <InputText
                                    label={"Other Names"}
                                    register={methods.register}
                                    required={false}
                                    name={"person.other_names"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"Email"}
                                    register={methods.register}
                                    required={false}
                                    name={"person.email"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                            <div className={""}>
                                <SingleComboBoxInput
                                    {...methods.register("person.gender")}
                                    label={"Gender"}
                                    options={[
                                        {
                                            id: "0",
                                            value: "Male"
                                        },
                                        {
                                            id: "1",
                                            value: "Female"
                                        },
                                        {
                                            id: "2",
                                            value: "Non-Binary"
                                        },
                                        {
                                            id: "3",
                                            value: "Prefer not to Say"
                                        },
                                    ]}
                                    onBlur={async () => {
                                    }}
                                    onChange={async () => {
                                    }}
                                />
                            </div>
                            <div className={"pb-2"}>
                                <SingleComboBoxInput
                                    {...methods.register("person.ethnicity")}
                                    label={"Ethnicity"}
                                    options={[
                                        {
                                            id: "african",
                                            value: "African"
                                        },
                                        {
                                            id: "hispanic",
                                            value: "Hispanic"
                                        },
                                        {
                                            id: "asian",
                                            value: "Asian"
                                        },
                                        {
                                            id: "white",
                                            value: "White"
                                        },
                                        {
                                            id: "other",
                                            value: "Other"
                                        },
                                    ]}
                                    onBlur={async () => {
                                    }}
                                    onChange={async () => {
                                    }}
                                />
                            </div>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"Education University"}
                                    register={methods.register}
                                    required={false}
                                    name={"person.education_university"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                        </div>
                        <div className={"w-full"}>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"Education Degree"}
                                    register={methods.register}
                                    required={false}
                                    name={"person.education_degree"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"Education Level"}
                                    register={methods.register}
                                    required={false}
                                    name={"person.education_level"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"Role"}
                                    register={methods.register}
                                    required={false}
                                    name={"role"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                            <div className={"pb-2"}>
								<DatePicker 
									type="date"
									outlined={true}
									required={true}
									label="Start Date"
									setValue={methods.setValue}
									getValues={methods.getValues}
									{...methods.register("start_date")}
								/>
                            </div>
                            <div className={"py-7"}>
                                    <CheckboxInput register={methods.register} label={"Currently working in this role"}
                                                   name={"current"}/>
                            </div>
                            <div className={"pb-2"}>
                                {!currentlyWorking ?
                                    <div className="mb-6">
										<DatePicker 
											label="Start Date"
											type="date"
											outlined={true}
											setValue={methods.setValue}
											getValues={methods.getValues}
											{...methods.register("end_date")}
										/>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        </div>
                        <div className={"w-full"}>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"LinkedIn"}
                                    register={methods.register}
                                    required={false}
                                    name={"person.socials.linkedin"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"X(Formerly Twitter)"}
                                    register={methods.register}
                                    required={false}
                                    name={"person.socials.twitter"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"Instagram"}
                                    register={methods.register}
                                    required={false}
                                    name={"person.socials.instagram"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"Facebook"}
                                    register={methods.register}
                                    required={false}
                                    name={"person.socials.facebook"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                            <div className={"pb-2"}>
                                <InputText
                                    label={"Website"}
                                    register={methods.register}
                                    required={false}
                                    name={"person.socials.website"}
                                    setValue={methods.setValue}
                                    getValues={methods.getValues}
                                />
                            </div>
                        </div>
						<div className={"pt-4 w-full"}>
							<button type="submit"
									disabled={upsertPersonLoading || upsertExperienceLoading}
									className="w-full border-1 py-2.5 px-2.5 bg-bs-light-primary rounded-md font-semibold">
										{(upsertPersonLoading || upsertExperienceLoading) ? "Adding...." : "Add Employee"}
							</button>
						</div>
                    </div>
                </form>
            </FormProvider>
        </div>
	)
}
