import React from 'react'
import {useForm} from "react-hook-form";
import {TextInput} from "../../interface/Form/Input/Inputs";
import {get} from "lodash";
import slugify from "slugify";
import {gql, useMutation} from "@apollo/client";
import {useFlash} from "../../context/FlashContext";

export const CreateResourceAuthorContainerWidget = ({onSuccess, onCancel}: any) => {
    const flash = useFlash();
    const [create, {data, loading, error}] = useMutation(gql`
        mutation createResourceAuthor($author: UpsertResourceAuthorRequest!) {
            createResourceAuthor(author: $author) {
                id
                display_name
                slug
            }
        }
    `)

    return <UpsertResourceAuthorForm onSubmit={(data: any) => {
        create({
            variables: {
                author: data
            }
        }).then((res) => {
            flash(`author ${data.display_name} created`, "success")
            onSuccess()
        }).catch((err) => {
            flash(err.message, "error")
        })
    }} onCancel={onCancel}/>
}

export const UpsertResourceAuthorForm = ({onSubmit, onCancel}: any) => {
    const {
        handleSubmit,
        register,
        watch,
        setValue
    } = useForm()

    React.useEffect(() => {
        const subscription = watch((value, {name, type}) => {
            if (name === "title" && type === "change" && get(value, 'id', undefined) === undefined) {
                setValue("slug", slugify(watch("display_name") ?? "", {
                    trim: true, lower: true, strict: true
                }))
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col gap-4">
            <TextInput
                placeholder={"Enter the title of the resource"}
                type={"text"}
                register={register}
                name={"display_name"}
                label={"Display Name"}
            />
            <TextInput
                placeholder={"Enter the title of the resource"}
                type={"text"}
                register={register}
                name={"slug"}
                label={"Machine name"}
            />
            <div>
                <button type="button"
                        className="rounded-md bg-bs-light-primary border text-sm border-bs-light-border-primary px-4 py-0.5"
                        onClick={(ev) => {
                            ev.preventDefault()
                            handleSubmit(onSubmit)()
                        }}>Create
                </button>
                <button type="button"
                        className="mx-8 text-sm px-4 py-0.5 border rounded-md"
                    onClick={(ev) => {
                        ev.preventDefault()
                        onCancel()
                    }}
                >
                    Cancel
                </button>
            </div>
        </form>
    )
}