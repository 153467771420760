import React, {useEffect, useRef} from "react";
import {useHover} from "usehooks-ts";
import {Spinner} from "../Spinner";

export interface ISEItem {
    value: any;
    id?: string;
}

export function SEItem({value, id}: ISEItem) {
    const hoverRef = useRef(null);
    const isHover = useHover(hoverRef);
    useEffect(() => {

    }, [isHover]);
    return (
        <div className="inline-block">
            <span ref={hoverRef} className="hover:underline">
                {value}
            </span>
            {
                isHover && (
                    <div
                        className="fixed right-0 h-screen w-96 top-0 z-30 border-l"
                        style={{backgroundColor: "rgba(255,255,255,0.85)"}}>
                        <div className="flex w-full h-screen justify-center">
                            <Spinner message={`Loading ${value}`}/>
                        </div>
                    </div>
                )
            }
        </div>
    )
}