import {useState} from "react"
import {FormProvider, useForm} from "react-hook-form";
import {useNavigate,useParams} from "react-router";
import {get} from "lodash";
import {format,parseISO} from "date-fns"
import {useEffect} from "react";
import {InputText} from "../../interface/Form/Input/TextInput";
import {gql, useLazyQuery, useMutation,useQuery} from "@apollo/client";
import {RHFCheckboxInput} from "../../interface/Form/Input/CheckBoxInput";
import {DateInput } from "../../interface/Form/Input/DateInput"
import { CheckboxInput,TextInput} from "../../interface/Form/Input/Inputs";
import {useFlash} from "../../context/FlashContext";
import {useDataContainerContext} from "../../interface/DataContainer/context/DataContainerContext"
import {
    GET_PEOPLE,
    FETCH_PERSON_EXP_OPTIONS,
    UPSERT_PERSON_EXPERIENCE,
    UPSERT_PERSON,
    GET_PERSON_EXPERIENCE
} from "../../queries/"
import { SingleComboBoxInput } from "../../interface/Form/Input/ComboBoxInput";



export const CreateNewPersonContainer = () => {
    const {id} = useParams()
    const flash = useFlash()
    const navigate = useNavigate()

    const [isCreating,setIsCreating] = useState(false)
    const [currentlyWorking,setCurrentlyWorking] = useState(false)
    const { applyFilters} = useDataContainerContext()

    const [upsertPersonExperience] = useMutation(UPSERT_PERSON_EXPERIENCE)
    const [upsertPerson] = useMutation(UPSERT_PERSON)
    const [getPersonExperience,{data: getPersonExpData,loading: getPersonExpLoading,error: getPersonExpError}] = useLazyQuery(GET_PERSON_EXPERIENCE,{variables: {id: id}})
    const {data: opts, loading: optsLoading} = useQuery(FETCH_PERSON_EXP_OPTIONS)
    const {data: people,loading: peopleLoading} = useQuery(GET_PEOPLE,{variables: {limit: 100}})

    const methods = useForm({
        shouldUnregister: true,
        reValidateMode: "onChange",
        values: get(getPersonExpData, "personExperience", {})
    });

    const onSubmit = (payload: any) => {
        // todo : replace below with upsert person
        if(isCreating) {
            upsertPerson({
                variables: {
                    id: id,
                    input: {
                        ...payload
                    }
                }
            }).then((res) => {
                flash(`person ${id ? "updated" : "created"}`,"success")
                setIsCreating(false)
                console.log("res",res)
            }).catch((err) => {
                flash(`failed  to ${id ? "update" : "create"}`,"error")
                console.log("err",err)
            })
        } else {
            console.log("msg",payload)
            // todo : write a query for updating and creating(upsert) person experience
            delete payload.current
            const normalized = {
                ...payload,
                person: payload.person.id,
                target: payload.organization.id,
                start_date: format(new Date(new Date(parseISO(payload.start_date)).toISOString()),"yyy-MM-dd"),
            }

            delete normalized.organization

            upsertPersonExperience({
                variables: {
                    id: id,
                    input: {
                        ...normalized
                    }
                }
            }).then((res) => {
                console.log("res",res)
                flash("employee added","success")
                navigate("/")
            }).catch((err) => {
                console.log("err",err)
                flash("failed to add employee","error")
            })
        }
    }

    const watchCurrentRole = methods.watch("current")

    useEffect(() => {
        if(id) {
            // fetch person by id
            getPersonExperience()
        }
    },[id])

    useEffect(() => {
        if(watchCurrentRole) {
            setCurrentlyWorking(true)
            console.log("no end date")
        } else {
            setCurrentlyWorking(false)
            console.log("end date required")
        }
    },[methods.register,methods.unregister,watchCurrentRole])

    console.log("test",getPersonExpData)

    return (
        <div className={"w-full scroll-smooth"}>
            <div className={"px-5"}>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit((f) => {
                        onSubmit(f)
                    })}>
                        <div style={{width: "100%",display: "flex",flexDirection: "column",gap: "1.5rem"}}>
                             <div>
                                <input type="submit" className="bg-bs-light-primary px-2.5 py-0.5 rounded-md"/>
                            </div>
                            <hr />

                            <div style={{width: "100%",display: "flex",flexDirection: "column",gap: "1.5rem"}}>

                            </div>
                                {isCreating ? 
                                    <div style={{display: "flex",gap: "1rem",flexDirection: "column"}}>
                                        <InputText
                                            label={"First Name"}
                                            register={methods.register}
                                            required={true}
                                            name={"first_name"}
                                            setValue={methods.setValue}
                                            getValues={methods.getValues}
                                        />
                                        <InputText
                                            label={"Other Names"}
                                            register={methods.register}
                                            required={false}
                                            name={"other_names"}
                                            setValue={methods.setValue}
                                            getValues={methods.getValues}
                                        />
                                        <InputText
                                            label={"Email"}
                                            register={methods.register}
                                            required={false}
                                            name={"email"}
                                            setValue={methods.setValue}
                                            getValues={methods.getValues}
                                        />
                                        <InputText
                                            label={"Gender"}
                                            register={methods.register}
                                            required={false}
                                            name={"gender"}
                                            setValue={methods.setValue}
                                            getValues={methods.getValues}
                                        />
                                        <InputText
                                            label={"Ethnicity"}
                                            register={methods.register}
                                            required={false}
                                            name={"ethnicity"}
                                            setValue={methods.setValue}
                                            getValues={methods.getValues}
                                        />
                                        <InputText
                                            label={"Education Level"}
                                            register={methods.register}
                                            required={false}
                                            name={"education_level"}
                                            setValue={methods.setValue}
                                            getValues={methods.getValues}
                                        />
                                        <InputText
                                            label={"Education Degree"}
                                            register={methods.register}
                                            required={false}
                                            name={"education_degree"}
                                            setValue={methods.setValue}
                                            getValues={methods.getValues}
                                        />
                                        <InputText
                                            label={"Education University"}
                                            register={methods.register}
                                            required={false}
                                            name={"education_university"}
                                            setValue={methods.setValue}
                                            getValues={methods.getValues}
                                        />
                                        <RHFCheckboxInput label={"Active"} {...methods.register("active")}/>
                                    </div>
                                    :
                                    <div>
                                        <div className="mb-6">

                                           <SingleComboBoxInput
                                              {...methods.register("organization")}
                                              name={"organization"}
                                              label={"Target Organization"} 
                                              options={get(opts,"organizations.data",[])} 
                                              onBlur={async () => {}} 
                                              onChange={async () => {}}
                                            />

                                        </div>
                                        <div className="mb-6">

                                           <SingleComboBoxInput
                                               {...methods.register("person")}
                                              name={"person"} 
                                              label={"Employee"} 
                                              options={get(people,"people",[])} 
                                              onBlur={async () => {}} 
                                              onChange={async (it : any) => console.log(it)}
                                            />

                                        </div>

                                        <div className="mb-6">
                                            <TextInput
                                                placeholder="Enter role name"
                                                data-testid="form-field-name"
                                                label={"Role"}
                                                register={methods.register} name={"role"} />
                                        </div>

                                        <div className="mb-6">
                                            <DateInput
                                                required
                                                register={methods.register}
                                                label={"Start Date"}
                                                type={"date"}
                                                formatLayout={"yyyy-MM-dd"}
                                                getValues={methods.getValues}
                                                name={"start_date"}
                                                setValue={methods.setValue}
                                            />
                                        </div>
                                        <div className="mb-4 mt-4">
                                            <CheckboxInput register={methods.register} label={"Currently working in this role"} name={"current"} />
                                        </div>

                                        {!currentlyWorking ? 
                                            <div className="mb-6">
                                                <DateInput
                                                    required
                                                    register={methods.register}
                                                    label={"End Date"}
                                                    type={"date"}
                                                    formatLayout={"yyyy-MM-dd"}
                                                    getValues={methods.getValues}
                                                    name={"end_date"}
                                                    setValue={methods.setValue}
                                                />
                                            </div>
                                            : 
                                            null
                                        }
                                    </div>
                                }
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    )
}
