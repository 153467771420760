import React, { ChangeEvent, useEffect, useState, useRef } from "react";

import _, { get } from "lodash";
import { FilterTitle } from "./DataContainer/filters/fragments/FilterTitle";
import { DropdownButtonMenu, DropdownButtonItem, DropdownButtonOption } from "@briterbridges/elements";
import { DropdownButtonContainer } from "@briterbridges/elements";
import { ORGANIZATION_MAPPINGS } from "../utils/mappings";
import { useOnClickOutside } from "usehooks-ts";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { Button } from "./Button/Button";

type SelectableDropdown = DropdownButtonOption & { selected: boolean }

export interface DatapointsDrodpownProps {
    title: string
    options: SelectableDropdown[]
}

export const MissingDatapointDropdown = ({ ...props }: DatapointsDrodpownProps) => {
    const { title, options } = props
    const [state, setState] = React.useState({
        isOpen: false
    });
    const ref = useRef(null)
    const handleClickOutside = () => {
        setState({ ...state, isOpen: false })
    }

    useOnClickOutside(ref, handleClickOutside)

    return (
        <DropdownButtonContainer ref={ref}>
            <Button title={"Search Missing Datapoints"} variant="secondary" size="sm"
                onClick={() => setState({ ...state, isOpen: !state.isOpen })}
                iconSuffix={state.isOpen ? <FaChevronDown height={30} width={30} /> : <FaChevronUp height={30} width={30} />}
            />
            {
                state.isOpen && (
                    <DropdownButtonMenu style={{width: "100%"}}>
                        {options.map((o) => {
                            return (
                                <DropdownButtonItem
                                    key={o.key}
                                    onClick={(ev) => {
                                        setState({ ...state, isOpen: false })
                                        o.onClick(ev)
                                    }}>
                                    <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem", width: "100%" }}>
                                        {/* <input type="checkbox" checked={isColumnSelected(o)} onChange={() => console.log("onChange")} /> */}
                                        {o.key}
                                    </div>
                                </DropdownButtonItem>
                            )
                        })}
                    </DropdownButtonMenu>
                )
            }
        </DropdownButtonContainer>
    )
}

export interface IActionFilters {
    addFilter?: any
    removeFilter?: any
    type: any
    title: any
    id: any
    value?: any
    defaultValues: any
    dropdownOpts?: SelectableDropdown[]
    active?: string
}

export const ActionsFilterMissingDatapoint = ({ ...props }: IActionFilters & { bucket: string }) => {
    const { addFilter, removeFilter, defaultValues, bucket, active } = props

    const [options, setOptions] = useState<SelectableDropdown[]>([])

    const getMappings = (bucket: string) => {
        switch (bucket) {
            case 'organizations':
                return ORGANIZATION_MAPPINGS
            default:
                // should never reach here
                break
        }
    }

    const getOptFromMappings = (mappings: any): SelectableDropdown[] => {
        const props = mappings['organizations']['mappings']['properties']

        let opts: SelectableDropdown[] = []

        Object.keys(props).forEach((key, value) => {
            const query = props[key].type
                ? getQueryFromProp(props[key].type, key)
                : { type: 'missing', key: key, value: '' };

            const opt = {
                selected: false,
                key: `${key}`,
                onClick: () => {
                    addFilter(query)
                },
                value: `${key}`
            }

            opts.push(opt)

        })

        return opts
    }

    const getQueryFromProp = (type: string, key: string) => {
        switch (type) {
            case 'text':
                return { type: 'boolean', key: `${key}.keyword`, value: '' }
            case 'boolean':
                return { type: 'boolean', key: key, value: '' }
            default:
                // works for deals,sectors i.e if its null or empty array
                return { type: 'missing', key: key, value: '' }
        }
    }

    const exclude = [
        "type", "attributes", "created_at", "deals_no", "ext_ref",
        "id", "impact", "investors_no", "object_edges", "sdgs_no",
        "sectors_no", "sub_sectors_no", "subject_edges", "technologies_no",
        "total_funding", "updated_at", "angellist", "business_models", "enabled", "facebook", "instagram", "founded",
        "linkedin", "investments", "operating_status", "primary_email_contact", "profile_image_url", "specifier",
        "sdgs", "twitter", "youtube",
    ]

    useEffect(() => {
        const opts = getOptFromMappings(getMappings(bucket))
        const newOpts = opts.filter((opt) => !exclude.includes(opt.key))
        setOptions(opts.filter((opt) => !exclude.includes(opt.key)))

    }, [bucket])

    return (
        // todo(nate): replace with downshift
        <div style={{ zIndex: "1" }}>
            <MissingDatapointDropdown
                title={active ? `Missing ${active}` : "Select Field"}
                options={options} />

        </div>
    )

}

export function ActionFiltersBoolean({ ...props }: IActionFilters) {
    const { addFilter, removeFilter, title, type, id, defaultValues, value } = props
    const v = _.get(defaultValues, 'value', false);
    const [checked, setChecked] = useState(defaultValues);

    useEffect(() => {
        setChecked(v);
    }, [v]);

    useEffect(() => {
        if (checked) {
            addFilter({ type, key: id, value: value || true })
        } else {
            removeFilter({ type, key: id, value: value || false })
        }
    }, [checked]);
    return (
        <div
            className="text-sm text-bs-light-black flex items-center py-1">
            <input
                type="checkbox"
                onChange={(ev) => {
                    setChecked(ev.target.checked)
                }}
                checked={checked}
                className="accent-black w-5 h-5"
            />

            <FilterTitle title={title} overrideStyle={"px-2 pt-0"} />
        </div>
    )
}



export function ActionFiltersDateRange({ ...props }: IActionFilters) {
    const { addFilter, type, id, defaultValues, removeFilter } = props
    const [values, setValues] = useState(get(defaultValues, 'value', {}))

    const update = (ev: ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [ev.target.name]: ev.target.value })
    }

    useEffect(() => {
        if (get(defaultValues, 'value.lte', undefined) || get(defaultValues, 'value.gte', undefined)) {
            setValues(get(defaultValues, 'value', {}))
        }
    }, [defaultValues])

    useEffect(() => {
        const lte = get(values, 'lte', undefined)
        const gte = get(values, 'gte', undefined)

        if (gte || lte) {
            addFilter({ type, key: id, value: values })
        } else {
            removeFilter({ type, key: id, value: values })
        }
    }, [values])

    const hasValues = get(values, 'lte', undefined) || get(values, 'gte', undefined)
    return (
        <>
            <div className="flex rounded-md gap-0">
                <button
                    className="p-2.5 px-3 bg-bs-light-gray-100 rounded-l-md">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round"
                            d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                    </svg>
                </button>
                <input type="date"
                    className="p-1.5 px-3 border-solid border-gray-100 border border-r-0"
                    name="gte"
                    value={get(values, 'gte', '')}
                    onChange={update}
                    placeholder="Select date" />
                <input type="date"
                       className="p-1.5 px-3 border-solid border-gray-100 border border-l-0"
                    name="lte"
                    value={get(values, 'lte', '')}
                    onChange={update}
                    placeholder="Select date" />
            </div>
        </>
    )
}

interface IActionRefresh {
    onClick?: any
    text?: string
}

export function ActionFiltersRefresh({ ...props }: IActionRefresh) {
    return (
        <button
            className="flex self-center items-center border border-bs-light-primary rounded-md bg-bs-light-primary py-2 px-2.5"
            onClick={props.onClick}
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                stroke="currentColor" className="w-4 h-4 mx-2.5">
                <path strokeLinecap="round" strokeLinejoin="round"
                    d="M4.5 12c0-1.232.046-2.453.138-3.662a4.006 4.006 0 013.7-3.7 48.678 48.678 0 017.324 0 4.006 4.006 0 013.7 3.7c.017.22.032.441.046.662M4.5 12l-3-3m3 3l3-3m12 3c0 1.232-.046 2.453-.138 3.662a4.006 4.006 0 01-3.7 3.7 48.657 48.657 0 01-7.324 0 4.006 4.006 0 01-3.7-3.7c-.017-.22-.032-.441-.046-.662M19.5 12l-3 3m3-3l3 3" />
            </svg>
            <span className="font-semibold pr-4 text-sm">{props.text || "Refresh"}</span>
        </button>
    )
}

export function ActionFilters({ bql, onlyBQL = false }: { bql?: any, onlyBQL?: boolean }) {
    const [active, setActive] = useState(false);

    return (
        <div className="flex action-bar gap-3.5">
            <div className="flex-grow flex border border-gray-300 rounded-md">
                <button className="p-2.5 px-3 bg-bs-light-gray-300 rounded-l-md">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                        stroke="currentColor" className="w-4 h-4">
                        <path strokeLinecap="round" strokeLinejoin="round"
                            d="M6 13.5V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m12-3V3.75m0 9.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 3.75V16.5m-6-9V3.75m0 3.75a1.5 1.5 0 010 3m0-3a1.5 1.5 0 000 3m0 9.75V10.5" />
                    </svg>

                </button>
                <div className="w-full relative">
                    <input
                        type="text"
                        className="w-full px-2 h-full outline-0"
                        placeholder="Filter options"
                        onFocus={() => setActive(true)}
                        onBlur={() => setActive(false)}
                    />
                    {
                        active && (
                            <div
                                className="absolute left-0 top-auto z-10 w-full bg-white drop-shadow-md rounded-md rounded-t-none border-t-2 border-black">
                                <table className="table-auto w-full text-sm">
                                    <tbody>
                                        {
                                            bql && bql.map((f: { id: {} | null | undefined; }) => {
                                                return (
                                                    <tr className="cursor-pointer hover:bg-bs-light-gray-50 bg-opacity-50">
                                                        <td className="bg-bs-light-primary px-2 py-2 w-6 bg-opacity-20">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24"
                                                                strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                                    d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z" />
                                                            </svg>
                                                        </td>
                                                        <td className="font-mono px-5">{f.id}</td>
                                                        <td className="text-bs-light-gray-800">
                                                            Filter results that contains <span
                                                                className="font-mono bg-bs-light-gray-100 text-bs-light-black px-2">{f.id}</span>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        )
                    }
                </div>
                <button className="p-2.5 px-3 font-semibold text-sm bg-bs-light-gray-300 rounded-r-md">BQL</button>
            </div>
            <ActionFiltersRefresh />
        </div>
    )
}
