import React, {createContext, useContext, useState} from "react";
import {ComposePane, IComposePane} from "../interface/Modal/ComposeModal";
import {upsert} from "../utils/arrays";
import _ from "lodash";
import {configure, GlobalHotKeys} from "react-hotkeys";

export interface IPlatformContext {
    addPane: (pane: IComposePane) => void;
    removePaneByID: (id: string) => void;
    bindKeys: (keys: object, handler: object) => void;
    bindMultipleKeys: (keys: object[], handler: object[]) => void;
    setHotKeysDisplay: (value: boolean) => void;
    hotKeys: object;
}

const PlatformContext = createContext<IPlatformContext>(
    {} as IPlatformContext
)

configure({
    stopEventPropagationAfterHandling: false,
    stopEventPropagationAfterIgnoring: false,
    allowCombinationSubmatches: true,
    simulateMissingKeyPressEvents: false,
    ignoreKeymapAndHandlerChangesByDefault: false
})


export function PlatformProvider({children}: { children: React.ReactNode }) {
    const [state, setState] = useState({
        composePanes: [] as IComposePane[],
        composeIndex: null
    });
    const [hotKeys, setHotKeys] = useState({
        keymap: {},
        handlers: {},
        isShowingHotkeys: false
    });

    const setHotKeysDisplay = (value: boolean) => {
        setHotKeys({
            ...hotKeys,
            isShowingHotkeys: value
        })
    }

    const bindMultipleKeys = (keys: object[], handler: object[]) => {
        setHotKeys({
            ...hotKeys,
            keymap: Object.assign(hotKeys.keymap, {...keys}),
            handlers: Object.assign(hotKeys.handlers, {...handler})
        })
    }

    const bindKeys = (keys: object, handler: object) => {
        setHotKeys({
            ...hotKeys,
            keymap: Object.assign(hotKeys.keymap, keys),
            handlers: Object.assign(hotKeys.handlers, handler)
        })
    }

    const addPane = (pane: IComposePane) => {
        const panes: IComposePane[] = upsert(state.composePanes, pane, 'id')
        setState({
            ...state,
            //@ts-ignore
            composeIndex: _.findIndex(panes, {id: pane.id}),
            composePanes: panes
        })
    }

    const onMinimize = () => {
        setState({
            ...state,
            composeIndex: null
        })
    }

    const onMaximize = (pane: IComposePane) => {
        setState({
            ...state,
            //@ts-ignore
            composeIndex: _.findIndex(state.composePanes, {id: pane.id})
        })
    }

    const removePane = (pane: IComposePane) => {
        if (pane.onClose) {
            pane.onClose(pane);
        }
        const panes = _.filter(state.composePanes, (f: IComposePane) => {
            return f.id !== pane.id
        });

        setState({
            ...state,
            composePanes: panes,
            composeIndex: null
        })
    }

    const removePaneByID = (id: string) => {
        const panes = _.filter(state.composePanes, (f: IComposePane) => {
            return f.id !== id
        });

        setState({
            ...state,
            composePanes: panes,
            composeIndex: null
        })
    }

    const minimized = _.filter(state.composePanes, (f: IComposePane, index: number) => {
        return index !== state.composeIndex
    });

    const paneMaximized = state.composeIndex !== null
        ? state.composePanes[state.composeIndex]
        : null;

    return (
        <PlatformContext.Provider
            value={{addPane, bindKeys, bindMultipleKeys, setHotKeysDisplay, hotKeys, removePaneByID}}>
            {
                // https://github.com/greena13/react-hotkeys/issues/219
                Object.keys(hotKeys.keymap).map((value) => {
                    return <GlobalHotKeys key={value}
                        //@ts-ignore
                                          keyMap={{[value]: hotKeys.keymap[value]}}
                        //@ts-ignore
                                          handlers={{[value]: hotKeys.handlers[value]}}
                                          allowChanges={true}
                    />
                })
            }
            {
                hotKeys.isShowingHotkeys && (
                    <div
                        className="fixed right-10 bottom-10 bg-bs-light-black bg-opacity-90 text-bs-light-white  rounded-md">
                        <div className="py-2 px-3">
                            <p className="text-sm pb-2.5">SHORTCUTS</p>
                            {
                                Object.keys(hotKeys.keymap).map((key) => {
                                    //@ts-ignore
                                    const {sequence, name} = hotKeys.keymap[key];
                                    if (!name) return '';
                                    return (
                                        <p className="text-sm pb-1" key={key}>
                                            {name}
                                            <span
                                                className="ml-2 uppercase text-xs px-1 py-0.5 bg-bs-light-white text-bs-light-black rounded-md">
                                                {sequence}
                                            </span>
                                        </p>
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            }
            {
                minimized.length > 0 && (
                    <div className="fixed bottom-0 z-50 w-5/6 inset-x-auto">
                        <div className="flex justify-end gap-5">
                            {
                                minimized.map((pane: IComposePane, index: number) => (
                                    <ComposePane pane={pane}
                                                 onMaximize={onMaximize}
                                                 onMinimize={onMinimize}
                                                 isMinimized={state.composeIndex !== index}
                                                 onClose={() => {
                                                     removePane(pane)
                                                 }}
                                    />
                                ))
                            }
                        </div>
                    </div>
                )
            }
            {
                paneMaximized && (
                    <ComposePane pane={paneMaximized}
                                 onMaximize={onMaximize}
                                 onMinimize={onMinimize}
                                 isMinimized={false}
                                 onClose={() => {
                                     removePane(paneMaximized)
                                 }}
                    />
                )
            }
            {children}
        </PlatformContext.Provider>
    )
}

export function usePlatformContext() {
    return useContext(PlatformContext);
}