import React from "react";
import {
    DataContainerProvider,
    useDataContainerContext
} from "../../interface/DataContainer/context/DataContainerContext";
import {find, get} from "lodash";
import {ActionFiltersBoolean, ActionFiltersDateRange, ActionFiltersRefresh} from "../../interface/ActionFilters";
import {gql, useQuery} from "@apollo/client";
import {
    addDays,
    endOfDay,
    endOfMonth,
    endOfYear,
    format,
    startOfMonth,
    startOfYear,
    sub,
    subDays,
    subMonths
} from "date-fns";

const FETCH_AGGREGATION_STAT_AGBASE = gql`
    query ($mtd: AggregationRequest!, $last: AggregationRequest!, $ytd: AggregationRequest!, $range: AggregationRequest!) {
        last: aggregate(aggregations: $last) {
            aggregations
            took
        }
        mtd: aggregate(aggregations: $mtd) {
            aggregations
            took
        }
        ytd: aggregate(aggregations: $ytd) {
            aggregations
            took
        }
        range: aggregate(aggregations: $range) {
            aggregations
            took
        }
    }
`

const NAIndicator = () => <span className="text-gray-400">N/A</span>

export default function AgBaseReportingContainer() {
    return (
        <DataContainerProvider filters={[]} defaultFilters={[
            {
                "type": "range",
                "key": "created_at",
                "value": {
                    "gte": format(sub(new Date(), { days: 7 }), "yyyy-MM-dd"),
                    "lte": format(endOfDay(new Date()), "yyyy-MM-dd")
                }
            },
        ]}>
            <div className="w-full px-10 py-8">
                <div className="flex-grow">
                    <TableResults/>
                </div>
            </div>
        </DataContainerProvider>

    )
}

const replaceTimeKeyword = (obj: any[], newKey: string, oldKey: string = "created_at") => {
    const newObj = obj
    obj.map((value, index) => {
        const rangeValues = get(value, 'range.created_at', undefined)
        if(rangeValues !== undefined) {
            newObj[index] = { range: { [newKey] : rangeValues }}
        }
        return value;
    })
    return newObj
}

const key = "agbase"
export const TableResults = () => {
    const {
        addFilter, removeFilter, filtersActive,
        applyFilters, isDirty, filtersQuery, clearAllFilters
    } = useDataContainerContext()

    const range = find(filtersActive, {key: 'created_at'})
    const values = range ? `${get(range, 'value.gte', '')} to ${get(range, 'value.lte', '')}` : ''
    const indicators = [
        {
            title: "% increase in the number of active users (monthly) in SS",
            target_desc: "25 active users (monthly) in SSA once the platform is available to users (includes Briter Intelligence users)",
            target_numeric: 25,
            target_unit: 'users',
            key: "users_active.value",
            query: {
                last: {
                    bucket: "activities",
                    query: {
                        filter: [
                            {
                                query_string: {
                                    query: `*${key}*`,
                                    default_field: "source.keyword"
                                }
                            },
                            {
                                range: {
                                    time: {
                                        gte: startOfMonth(subMonths(new Date(), 1)),
                                        lte: endOfMonth(subMonths(new Date(), 1)),
                                    }
                                }
                            }
                        ]
                    },
                    list: [
                        {
                            key: "users_active",
                            type: "cardinality",
                            params: {
                                field: "subject.keyword"
                            }
                        }
                    ]
                },
                mtd: {
                    bucket: "activities",
                    query: {
                        filter: [
                            {
                                query_string: {
                                    query: `*${key}*`,
                                    default_field: "source.keyword"
                                }
                            },
                            {
                                range: {
                                    time: {
                                        gte: startOfMonth(new Date()),
                                        lte: endOfMonth(new Date()),
                                    }
                                }
                            }
                        ]
                    },
                    list: [
                        {
                            key: "users_active",
                            type: "cardinality",
                            params: {
                                field: "subject.keyword"
                            }
                        }
                    ]
                },
                ytd: {
                    bucket: "activities",
                    query: {
                        filter: [
                            {
                                query_string: {
                                    query: `*${key}*`,
                                    default_field: "source.keyword"
                                }
                            },
                            {
                                range: {
                                    time: {
                                        gte: startOfYear(new Date()),
                                        lte: endOfYear(new Date()),
                                    }
                                }
                            }
                        ]
                    },
                    list: [
                        {
                            key: "users_active",
                            type: "cardinality",
                            params: {
                                field: "subject.keyword"
                            }
                        }
                    ]
                },
                range: {
                    bucket: "activities",
                    query: {
                        filter: [
                            ...replaceTimeKeyword(filtersQuery, "time"),
                            {
                                query_string: {
                                    query: `*${key}*`,
                                    default_field: "source.keyword"
                                }
                            },
                        ]
                    },
                    list: [
                        {
                            key: "users_active",
                            type: "cardinality",
                            params: {
                                field: "subject.keyword"
                            }
                        }
                    ]
                }
            },
            render: (data: any) => {
                return get(data, "users_active.value", 0)
            }
        },
        {
            title: "No. of freemium subscribers",
            target_desc: "150 free subscribers",
            target_numeric: 150,
            target_unit: 'users',
            key: "freemium_users.value",
            query: {
                last: {
                    bucket: "users",
                    query: {
                        filter: [
                            {
                                query_string: {
                                    query: `*${key}*`,
                                    default_field: "source.keyword"
                                }
                            },
                            {
                                range: {
                                    created_at: {
                                        gte: startOfMonth(subMonths(new Date(), 1)),
                                        lte: endOfMonth(subMonths(new Date(), 1)),
                                    }
                                }
                            }
                        ]
                    },
                    list: [
                        {
                            key: "freemium_users",
                            type: "value_count",
                            params: {
                                field: "id.keyword"
                            }
                        }
                    ]
                },
                mtd: {
                    bucket: "users",
                    query: {
                        filter: [
                            {
                                query_string: {
                                    query: `*${key}*`,
                                    default_field: "source.keyword"
                                }
                            },
                            {
                                range: {
                                    created_at: {
                                        gte: startOfMonth(new Date()),
                                        lte: endOfMonth(new Date()),
                                    }
                                }
                            }
                        ]
                    },
                    list: [
                        {
                            key: "freemium_users",
                            type: "value_count",
                            params: {
                                field: "id.keyword"
                            }
                        }
                    ]
                },
                ytd: {
                    bucket: "users",
                    query: {
                        filter: [
                            {
                                query_string: {
                                    query: `*${key}*`,
                                    default_field: "source.keyword"
                                }
                            },
                            {
                                range: {
                                    created_at: {
                                        gte: startOfYear(new Date()),
                                        lte: endOfYear(new Date()),
                                    }
                                }
                            }
                        ]
                    },
                    list: [
                        {
                            key: "freemium_users",
                            type: "value_count",
                            params: {
                                field: "id.keyword"
                            }
                        }
                    ]
                },
                range: {
                    bucket: "users",
                    query: {
                        filter: [
                            ...filtersQuery,
                            {
                                query_string: {
                                    query: `*${key}*`,
                                    default_field: "source.keyword"
                                }
                            },
                        ]
                    },
                    list: [
                        {
                            key: "freemium_users",
                            type: "value_count",
                            params: {
                                field: "id.keyword"
                            }
                        }
                    ]
                }
            },
            render: (data: any) => {
                return get(data, "freemium_users.value", "-")
            }
        },

        {
            title: "No. of resources published (articles, reports, infographics)",
            target_desc: "No. of resources published (articles, reports, infographics)",
            target_numeric: "TBD",
            target_unit: 'resources',
            query: {
                last: {
                    bucket: "resources,articles",
                    query: {
                        filter: [
                            {
                                terms: {
                                    "agbase_visibility.name.keyword": ["Visible"]
                                }
                            },
                            {
                                range: {
                                    published_at: {
                                        gte: startOfMonth(subMonths(new Date(), 1)),
                                        lte: endOfMonth(subMonths(new Date(), 1)),
                                    }
                                }
                            }
                        ]
                    },
                    list: [
                        {
                            key: "ag_resources",
                            type: "value_count",
                            params: {
                                field: "id.keyword"
                            }
                        }
                    ]
                },
                mtd: {
                    bucket: "resources,articles",
                    query: {
                        filter: [
                            {
                                terms: {
                                    "agbase_visibility.name.keyword": ["Visible"]
                                }
                            },
                            {
                                range: {
                                    published_at: {
                                        gte: startOfMonth(new Date()),
                                        lte: endOfMonth(new Date()),
                                    }
                                }
                            }
                        ]
                    },
                    list: [
                        {
                            key: "ag_resources",
                            type: "value_count",
                            params: {
                                field: "id.keyword"
                            }
                        }
                    ]
                },
                ytd: {
                    bucket: "resources,articles",
                    query: {
                        filter: [
                            {
                                terms: {
                                    "agbase_visibility.name.keyword": ["Visible"]
                                }
                            },
                            {
                                range: {
                                    published_at: {
                                        gte: startOfYear(new Date()),
                                        lte: endOfYear(new Date()),
                                    }
                                }
                            }
                        ]
                    },
                    list: [
                        {
                            key: "ag_resources",
                            type: "value_count",
                            params: {
                                field: "id.keyword"
                            }
                        }
                    ]
                },
                range: {
                    bucket: "resources,articles",
                    query: {
                        filter: [
                            ...replaceTimeKeyword(filtersQuery, "published_at"),
                            {
                                terms: {
                                    "agbase_visibility.name.keyword": ["Visible"]
                                }
                            },
                        ]
                    },
                    list: [
                        {
                            key: "ag_resources",
                            type: "value_count",
                            params: {
                                field: "id.keyword"
                            }
                        }
                    ]
                }
            },
            render: (data: any) => {
                return get(data, 'ag_resources.value', 0)
            }
        },
        {
            title: "Average time-on-platform per user from SSA",
            target_desc: "5 minutes average time-on-platform per user from SSA.",
            target_numeric: (5 * 60),
            target_unit: 'seconds',
            render: (data: any) => {
                return <NAIndicator />
            }
        },
        {
            title: "% of recurring users from SSA",
            target_desc: "5 of users recurring",
            target_numeric: 5,
            target_unit: 'units',
            render: (data: any) => {
                return <NAIndicator />
            }
        },
        {
            title: "% of users generating intelligence products automatically through the platform",
            target_desc: "10% of the users automatically generate intelligence products",
            target_numeric: 10,
            target_unit: '%',
            render: (data: any) => {
                return <NAIndicator />
            }
        },
        {
            title: "No. of Global South subscribers on the platform",
            target_desc: "100 free subscribers to Agbase platform from the Global South",
            target_numeric: 100,
            target_unit: 'users',
            render: (data: any) => {
                return <NAIndicator />
            }
        },
        {
            title: "No. of Global North subscribers on the platform",
            target_desc: "10 free subscribers to Agbase platform from the Global North",
            target_numeric: 10,
            target_unit: 'users',
            render: (data: any) => {
                return <NAIndicator />
            }
        },
        {
            title: "Churn rate",
            target_desc: "5% churn rate for all users",
            target_numeric: 5,
            target_unit: '%',
            render: (data: any) => {
                return <NAIndicator />
            }
        },
        {
            title: "Conversion rate from free to paid users",
            target_desc: "No. of paid subscribers in year 1",
            target_numeric: 0,
            target_unit: 'users',
            render: (data: any) => {
                return <NAIndicator />
            }
        },
        {
            title: "No. of subscribers to The Feed by AgBase",
            target_desc: "No. of subscribers to the newsletter",
            target_numeric: "TBD",
            target_unit: 'users',
            render: (data: any) => {
                return <NAIndicator />
            }
        },
        {
            title: "No. of newsletters published as The Feed by AgBase",
            target_desc: "No. of newsletters published",
            target_numeric: "TBD",
            target_unit: 'newsletters',
            render: (data: any) => {
                return <NAIndicator />
            }
        },
    ]

    return (
        <div className="w-full">
            <div className="pb-5 flex gap-5 justify-end">
                <ActionFiltersDateRange
                    type="range"
                    addFilter={addFilter}
                    removeFilter={removeFilter}
                    defaultValues={find(filtersActive, {key: 'created_at'})}
                    id={"created_at"}
                    title={""}
                />
                <ActionFiltersRefresh
                    onClick={applyFilters}
                    text={isDirty ? "Apply" : "Refresh"}
                />
            </div>
            <table className="table-auto w-full border-gray-100">
                <thead>
                <tr>
                    <th className="font-semibold text-sm p-1 px-2 text-left bg-bs-light-primary">Target (Indicator)</th>
                    <th className="font-semibold text-sm p-1 px-2 text-center bg-bs-light-primary">TARGET</th>
                    <th className="font-semibold text-sm p-1 px-2 text-center bg-bs-light-primary">PREV.MONTH</th>
                    <th className="font-semibold text-sm p-1 px-2 text-center bg-bs-light-primary">MTD</th>
                    <th className="font-semibold text-sm p-1 px-2 text-center bg-bs-light-primary">YTD</th>
                    <th className="font-semibold text-sm p-1 px-2 text-center bg-bs-light-primary uppercase">
                        {values}
                    </th>
                </tr>
                </thead>
                <tbody>
                {
                    indicators.map((item) => {
                        return <IndicatorRow item={item} key={item.key} />
                    })
                }
                </tbody>
            </table>
        </div>
    )
}

const RenderDiff = ({prev, current} : { prev: number, current: number}) => {
    const diff = current - prev;
    if(isNaN(diff) || current === 0) {
        return <></>
    }
    const percentage = diff / prev * 100
    if( diff > 0 ) {
        return <span className="text-green-600 text-sm px-1">(+{percentage.toFixed(1)}%)</span>;
    } else if (diff < 0) {
        return <span className="text-red-600 text-sm px-1">({percentage.toFixed(1)}%)</span>;
    }

    return <span>(-)</span>;
}

const IndicatorRow = ({ item } : any) => {
    const { data, error , loading } = useQuery(FETCH_AGGREGATION_STAT_AGBASE, {
        variables: item.query
    })
    return (
        <tr key={item.title} className="hover:bg-gray-100">
            <td className="p-2 flex flex-col">
                <span>{item.target_desc}</span>
                <span className="text-gray-500 text-sm px-2">({item.title})</span>
            </td>
            <td className="p-2 align-middle text-center">
                {item.target_numeric} <span className="text-xs">{item.target_unit}</span>
            </td>
            <td className="p-2 align-middle text-center">
                {item.render(get(data, 'last.aggregations', {}))}
            </td>
            <td className="p-2 align-middle text-center">
                {item.render(get(data, 'mtd.aggregations', {}))} <RenderDiff
                    prev={item.render(get(data, 'last.aggregations', {}))}
                    current={item.render(get(data, 'mtd.aggregations', {}))} />
            </td>
            <td className="p-2 align-middle text-center">
                {item.render(get(data, 'ytd.aggregations', {}))}
            </td>
            <td className="p-2 align-middle text-center">
                {item.render(get(data, 'range.aggregations', {}))}
            </td>
        </tr>
    )
}