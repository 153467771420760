import { useState, useEffect } from "react";
import styled from "styled-components";
import { get } from "lodash";

interface WordContainerProps {
    word: string;
    jobs: any;
}

const StyledWordContainer = styled.span<{ $label: string }>`
    padding: 0;
    margin-left: 0;
    margin-bottom: 0;
    border-radius: 0.25rem;
    ${(props) => {
        switch (props.$label) {
            case "B-MISC":
                return `
                    border: none;
                    margin-left: 0;
                `;
                break;
            case "B-ORG":
                return `
                    background-color: #ffdb80;
                    margin-left: 0.5rem;
                `;
                break;
            case "B-LOC":
                return `
                    border: 2px solid #4ade80;
                    margin-left: 0.5rem;
                `;
                break;
            case "B-PER":
                return `
                    background-color: red;
                    margin-left: 0.5rem;
                `;
                break;
            default:
                return `
                    border: none;
                    margin-left: 0;
                `;
                break;
        }
    }};
`;

export const WordContainer = ({ ...props }: WordContainerProps) => {
    const { word, jobs } = props;
    const [label, setLabel] = useState("");
    const [showCategories, setShowCategories] = useState(false);

    const CATEGORIES = ["B-ORG", "B-PER", "B-LOC"];

    const handleClick = () => {
        //setShowCategories(!showCategories)
    };

    const findLabel = (word: string, jobs: any) => {
        let label = "";
        for (let i = 0; i < jobs.length; i++) {
            const t = get(jobs[i], "type", "SingleOrganizationCreate");
            if (
                t == "SingleOrganizationCreate" &&
                word === get(jobs[i], "payload.msg.name", "")
            ) {
                label = "B-ORG";
                break;
            }
            if (
                t == "SingleDealCreate" &&
                word === get(jobs[i], "payload.msg.target.name", "")
            ) {
                label = "B-PER";
                break;
            }
        }

        return label;
    };

    const handleMouseDown = (event: any) => {
        console.log("word", word);
    };

    const handleMouseUp = (event: any) => {
        console.log("word", word);
    };

    const handleChange = (event: any) => {
        setLabel(event.target.value);
        setShowCategories(false);
    };

    useEffect(() => {
        const label = findLabel(word, jobs);
        setLabel(label);
    }, []);

    return (
        <div style={{ padding: "0.25rem" }}>
            {showCategories && (
                <StyledSelect onChange={handleChange}>
                    <option value="">Select your category</option>
                    <option value={"B-MONEY"}>Money</option>
                    <option value={"B-ORG"}>Organization</option>
                    <option value={"B-LOC"}>Location</option>
                    <option value={"B-PER"}>Person</option>
                </StyledSelect>
            )}
            <StyledWordContainer
                $label={label}
                onClick={handleClick}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
            >
                {word}
            </StyledWordContainer>
        </div>
    );
};

export const StyledSelect = styled.select`
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    display: block;
`;

export interface AnnotationGridProps {
    style?: React.CSSProperties;
	job: any
}

export const AnnotationGrid = ({ ...props }: AnnotationGridProps) => {
    const {
		job,
        style = {
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            overflowY: "scroll",
            height: "600px",
        },
    } = props;

	const jobs = get(job,"jobs",[])
    const [allWords, setAllWords] = useState(get(job,"media_mention.content","").split(" "));
	const [analysis,setAnalysis] = useState(get(job,"media_mention.analysis",{}))

	console.log("test",analysis)

    return (
        <div style={style}>
            {allWords.map((it: any) => (
                <WordContainer word={it} jobs={jobs} />
            ))}
        </div>
    );
};
