import React, {createContext, ReactNode, useContext, useEffect, useState} from "react";
import {gql, useLazyQuery} from "@apollo/client";
import {SpinnerView} from "../interface/Spinner";
import {useNavigate} from "react-router-dom";

export interface Session {
    user?: User;
    initialized: boolean;
    id: string;
}

export interface User {
    id: string;
    email: string;
    first_name: string;
    other_names: string;
    initialized: boolean;
}

export interface AuthContextType {
    session?: Session;
    loading: boolean;
    error?: any;
    check: any;
    setSession: any;
    // login: (email: string, password: string) => void;
    // signUp: (email: string, name: string, password: string) => void;
    // logout: () => void;
}

const AuthContext = createContext<AuthContextType>(
    {} as AuthContextType
);

const CHECK_AUTH = gql`
query auth {
    auth {
        id
        user {
            id
            first_name
            other_names
            email
        }
    }
}
`

export function AuthProvider({children}: { children: ReactNode }): JSX.Element {
    const [session, setSession] = useState<Session>({
        id: "", initialized: false, user: undefined
    });
    const [auth, {loading, data, error, refetch}] = useLazyQuery(CHECK_AUTH);
    const navigate = useNavigate();
    const check = (redirect: string) => {
        try {
            return auth()
                .then(() => {
                    if (error === undefined) {
                        console.log("success auth check")
                        setSession({...data.auth, initialized: true});
                        navigate(redirect);
                        return true;
                    } else {
                        console.log("failed auth check")
                        return false;
                    }
                })
        } catch (e) {
            // error state
        }
    }

    useEffect(() => {
        auth().then((r) => {
            setSession({...r.data?.auth, initialized: true})
        })
    }, []);

    // console.log("session", session, "loading", loading);
    return (
        <AuthContext.Provider value={{session, error, loading, check, setSession}}>
            {loading && !session.initialized && (
                <SpinnerView message="Checking current session.."/>
            )}
            {!loading && session.initialized && children}
        </AuthContext.Provider>
    )
}

export function useAuth() {
    return useContext(AuthContext);
}