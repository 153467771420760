import React, {useEffect, useState} from "react";
import {IDataGrid, IDataGridHeader} from "./DataGridTypes";
import {DataGrid} from "./DataGrid";
import {DocumentNode, QueryResult, TypedDocumentNode, useQuery} from "@apollo/client";
// @ts-ignore
import _, {get} from "lodash";

interface IDataGridGraphQL extends IDataGrid {
    query: DocumentNode | TypedDocumentNode;
    property?: string;
    map?: (data: QueryResult) => void;
    root: string;
    filters?: any;
    variables?: any;
    options?: any;
    allHeaders?: IDataGridHeader[];
    onColumnsChange?: (col: IDataGridHeader) => void;
}

export interface IDataGridGraphQLSort {
    key: string;
    dir: string;
}

/**
 * @todo Whenever there's a change in the Context, this component re-render.
 *
 * @param property
 * @param props
 * @constructor
 */
export function DataGridGraphQL({property = 'data', ...props}: IDataGridGraphQL) {
    const {
        query,
        root,
        filters,
        variables, sort,
        onColumnsChange, allHeaders,
        rowsPerPage = 50
    } = props;


    const [state, setState] = useState({
        offset: 0,
        size: rowsPerPage,
        page: 1,
        sort: sort,
        rerender: true
    });

    const v = {
        ...variables,
        [root]: {
            ...get(variables, root, {}),
            size: state.size,
            sort: state.sort,
            query: {
                filter: filters
            },
            filters: []
        }
    }

    // console.log(v, filters)
    const {data, loading, error, client, refetch} = useQuery(query, {
        variables: v,
        fetchPolicy: 'network-only',
        // pollInterval: 1000
    })

    useEffect(() => {
        refetch();
    }, [state.offset, state.size, state.page, filters])

    const rows = _.get(data, [root, property].join("."), []).map((c: any) => c);
    const count = _.get(data, [root, 'total'].join('.'), 0);
    const pages = _.get(data, [root, 'pages'].join('.'), 0);
    const current = _.get(data, [root, 'current'].join('.'), 0);
    const took = _.get(data, [root, 'took'].join("."), 0);

    const handleUpdateProperty = (id: any, entity: any, prop: any, type: any, payload: any) => {
        console.log("updating....", id, entity, prop, type, payload)
    }

    return (

        <DataGrid
            {...props}
            loading={loading}
            data={rows}
            count={count}
            pages={pages}
            took={took}
            currentPage={current}
            rowsPerPage={state.size}
            sort={state.sort}
            allHeaders={allHeaders}
            sortBy={(data) => {
                setState({
                    ...state,
                    sort: [data]
                })
            }}
            // onSort={}
            onChangeRowsPerPage={(value) => {
                setState({
                    ...state,
                    size: value
                });
            }}
            onPagination={(value) => {
                setState({
                    ...state,
                    page: value,
                    offset: state.size * (value - 1)
                })
            }}
            onColumnsChange={onColumnsChange}
        />
    )
}
