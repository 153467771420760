import React, {useEffect, useRef, useState} from "react";
import {Button} from "../Button/Button";
import {ChevronDownIcon, ChevronUpIcon} from "@heroicons/react/outline";
import {useDataContainerContext} from "./context/DataContainerContext";
import classNames from "classnames";
import FilterFactory from "./filters/FilterFactory";
import _ from "lodash";
import {useHover, useLocalStorage, useOnClickOutside} from "usehooks-ts";

const countActiveFilters = (filters: any[], actives: string[]): number => {
    const a = filters?.filter((f) => actives.includes(f.key));
    return a ? a.length : 0;
}

export function DataContainerFilters() {
    const {
        filters,
        resetFilters,
        isDirty,
        filtersActive,
        filtersChanges,
        applyFilters,
        addFilter,
        removeFilter,
        clearAllFilters
    } = useDataContainerContext();
    const [isVisible, setIsVisible] = useLocalStorage('showFilters', true)
    const [state, setState] = useState({isVisible: isVisible});

    const setVisibilityState = (current: boolean) => {
        setIsVisible(current)
        setState({ ...state, isVisible: current })
    }

    const clsContainer = classNames({
        "filters relative shrink-0 max-h-screen bg-bs-light-gray-10 border-r-bs-light-border-light border-r min-h-screen": true,
        "w-[20rem] flex-grow max-w-[20rem]": state.isVisible,
        "w-10": !state.isVisible
    })
    const clsFilters = classNames({
        "p-4 pt-16 overflow-y-auto max-h-full": true,
        "hidden": !state.isVisible
    })

    const activeFiltersKeys = filtersActive.map((f) => f.key);
    const changesFilterKeys = filtersChanges.map((f) => f.key);
    const ref = useRef(null)

    return (
        <div ref={ref}
            className={clsContainer}>
            <button
                onClick={() => setVisibilityState(!state.isVisible)}
                onMouseEnter={() => setVisibilityState(true)}
                className="absolute rounded-full top-2 p-1.5 bg-gray-200 transition-all duration-[250ms] right-0 rounded-r-none">
                {
                    !state.isVisible && (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd"
                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                  clipRule="evenodd"/>
                        </svg>
                    )
                }
                {
                    state.isVisible && (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd"
                                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                  clipRule="evenodd"/>
                        </svg>
                    )
                }
            </button>
            {
                isVisible && (
                    <div className="absolute z-0 top-3 left-0 bg-bs-light-gray-10">
                        <div className="w-full">
                            <div className="flex space-between px-8 gap-2">
                                {
                                    (activeFiltersKeys.length > 0) && (
                                        // <Button color={"tertiary"} onClick={resetFilters}>Clear All</Button>
                                        <Button title={"Clear filters"} size="xs" variant="none" onClick={clearAllFilters}/>
                                    )
                                }
                                {
                                    isDirty && (
                                        // <Button color={"primary"} onClick={applyFilters}>Apply Filters</Button>
                                        <Button title={"Apply filters"} size="xs" variant="primary" onClick={(ev) => {
                                            ev.preventDefault()
                                            applyFilters()
                                            setVisibilityState(false)
                                        }}/>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }
            <div className={clsFilters}>
                {
                    filters.map((category: any, index: number) => {
                        const categoryActiveFiltersCount = countActiveFilters(category?.filters, activeFiltersKeys);
                        const categoryChangeFilterCount = countActiveFilters(category?.filters, changesFilterKeys);

                        const [state, setState] = useState({
                            isOpen: index === 0
                        });

                        const subfilters = _.get(category, 'filters', []);
                        return (
                            <div className="mb-5" key={category.title}>
                                <div className="flex cursor-pointer select-none mb-2.5"
                                     onClick={() => setState({...state, isOpen: !state.isOpen})}>
                                    <p className="uppercase font-semibold text-sm flex-grow">
                                        {category.title}
                                        {
                                            ((categoryChangeFilterCount > 0) || (categoryActiveFiltersCount > 0)) && (
                                                <span
                                                    className={classNames({
                                                        "font-normal py-1 px-2 text-xs ml-2 rounded-md": true,
                                                        "bg-bs-light-gray-300": categoryChangeFilterCount === categoryActiveFiltersCount,
                                                        "bg-bs-light-black text-white": categoryChangeFilterCount !== categoryActiveFiltersCount,
                                                    })}>
                                                {categoryChangeFilterCount}
                                            </span>
                                            )
                                        }
                                    </p>
                                    {
                                        state.isOpen && (
                                            <ChevronUpIcon height={18} width={18}/>
                                        )
                                    }
                                    {
                                        !state.isOpen && (
                                            <ChevronDownIcon height={18} width={18}/>
                                        )
                                    }
                                </div>
                                <div
                                    className="font-normal space-y-3.5"
                                    style={{display: state.isOpen ? "block" : "none"}}>
                                    {
                                        subfilters.map((filter: any) => {
                                            return (
                                                <FilterFactory
                                                    key={filter.key}
                                                    filtersChanges={filtersChanges}
                                                    filtersActive={filtersActive}
                                                    filter={{...filter, addFilter, removeFilter}}
                                                    addFilter={addFilter}
                                                    removeFilter={removeFilter}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}