import React, {useState} from "react";
import {DataTableGraphQL} from "../../../interface/DataTable/DataTable";
import {Pillola} from "../../../interface/Pillole";
import {MDSection} from "../../../interface/Typography";
import {FETCH_AGGREGATION_STAT, FETCH_SEARCH} from "../../../queries";
import {useDataContainerContext} from "../../../interface/DataContainer/context/DataContainerContext";
import {SimpleTimeChartGraphQL} from "../../../interface/Charts/SimpleTimeChart";
import {format, formatDistance, parseISO} from "date-fns";
import {TagDisplayer} from "../../home/HomeDashboardContainer";

export default function UsersActivityStreamPane() {
    const {filtersQuery, activeSort, sortBy} = useDataContainerContext();
    const [sizeOfTable, setSizeOfTable] = useState<number>(100)
    return (
        <section className="p-4">
            <SimpleTimeChartGraphQL
                query={FETCH_AGGREGATION_STAT}
                root={"aggregate.aggregations"}
                property={"activities_hours_col2d.buckets"}
                filters={filtersQuery}
                variables={{
                    "bucket": "activities",
                    "list": [
                        {
                            "key": "activities_hours_col2d",
                            "type": "date_histogram",
                            "params": {
                                "field": "created_at",
                                "calendar_interval": "day"
                            }
                        }
                    ]
                }}
                queryMapper={(data: any) => {
                    return data.map((datum: any) => ({
                        label: format(parseISO(datum.key_as_string), 'Y-M-d'),
                        value: datum.doc_count,
                        showValue: 0,
                        color: '#666666'
                    }))
                }}
                config={{
                    type: "splinearea",
                    height: "275",
                    showValues: 1,
                    dataSource: {
                        chart: {
                            showValues: 1,
                            rotateLabels: false,
                        }
                    },
                }}/>
            <MDSection
                title={"Latest Activities"}
                subtitle={`Last update ${(new Date()).toISOString()}`}
            >
                <DataTableGraphQL
                    headers={[
                        {
                            dataIndex: "time", title: "Detection Time",
                            render: (value: any) => {
                                return formatDistance(new Date(value.created_at), new Date(), { addSuffix: true })
                            }
                        },
                        {
                            dataIndex: "subject_name",
                            title: "User",
                            render: (value: any) => {
                                if (value.context?.subject?.type == "user") {
                                    return (
                                        <>
                                            <p>{value.context?.subject?.name}</p>
                                            <p className="text-xs text-bs-light-gray-800">{value.context?.subject?.id}</p>
                                        </>
                                    )
                                }
                                return JSON.stringify(value?.context?.subject)
                            }
                        },
                        {
                            dataIndex: "source", title: "Source",
                            render: (v: any) => {
                                try {
                                    const u = (new URL(v.source))
                                    return u.hostname
                                } catch (e) {
                                    return v.source
                                }
                            }
                        },
                        {
                            dataIndex: "category",
                            title: "Category",
                            render: (v: any) => <Pillola text={v.category} variant="ghost"/>
                        },
                        {
                            dataIndex: "object_qname",
                            title: "Object",
                            render: (v: any) => {
                                return (
                                    <div style={{display: "flex", gap: "0.5rem", width: "100%"}}>
                                        <Pillola text={v.context?.bucket} variant="neutral" />
                                        {
                                            v.context?.object?.name && (
                                                <Pillola text={v.context?.object?.name} variant="positive" />
                                            )
                                        }

                                        {v.context?.bucket && (
                                            <TagDisplayer filter={v.context?.query?.filter} />
                                        )}
                                        {
                                            v.context?.object?.id && (
                                                <span
                                                    className="text-xs text-bs-light-gray-800 pl-1.5">
                                                ({v.context?.object?.id?.substring(0, 8)})
                                            </span>
                                            )
                                        }
                                    </div>
                                )
                            }
                        },
                        {
                            dataIndex: "action",
                            title: "Action",
                            render: (v: any) => <Pillola text={v.action} variant={"neutral"}/>
                        },
                        {dataIndex: "id", title: "ID", render: (value: any) => value.id.substring(0, 8)},
                    ]}
                    root="search"
                    property="items"
                    query={FETCH_SEARCH}
                    variables={{
                        search: {
                            bucket: "activities",
                            size: sizeOfTable,
                            sort: [{key: "time", dir: "desc"}],
                        }
                    }}
                    filters={filtersQuery}
                    onChangeRowsPerPage={(n) => {
                        console.log("size", n)
                        setSizeOfTable(n)
                    }}
                    data={[]}
                />
            </MDSection>
        </section>
    )
}