import { useNavigate } from "react-router";
import { formatDistance, format } from "date-fns";
import { Button } from "../../interface/Button/Button";
import { MDSection } from "../../interface/Typography";
import { DataTableGraphQL } from "../../interface/DataTable/DataTable";
import { Pillola } from "../../interface/Pillole";
import { Tabs } from "../../interface/Tabs/Tabs";
import { FETCH_PENDING_JOBS } from "../../queries/";
import { Persona } from "../../interface/Persona";
import { get } from "lodash";
import { useQuery } from "@apollo/client";

export default function JobsContainer() {
    const nav = {
        pending: {
            title: "Pending Jobs",
            key: "pending",
            pane: <PendingJobsList />,
        },
        list: {
            title: "Approved/Rejected Jobs",
            key: "list",
            pane: <ProcessedJobsList />,
        },
    };
    return (
        <div className="flex-grow flex flex-row">
            <div className="flex-grow">
                <Tabs index={"pending"} tabs={nav} />
            </div>
        </div>
    );
}

export const ProcessedJobsList = () => {
    const navigate = useNavigate();

    const { loading, error, data } = useQuery(FETCH_PENDING_JOBS, {
        variables: {
            limit: 10,
            offset: 0,
            sort: {
                field: "created_at",
                order: "desc",
            },
            filters: {
                or: [
                    {
                        expression: {
                            field: "job.stage",
                            eq: "rejected",
                        },
                    },
                    {
                        expression: {
                            field: "job.stage",
                            eq: "completed",
                        },
                    },
                    {
                        expression: {
                            field: "job.stage",
                            eq: "validated",
                        },
                    },
                ],
                not: [
                    {
                        expression: {
                            field: "job.stage",
                            eq: "created",
                        },
                    },
                ],
            },
        },
    });

    const processedHeaders = [
        {
            key: "id",
            title: "ID",
            dataIndex: "id",
            render: (job: any) => job.id.slice(0, 8),
        },
        {
            key: "created_at",
            title: "Created At",
            dataIndex: "created_at",
            render: (job: any) => (
                <span>
                    {formatDistance(new Date(job.created_at), new Date(), {
                        addSuffix: true,
                    })}{" "}
                    ({format(new Date(job.created_at), "Y-MM-dd")})
                </span>
            ),
        },
        { key: "type", title: "Type", dataIndex: "type" },
        {
            key: "user",
            title: "User",
            dataIndex: "user",
            render: (job: any) => {
                return (
                    <Persona
                        size="xs"
                        text={`${job.user.first_name} ${job.user.other_names}`}
                        secondaryText={job.user.email}
                        imageURL={job.user.profile_image_url}
                    />
                );
            },
        },
        {
            key: "stage",
            title: "Stage",
            dataIndex: "stage",
            render: (job: any) => {
                return (
                    <div>
                        {((job: any) => {
                            switch (job.stage) {
                                case "created":
                                    return (
                                        <Pillola
                                            text={job.stage}
                                            variant={"neutral"}
                                        />
                                    );
                                case "completed":
                                    return (
                                        <Pillola
                                            text={job.stage}
                                            variant={"success"}
                                        />
                                    );
                                case "rejected":
                                    return (
                                        <Pillola
                                            text={job.stage}
                                            variant={"negative"}
                                        />
                                    );
                                default:
                                    return (
                                        <Pillola
                                            text={job.stage}
                                            variant={"neutral"}
                                        />
                                    );
                            }
                        })(job)}
                    </div>
                );
            },
        },
        {
            key: "actions",
            title: "Actions",
            dataIndex: "actions",
            render: (job: any) => {
                return (
                    <div style={{ display: "block", position: "relative" }}>
                        <div style={{ display: "flex", gap: "1rem" }}>
                            <Button
                                title="View"
                                variant="primary"
                                size="sm"
                                onClick={() => {
                                    navigate(`/approve/jobs/${job.id}`);
                                }}
                            />
                        </div>
                    </div>
                );
            },
        },
    ];

    console.log("data", data);

    // todo(nate): make the query here so we can get the list to add into  a provider
    return (
        <div className="w-full p-5">
            <MDSection title="Processed jobs">
                <DataTableGraphQL
                    root="jobs"
                    emptyMessage="No processed jobs"
                    property="data"
                    variables={{
                        limit: 10,
                        offset: 0,
                        sort: {
                            field: "created_at",
                            order: "desc",
                        },
                        filters: {
                            or: [
                                {
                                    expression: {
                                        field: "job.stage",
                                        eq: "rejected",
                                    },
                                },
                                {
                                    expression: {
                                        field: "job.stage",
                                        eq: "completed",
                                    },
                                },
                                {
                                    expression: {
                                        field: "job.stage",
                                        eq: "validated",
                                    },
                                },
                            ],
                            not: [
                                {
                                    expression: {
                                        field: "job.stage",
                                        eq: "created",
                                    },
                                },
                            ],
                        },
                    }}
                    query={FETCH_PENDING_JOBS}
                    pollInterval={10000}
                    headers={processedHeaders}
                    data={[]}
                />
            </MDSection>
        </div>
    );
};

export function PendingJobsList() {
    const navigate = useNavigate();

    const { loading, error, data } = useQuery(FETCH_PENDING_JOBS, {
        variables: {
            limit: 10,
            offset: 0,
            sort: {
                field: "created_at",
                order: "desc",
            },
            filters: {
                or: [
                    {
                        expression: {
                            field: "job.stage",
                            eq: "rejected",
                        },
                    },
                    {
                        expression: {
                            field: "job.stage",
                            eq: "completed",
                        },
                    },
                    {
                        expression: {
                            field: "job.stage",
                            eq: "validated",
                        },
                    },
                ],
                not: [
                    {
                        expression: {
                            field: "job.stage",
                            eq: "created",
                        },
                    },
                ],
            },
        },
    });

    const pendingHeaders = [
        {
            key: "id",
            title: "ID",
            dataIndex: "id",
            render: (job: any) => job.id.slice(0, 8),
        },
        {
            key: "created_at",
            title: "Created At",
            dataIndex: "created_at",
            render: (job: any) => (
                <span>
                    {formatDistance(new Date(job.created_at), new Date(), {
                        addSuffix: true,
                    })}{" "}
                    ({format(new Date(job.created_at), "Y-MM-dd")})
                </span>
            ),
        },
        {
            key: "type",
            title: "Type",
            dataIndex: "type",
            render: (job: any) => {
                return <TypeInfo job={job} />;
            },
        },
        {
            key: "user",
            title: "User",
            dataIndex: "user",
            render: (job: any) => {
                return (
                    <Persona
                        size="xs"
                        text={`${job.user.first_name} ${job.user.other_names}`}
                        secondaryText={job.user.email}
                        imageURL={job.user.profile_image_url}
                    />
                );
            },
        },
        {
            key: "stage",
            title: "Stage",
            dataIndex: "stage",
            render: (job: any) => {
                return (
                    <div>
                        {((job: any) => {
                            switch (job.stage) {
                                case "created":
                                    return (
                                        <Pillola
                                            text={job.stage}
                                            variant={"neutral"}
                                        />
                                    );
                                case "completed":
                                    return (
                                        <Pillola
                                            text={job.stage}
                                            variant={"success"}
                                        />
                                    );
                                case "rejected":
                                    return (
                                        <Pillola
                                            text={job.stage}
                                            variant={"negative"}
                                        />
                                    );
                                default:
                                    return (
                                        <Pillola
                                            text={job.stage}
                                            variant={"neutral"}
                                        />
                                    );
                            }
                        })(job)}
                    </div>
                );
            },
        },
        {
            key: "actions",
            title: "Actions",
            dataIndex: "actions",
            render: (job: any) => {
                return (
                    <div style={{ display: "block", position: "relative" }}>
                        <div style={{ display: "flex", gap: "1rem" }}>
                            <Button
                                title="View"
                                variant="primary"
                                size="sm"
                                onClick={() => {
                                    navigate(`/approve/jobs/${job.id}`);
                                }}
                            />
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <div className="w-full p-5">
            <MDSection title="Pending jobs">
                <DataTableGraphQL
                    root="jobs"
                    emptyMessage="No pending jobs"
                    property="data"
                    variables={{
                        limit: 10,
                        offset: 0,
                        sort: {
                            field: "created_at",
                            order: "desc",
                        },
                        filters: {
                            or: [
                                {
                                    expression: {
                                        field: "job.stage",
                                        eq: "created",
                                    },
                                },
                                {
                                    expression: {
                                        field: "job.stage",
                                        eq: "validated",
                                    },
                                },
                            ],
                            not: [
                                {
                                    expression: {
                                        field: "job.type",
                                        eq: "AnalyseArticleJob",
                                    },
                                },
                            ],
                            and: [
                                {
                                    expression: {
                                        field: "job.stage",
                                        eq: "created",
                                    },
                                },
                            ],
                        },
                    }}
                    query={FETCH_PENDING_JOBS}
                    pollInterval={10000}
                    headers={pendingHeaders}
                    data={[]}
                />
            </MDSection>
        </div>
    );
}

const TypeInfo = ({ job }: any) => {
    return (
        <div>
            {(() => {
                switch (job.type) {
                    case "BulkCreateOrganizationCSV":
                        return <p>{get(job, "type", "BulkOrganizations")}</p>;
                    case "SingleOrganizationCreate":
                        return (
                        	<p>{get(job, "type", "SingleOrganizationCreate")}</p>
                        );
                    case "BulkUpdateOrganizationCSV":
						return (
							<p>{get(job, "type", "BulkOrganizations")}</p>
						);
                    case "AnalyseArticleJob":
						return <p>{get(job, "type", "AnalyseArticleJob")}</p>
					case "CreateEmployees":
						return <p>Bulk - {job.type}</p>
                    default:
                        return <p>Not Implemented</p>;
                }
            })()}
        </div>
    );
};
