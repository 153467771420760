import {gql} from "@apollo/client";

export const FILE_UPLOAD_MUTATION = gql`
    mutation ($file: Upload!,$path: String!) {
        singleUpload(file: $file,path: $path) {
            id
            uri
        }
    }
`;

export const FETCH_DROPDOWN_OPTIONS_FORM = gql`
    query fetchDropDownOptions {
        sectors(limit: 10000) {
            data {
                id
                value: name
            }
        }
        agbase_categories(limit: 10000) {
            data {
                id
                value: name
            }
        }
        agbase_visibility(limit: 10000) {
            data {
                id
                value: name
            }
        }
        agbase_valuechains(limit: 10000) {
            data {
                id
                value: name
            }
        }
        business_models(limit: 10000) {
            data {
                id
                value: name
            }
        }
        sub_sectors(limit: 10000) {
            data {
                id
                value: name
            }
        }
        countries(limit: 1000) {
            data {
                id
                value: name
            }
        }
        cities(limit: 1000) {
            data {
                id
                value: name
            }
        }
        sdgs(limit: 1000) {
            data {
                id
                value: name
            }
        }
        technologies(limit: 1000) {
            data {
                id
                value: name
            }
        }
    }
`


export const UPSERT_ORGANIZATION = gql`
    mutation upsertOrganization($id: ID, $input: InputUpdateOrganizationRequest) {
        upsertOrganization(id: $id, input: $input) {
            id
        }
    }
`

export const CREATE_CITY = gql`
    mutation createCity($input: CityInput!) {
        createCity(input: $input) {
            city {
                name
                id
            }
        }
    }
`

export const ADD_LOCATION = gql`
    mutation addOfficeLocation($input: CreateOfficeLocationInput!) {
        addOfficeLocation(input: $input) {
            id
        }
    }
`

export const UPDATE_JOB = gql`
    mutation upsertJob($id : ID!,$req: UpsertJobRequest!) {
        updateJobByID(id:$id,req:$req) {
            id
        }
    }
`


export const JOB_QUERY = gql`
    query getJobByID($id: ID!) {
        getJobByID(id: $id) {
            id
            stage
            is_rejected
            reject_reason
            type
            jobs {
                id
                type
                stage
                payload
            }
        }
    }
`

export const REJECT_JOB = gql`
    mutation rejectJob($id: ID!) {
        rejectJobByID(id: $id) {
            id
        }
    }
`;

export const GET_JOB = gql`
    query getJobByID($id: ID!) {
        getJobByID(id : $id) {
            id
            type
            is_rejected
            reject_reason
            stage
            payload
            history
            job_id
            media_mention {
                uri
                excerpt
                content
                raw_html
                title
                analysis
            }
            jobs {
                id
                type
                stage
                payload
                job_id
            }
        }
    }
`

export const PROCESS_JOB = gql`
    mutation processJob($id: ID!) {
        processJobById(id: $id) {
            id
            stage
        }
    }
`

export const FETCH_PENDING_JOBS = gql`
    query jobs($filters: FiltersInput,$sort: SortInput,$limit:Int,$offset: Int) {
        jobs(filters: $filters,sort: $sort,limit:$limit,offset:$offset) {
            pages
            data {
                created_at
                id
                is_processing
                is_rejected
                is_pending_approval
                is_completed
                type
                stage
                payload
                history
                user {
                    id
                    email
                    first_name
                    other_names
                    profile_image_url
                }
                jobs {
                    id
                    stage
                    type
                    payload
                }
                media_mention {
                    uri
                    content
                    source
                }
            }
        }
    }
`


export const FETCH_DEALS_DROPDOWN_OPTIONS_FORM = gql`
    query getOrganizations {
        organizations(limit: 15000) {
            data {
                id
                value: name
            }
        }
    }
`

export const DEALS_BY_ID = gql`
    query getDealByID($id: ID!) {
        getDealByID(id: $id) {
            id
            target {
                id
                name
                value: name
            }
            investors {
                id
                name
                value: name
            }
            amount
            date
            currency_code
            type
            equity
            stage
        }
    }
`

export const MUTATION_UPDATE_DEAL = gql`
    mutation updateDeals($id: ID!, $req: UpsertDealRequest!) {
        updateDeal(id: $id, req: $req) {
            id
        }
    }
`

export const MUTATION_CREATE_DEAL = gql`
    mutation createDeal($req: UpsertDealRequest!) {
        createDeal(req: $req) {
            id
        }
    }
`


export const FETCH_INITIATIVE_DROPDOWN_OPTIONS = gql`
    query {
        regions {
            data {
                id
                name
                value: name
                key: id
            }
        }
        agbase_categories {
            data {
                id
                name
                value: name
                key: id
            }
        }
        agbase_visibility {
            data {
                id
                name
                value: name
                key: id
            }
        }
        agbase_valuechains {
            data {
                id
                name
                value: name
                key: id
            }
        }
        sub_regions {
            data {
                id
                name
                value: name
                key: id
            }
        }
        sectors(limit: 10000) {
            data {
                id
                name
                value: name
                key: id
            }
        }
        organizations(limit: 100000) {
            data {
                id
                name
                value: name
                key: id
            }
        }
        initiativeFormats(limit: 10000) {
            data {
                id
                name
                value: name
                key: id
            }
        }
        countries(limit: 1000) {
            data {
                id
                name
                value: name
                key: id
            }
        }
    }
`


export const FETCH_INITIATIVE = gql`
    query initiative($id: ID) {
        initiative(id: $id) {
            id
            title
            type
            description
            banner
            start_at
            body
            uri
            end_at
            locations {
                id
                address
                city {
                    id
                    name
                    sub_division {
                        id
                        name
                        iso_31662
                        type
                        country {
                            id
                            name
                        }
                    }
                }
                sub_division {
                    id
                    name
                    iso_31662
                    type
                    country {
                        id
                        name
                    }
                }
                country {
                    id
                    name
                }
            }
            sectors {
                id
                value: name
            }
            subregions_interests {
                id
                value: name
            }
            organizers {
                id
                value: name
            }
            sponsors {
                id
                value: name
            }
            organizations_speakers {
                id
                value: name
            }
            countries_interests {
                id
                value: name
            }
        }
    }
`


export const UPDATE_INITIATIVE = gql`
    mutation updateInitiative($id: ID, $input: CreateInitiativeRequest) {
        updateInitiative( id: $id, input: $input) {
            id
            title
        }
    }
`

export const DELETE_INITIATIVE = gql`
    mutation deleteInitiative($id: ID) {
        deleteInitiative(id: $id)
    }
`

export const CREATE_INITIATIVE = gql`
    mutation createInitiative($input: CreateInitiativeRequest) {
        createInitiative(input: $input) {
            id
            title
        }
    }
`

export const FETCH_PERSON_EXP_OPTIONS = gql`
    query getOrganizations {
        organizations(limit: 15000) {
            data {
                id
                value: name
            }
        }
    }
`


export const UPSERT_PERSON_EXPERIENCE = gql`
    mutation upsertPersonExperience($id: String,$input: UpsertPersonExperienceInput!) {
        upsertPersonExperience(id: $id,input: $input) {
            id
        }
    }
`

export const GET_PERSON_EXPERIENCE = gql`
    query getExperienceByID($id: String!) {
        personExperience(id: $id) {
            id
            role
            person {
                id
                value: first_name
            }
            organization {
                id
                value: name
            }
        }
    }
`

export const UPSERT_PERSON = gql`
    mutation upsertPerson($id: String,$input: PersonInput!) {
        upsertPerson(id: $id,input: $input) {
            id
        }
    }
`

export const GET_PEOPLE = gql`
    query people($limit: Int) {
        people(limit: $limit) {
            id
            value: first_name
            name: other_names
        }
    }
`

export const GET_PERSON = gql`
    query getPerson($id: String!) {
        person(id: $id) {
            person {
                first_name
                other_names
                active
                email
                gender
                ethnicity
                education_level
                education_degree
                education_university
            }
            person_experience {
                organization_id
            }
        }
    }
`

export const UPDATE_PERSON = gql`
    mutation updatePerson($id: String!,$input: PersonInput!) {
        updatePerson(id: $id,input: $input) {
            first_name
            other_names
            gender
        }
    }
`

export const QUERY_GET_ORG_BY_NAME = gql`
    query getOrganizationByName($name: String) {
        getOrganizationByName(name: $name) {
            id
            name
            deals {
                id
                amount
                stage
                type
                date
                investors {
                    id
                    name
                }
            }
            socials {
                website
            }
            founded
        }
    }
`


export const GET_RESOURCE_ARTICLE_BY_ID = gql`
    query getResourceArticleById($id: ID!) {
        getResourceArticleByID(id: $id) {
            id
            body
            title
            history
        }
    }
`

export const GET_RESOURCE_UPLOAD_BY_ID = gql`
    query getResourceUploadByID($id: ID!) {
        getResourceUploadByID(id: $id) {
            id
            description
            title
            history
        }
    }
`