import {Avatar} from "./Avatar";
import {Link} from "react-router-dom";

export interface IPersona {
    text: string;
    size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl";
    secondaryText?: string;
    tertiaryText?: string;
    optionalText?: string;
    status?: string;
    imageURL?: string;
    imageInitials?: string;
    extra?: any,
    to?: string
    value?: any
}

export function Persona(props: React.PropsWithChildren<IPersona>) {
    return (
        <div className="flex self-center items-center">
			<AvatarContainer text={props.text} imageURL={props.imageURL} size={props.size}/>
        </div>
    )
}

export const AvatarContainer = ({...props}: any) => {
    return (
        <div style={{display: "flex",alignItems: "center"}}>
            <div role="presentation" className="pr-2">
                <Avatar text={props.text} imageURL={props.imageURL} size={props.size || "md"}/>
            </div>
            {
                props.to ? <Link to={props.to}>{props.text}</Link> : <p>{props.text}</p>
            }
        </div>
    )
}
