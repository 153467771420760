import { styled } from "./stiches.config";
import React from "react";
import { Button, DropdownOpt } from "@briterbridges/elements";
import Select from "react-select"

export interface EditableCellProps {
    type?: string
    defaultValue?: any
    options?: any
    onChange?: any
    onSave?: () => void
    onCancel?: (e: any) => void
}

export const EditableCell = ({ ...props }: EditableCellProps) => {
    const { type, defaultValue, onChange, options } = props

    const renderCellContent = () => {
        switch (type) {
            case "text":
                return (
                    <EditableCellInput
                        value={defaultValue}
                        placeholder="Edit the value"
                        onChange={onChange}
                        defaultValue={defaultValue}
                    />
                )
            case 'select':
                // todo: fetch the options to display on the dropdown
                return (
                    <EditableDropdown
                        options={options}
                        selectedValue={defaultValue}
                        onChange={onChange}
                        onSave={() => console.log("onSaved")}
                        onCancel={() => console.log("onCancel")}
                    />
                );
            case "multi_select":
                // todo: fetch the options to display on the dropdown
                return (
                    <EditableComboBox
                        onChange={onChange}
                        options={options}
                        selectedValues={defaultValue}
                    />
                )
            default:
                return (
                    <div>Not Supported</div>
                );
        }
    };

    return (
        <StyledEditableCell>
            <div style={{ display: "flex", zIndex: "20" }}>
                {renderCellContent()}
            </div>
            <div style={{ display: "flex", gap: "0.25rem", alignItems: "center" }}>
                <Button size="xs" color="primary" onClick={props.onSave}>
                    Save
                </Button>
                <Button size="xs" color="secondary" onClick={props.onCancel}>
                    Cancel
                </Button>
                <div>
                    <Button
                        size="xs"
                        color="secondary"
                        onClick={() => {
                            // @ts-ignore
                            props.onSelect('');
                        }}
                    >
                        Clear
                    </Button>
                </div>
            </div>
        </StyledEditableCell>
    );
};

export interface EditableComboBoxProps {
    options: any[]
    selectedValues: any
    onChange: any
}

// todo: migrate the component to use downshift
export const EditableComboBox = ({ children, ...props }: React.PropsWithChildren<EditableComboBoxProps>) => {
    const { options, selectedValues, onChange } = props;

    return (
        <div style={{ width: "100%", display: "flex", justifyContent: "start", alignItems: "center" }}>
            <Select
                styles={{
                    menu: (base, props) => ({
                        ...base,
                        width: "max-content",
                        minWidth: "100%"
                    }),
                    multiValueLabel: (base, props) => ({
                        ...base,
                        backgroundColor: "#FFDB80FF",
                        color: "black",
                        "&:hover": {
                            backgroundColor: "rgba(252,186,122,0.68)",
                            borderColor: "red"
                        }
                    }),
                    multiValueRemove: (base, props) => ({ ...base, backgroundColor: "#FFDB80FF", color: "black" })
                }}
                getOptionLabel={option => option.value}
                getOptionValue={option => option.id}
                options={options}
                isMulti={true}
                onChange={onChange}
                defaultValue={selectedValues} />
        </div>
    )
}

export interface EditableDropdownProps {
    selectedValue?: string
    options: DropdownOpt[]
    onSave?: (item: string) => void
    onCancel?: (item: string) => void
    onChange: any
}

export const EditableDropdown = ({ ...props }: EditableDropdownProps) => {
    const { selectedValue, options, onSave, onCancel, onChange } = props
    return (
        <div>
            <select onChange={onChange}>
                {options.map((opt: DropdownOpt) => (
                    <option value={opt.value} key={opt.key}>{opt.value}</option>
                ))}
            </select>
        </div>
    )
}

export const Pill = styled('span', {
    backgroundColor: "$default",
    paddingLeft: "0.025rem",
    paddingRight: "0.025rem",
    paddingTop: "0.025rem",
    paddingBottom: "0.025rem",
    borderRadius: "0.1875rem",
})

export const StyledEditableCell = styled('div', {
    backgroundColor: "red",
    zIndex: "999999",
    background: "white",
    display: "flex",
    justifyContent: "start",
    gap: "0.25rem",
    position: "absolute",
    maxWidth: "400px",
    minWidth: "200px",
})

export const EditableCellContainer = styled('div', {
    border: "2px solid blue",
    display: "inline-flex",
    alignItems: "center",
    borderRadius: "4px",
})

export const EditableCellInput = styled('input', {
    width: "200px",
    padding: "12px 20px",
    margin: "8px 0",
    background: "transparent",
    boxSizing: "border-box"
})

export const EditableCellInputSelect = styled('input', {
    width: "100%",
    padding: "12px 20px",
    background: "transparent",
    boxSizing: "border-box",
    borderColor: "$default",
    borderBottomRightRadius: "0",
    borderBottomLeftRadius: "$small",
    borderTopLeftRadius: "$small",
    borderTopRightRadius: "0",
    color: "$hiContrast",
    borderRight: "0",
    border: "none",
    '&:focus': {
        outline: "none !important",
        borderRight: "0",
    }
})

export const ToggleButton = styled('button', {
    padding: "12px 14px",
    border: "none",
    borderLeft: "0",
})

