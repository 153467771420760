import { get,first } from "lodash";
import { ProcessOrgJobContainer } from "../fragments/ProcessOrgJobContainer";
import { DealJobForm } from "./DealJobForm";

export interface ProcessArticleJobFormProps {
    job: any;
    onProcessJob: any;
}

export const ProcessArticleJobForm = ({ ...props }: ProcessArticleJobFormProps) => {

	const {job,onProcessJob} = props
	const initialJob = first(get(job,"jobs",[]))

    return (
		<div className="p-4" style={{width: "100%"}}>
				{((firstJob: any) => {
					switch(firstJob.type) {
						case "SingleOrganizationCreate":
							return (
								<ProcessOrgJobContainer 
									job={first(get(job,"jobs",[]))} 
									onProcessJob={onProcessJob} />
							)
						case "SingleOrganizationUpdate":
							return (
								<ProcessOrgJobContainer 
									job={first(get(job,"jobs",[]))} 
									onProcessJob={onProcessJob} />
							)
						case "SingleDealCreate":
							return (
								<DealJobForm
									job={job}
									onProcessJob={onProcessJob} />
							)
						case "SingleDealUpdate":
							return (
								<DealJobForm
									job={job}
									onProcessJob={onProcessJob} />
							)
						default:
							return (
								<p>Not Implemented - {JSON.stringify(firstJob)}</p>
							)
					}
				})(initialJob)}
		</div>
	);
}; 
