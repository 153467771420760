import {Persona} from "../../../interface/Persona";
import _, {get} from "lodash";
import {Pillola} from "../../../interface/Pillole";
import {DataTable} from "../../../interface/DataTable/DataTable";
import {useMutation, useQuery} from "@apollo/client";
import {GET_JOB, JOB_QUERY, PROCESS_JOB,REJECT_JOB} from "../../../queries/";
import {Button} from "../../../interface/Button/Button";
import { useNavigate } from "react-router-dom";
import {useFlash} from "../../../context/FlashContext";

interface CreateEmployeesContainerProps {
    id: string
}

export const CreateEmployeesContainer = ({...props} : CreateEmployeesContainerProps) => {
    const {id} = props
    const navigate = useNavigate()
    const flash = useFlash()

    const {loading,data,error} = useQuery(JOB_QUERY,{variables: {id: id}})
    const [processJob,{loading: procJobLoading}] = useMutation(PROCESS_JOB);
    const [rejectJob,{loading: rejectJobLoading}] = useMutation(REJECT_JOB);

    const handleProcessJob = (id: string) => {
        processJob({
            variables: {
                id: id,
            },
            refetchQueries: [GET_JOB, "GetJobByID"],
        })
            .then((res) => {
                if ( _.get(res, "data.processJobById.stage", "") === "completed") {
                    navigate("/approval");
                }
                flash("job processed successfully", "success");
            }).catch((err) => {
            flash(`${err.message}`, "error");
        });
    };

	const handleRejectJob = (id: string) => {
		rejectJob({
			variables: {id: id},
		}).then((res: any) => {
			console.log("res",res)
			navigate("/approval?t=list")
		}).catch((err: any) => {
			console.log("err",err)
		})
	}

    const headers = [
        {
            key: "person",
            title: "Person",
            dataIndex: "person",
            render: (emp: any) => {
                return <Persona
                    size={"xs"}
                    text={get(emp,"payload.msg.person.first_name","") + " " + get(emp,"payload.msg.person.other_names","")}
                />
            }
		},
        {key: "role", title: "Role", dataIndex: "role",render: (job: any) => {
            return <p>{get(job,"payload.msg.role")}</p>
            }},
        {
            key: "organization",title: "Company",dataIndex: "organization",
            render: (emp: any) => {
                return (
                    <Persona
                        size={"xs"}
                        text={get(emp,"payload.msg.organization.name","")}
                    />
                )
            }
        },
        {
            key: "person",
            title: "Ethnicity",
            dataIndex: "person",
            render: (emp: any) => {
                return <p>{get(emp,"payload.msg.person.ethnicity")}</p>
            }
        },
        {
            key: "person",
            title: "Gender",
            dataIndex: "person.gender",
            render: (emp: any) => {
                return <p>{get(emp,"payload.msg.person.gender")}</p>
            }
        },
        {
            key: "person",
            title: "Level",
            dataIndex: "person.eduction_level",
            render : (emp: any) => {
                return <p>{get(emp,"payload.msg.person.education_level")}</p>
            }
        },
        {
            key: "person",
            title: "Degree",
            dataIndex: "person.eduction_degree",
            render : (emp: any) => {
                return <p>{get(emp,"payload.msg.person.education_degree")}</p>
            }
        },
        {
            key: "person",
            title: "University",
            dataIndex: "person.eduction_university",
            render : (emp: any) => {
                return <p>{get(emp,"payload.msg.person.education_university")}</p>
            }
        },
		{
			key: "stage",title: "Stage",dataIndex: "stage",
			render: (emp: any) => {
				return (
					<div className="items-center">
                        <Pillola text={get(emp,"stage")} variant={get(emp,"stage") !== "completed" ? "default" : "success"} size={"sm"}/>
					</div>
				)
			}
		},
        {
            key: "person",
            title: "LinkedIn",
            dataIndex: "person.socials.linkedin",
            render : (emp: any) => {
                return <p>{get(emp,"payload.msg.person.socials.linkedin")}</p>
            }
        },
    ]

    const employees = get(data,"getJobByID.jobs",[])
    console.log("employees",employees)

	if(loading || rejectJobLoading || procJobLoading) return <p>Loading....</p>

    return (
        <div>
            <h1 className={"text-lg font-bold"}>{get(data, "getJobByID.type")}</h1>
            <DataTable headers={headers} data={employees}/>

            <div className={"flex gap-2"}>
                <Button
                    title={loading ? "Loading...." : "Approve"}
                    variant={loading ? "none" : "secondary"}
                    size="sm"
                    onClick={() => handleProcessJob(get(data,"getJobByID.id"))}
                />
                <Button
                    title={"Reject"}
                    type={"submit"}
                    variant={"danger"}
					onClick={() => handleRejectJob(get(data,"getJobByID.id"))}
                    size="sm"
                />
            </div>
        </div>
    );
};
