import React, {FC, useEffect, useState} from "react";
import {Input, InputSelect, Textarea} from "./Input";
import _, {get, isString, unset} from "lodash";
import {Controller, FormProvider, useForm, useFormContext} from "react-hook-form";
import {Fieldset} from "./Fieldset";
import {Button} from "../Button/Button";
import {DateInput} from "./Input/DateInput";
import {InputText} from "./Input/TextInput";

export const replaceWithProperty = (data: any, property: string, replace: string): any => {
    data[property] = _.get(data, replace, [])
    unset(data, replace)
    return data
}

/**
 *
 * @param iter
 * @param options
 *
 * @todo Find the path with key
 */
export const decorateWithOptions = (iter: { dataPath: string, valuesPath: string, data: any[] }[], options: any[]) => {
    iter.map((i) => {
        options = _.setWith(options, i.dataPath, reMapSelectInput(i.valuesPath, i.data))
    })
    return options
}

/**
 *
 * @param path
 * @param data
 */
export const reMapSelectInput = (path: string, data: any) => {
    return get(data, path, []).map((s: any) => ({
        value: s.value,
        key: s.id,
        id: s.id
    }))
}

const MakeTOC: FC<{ options: any }> = ({options}) => {
    return (
        <p>TOC</p>
    )
}


const MakeForm: FC<{ options: any }> = ({options}) => {
    const {register, control, setValue, getValues, formState: {errors, isDirty}} = useFormContext()
    return options.map((o: any) => {
        switch (o.type) {
            case 'fieldset':
                return (
                    <Fieldset key={o.key} direction={o.direction} title={o.title} style={o?.options?.style}>
                        <MakeForm options={o.sets}/>
                    </Fieldset>
                )
            case 'date':
                return <DateInput label={o.label} register={register} formatLayout={o?.options?.formatLayout}
                                  type={o?.options?.type}
                                  required={o.required} name={o.name} setValue={setValue} getValues={getValues}
                />
            case 'text':
                return <InputText label={o.label} register={register} required={o.required}
                                  name={o.name} setValue={setValue} options={o?.options} getValues={getValues}/>
            case 'textarea':
                return (
                    <Textarea
                        key={o.name}
                        label={o.label}
                        type={"text"}
                        {...register(o.name, {...o.register})}
                        required={o.required ?? false} errors={get(errors, o.name)}
                    />
                )
            case 'select':
                return (
                    <Controller
                        name={o.name}
                        key={o.key}
                        control={control}
                        render={({field}) => {
                            const value = o.onGetValues
                                ? o.onGetValues(field, o?.options)
                                : field.value && field.value.length > 0 && !isString(field.value[0])
                                    ? field.value.map((f: any) => f && f.id)
                                    : field.value
                            return (
                                <InputSelect
                                    description={o.description}
                                    label={o.label}
                                    loading={o.loading}
                                    maxTagCount={o?.options.maxTagCount}
                                    maxTagTextLength={o?.options.maxTagTextLength}
                                    options={{options: o?.options?.values}}
                                    type={o?.options?.mode ?? false}
                                    value={value}
                                    onChange={(value: any, opts: any) => {
                                        console.log("onChange", value)
                                        setValue(o.name, o.onSetValues ? o.onSetValues(value, o?.options) : value)
                                    }}
                                    getValues={getValues}
                                    setValue={setValue}
                                />
                            )
                        }}
                    />
                )
            default:
                return (
                    <div>
                        <label className="text-bs-light-gray-800 text-sm">
                            {o.label} {o.required && (<span className="text-red-500">*</span>)}
                        </label>
                        <p>Type {o.type} not implemented</p>
                    </div>
                )
        }
    })
}

export interface IFormBuilder {
    onSubmit: any;
    onCancel?: any;
    options: any;
    data: any;
    toc?: boolean;
    debug?: boolean;
    isSending: boolean
}

export const FormBuilder: FC<IFormBuilder> = ({
                                                  onSubmit,
                                                  onCancel,
                                                  options,
                                                  debug = false,
                                                  isSending,
                                                  data,
                                                  toc = false
                                              }) => {
    const [d, setDebug] = useState({json: JSON.stringify({})})
    const methods = useForm({
        shouldUnregister: true,
        reValidateMode: "onChange"
    });

    useEffect(() => {
        methods.reset(data)
    }, [data])

    const onBeforeSubmit = (data: any) => {
        setDebug({...d, json: JSON.stringify(data, undefined, 2)})
        onSubmit(data)
    }

    const menu = options.filter((o: any) => o.type === "fieldset")
    return (
        <div className="w-full scroll-smooth">
            <div className="w-full flex items-stretch">
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onBeforeSubmit)} className="w-full p-5 flex flex-col">
                        <MakeForm options={options}/>
                        {
                            onSubmit && methods.formState.dirtyFields && false && (
                                <>
                                    <p>You have modified these fields:</p>
                                    <pre>{JSON.stringify(methods.formState.dirtyFields)}</pre>
                                </>
                            )
                        }
                        {
                            onSubmit && !isSending && (
                                <div className="flex">
                                    <Button title={"Submit"} variant="primary" type="submit"/>
                                    <Button title={"Cancel"} variant={"link"} type="reset" onClick={(ev) => {
                                        ev.preventDefault()
                                        onCancel()
                                    }}/>
                                </div>
                            )
                        }
                    </form>
                    {
                        toc && (
                            <div className="flex flex-grow bg-bs-light-gray-100 whitespace-nowrap relative w-72">
                                <div className="fixed">
                                    {
                                        menu.length > 1 && menu.map((o: any) => {
                                            return <p key={o.title} className="py-2.5 px-5 last:bg-bs-light-white">{o.title}</p>
                                        })
                                    }
                                </div>
                            </div>
                        )
                    }
                </FormProvider>
            </div>
            {
                debug && d && d.json && <div className={"px-5"}><p>Debug</p>
                    <pre>{d.json}</pre>
                </div>
            }
        </div>
    )
}