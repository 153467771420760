import React, {useCallback, useEffect, useRef, useState} from "react";
import classNames from "classnames";
import {CheckIcon, ExclamationCircleIcon, XIcon} from "@heroicons/react/outline";
import Select, {Option} from 'rc-select';
import {useDropzone} from "react-dropzone";
import {gql, useMutation} from "@apollo/client";
import {get} from "lodash";
import "../DataContainer/filters/select.scss"
//@ts-ignore
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import {useResizeObserver} from "usehooks-ts";



export type TInputStatus = "error" | "success" | "undefined";

export interface IInput {
    type: string;
    transform?: any;
    value?: any;
    loading?: boolean;
    options?: any;
    label?: string;
    required?: boolean;
    setValue?: any;
    getValues?: any;
    description?: string;
    name?: string;
    onChange?: any;
    errors?: any;
    status?: TInputStatus;
    ref: HTMLDivElement;
    compact?: boolean;
    maxTagCount?: number
    maxTagTextLength?: number
    path?: string;
    outlined?: boolean
    disabled?: boolean
    preview?: boolean
	  placeholder?: string
}

export const Input = React.forwardRef(({...props}: IInput, ref) => {
    const {label, placeholder, type, required, errors, name, description, onChange,disabled,outlined,compact = false} = props

    const cls = outlined ? classNames({
        "w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-0 focus:border-teal block w-full p-2.5": true,
        "border-red-500": get(errors,`${name}.message`,undefined) !== undefined
    }) : classNames({
        "border-b border-bs-light-gray-200 focus:border-bs-light-gray-800 outline-0 py-1 w-full": true,
        "border-red-500": errors !== undefined
    })

    return (
        <div style={{display: "flex",flexDirection: "column"}}>
            <label className="text-bs-light-gray-800 text-sm pb-1">
                {label} {required && (<span className="text-red-500">*</span>)}
            </label>
            <div style={{width: "100%",position: "relative"}}>
                {
                    get(errors,`${name}.message`) && (
                        <span style={{position: "absolute",right: "0",transform: outlined ? `translateY(50%)` : "none",paddingRight: "0.5rem"}}>
                            <ExclamationCircleIcon className="w-5 h-5 text-red-500"/>
                        </span>
                    )
                }
                <input type={type} placeholder={placeholder}
                       name={name}
                       onChange={onChange}
                    //@ts-ignore
                       ref={ref}
                       disabled={disabled}
                       className={cls}/>
            </div>
            {(!compact && description) && (
                <p className="text-sm text-gray-300 py-1">
                    {description} {errors && <span className="text-red-500">{errors.message}</span>}
                </p>
            )}
            {
                errors && (
                    <p className="text-sm text-gray-300 py-1">
                        {errors && <span className="text-red-500">{get(errors,"name.message")}</span>}
                    </p>
                )
            }
        </div>
    )
})

export const Textarea = React.forwardRef(({...props}: IInput, ref) => {
    const {label, required, description, errors, name, onChange} = props
    const cls = classNames({
        "border-b border-t-bs-light-gray-200 focus:border-bs-light-gray-800 outline-0 py-1 w-full": true,
        "border-red-500": errors !== undefined
    })
    return (
        <div className="flex flex-col" style={{ flexGrow: 0}}>
            <label className="text-bs-light-gray-800 text-sm">
                {label} {required && (<span className="text-red-800">*</span>)}
            </label>
            <div className="relative w-full" style={{display: "flex", flexGrow: 0}}>
                {
                    errors && (
                        <span className="absolute right-0">
                            <ExclamationCircleIcon className="w-5 h-5 text-red-500"/>
                        </span>
                    )
                }
                <textarea
                    style={{
                        borderLeft: "1px solid #eee", borderRight: "1px solid #eee", borderTop: "1px solid #eee",
                        borderBottom: "1px solid rgb(170, 170, 170)", padding: "0.5rem", height: "auto"}}
                    onChange={onChange}
                    name={name}
                    //@ts-ignore
                    ref={ref}
                    className={cls}
                />
            </div>
            {(description || errors) && (
                <p className="text-sm text-gray-300 py-1">
                    {description} {errors && <span className="text-red-500">{errors.message}</span>}
                </p>
            )}
        </div>
    )
})

interface DatePickerInputProps extends IInput {
    formatLayout?: string
}

export const DatePicker = React.forwardRef(({...props}: DatePickerInputProps, ref) => {
    const {label, required, description, errors, name,setValue,getValues,onChange, value, type,outlined,formatLayout} = props

    const cls = outlined ? classNames({
        "w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-sm focus:ring-0 focus:border-teal block w-full p-2.5": true,
        "border-red-500": get(errors,`${name}.message`,undefined) !== undefined
    }) : classNames({
        "border-b border-bs-light-gray-200 focus:border-bs-light-gray-800 outline-0 py-1 w-full": true,
        "border-red-500": errors !== undefined
    })

	const dateValue = getValues(name)
    console.log("dateValue", dateValue)
	const currentDate = dateValue ? dateValue?.split(" ")[0]?.split("T")[0] : undefined
	setValue(name,currentDate)

    return (
        <div className="flex flex-col">
            <label className="text-bs-light-gray-800 text-sm">
                {label} {required && (<span className="text-red-800">*</span>)}
            </label>
            <div className="w-full relative">
                {
                    errors && (
                        <span className="absolute right-0">
                            <ExclamationCircleIcon className="w-5 h-5 text-red-500"/>
                        </span>
                    )
                }
                <input type={type}
                       name={name}
                       onChange={onChange}
						//@ts-ignore
                       ref={ref}
                       className={cls}/>
            </div>
            {(description || errors) && (
                <p className="text-sm text-gray-300 py-1">
                    {description} {errors && <span className="text-red-500">{errors.message}</span>}
                </p>
            )}
        </div>
    )
})


const FILE_UPLOAD_MUTATION = gql`
  mutation ($file: Upload!,$path: String!) {
    singleUpload(file: $file,path: $path) {
        id
        uri
    }
  }
`;

export const InputPDF = React.forwardRef(({...props}: IInput, ref) => {
    const {
        label,
        required,
        description,
        errors, name,
        getValues,
        onChange,
        type,
        preview = false,
        setValue,
        path} = props
    const [mutate] = useMutation(FILE_UPLOAD_MUTATION);

    const [state, setState] = useState({
        uploading: false,
        uri: getValues(name)
    });

    const onDrop = useCallback(acceptedFiles => {
        setState({...state, uploading: true})
        mutate({
            variables: {
                file: acceptedFiles[0],
                path: path ? path : "resources"
            }
        }).then((res: any) => {
            setValue(get(res, 'data.singleUpload.uri'));
            setState({...state, uploading: false, uri: get(res, 'data.singleUpload.uri')});
        })
    }, [])

    const [numPages, setNumPages] = useState<number>();
    const cref = useRef<HTMLDivElement>(null)

    const { width = 0, height = 0 } = useResizeObserver({
        ref: cref
    })

    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
    } = useDropzone({onDrop});
    const value = getValues(name)
    useEffect(() => {
        setState({...state, uri: value})
    }, [value])

    return (
        <div className="flex flex-col gap-1">
            <label className="pb-1 text-sm text-bs-light-black">
                {label} {required && (<span className="text-red-800">*</span>)}
            </label>
            <div {...getRootProps()}>
                <div
                    ref={cref}
                    className={classNames({
                        "border border-dashed bg-bs-light-gray-50 flex align-middle bg-contain bg-no-repeat max-w-full": true,
                        "p-1.5 border-gray-100": !preview,
                        "p-5 border-gray-500": preview
                    })}>
                    {
                        state.uploading && (<p>Uploading..</p>)
                    }
                    {
                        !state.uploading && !state.uri && (
                            <p>Click here to upload a file</p>
                        )
                    }
                    {
                        !state.uploading && state.uri && !preview && (
                            <p>{state.uri}</p>
                        )
                    }
                    {
                        !state.uploading && state.uri && preview && (
                            //@ts-ignore
                            <Document
                                className="m-1 overflow-hidden w-full"
                                      file={state.uri}
                                      onLoadSuccess={({ numPages }) => {
                                        setNumPages(numPages);
                                      }}

                            >
                                <Page
                                    pageNumber={1} className="max-w-[calc(100% - 2em)]"
                                    width={width}
                                    height={height}
                                />
                            </Document>
                        )
                    }
                    <input
                        {...getInputProps()}
                        onChange={onChange}
                        name={name}
                        accept="application/pdf"
                    />
                </div>
            </div>
            {(description || errors) && (
                <p className="text-sm text-gray-300 py-1">
                    {description} {errors && <span className="text-red-500">{errors.message}</span>}
                </p>
            )}
        </div>
    )
})

export const InputImage = React.forwardRef(({...props}: IInput, ref) => {
    const {label, required, description, errors, name, getValues, onChange, type, setValue,path} = props
    const [mutate] = useMutation(FILE_UPLOAD_MUTATION);
    console.log(getValues(name))
    const [state, setState] = useState({
        uploading: false,
        uri: getValues(name)
    });
    const onDrop = useCallback(acceptedFiles => {
        setState({...state, uploading: true})
        mutate({
            variables: {
                file: acceptedFiles[0],
                path: path ? path : "images/initiatives"
            }
        }).then((res: any) => {
            setValue(get(res, 'data.singleUpload.uri'));
            setState({...state, uploading: false, uri: get(res, 'data.singleUpload.uri')});
        })
    }, [])
    const {
        acceptedFiles,
        getRootProps,
        getInputProps,
    } = useDropzone({onDrop});
    const value = getValues(name)
    useEffect(() => {
        setState({...state, uri: value})
    }, [value])

    return (
        <div className="flex flex-col gap-1">
            <label className="pb-1 text-sm text-bs-light-black">
                {label} {required && (<span className="text-red-800">*</span>)}
            </label>
            <div {...getRootProps()}
                 className="border bg-gray-50 border-dashed max-h-[50vh] aspect-video p-5 border-gray-300 flex align-middle bg-contain bg-no-repeat justify-center"
                 style={{aspectRatio: "9:16"}}
            >
                {
                    state.uploading && (<p>Uploading..</p>)
                }
                {
                    !state.uploading && state.uri && (
                        <img src={state.uri} alt="Preview image" />
                    )
                }
                {
                    !state.uploading && !state.uri && (
                        <p>Click here to upload an image</p>
                    )
                }
                <input
                    {...getInputProps()}
                    onChange={onChange}
                    name={name}
                />
            </div>
            {(description || errors) && (
                <p className="text-sm text-gray-300 py-1">
                    {description} {errors && <span className="text-red-500">{errors.message}</span>}
                </p>
            )}
        </div>
    )
})

// todo(nate) : replace this with downshift and synchronize all the fields no duplicates

export const InputSelect = React.forwardRef(({...props}: IInput, ref) => {
    const {
        label,
        required,
        description,
        errors,
        name,
        value,
        getValues,
        setValue,
        options,
        loading,
        onChange,
        maxTagCount = 2,
        maxTagTextLength = 16,
        type
    } = props

    return (
        <div className="flex flex-col w-full">
            <label className="text-bs-light-gray-800 text-sm">
                {label} {required && (<span className="text-red-800">*</span>)}
            </label>
            <div className="w-full relative">
                {
                    errors && (
                        <span className="absolute right-0">
                            <ExclamationCircleIcon className="w-5 h-5 text-red-500"/>
                        </span>
                    )
                }
                <Select
                    mode={type == "multiple" ? "multiple" : undefined}
                    prefixCls="rs"
                    maxTagCount={maxTagCount}
                    maxTagTextLength={maxTagTextLength}
                    disabled={loading}
                    allowClear={true}
                    showSearch
                    loading={loading}
                    value={value}
                    optionFilterProp="children"
                    notFoundContent={<span className="p-2.5">Not found</span>}
                    tokenSeparators={[' ', ',']}
                    menuItemSelectedIcon={<CheckIcon className="w-4 h-4"/>}
                    clearIcon={<XIcon className="w-4 h-4"/>}
                    removeIcon={<XIcon className="w-3 h-3"/>}
                    onChange={onChange}
                >
                    {
                        get(options, 'options', []).map((i: any) => (
                            <Option key={i.id} value={i.id}>{i.value}</Option>
                        ))
                    }
                </Select>
            </div>
            {(description || errors) && (
                <p className="text-sm text-gray-300 py-1">
                    {description} {errors && <span className="text-red-500">{errors.message}</span>}
                </p>
            )}
        </div>
    )
})
