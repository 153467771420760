import React from "react"
import {StyledElementContainer, StyledInput, StyledLabel} from "../StyledElements";

type TextInputProps = {
    label: string;
    register: any;
    options?: any;
    errors?: any;
    required: boolean;
    name: string;
    setValue: any;
    getValues: any;
    inline?: boolean
}

export const InputText = ({label, register, name, required, options, errors, inline = false}: TextInputProps) => {
    const type = options?.type ?? "text"
    return (
        <StyledElementContainer inline={inline}>
            <StyledLabel>{label}</StyledLabel>
            <StyledInput type={type} {...register(name, {required})} {...options} />
        </StyledElementContainer>
    )
}

function getLocaleValue(type: string = "text", options: object = {}) {

}