import React from "react";
import classNames from "classnames";

type ButtonSize = "xs" | "sm" | "md" | "lg" | "xl";
type ButtonVariant = "primary" | "secondary" | "success" | "danger" | "warning" | "none" | "link" | "link-inline";

export interface TButton {
    title: string;
    iconSuffix?: JSX.Element;
    iconPrefix?: JSX.Element;
    onClick?: (value: any) => void;
    size?: ButtonSize;
    variant?: ButtonVariant;
    uppercase?: boolean;
    type?: "button" | "submit" | "reset" | undefined
    disabled?: boolean;
}

export function Button({
                           title,
                           iconPrefix,
                           size = "md",
                           variant = "none",
                           iconSuffix,
                           onClick,
                           uppercase,
                           type = "button",
                           disabled
                       }: TButton) {
    const clsButton = classNames({
        "flex justify-center rounded border": true,
        "px-6 py-2": size === "md",
        "text-sm px-4 py-1": size === "sm",
        "text-xs px-4 py-1": size === "xs",
        "bg-red-400 text-white border-red-500": variant === "danger",
        "bg-bs-light-primary text-bs-light-black border-bs-light-primary": variant === "primary",
        "bg-bs-light-black text-bs-light-white border-bs-light-black": variant === "secondary",
        "uppercase": uppercase,
        "border-none": variant === "link",
        "border-none px-0": variant === "link-inline",
        "opacity-75": disabled,
        "cursor-not-allowed": disabled,

    })
    return (
        <button className={clsButton} onClick={onClick} type={type} disabled={disabled}>
            {
                iconPrefix && (
                    <span className="px-1 self-center">{iconPrefix}</span>
                )
            }
            <span>{title}</span>
            {
                iconSuffix && (
                    <span className="self-center px-1">{iconSuffix}</span>
                )
            }
        </button>
    )
}
