import {format} from 'date-fns'
import {enUS} from 'date-fns/locale'

const locales: { [key: string]: Locale } = {enUS}

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
export default function (date: Date, formatStr = 'PP') {
    return format(date, formatStr, {
        //@todo type here.
        //@ts-ignore
        locale: locales[window.__localeId__] // or global.__localeId__
    })
}