import React, { useEffect, useState } from "react"

import {FormProvider, useForm, useWatch} from "react-hook-form";
import {InputLocationGraphQL} from "../../interface/Form/Location"
import { gql, useLazyQuery, useQuery, useMutation } from "@apollo/client";
import {find, first, get, has} from "lodash";
import { useParams, useNavigate } from "react-router";
import { GET_ORG } from "../companies/queries";
import { useFlash } from "../../context/FlashContext";
import { CheckboxInput, TextAreaInput, TextInput, DateInput } from "../../interface/Form/Input/Inputs";
import {InputImage} from "../../interface/Form/Input"
import { MultiSelectComboBox, SelectComboBox } from "../../interface/Form/Select/Select";

import {UPSERT_ORGANIZATION,CREATE_CITY,ADD_LOCATION,FETCH_DROPDOWN_OPTIONS_FORM} from "../../queries/"
import {FaCaretDown} from "react-icons/fa6";
import {HiArrowDown} from "react-icons/hi2";
import {SectionCollapsible} from "../../interface/Accordion";

function getMapAgbaseCategoryToValueChain() {
    return {
        "Agriculture FinTech": "On Farm",
        "Agriculture Marketplace": "On Farm",
        "Farm Robotics, Mechanization & Equipment": "On Farm",
        "Ag Biotech, Bioenergy & Biomaterials": "On Farm",
        "Novel Farming Systems": "On Farm",
        "Farm Management Services": "On Farm",
        "Agroclimatic Risk Intelligence Services and Products": "On Farm",
        "Food Traceability & Supply Chain Management": "Post Farm",
        "Food Processing Technologies": "Post Farm",
        "Innovative Food & Beverages": "Post Farm",
        "Logistics, Transportation, and Warehousing Infrastructure": "Post Farm",
        "Food Delivery Tech": "Retail",
        "Restaurant & Retail Tech": "Retail",
        "Home and cooking tech": "Consumer"
    }
}

export default function CreateNewOrganizationContainer() {

    const flash = useFlash();
    const { id } = useParams();
    const navigate = useNavigate();

    const [fetchOrganization, { data, loading }] = useLazyQuery(GET_ORG, {
        variables: {
            id: id
        }
    })

    const { data: opts, loading: optsLoading } = useQuery(FETCH_DROPDOWN_OPTIONS_FORM)

    const [upsertOrganization, { data: upsertData, loading: upsertLoading, error: upsertError }] = useMutation(UPSERT_ORGANIZATION);

    useEffect(() => {
        if (id) {
            fetchOrganization().then((res) => {
                console.log("res", res)
            }).catch(err => {
                console.log("err", err)
            })
        }
    }, [id])



    const buildImpacts = (data : any) => {
        const valueMap: Record<string, string> = {
            "female_cofounder": "Female CoFounder",
            "assistive_tech": "Assistive Tech",
            "bop": "BoP",
            "elderly_people": "Elderly People",
            "employment_creation": "Employment Creation",
            "female_focused": "Female Focoused",
            "fifty_local": "Fifty Local",
            "informal_sector": "Informal Sector",
            "rural_focused" : "Rulal Focused",
            "youth_focused": "Youth Focused",
            "zero_emissions": "Zero Emissions"
        };

        const selectedImpacts = []
        for(const key in data) {
            if(Object.prototype.hasOwnProperty.call(data,key) && data[key] === true) {
                selectedImpacts.push({id: key,value: valueMap[key]})
            }
        }
        return selectedImpacts
    }

    const methods = useForm({
        shouldUnregister: false,
        values: {
            ...get(data, "organization", {}),
            impact: buildImpacts(get(data,"organization.impact",{}))
        },
        defaultValues: {
            ...get(data, "organization", {}),
        },
    });

    const watchOrgType = methods.watch('type')

    const onSubmit = (data: any) => {

        const off = data.offices ? data.offices.map((it: any) => {
            const subId = it.city.sub_division ? it.city.sub_division.id : (it.sub_division ? it.sub_division.id :  "")
            delete it.city.sub_division
            const res = {...it,sub_division: subId}
            // return {...it,sub_division: it.city.subdivision ? it.subdivision.id : ""}
            return res
        }) : []

        const normalized = {
            ...data, description: data.description,
            business_models: data.business_models ? data.business_models.map((it: any) => it.id) : [],
            sectors: data.sectors ? data.sectors.map((it: any) => it.id) : [],
            enabled: data.enabled,
            sub_sectors: data.sub_sectors ? data.sub_sectors.map((it: any) => it.id) : [],
            sdgs: data.sdgs ? data.sdgs.map((it: any) => it.id) : [],
            agbase_category: data.agbase_category ? data.agbase_category.map((it: any) => it.id) : [],
            agbase_value_chain: data.agbase_value_chain ? data.agbase_value_chain.map((it: any) => it.id) : [],
            agbase_visibility: data.agbase_visibility ? data.agbase_visibility.map((it: any) => it.id) : [],
            technologies: data.technologies ? data.technologies.map((it: any) => it.id) : [],
            ops_countries: data.ops_countries ? data.ops_countries.map((it: any) => it.id) : [],
            ops_cities: data.ops_cities ? data.ops_cities.map((it: any) => it.id) : [],
            incorporation_number: `${data.incorporation_number}`,
            type: data.type ? (typeof data.type === "string" ? data.type : data.type.id) : "",
            operating_status: data.operating_status ? data.operating_status.id : "",
            number_employees: data.number_employees ? data.number_employees.value : "0-15",
            ownership_structure: data.ownership_structure ? data.ownership_structure : "",
            specifier: data.specifier ? data.specifier.id : "",
            offices:  data.offices ? off : [],
            impact: data.impact ? data.impact : []
        }

        delete normalized.id
        upsertOrganization({
            variables: {
                id: id,
                input: {
                    ...normalized
                }
            }
        }).then((res) => {
            flash(id ? "organization updated successfully" : "organization created successfully", "success")
            navigate(`/organizations/directory/orgs/${res.data.upsertOrganization.id}`)
        }).catch((err) => flash(`${err.message}`, "error"))
    }

    // this piece of code here watches the offices field. If you remove it the offices won't update
    // so let it stay here
    const watchOffices = methods.watch("offices")
    useEffect(() => {
        const offices = methods.getValues("offices")
    },[watchOffices])

    // populate automatically the value chain based on category
    const watchAgbaseCategory = methods.watch("agbase_category", [])
    useEffect(() => {
        const current = methods.getValues("agbase_value_chain")
        if (current === undefined || current?.length < 1) {
            const category = get(first(watchAgbaseCategory), 'value', '')
            const valueChain = find(get(opts, "agbase_valuechains.data", []), { value: get(getMapAgbaseCategoryToValueChain(), category) })
            if (has(valueChain, 'id')) {
                methods.setValue("agbase_value_chain", [valueChain])
            }
        }
    }, [watchAgbaseCategory, opts]);

    const optsImpact = [
        {id: "assistive_tech",value: "Assistive Tech"},
        {id: "bop",value: "BoP"},
        {id: "elderly_people",value: "Elderly People"},
        {id: "female_cofounder",value: "Female CoFounder"},
        {id: "employment_creation", value: "Employment Creation"},
        {id: "female_focused", value: "Female Focoused"},
        {id: "fifty_local", value: "Fifty Local"},
        {id: "informal_sector",value: "Informal Sector"},
        {id: "rural_focused", value: "Rural Focused"},
        {id: "youth_focused",value: "Youth Focused"},
        {id: "zero_emissions",value: "Zero Emissions"},
    ]

    if (optsLoading) return <p>Loading options...</p>
    if (loading) return <p>Loading organization..</p>

    return (
        <div className="w-full scroll-smooth">
            <div className="px-5">
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <div className="w-full flex justify-end mb-8">
                            <button type="submit" className="text-gray-900 bg-bs-light-primary hover:bg-bs-light-border-primary focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-bs-light-primary dark:hover:bg-bs-light-border-primary dark:focus:ring-blue-800">Submit</button>
                        </div>
                        <div className="grid md:grid-cols-1 gap-8 lg:grid-cols-2">
                            <div className="w-full">
                                <div className="mb-6 w-full">
                                    <TextInput
                                        placeholder="Enter company name"
                                        data-testid="form-field-name"
                                        label={"Company Name"}
                                        register={methods.register} name={"name"} />
                                </div>
                                <div className="mb-6 flex gap-8">
                                    <div className="w-1/2">
                                        <InputImage
                                            label="Profile Image/Logo (image)"
                                            getValues={methods.getValues}
                                            setValue={(v: string) => methods.setValue("profile_image_url", v)}
                                            {...methods.register("profile_image_url", {
                                                required: {value: false, message: "Banner/cover is required."}
                                            })}
                                            path="images/logos"
                                            description="Add the organization profile image here"
                                            type={"image"} required={true}
                                        />
                                    </div>
                                    <div className="w-1/2">
                                    <TextAreaInput
                                        placeholder="Enter the description"
                                        data-testid="form-field-description"
                                        label={"Description"}
                                        register={methods.register}
                                        name={"description"}/>
                                    </div>
                                </div>
                                <div className="w-full flex gap-8">
                                <div className="mb-6 w-1/2">
                                    <TextInput
                                        placeholder={"Primary Email"}
                                        type={"email"}
                                        register={methods.register}
                                        name={"primary_email_contact"}
                                        label={"Primary Email"}/>
                                </div>
                                <div className="mb-6 w-1/2">
                                    <TextInput
                                        placeholder={"Primary Phone Number"}
                                        type={"tel"}
                                        register={methods.register}
                                        name={"primary_phone_number"}
                                        label={"Primary Phone Number"} />
                                </div>
                                </div>
                                <div className="mb-6">
                                    <DateInput
                                        type={"month"}
                                        register={methods.register}
                                        name={"founded"}
                                        label={`Founded ${JSON.stringify(methods.watch("founded"))}`}
                                        methods={methods}
                                    />
                                </div>
                                <div className="mb-6">
                                    <SelectComboBox {...methods.register("type")} name={"type"} label={"Category"} control={methods.control} options={[
                                        { id: "startup", value: "Startup" },
                                        { id: "investor", value: "Investor" },
                                        { id: "ngo_charity", value: "NGO/Charity" },
                                        { id: "hub", value: "Hub/Accelerator" }
                                    ]} />
                                </div>
                                <div className="mb-6">
                                    {
                                        watchOrgType && (watchOrgType.id === "hub" || methods.getValues('type') === "hub") && (
                                            <SelectComboBox {...methods.register("specifier")} name={"specifier"} label={"Hub Type"} control={methods.control} options={[
                                                { id: "ACCELERATOR", value: "Accelerator" },
                                                { id: "COWORKING", value: "Co-Working Space" },
                                                { id: "MAKER_SPACE", value: "Maker Space" },
                                                { id: "INNOVATION_HUB", value: "Innovation Hub" },
                                                { id: "INCUBATOR", value: "Incubator" },
                                                { id: "VENTURE_BUILDER", value: "Venture Builder" },
                                                { id: "OTHER", value: "Other" },
                                            ]} />
                                        )
                                    }
                                    {
                                        watchOrgType && (watchOrgType.id === "ngo_charity" || methods.getValues('type') === "ngo_charity") && (
                                            <SelectComboBox {...methods.register("specifier")} name={"specifier"} label={"NGO/Charity Type"} control={methods.control} options={[
                                                { id: "ADVOCACY_GROUP", value: "Advocacy Group" },
                                                { id: "CIVIL_SOCIETY_GROUP", value: "Civil Society Group" },
                                                { id: "FOUNDATION", value: "Foundation" },
                                                { id: "HUMANITARIAN_ORGANIZATION", value: "Humanitarian Organisation" },
                                                { id: "OTHER", value: "Other" },
                                            ]} />
                                        )
                                    }
                                    {
                                        watchOrgType && (watchOrgType.id === "investor" || methods.getValues('type') === "investor") ?
                                        <SelectComboBox {...methods.register("specifier")} name={"specifier"} label={"Investor Type"} control={methods.control} options={[
                                            { id: "ANGEL", value: "Angel" },
                                            { id: "ANGEL_SYNDICATE", value: "Angel Syndicate" },
                                            { id: "INVESTMENT_MANAGEMENT_FIRM", value: "Investment Management Firm" },
                                            { id: "BANK", value: "Bank" },
                                            { id: "CORPORATE", value: "Corporate" },
                                            { id: "CVC", value: "Corporate Venture Capital (CVC)" },
                                            { id: "DFI", value: "Development Finance Institution (DFI)" },
                                            { id: "FAMILY_OFFICE", value: "Family Office" },
                                            { id: "GOVERNMENT", value: "Government" },
                                            { id: "HEDGE_FUND", value: "Hedge Fund" },
                                            { id: "IMPACT_INVESTOR", value: "Impact Investor" },
                                            { id: "PITCH_COMPETITION", value: "Pitch Competition & Hackathons" },
                                            { id: "PRIVATE_EQUITY_FIRM", value: "Private Equity Firm" },
                                            { id: "VC", value: "Venture Capital Firm" },
                                            { id: "PENSION_FUND", value: "Pension Fund" },
                                            { id: "SWF", value: "Sovereign Wealth Fund (SWF)" },
                                            { id: "OTHER", value: "Other" },
                                        ]} />
                                        :
                                        null
                                    }
                                </div>
                                <div className="mb-6">
                                    <SelectComboBox {...methods.register("operating_status")} name={"operating_status"} label={"Operating Status"} control={methods.control} isMulti={false} options={[
                                        { id: "y", value: "Operational"},
                                        { id: "c", value: "No Recent Activity" },
                                        { id: "n", value: "Shut Down"}
                                    ]} />
                                </div>
                                <div className="mb-6">
                                    <SelectComboBox {...methods.register("number_employees")} name={"number_employees"} label={"Number of Employees"} control={methods.control} options={[
                                        { id: 0, value: "Self-employed" },
                                        { id: 1, value: "1-10" },
                                        { id: 11, value: "11-50" },
                                        { id: 51, value: "51-200" },
                                        { id: 201, value: "201-500" },
                                        { id: 501, value: "501-1000" },
                                        { id: 1001, value: "1001-5000" },
                                        { id: 5001, value: "5001-10,000" },
                                        { id: 10001, value: "10,000+" },
                                    ]} />
                                </div>

                                <div className="mb-6">
                                    <CheckboxInput register={methods.register} label={"Active"} name={"enabled"} />
                                </div>
                            </div>
                            <div className="w-full flex flex-col gap-2">
                                <SectionCollapsible label="AgBase Specific" defaultOpen={false}>
                                    <div className="w-full flex flex-col gap-4">

                                        <MultiSelectComboBox {...methods.register("agbase_category")}
                                                             name={"agbase_category"}
                                                             label={"AgBase Category"} control={methods.control}
                                                             options={get(opts, "agbase_categories.data", [])}/>


                                        <MultiSelectComboBox {...methods.register("agbase_value_chain")}
                                                             name={"agbase_value_chain"}
                                                             label={"AgBase Value Chain"} control={methods.control}
                                                             options={get(opts, "agbase_valuechains.data", [])}/>

                                        <MultiSelectComboBox {...methods.register("agbase_visibility")}
                                                             name={"agbase_visibility"}
                                                             label={"AgBase Visibility"} control={methods.control}
                                                             options={get(opts, "agbase_visibility.data", [])}/>
                                    </div>
                                </SectionCollapsible>
                                <SectionCollapsible label="Locations" defaultOpen={true}>
                                    <div className="w-full flex flex-col gap-4">
                                        <div className="">
                                            <InputLocationGraphQL
                                                type="multiple" label="Headquarters & Offices" name="offices"
                                                locations={methods.getValues("offices")}
                                            />
                                        </div>
                                        <div className="">
                                            <MultiSelectComboBox {...methods.register("ops_countries")}
                                                                 name={"ops_countries"}
                                                                 label={"Operating Countries"} control={methods.control}
                                                                 options={get(opts, "countries.data", [])}/>
                                        </div>
                                        <div className="">
                                            <MultiSelectComboBox {...methods.register("ops_cities")} name={"ops_cities"}
                                                                 label={"Operating Cities"} control={methods.control}
                                                                 options={get(opts, "cities.data", [])}/>
                                        </div>
                                    </div>
                                </SectionCollapsible>
                                <SectionCollapsible label="Metadata">
                                    <div className="w-full flex flex-col gap-4">
                                        <MultiSelectComboBox {...methods.register("sectors")} name={"sectors"}
                                                             label={"Sectors"} control={methods.control}
                                                             options={get(opts, "sectors.data", [])}/>

                                        <MultiSelectComboBox {...methods.register("sub_sectors")} name={"sub_sectors"}
                                                             label={"Sub Sectors"} control={methods.control}
                                                             options={get(opts, "sub_sectors.data", [])}/>

                                        <MultiSelectComboBox {...methods.register("technologies")} name={"technologies"}
                                                             label={"Technologies"} control={methods.control}
                                                             options={get(opts, "technologies.data", [])}/>

                                        <MultiSelectComboBox {...methods.register("business_models")}
                                                             name={"business_models"}
                                                             label={"Business Models"}
                                                             control={methods.control}
                                                             options={get(opts, "business_models.data", [])}/>

                                        <MultiSelectComboBox {...methods.register("impact")} name={"impact"}
                                                             label={"Impact"} control={methods.control}
                                                             options={optsImpact}/>

                                        <MultiSelectComboBox {...methods.register("sdgs")} name={"sdgs"} label={"SDGs"}
                                                             control={methods.control}
                                                             options={get(opts, "sdgs.data", [])}/>
                                    </div>
                                </SectionCollapsible>
                                <SectionCollapsible label="Socials / Ext. Links">
                                    <div className="w-full flex flex-col gap-4">
                                        <TextInput placeholder="http://example.com" data-testid="form-field-website"
                                                   label={"Website"} register={methods.register}
                                                   name={"socials.website"}/>

                                        <TextInput placeholder="http://x.com/username" data-testid="form-field-twitter"
                                                   label={"Twitter"} register={methods.register}
                                                   name={"socials.twitter"}/>

                                        <TextInput placeholder="http://facebook.com/username"
                                                   data-testid="form-field-facebook" label={"Facebook"}
                                                   register={methods.register} name={"socials.facebook"}/>

                                        <TextInput placeholder="http://angellist.com/username"
                                                   data-testid="form-field-angellist" label={"Angellist"}
                                                   register={methods.register} name={"socials.angellist"}/>

                                        <TextInput placeholder="http://instagram.com/username"
                                                   data-testid="form-field-instagram" label={"Instagram"}
                                                   register={methods.register} name={"socials.instagram"}/>

                                        <TextInput placeholder="http://linkedin.com/in/username"
                                                   data-testid="form-field-linkedin" label={"Linked In"}
                                                   register={methods.register} name={"socials.linkedin"}/>
                                    </div>

                                </SectionCollapsible>
                                <div className="mb-6">
                                    <TextInput label={"Ownership Structure"} register={methods.register}
                                               name={"ownership_structure"}/>
                                </div>
                                <div className="mb-6">
                                    <TextInput label={"Incorporation Number"} register={methods.register}
                                               name={"incorporation_number"}/>
                                </div>
                            </div>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    )
}
