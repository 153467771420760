import React, {useEffect, useRef, useState} from "react";
import {
    DataContainerProvider,
    useDataContainerContext
} from "../../interface/DataContainer/context/DataContainerContext";
import {Tabs} from "../../interface/Tabs/Tabs";
import {DataGridGraphQL} from "../../interface/DataGrid/DataGridGraphQL";
import {Link, useNavigate} from "react-router-dom";
import {Persona} from "../../interface/Persona";
import {Socials} from "../../interface/Socials";
import {filters} from "./filters"
import {FETCH_COMPANIES, FETCH_OFFICES_BY_ORG_ID, UPDATE_LOCS_BY_ORG_ID} from "./queries";
//@ts-ignore
import { FlagIcon } from "react-flag-kit";
import {Pillola} from "../../interface/Pillole";
import {DotsVerticalIcon, PencilIcon} from "@heroicons/react/outline";
import {find, get} from "lodash";
import {DataGridArrayCell} from "../../interface/DataGrid";
import classNames from "classnames";
import {format} from "date-fns";
import {HiOutlineXMark, HiPencil} from "react-icons/hi2";
import {InputLocationGraphQL} from "../../interface/Form/Location";
import {FormProvider, useForm} from "react-hook-form";
import {useMutation, useQuery} from "@apollo/client";
import {useFlash} from "../../context/FlashContext";
import DynamicPortal from "../../interface/DynamicPortal";
import {useHover} from "usehooks-ts";
import numeral from "numeral";

export function CompaniesDirectoryContainer() {
    const {filtersQuery} = useDataContainerContext();
    const navigate = useNavigate()
    return (
        <section className="px-5">
            <div className="max-w-full">
                <DataGridGraphQL
                    loading={false}
                    query={FETCH_COMPANIES}
                    root="search"
                    property="items"
                    filters={filtersQuery}
                    variables={{
                        "search": {
                            "bucket": "organizations",
                            "sort": [{key: "name.keyword", dir: "asc"}],
                            "query": {
                                "filters": [{"term": {"enabled": true}}]
                            }
                        }
                    }}
                    entityName="organizations"
                    headers={[
                        {
                            title: "Company Name",
                            dataIndex: "name",
                            sortingKey: "name.keyword",
                            key: "name",
                            render: (value) => {
                                return (
                                    <Link to={['/organizations/directory/orgs', value.id].join("/")}>
                                        <Persona text={value.name} size="xs" imageURL={value.profile_image_url}/>
                                    </Link>
                                )
                            }
                        },
                        {
                            title: "Type",
                            dataIndex: "type",
                            key: "type",
                            sortingKey: "type.keyword",
                            render: (value) => <Pillola text={value.type} variant={"ghost"}/>
                        },
                        {
                            title: "Deals Info",
                            dataIndex: "deals_no",
                            key: "deals_no",
                            sortingKey: "deals_no",
                            render: (value) => {
                                const deals = get(value, 'type', 'startup')
                                    ? (get(value, 'deals_no', 0))
                                    : get(value, 'investment_no', 0)

                                return <p className="text-center">
                                    { deals } {
                                    get(value, 'type', 'startup')
                                        ? "deals"
                                        : "investments"
                                } {
                                        deals > 0 && (
                                            <span>
                                                ({ numeral((get(value, 'total_funding', 0))).format('$0.0 a') })
                                            </span>
                                    )
                                }
                                </p>
                            }
                        },
                        {
                            title: "Sectors",
                            dataIndex: "sectors",
                            key: "sectors",
                            render: (value) => {
                                const values = value.sectors.map((s: any) => ({name: s.name}));
                                return <DataGridArrayCell value={values}/>
                            }
                        },
                        {
                            title: "HQ Country",
                            dataIndex: "hq_country",
                            key: "hq_country",
                            render: (value) => {
                                return <HQCountries offices={get(value, 'offices', [])} org={value} />
                            }
                        },
                        {
                            title: "Operating Status",
                            dataIndex: "operating_status",
                            key: "operating_status",
                            render: (value) => {
                                const cls = classNames({
                                    "self-center px-2 py-1 text-xs rounded-md": true
                                })
                                switch (value.operating_status) {
                                    case 'y':
                                        return (
                                            <div className="flex-grow justify-center items-center flex">
                                                <Pillola text="ACTIVE" variant="positive"/>
                                            </div>
                                        )
                                    case 'n':
                                        return (
                                            <div className="flex-grow justify-center items-center flex">
                                                <Pillola text="SHUT DOWN" variant="negative"/>
                                            </div>
                                        )
                                    case 'c':
                                        return (
                                            <div className="flex-grow justify-center items-center flex">
                                                <Pillola text="UNDER REVIEW" variant="neutral"/>
                                            </div>
                                        )
                                    default:
                                        return <span
                                            className={cls}>{value.operating_status}</span>
                                }
                            }
                        },
                        {
                            title: "Contacts",
                            dataIndex: "contacts",
                            key: "contacts",
                            render: (value) => {
                                return <Socials size="sm" type={"horizontal"} socials={{
                                    angellist: get(value, 'angellist'),
                                    website: get(value, 'website'),
                                    // linkedin: get(value, 'linkedin'),
                                    // facebook: get(value, 'facebook')
                                }}/>
                            }
                        },
                        {
                            title: "Last Update",
                            dataIndex: "updated_at",
                            key: "updated_at",
                            sortingKey: "updated_at",
                            render: (value) => {
                                return format(new Date(value.updated_at), 'Pp')
                            }
                        },
                        {
                            title: "",
                            dataIndex: "actions",
                            key: "actions",
                            render: (value) => {
                                return (
                                    <div className="flex gap-2.5">
                                        <PencilIcon className="w-5 h-5 cursor-pointer" onClick={() => {

                                            navigate(`/organizations/directory/orgs/${value.id}/edit`)
                                            // addPane({
                                            //     title: `${value.name} (organization)`,
                                            //     content: <EditCompanyFragment id={value.id}/>,
                                            //     id: `edit-org-${value.id}`,
                                            //     type: "edit"
                                            // })
                                        }}/>
                                        <DotsVerticalIcon
                                            className="w-5 h-5 text-bs-light-gray hover:text-bs-light-black hover:cursor-pointer"
                                        />
                                    </div>
                                )
                            }
                        }
                    ]}
                    data={[]}
                />
            </div>
        </section>
    )
}

export default function OrganizationsContainer() {
    const nav = {
        "directory": {
            title: 'Directory',
            key: 'directory',
            pane: <CompaniesDirectoryContainer/>
        }
    }


    return (
        <DataContainerProvider filters={filters}>
            <div className="w-full">
                <div className="flex-grow">
                    <Tabs
                        index={'directory'}
                        tabs={nav}
                    />
                </div>
            </div>
        </DataContainerProvider>
    )
}


const HQCountries = ({ offices, org }: any) => {
    const [isEditing, setIsEditing] = useState(false);
    const headquarter = find(offices, { is_hq_primary: true })
    const refHover = useRef(null)
    const hover = useHover(refHover)
    return (
        <div className="w-full relative">
            {
                headquarter && (
                    <span className="flex gap-2 items-center relative"  onClick={(ev) => {
                        ev.preventDefault()
                        setIsEditing(true)
                    }} ref={refHover}>
                        <FlagIcon code={get(headquarter, 'country.alpha2')} className="rounded-sm" />
                        <p className="grow">
                        {
                            get(headquarter, 'city.name') && (
                                <span>{get(headquarter, 'city.name')}, </span>
                            )
                        }{get(headquarter, 'country.name')}
                            </p>
                        {hover && <button><HiPencil className="text-sm" /></button>}
                    </span>
                )
            }
            {
                !headquarter && (
                    <button className="flex cursor-pointer gap-2 items-center bg-red-200 py-1 px-2 rounded-md text-red-800"
                            onClick={(ev) => {
                                ev.preventDefault()
                                setIsEditing(true)
                            }}>
                        Not set <HiPencil className="text-sm" />
                    </button>
                )
            }
            {
                isEditing && (
                    <DynamicPortal id={`id-${get(org, 'id')}`}>
                    <div className="fixed left-0 top-0 bg-[#ffffffaa] z-10 h-screen w-screen">
                        <div className="pt-5">
                            <div className="bg-white max-w-screen-lg mx-auto p-8 flex flex-col shadow-md rounded-md">
                                <div className="self-end cursor-pointer" onClick={() => setIsEditing(false)}>
                                    <HiOutlineXMark className="text-lg" />
                                </div>
                                <HQCountryEdit org_id={get(org, 'id')} key={get(org, 'id')} onSuccess={() => {
                                    setIsEditing(false)
                                }} />
                            </div>
                        </div>
                    </div>
                    </DynamicPortal>
                )
            }
        </div>
    )
}

const HQCountryEdit = ({ org_id, onSuccess }: any) => {
    const { data, loading, error } = useQuery(FETCH_OFFICES_BY_ORG_ID, {
        variables: { id: org_id },
        fetchPolicy: "network-only"
    })
    const flash = useFlash()
    const [updateLocsByOrgID, {  }] = useMutation(UPDATE_LOCS_BY_ORG_ID);

    const methods = useForm({
        defaultValues: {
            ...get(data, 'organization', {})
        },
        values: get(data, 'organization', {}),
        shouldUnregister: true
    })

    const onSubmit = (data: any) => {
        updateLocsByOrgID({
            variables: {
                id: get(data, 'id'),
                offices: get(data, 'offices', [])
            }, refetchQueries: [FETCH_COMPANIES]
        }).then((res) => {
            flash(data.id ? "offices updated successfully" : "offices created successfully", "success")
            onSuccess()
        }).catch((err) => flash("failed to create/update offices", "error"))
    }

    if(loading) return  <p>Loading..</p>
    if(error) return <p>{error.message}</p>

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <input type="hidden" {...methods.register("id")} />
                <InputLocationGraphQL
                    type="multiple" label={`Headquarters & Offices of ${methods.getValues("name")}`} name="offices"
                    locations={methods.getValues("offices")}
                />
                <div className="py-4 inline-flex">
                    <button className="bg-bs-light-primary px-2 py-1 rounded-md">Save</button>
                </div>
            </form>
        </FormProvider>
    )
}