import {format} from "date-fns";
import {PencilIcon,TrashIcon} from "@heroicons/react/solid";
import {GroupPillole, Pillola} from "../../interface/Pillole";
import {Socials} from "../../interface/Socials";
import {HSection, MDSection} from "../../interface/Typography";

export interface OrganizationPrimaryInfoProps {
  org: any
  onDelete: any
  onEdit: any
  hideLogo?: boolean
}

export const OrganizationPrimaryInfo = ({...props} : OrganizationPrimaryInfoProps) => {
  const {org,onEdit,onDelete,hideLogo} = props

  return (
    <div className="flex flex-col flex-grow gap-5 py-5">
        <div className="flex gap-5 items-center">
            <p className="text-5xl font-light">{org.name}</p>
            <Pillola text={org.type} variant={"neutral"}/>
            <PencilIcon className="w-5 h-5 cursor-pointer" onClick={onEdit}/>
            <TrashIcon className="w-5 h-5 cursor-pointer" onClick={onDelete}/>
        </div>
        <div>
            <SideRow label="Last Update"
                     value={org.updated_at ? format(new Date(org?.updated_at), 'PPP ppp') : 'never'}
            />
            <SideRow label="First Entry"
                     value={org.created_at ? format(new Date(org?.created_at), 'PPP ppp') : 'never'}
            />
        </div>
        <div className="flex gap-5 w-full">
            {
              !hideLogo ? 
                      <img className="w-60 h-60 object-contain border-2 p-5" src={org.profile_image_url}/>
              : 
                      null

            }
            <div className="w-5/6">
                <MDSection title="Description"
                           variant={org?.description?.length > 160 ? "success" : "error"}
                           badge={org?.description?.length > 160 ? "" : "Min characters > 160"}>
                    <p>{org?.description}</p>
                </MDSection>
                <MDSection title="socials">
                    <Socials type={"horizontal"} hasLabel={true} allowAll socials={org.socials}/>
                </MDSection>
                <MDSection title="Company Info">
                    <p>Ownership structure : {org?.ownership_structure}</p>
                    <p>Incorporation number : {org?.incorporation_number}</p>
                </MDSection>
            </div>
        </div>
    </div>
  )
}

const SideRow = ({label, value}: { label: string, value: any }) => {
    return (
        <p className="text-sm">
            <span className="font-semibold text-xs mr-2 uppercase">{label}</span>
            <span className="font-normal">{value}</span>
        </p>
    )
}
