import React from "react";
import {MDSection} from "../../interface/Typography";
import {DataTableGraphQL} from "../../interface/DataTable/DataTable";
import {FETCH_FULFILLED_CLAIMS, FETCH_PENDING_CLAIMS} from "../../queries/Claims";
import {format, formatDistance} from "date-fns";
import {Button} from "../../interface/Button/Button";
import {ArrowRightIcon, ShieldExclamationIcon} from "@heroicons/react/solid";
import {Tabs} from "../../interface/Tabs/Tabs";
import {Pillola} from "../../interface/Pillole";
import {usePlatformContext} from "../../context/PlatformContext";
import {ClaimApproveRejectFragment} from "./fragments/ClaimApproveRejectFragment";
import {Persona} from "../../interface/Persona";

const pendingHeaders = [
    {key: "id", title: "ID", dataIndex: "id", render: (claim: any) => claim.id.slice(0, 8)},
    {
        key: "created_at", title: "Request Date", dataIndex: "created_at",
        render: (claim: any) => (
            <span>
                {formatDistance(new Date(claim.created_at), new Date(), {addSuffix: true})} ({format(new Date(claim.created_at), 'Y-MM-dd')})
            </span>
        )
    },
    {
        key: "company", title: "Company", dataIndex: "organization",
        render: (claim: any) => (
            <Persona size="xs" text={claim.organization.name} imageURL={claim.organization.profile_image_url}/>
        )
    },
    {key: "wants_to_claim", title: "", dataIndex: "wants_to_claim", render: () => <ArrowRightIcon/>},
    {
        key: "user", title: "User", dataIndex: "user",
        render: (claim: any) => (
            <Persona size="xs" text={`${claim.user.first_name} ${claim.user.other_names}`}
                     secondaryText={claim.user.email} imageURL={claim.user.profile_image_url}/>
        )
    },
    {key: "user.email", title: "Email", dataIndex: "user.email"},
    {
        key: "actions", title: "Actions", dataIndex: "actions",
        render: (claim: any) => {
            const {addPane, removePaneByID} = usePlatformContext()
            return (
                <div style={{display: "block", position: "relative"}}>
                    <div style={{display: "flex", gap: "1rem"}}>
                        <Button title="Approve" variant="primary" size="sm" onClick={(ev) => {
                            ev.preventDefault();
                            addPane({
                                title: `${claim.user.first_name} ${claim.user.other_names} wants to claim ${claim.organization.name}`,
                                type: "approve",
                                id: `approve-${claim.id}`,
                                content: <ClaimApproveRejectFragment id={claim.id} action="APPROVE" />,
                                onClose: (pane) => removePaneByID(pane.id)
                            })
                        }} />
                        <Button title="Reject" variant="secondary" size="sm" onClick={(ev) => {
                            ev.preventDefault()
                            addPane({
                                title: `${claim.user.first_name} ${claim.user.other_names} wants to claim ${claim.organization.name}`,
                                type: "reject",
                                id: `reject-${claim.id}`,
                                content: <ClaimApproveRejectFragment id={claim.id} action="REJECT" />,
                                onClose: (pane) => removePaneByID(pane.id)
                            })
                        }} />
                    </div>
                </div>
            )
        }
    },
]
const resolvedHeaders = [
    {key: "id", title: "ID", dataIndex: "id", render: (claim: any) => claim.id.slice(0, 8)},
    {
        key: "updated_at", title: "Last Update", dataIndex: "updated_at",
        render: (claim: any) => (
            <span>
                {formatDistance(new Date(claim.updated_at), new Date(), {addSuffix: true})} ({format(new Date(claim.updated_at), 'Y-MM-dd')})
            </span>
        )
    },
    {
        key: "company", title: "Company", dataIndex: "organization",
        render: (claim: any) => (
            <Persona size="xs" text={claim.organization.name} imageURL={claim.organization.profile_image_url}/>
        )
    },
    {
        key: "user", title: "User", dataIndex: "user",
        render: (claim: any) => (
            <Persona size="xs" text={`${claim.user.first_name} ${claim.user.other_names}`}
                     secondaryText={claim.user.email} imageURL={claim.user.profile_image_url}/>
        )
    },
    {key: "user.email", title: "Email", dataIndex: "user.email"},
    {
        key: "status", title: "Status", dataIndex: "status",
        render: (claim: any) => (
            <p style={{textTransform: "uppercase", display: "flex", alignItems: "center", gap: "0.5rem"}}>
                {claim.status === 1
                    ? <><Pillola text="Approved" variant="success"/> ({claim.status_reason})</>
                    : <><Pillola text="Rejected" variant="negative"/> ({claim.status_reason})</>}
            </p>
        )
    },
    {
        key: "actions", title: "Actions", dataIndex: "actions",
        render: (claim: any) => {
            const {addPane, removePaneByID} = usePlatformContext()
            return (
                <div style={{display: "flex", gap: "1rem"}}>
                    {
                        claim.status === -1 && (
                            <Button title="Change to Approve" variant="primary" size="sm"
                                    iconPrefix={<ShieldExclamationIcon/>} onClick={(ev) => {
                                ev.preventDefault()
                                addPane({
                                    title: `${claim.user.first_name} ${claim.user.other_names} wants to claim ${claim.organization.name}`,
                                    type: "change-to-approve",
                                    id: `change-to-approve-${claim.id}`,
                                    content: <ClaimApproveRejectFragment id={claim.id} action="APPROVE" />,
                                    onClose: (pane) => removePaneByID(pane.id)
                                })
                            }}
                            />
                        )
                    }
                    {
                        claim.status === 1 && (
                            <Button title="Change to Reject" variant="danger" size="sm" iconPrefix={<ShieldExclamationIcon/>}
                                    onClick={(ev) => {
                                        ev.preventDefault()
                                        addPane({
                                            title: `${claim.user.first_name} ${claim.user.other_names} wants to claim ${claim.organization.name}`,
                                            type: "change-to-reject",
                                            id: `change-to-reject-${claim.id}`,
                                            content: <ClaimApproveRejectFragment id={claim.id} action="REJECT" />,
                                            onClose: (pane) => removePaneByID(pane.id)
                                        })
                                    }}
                            />
                        )
                    }
                </div>
            )
        }
    },
]

export default function CrowdsourcingContainer() {
    const nav = {
        "pending": {
            title: "Pending Claims",
            key: "pending",
            pane: <PendingClaimPane />
        },
        "list": {
            title: "Approved/Rejected Claims",
            key: "list",
            pane: <ApprovedRejectedClaims />
        },
        "activities": {
            title: "Crowdsourcing Activities",
            key: "activities",
            pane: (
                <div style={{padding: "0.5rem 1rem", width: "100%"}}>
                    <p>Display all the crowdsourcing suggestions</p>
                </div>
            )
        }
    }

    return (
        <div className="flex-grow flex flex-row">
            <div className="flex-grow">
                <Tabs
                    index={'pending'}
                    tabs={nav}
                />
            </div>
        </div>
    )
}

function PendingClaimPane() {
    return (
        <div style={{padding: "0.5rem 1rem", width: "100%"}}>
            <MDSection title="Pending claims">
                <DataTableGraphQL root="claims"
                                  emptyMessage="No pending claims"
                                  property="data"
                                  query={FETCH_PENDING_CLAIMS}
                                  pollInterval={10000}
                                  headers={pendingHeaders} data={[]}/>
            </MDSection>
        </div>
    )
}

function ApprovedRejectedClaims() {
    return (
        <div style={{padding: "0.5rem 1rem", width: "100%"}}>
            <MDSection title="Claims History">
                <DataTableGraphQL root="claims"
                                  pollInterval={10000}
                                  emptyMessage="No approved/rejected of claims"
                                  property="data"
                                  query={FETCH_FULFILLED_CLAIMS}
                                  headers={resolvedHeaders} data={[]}/>
            </MDSection>
        </div>
    )
}