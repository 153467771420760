import {IDataGridHeader, IDataGridOptions, IDataGridRow} from "./DataGridTypes";
import React, {useRef} from "react";
import {SEItem} from "../SearchEverywhere/SEItem";
import {DataGridArrayCell} from "./Cell";
import {get} from "lodash";

export interface TDataGridRow {
    headers: IDataGridHeader[],
    row: IDataGridRow,
    rowNumber: number,
    options: IDataGridOptions,
    isRowSelected?: boolean
    onSelectRow?: (row: any) => void;
    className?: string
}

export const DataGridRow = ({
                                headers,
                                row,
                                rowNumber,
                                options,
                                onSelectRow,
                                isRowSelected,
                                className = ""
                            }: TDataGridRow) => {
    // todo(nate) : fix this piece of code
    const renderCellValue = (h: IDataGridHeader, index: number, isSelectable: boolean) => {
        if (h.render) {
            return h.render(row);
        }

        const dataIndex = row[h.dataIndex];
        if (!Array.isArray(dataIndex)) {
            if (options && options.isSearchingEverywhere) {
                return <SEItem value={dataIndex}/>;
            }
            if (options && !options.isSearchingEverywhere) {
                return dataIndex;
            }
        } else {
            return <DataGridArrayCell value={dataIndex} colInfo={h} initialData={row}/>;
        }
        return null;
    };

    const handleSelectRow = (row: any) => {
        if (onSelectRow) {
            onSelectRow(row)
        }
    }

    const renderSelectableCell = (isSelectable: boolean) => {
        if (isSelectable) {
            return (
                <div
                    role="gridcell"
                    key={`DataGridRow__row__${rowNumber}__item__1`}
                    style={{gridColumnStart: 1}}
                    className={`self-center border-b border-b-bs-light-gray-50 items-center justify-center flex h-full`}
                >
                    <input type="checkbox" onChange={() => handleSelectRow(row)} checked={isRowSelected}
                           onClick={() => handleSelectRow(row)}/>
                </div>
            );
        }

        return null;
    };

    const isSelectable = options && options.isSelectable || false;

    const rowRef = useRef<HTMLDivElement>(null)
    return (
        <div role="row" className="contents" style={{gridRowStart: rowNumber + 2,}} ref={rowRef}>
            {renderSelectableCell(isSelectable)}
            {headers.map((h: IDataGridHeader, index: number) => {
                const classes = get(h, 'className', "")
                return (
                    <div
                        role="gridcell"
                        key={`DataGridRow__row__${rowNumber}__item__${
                            index + (isSelectable ? 2 : 1)
                        }`}
                        style={{
                            gridColumnStart: index + (isSelectable ? 2 : 1),
                            overflow: "hidden"
                        }}
                        className={`self-center border-b border-b-blue-600 items-center flex h-full px-2 py-0.5 ${classes}`}
                    >
                        {renderCellValue(h, index, isSelectable)}
                    </div>
                )
            })}
        </div>
    );
};






