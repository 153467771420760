export const filters: any[] = [
    {
        title: "General",
        filters: [
            {
                type: "select-or",
                title: "Investor Name",
                key: "investors.name.keyword",
                id: "investors.name.keyword",
                dynamic: true,
                options: [],
                bucket: "deals"
            },
            {
                type: "select-or",
                title: "Company Name",
                key: "target.name.keyword",
                id: "target.name.keyword",
                dynamic: true,
                options: [],
                bucket: "deals"
            },
            {
                type: "select-or",
                title: "Type",
                key: "type.keyword",
                id: "type.keyword",
                dynamic: true,
                options: [],
                bucket: "deals"
            },
            {
                type: "select-or",
                title: "Stage",
                key: "stage.keyword",
                id: "stage.keyword",
                dynamic: true,
                options: [],
                bucket: "deals"
            },
            {
                type: "range",
                title: "Amount",
                key: "amount",
                id: "amount"
            }
        ]
    }
];