import React, {ReactNode, useEffect, useRef, useState} from "react";
// @ts-ignore
import srcLogo from "../../assets/briter-logo-notext.png";
import {useAuth} from "../../context/AuthContext";
import {NavLink} from "react-router-dom";
import {BaseLayoutProvider, useBaseLayoutContext} from "./context/BaseLayoutContext";
import {
    NavIconItem,
    SubSideNav,
    SubSideNavMenu,
    SubSideNavMenuItem,
    SubSideNavMenuLink
} from "../../interface/UIShell/UIShell";
import {usePlatformContext} from "../../context/PlatformContext";
import {MenuCreator} from "./fragments/MenuCreator";
import {useEventListener} from "usehooks-ts";
import MenuSearchAnywhere from "./fragments/MenuSearchAnywhere";
import {StatGraphQL} from "../../containers/fragments/StatGraphQL";
import {FETCH_AGGREGATION_STAT} from "../../queries";
import {formatISO, sub} from "date-fns";
import FlashMessage from "../../interface/FlashMessage";
import {Avatar} from "../../interface/Avatar";
import {get} from "lodash";


const MenuIcon = () => {
    const {isMenuAttached, setMenuAttached} = useBaseLayoutContext();

    return (
        <div className="text-white select-none">
            <div className="rounded p-1 cursor-pointer"
                 onClick={() => setMenuAttached(!isMenuAttached)}
                 onMouseEnter={() => setMenuAttached(false) }
            >
                {
                    isMenuAttached && (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd"
                                  d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                                  clipRule="evenodd"/>
                            <path fillRule="evenodd"
                                  d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                                  clipRule="evenodd"/>
                        </svg>
                    )
                }
                {
                    !isMenuAttached && (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd"
                                  d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z"
                                  clipRule="evenodd"/>
                        </svg>
                    )
                }
            </div>
        </div>
    )
}

export function ProfileButtonMenu(): JSX.Element {
    const [state, setState] = useState({visible: false});
    const {session} = useAuth();
    return (
        <div className="relative">
            <NavIconItem isActive={false}>
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24"
                     stroke="currentColor" strokeWidth={2}>
                    <path strokeLinecap="round" strokeLinejoin="round"
                          d="M12 11c0 3.517-1.009 6.799-2.753 9.571m-3.44-2.04l.054-.09A13.916 13.916 0 008 11a4 4 0 118 0c0 1.017-.07 2.019-.203 3m-2.118 6.844A21.88 21.88 0 0015.171 17m3.839 1.132c.645-2.266.99-4.659.99-7.132A8 8 0 008 4.07M3 15.364c.64-1.319 1-2.8 1-4.364 0-1.457.39-2.823 1.07-4"/>
                </svg>
            </NavIconItem>
            {state.visible && (
                <div className="absolute top-10 right-0 border">
                    <p>{session?.user?.email}</p>
                    <NavLink to={"/settings"}>Settings</NavLink>
                </div>
            )}
        </div>
    )
}

export function BaseLayout({children}: { children: ReactNode }): JSX.Element {
    const {setSearchAnywhereVisible, isMenuAttached} = useBaseLayoutContext();
    const { session } = useAuth()
    const {bindKeys, setHotKeysDisplay} = usePlatformContext();

    const documentRef = useRef<Document>(document)
    useEventListener("visibilitychange", () => {
        setHotKeysDisplay(false)
    }, documentRef);


    useEffect(() => {
        bindKeys({SHOW_HELP: {sequence: "command", action: "keydown", name: "Show this help"}}, {
            SHOW_HELP: (event: any) => {
                event.preventDefault();
                setHotKeysDisplay(true);
                setTimeout(() => {
                    setHotKeysDisplay(false);
                }, 10000)
            }
        })
        bindKeys({HIDE_HELP: {sequence: "command", action: "keyup"}}, {
            HIDE_HELP: (event: any) => {
                event.preventDefault();
                setHotKeysDisplay(false);
            }
        })
        bindKeys({SHOW_SEARCH_ANYWHERE: {sequence: "command+s", name: "Display Search Anywhere"}}, {
            SHOW_SEARCH_ANYWHERE: (event: any) => {
                event.preventDefault();
                setSearchAnywhereVisible(true);
            }
        });
        bindKeys({HIDE_SEARCH_ANYWHERE: {sequence: "esc", name: "Escape Search Anywhere"}}, {
            HIDE_SEARCH_ANYWHERE: (event: any) => {
                setSearchAnywhereVisible(false);
            }
        })
    }, [])

    return (
        <div className="min-h-screen flex flex-col">
            <nav
                className="min-h-fit max-h-10 flex h-10 gap-16 fixed w-full bg-[#2a2e34] z-10">
                <div className="flex max-h-fit items-center gap-4 pl-4">
                    <NavLink to={"/"}>
                        <img src={srcLogo} className="h-4"/>
                    </NavLink>
                    <MenuIcon/>
                </div>
                <div className="flex flex-grow items-center justify-center gap-4">
                    <MenuSearchAnywhere/>
                    <MenuCreator/>
                </div>
                <div className="flex items-center justify-center gap-4">
                    <div className="px-4">
                        <Avatar text={get(session, 'user.first_name', '')} size="xs" />
                    </div>
                </div>
            </nav>
            {/*todo: use react portal here*/}
            <FlashMessage />
            <div className="flex w-full h-full flex-grow mt-10">
                <SubSideNav state={{isCollapsed: isMenuAttached, isHovering: true}}>
                    <SubSideNavMenuLink to={"/"}>Overview</SubSideNavMenuLink>
                    <SubSideNavMenuLink to={"/crowdsourcing"}>Crowdsourcing</SubSideNavMenuLink>
                    <SubSideNavMenu menuLinkName="Search Automation">
                        {/*<SubSideNavMenuItem to={"/search-automation/overview"}>Dashboards</SubSideNavMenuItem>*/}
                        <SubSideNavMenuItem to={"/search-automation/scraping-backlog"}>Automation
                            Backlog</SubSideNavMenuItem>
                        {/*<SubSideNavMenuItem to={"/search-automation/history"}>Changes History</SubSideNavMenuItem>*/}
                    </SubSideNavMenu>
                    <SubSideNavMenu menuLinkName="Laundromat">
                        <SubSideNavMenuItem to={"/approval"}>Approval System</SubSideNavMenuItem>
                        <SubSideNavMenuItem to={"/laundromat/overview"}>Overview</SubSideNavMenuItem>
                        <SubSideNavMenuItem to={"laundromat/overview?t=bulk-upload"}>Bulk Upload</SubSideNavMenuItem>
                        <SubSideNavMenuItem to={"datapoints/manage"}>Manage Datapoints</SubSideNavMenuItem>
                    </SubSideNavMenu>
                    <SubSideNavMenu menuLinkName="Organizations Management">
                        {/*<SubSideNavMenuItem to={"/organizations/overview"}>Dashboards</SubSideNavMenuItem>*/}
                        <SubSideNavMenuItem to={"/organizations/directory"}>Organizations Directory</SubSideNavMenuItem>
                        <SubSideNavMenuItem to={"/organizations/meta"}>Metadata Management</SubSideNavMenuItem>
                    </SubSideNavMenu>
                    <SubSideNavMenu menuLinkName="Deals Management">
                        {/*<SubSideNavMenuItem to={"/deals/overview"}>Dashboards</SubSideNavMenuItem>*/}
                        <SubSideNavMenuItem to={"/deals/directory"}>Deals Directory</SubSideNavMenuItem>
                    </SubSideNavMenu>
                    <SubSideNavMenu menuLinkName={"Initiatives Management"}>
                        {/*<SubSideNavMenuItem to={"/initiatives/overview"}>Dashboards</SubSideNavMenuItem>*/}
                        <SubSideNavMenuItem to={"/initiatives/events"}>Events Management</SubSideNavMenuItem>
                        {/*<SubSideNavMenuItem to={"/initiatives/cfa"}>CFA Management</SubSideNavMenuItem>*/}
                    </SubSideNavMenu>
                    <SubSideNavMenu menuLinkName="People Management">
                        {/*<SubSideNavMenuItem to={"/people/overview"}>Dashboards</SubSideNavMenuItem>*/}
                        <SubSideNavMenuItem to={"/people/directory"}>People Directory</SubSideNavMenuItem>
                    </SubSideNavMenu>
                    {/*<SubSideNavMenu menuLinkName="Jobs Management">*/}
                    {/*    <SubSideNavMenuItem to={"/jobsad/overview"}>Dashboards</SubSideNavMenuItem>*/}
                    {/*    <SubSideNavMenuItem to={"/jobsad/directory"}>Jobs Directory</SubSideNavMenuItem>*/}
                    {/*</SubSideNavMenu>*/}
                    {/*<SubSideNavMenu menuLinkName="IP Management">*/}
                    {/*    <SubSideNavMenuItem to={"/ipmgm/overview"}>Dashboards</SubSideNavMenuItem>*/}
                    {/*    <SubSideNavMenuItem to={"/ipmgm/directory"}>IP Directory</SubSideNavMenuItem>*/}
                    {/*</SubSideNavMenu>*/}
                    <SubSideNavMenu menuLinkName="Media Mentions">
                        <SubSideNavMenuItem to={"/media-mentions/overview"}>Dashboards</SubSideNavMenuItem>
                        {/*<SubSideNavMenuItem to={"/media-mentions/directory"}>MM Directory</SubSideNavMenuItem>*/}
                    </SubSideNavMenu>
                    <SubSideNavMenu menuLinkName="Identity & Access" path="/iam">
                        <SubSideNavMenuItem to={"/iam/users"}>Users Management</SubSideNavMenuItem>
                        <SubSideNavMenuItem to={"/iam/policies"}>Policies & Permissions</SubSideNavMenuItem>
                    </SubSideNavMenu>
                    <SubSideNavMenuLink to={"/contents"}>Content Management</SubSideNavMenuLink>
                </SubSideNav>
                {children}
            </div>
        </div>
    )
}

export default function ({children}: { children: ReactNode }) {
    return (
        <BaseLayoutProvider>
            <BaseLayout>
                {children}
            </BaseLayout>
        </BaseLayoutProvider>
    )
}
