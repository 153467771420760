import React, {useState} from "react";
import srcLogo from "./../assets/briter-logo.png"
import {gql, useLazyQuery, useMutation} from "@apollo/client";
import {differenceInSeconds} from "date-fns";
import {Spinner} from "../interface/Spinner";
import {useAuth} from "../context/AuthContext";
import {useInterval} from "usehooks-ts";

const CHECK_AUTH = gql`
query auth {
    auth {
        id
        user {
            id
            first_name
            other_names
            email
        }
    }
}
`

const GOOGLE_URL = process.env.REACT_APP_GOOGLE_AUTH

const LoginWithGoogle = ({onSuccess, onError}) => {
    const [state, setState] = useState({
        isLogging: false,
        count: 0
    })

    const [auth, {loading, data, error, refetch}] = useLazyQuery(CHECK_AUTH);

    useInterval(() => {
        setState({...state, count: state.count + 1})
        auth().then((res) => {
            if (res.error === undefined) {
                window.location.reload()
            }
        })
    }, state.isLogging ? 1000 : null)

    return (
        <div className="w-full">
            {
                !state.isLogging && (
                    <button
                        className="bg-white border w-full rounded p-2"
                        onClick={(ev) => {
                            ev.preventDefault()
                            setState({isLogging: true, count: 1})
                            window.open(GOOGLE_URL, "_blank", "popup=true width=500 height=600")
                        }}
                    >
                        Sign in with your Briter account
                    </button>
                )
            }
            {
                state.isLogging && (
                    <Spinner message="checking..."/>
                )
            }
        </div>
    )
}

const CHECK_OTP = gql`
mutation checkOTP($id: ID!, $otp: String) {
    checkOTP(input: { id: $id, otp: $otp }) {
        id
        access_token
        expire_at
        user {
            email
            first_name
            other_names
        }
    }
}
`

const LOGIN = gql`
mutation login($username: String!, $password: String!) {
    login(input: { username: $username, password: $password }) {
        id
        otp_expire_at
        user {
            email
            first_name
        }
    }
}
`;

const Countdown = ({
                       target = 60, onExpiration = () => {
    }, suffix = null
                   }) => {
    const [counter, setCounter] = React.useState(target);
    React.useEffect(() => {
        if (counter > 0) {
            const tID = setTimeout(() => setCounter(counter - 1), 1000);
            return () => {
                clearTimeout(tID)
            }
        }
    }, [counter]);

    if (counter < 1) {
        onExpiration();
    }
    if (suffix) {
        return counter > 0 ? [counter, suffix].join(" ") : 'expired';
    }
    return counter > 0 ? counter : 'expired';
}

export default function LoginContainer() {
    const {check, session} = useAuth();
    const [credentials, setCredentials] = useState({username: '', password: ''});
    const [login, {loading, error, data, reset}] = useMutation(LOGIN);
    const [checkOTP, {error: OTPError, loading: OTPLoading}] = useMutation(CHECK_OTP);
    const [state, setState] = useState({
        sID: null,
        first_name: '',
        OTP: '',
        otp_expire: null
    })

    const duration = state.otp_expire ? differenceInSeconds(new Date(state.otp_expire), new Date()) : 0;

    if (session.id) {
        return (
            <p>Authenticated</p>
        )
    }
    return (
        <div className="flex flex-row min-h-screen">
            <div className="w-full lg:w-1/3">
                <div className="flex flex-col p-10 md:p-20 justify-between min-h-full">
                    <img src={srcLogo} className="w-28"/>
                    <div className="flex-grow pt-10">
                        {
                            state.sID === null && (
                                <>
                                    <h1 className="font-medium text-4xl pt-5 pb-2">Hey, Welcome Back!</h1>
                                    <p className="pb-10 text-sm text-gray-500">In case of any issue, please shoot a message
                                        in the Slack channel</p>
                                </>
                            )
                        }
                        {
                            state.sID === null && (
                                <div className="flex flex-col flex-grow items-center">
                                    <LoginWithGoogle onSuccess={() => alert("Success")} onError={() => alert("Error!")}/>
                                    <p className="py-5">OR</p>
                                </div>
                            )
                        }
                        {
                            state.sID !== null && (
                                <>
                                    <h1 className="font-medium text-4xl pt-5 pb-2">{state.first_name}, please verify it's
                                        you.</h1>
                                    <p className="pb-10 text-sm text-gray-500">We have sent you an OTP using your preferred
                                        communication channel.</p>
                                </>
                            )
                        }

                        {error && (
                            <p className="p-2 mb-5 text-sm text-red-900 rounded bg-red-200">{error.message}</p>
                        )}
                        {
                            (loading || OTPLoading) && (
                                <Spinner message="checking..."/>
                            )
                        }
                        {
                            state.sID === null && !loading && (
                                <form className="flex flex-col gap-5" onSubmit={(ev) => {
                                    ev.preventDefault();
                                    login({variables: {...credentials}}).then(r => setState({
                                        sID: r.data.login.id,
                                        first_name: r.data.login.user.first_name,
                                        otp_expire: r.data.login.otp_expire_at
                                    }));
                                }}>
                                    <div>
                                        <label className="block mb-2 text-xs uppercase text-gray-900"
                                               htmlFor="username">Username</label>
                                        <input
                                            className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                            type="text"
                                            onChange={(ev) => setCredentials({...credentials, username: ev.target.value})}
                                        />
                                    </div>
                                    <div>
                                        <label className="block mb-2 text-xs uppercase text-gray-900"
                                               htmlFor="password">Password</label>
                                        <input
                                            className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                            type="password"
                                            onChange={(ev) => setCredentials({...credentials, password: ev.target.value})}
                                        />
                                    </div>
                                    <button className="bg-yellow-500 w-full rounded p-2">Continue</button>
                                </form>
                            )
                        }
                        {
                            state.sID !== null && (
                                <div>
                                    {
                                        OTPError && (
                                            <p className="p-2 mb-5 text-sm text-red-900 rounded bg-red-200">{OTPError.message}</p>
                                        )
                                    }
                                    <form onSubmit={(ev) => {
                                        ev.preventDefault()
                                        checkOTP({variables: {id: state.sID, otp: state.OTP}})
                                            .then((r) => {
                                                check();
                                            })
                                    }} className="flex flex-col gap-5">
                                        <div className="relative">
                                            <span
                                                className="absolute text-sm inset-y-0 text-gray-500 right-0 flex items-center pr-5">
                                                <Countdown target={duration} onExpiration={() => {
                                                }} suffix="seconds"/>
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control block w-full px-5 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                                onChange={(ev) => {
                                                    setState({...state, OTP: ev.target.value})
                                                }}
                                            />
                                        </div>
                                        <button className="bg-yellow-500 w-full rounded p-2">Log in</button>
                                    </form>
                                </div>
                            )
                        }
                    </div>
                    <p className="text-sm text-gray-500">&#169; Briter Bridges 2022</p>
                </div>
            </div>
            <div className="hidden lg:block lg:w-2/3 bg-gray-900">
                Right Login
            </div>
        </div>
    )
}