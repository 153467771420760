import {gql} from "@apollo/client";

export const MUTATE_CLAIM_BY_ID = gql`
    mutation updateClaimByID($reason: String!, $status: Int!, $id: ID!) {
        claimUpdate(status_reason: $reason, status: $status, claim_id: $id) {
            id
            status
            status_reason
        }
    }
`

export const FETCH_CLAIM_BY_ID = gql`
    query getClaimByID ($id: ID!) {
        claim(id: $id) {
            id
            updated_at
            organization {
                id
                name
                profile_image_url
                claimers {
                    id
                    user {
                        id
                        first_name
                        other_names
                        email
                        profile_image_url
                    }
                }
            }
            user {
                id
                other_names
                first_name
                profile_image_url
                email
                claims {
                    id
                    status
                    status_reason
                    organization {
                        id
                        name
                        profile_image_url
                    }
                }
            }
            status
            status_reason
        }
    }
`

export const FETCH_PENDING_CLAIMS = gql`
    query getPendingClaims {
        claims(status: [0]) {
            data {
                id
                updated_at
                created_at
                status
                organization {
                    id
                    name
                    profile_image_url
                }
                user {
                    id
                    email
                    first_name
                    other_names
                    profile_image_url
                }
            }
        }
    }
`

export const FETCH_FULFILLED_CLAIMS = gql`
    query getFulfilledClaims {
        claims(status: [-1,1]) {
            data {
                id
                updated_at
                created_at
                status
                status_reason
                organization {
                    id
                    name
                    profile_image_url
                }
                user {
                    id
                    email
                    first_name
                    other_names
                    profile_image_url
                }
            }
        }
    }
`