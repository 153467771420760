import React, {createContext, ReactNode} from "react";

export interface TabsContext {
    tabs: any;
    activeKey: string;
}

const TabsContext = createContext<TabsContext>({} as TabsContext);

export function TabsContextProvider({children}: { children: ReactNode }) {

    return (
        <TabsContext.Provider value={{} as TabsContext}>
            {children}
        </TabsContext.Provider>
    )
}
