export function isURL(str: string): [boolean, URL | null] {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

    try {
        if (pattern.test(str)) {
            return [pattern.test(str), new URL(str)];
        }
    } catch (e) {
        return [pattern.test(str), null]
    }

    return [false, null];
}

export const isValidUrl = (url: string) : [boolean, URL | null] => {
    try {
        new URL(url);
    } catch (e) {
        return [false, null];
    }
    return [true, new URL(url)];
};