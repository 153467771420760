import { IDataGridArrayCell } from "./DataGridTypes";
import { useDataContainerContext } from "../DataContainer/context/DataContainerContext";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { join } from "lodash";
import { DropdownOpt } from "@briterbridges/elements";
import { EditableCell } from "../EditableCell";
import { useFlash } from "../../context/FlashContext";

const QUERY_FETCH_SUB_SECTOR_BY_ID = gql`
    query fetchSubSectorById($id: ID) {
        sub_sectors(id: $id) {
            id
            name
        }
    }
`

const FETCH_PEOPLE = gql`
    query fetchPeople($limit: Int) {
        people(limit: $limit) {
            id
            first_name
            other_names
        }
    }
`

const MUTATION_UPDATE_ENTITY = gql`
    mutation updateEntity($input: UpdateEntityRequest) {
        updateEntity(input: $input) {
            id
            entity
        }
    }
`

const QUERY_FETCH_SECTORS = gql`
    query fetchSectors($filters: FiltersInput,$sort: SortInput,$limit: Int,$offset: Int) {
        sectors(filters: $filters,sort: $sort,limit: $limit,offset: $offset) {
            data {
                id
                name
                enabled
                is_sub_sector
            }
            count
            pages
            current
        }
    }
`

const QUERY_FETCH_SUB_SECTORS = gql`
    query fetchSubSectors($filters: FiltersInput,$sort: SortInput,$limit: Int,$offset: Int) {
        sub_sectors(filters: $filters,sort: $sort,limit: $limit,offset: $offset) {
            data {
                id
                name
                enabled
            }
            count
            pages
            current
        }
    }
`

const QUERY_FETCH_TECHNOLOGIES = gql`
    query fetchTechnologies($filters: FiltersInput,$sort: SortInput,$limit: Int,$offset: Int) {
        technologies(filters: $filters,sort:$sort,limit:$limit,offset: $offset) {
            data {
                id 
                name 
            }
            count
        }
    }
`

const QUERY_FETCH_SDGS = gql`
    query fetchSDGS($filters: FiltersInput,$sort: SortInput,$limit: Int,$offset: Int) {
      sdgs(filters: $filters,sort:$sort,limit: $limit,offset:$offset) {
        data {
          id
          name
        }
        count
      }
    }
`

// refactor this
export const DataGridArrayCell = ({ value, initialData, displayNumber = 1, fetch, options, query, colInfo }: IDataGridArrayCell): JSX.Element => {

    const { applyFilters } = useDataContainerContext();
    const flash = useFlash();

    const [getSectors, { loading: loadingSectors, error: sectorsError, data: sectorsData }] = useLazyQuery(QUERY_FETCH_SECTORS);
    const [getSubsectors, { loading: loadingSubsectors, error: subSectorsError, data: subSectorsData }] = useLazyQuery(QUERY_FETCH_SUB_SECTORS)
    const [getTechnologies, { loading: loadingTechnologies, error: technologiesError, data: technologiesData }] = useLazyQuery(QUERY_FETCH_TECHNOLOGIES)
    const [getSDGS, { loading: loadingSDGS, error: sdgsError, data: sdgsData }] = useLazyQuery(QUERY_FETCH_SDGS)
    const [getPeople, { data: peopleData }] = useLazyQuery(FETCH_PEOPLE)
    const [update, { data, loading, error }] = useMutation(MUTATION_UPDATE_ENTITY)

    const [state, setState] = useState({ isHovering: false });
    const [editable, setEditable] = useState(false)
    const [opts, setOpts] = useState<DropdownOpt[]>([])
    const [selectedValues, setSelectedValues] = useState<any[]>([])

    const getAllSDGS = () => {
        getSDGS({
            variables: {
                sort: {
                    field: "name",
                    order: "asc"
                },
                limit: 1,
                offset: 0
            }
        }).then((res) => {
            const count = res.data.sdgs.count
            getSDGS({
                variables: {
                    sort: {
                        field: "name",
                        order: "asc"
                    },
                    limit: count,
                    offset: 0
                }
            }).then((res) => {
                console.log("res", res)
                // build the options now
                const s = res.data.sdgs.data
                setOpts(s.map((it: any) => {
                    return {
                        id: it.id,
                        value: it.name
                    }
                }))
            }).catch((err) => {
                console.log("err", err)
            })
        }).catch((err) => {
            console.log("err", err)
        })
    }

    const getAllTechnologies = () => {
        getTechnologies({
            variables: {
                sort: {
                    field: "name",
                    order: "asc"
                },
                limit: 1,
                offset: 0
            }
        }).then((res) => {
            console.log("res", res)
            // get the count here and make another query to get all the technologies
            const s = res.data.technologies
            const count = s.count ? s.count : 0
            console.log("technologies count", count)

            getTechnologies({
                variables: {
                    sort: {
                        field: "name",
                        order: "asc"
                    },
                    limit: count,
                    offset: 0
                }
            }).then((res) => {
                const t = res.data.technologies.data
                setOpts(t.map((it: any) => {
                    return {
                        id: it.id,
                        value: it.name
                    }
                }))
            }).catch((err) => console.log("err", err))
        }).catch(err => {
            console.log("err", err)
        })
    }

    const getAllSubSectors = () => {
        getSubsectors({
            variables: {
                sort: {
                    field: "name",
                    order: "desc",
                },
                limit: 1,
                offset: 0
            }
        }).then((res: any) => {

            const s = res.data.sub_sectors
            const count = s.count ? s.count : 0

            getSubsectors({
                variables: {
                    sort: {
                        field: "name",
                        order: "desc",
                    },
                    limit: count,
                    offset: 0
                }
            }).then((res: any) => {
                const s = res.data.sub_sectors.data
                setOpts(s.map((it: any) => {
                    return {
                        id: it.id,
                        value: it.name
                    }
                }))

            }).catch((err: any) => {
                console.log("got an error from countable subsectors", err)
            })

        }).catch((err: any) => {
            console.log("err", err)
        })
    }

    const getAllSectors = () => {
        // todo : get the count and make another query to get all the sectors we are not getting all the sectors
        getSectors({
            variables: {
                sort: {
                    field: "name",
                    order: "desc",
                },
                limit: 70,
                offset: 3
            }
        }
        ).then((res: any) => {
            console.log("test", res.data.count)
            const s = res.data.sectors.data
            setOpts(s.map((it: any) => {
                return {
                    id: it.id,
                    value: it.name
                }
            }))
        });
    }

    const getAllPeople = () => {
        // todo : get the count of all people and return their names
        console.log("getting people")
        getPeople({
            variables: {
                limit: 10,
            }
        }).then((res) => {
            console.log("res", res)
            const s = res.data.people
            setOpts(s.map((it: any) => {
                return {
                    id: it.id,
                    value: it.first_name + " " + it.other_names
                }
            }))
        }).catch((err) => {
            console.log("err", err)
        })
    }

    const handleDoubleClick = (e: React.MouseEvent<HTMLDivElement>) => {

        // todo: build from the cell options 
        setEditable(true)

        const property = colInfo ? colInfo.key : ""

        // todo: use the type to get cell options
        const type = colInfo ? colInfo.type : ""

        switch (property) {
            case "technologies":
                getAllTechnologies()
                // get the count 
                break;
            case "sub_sectors":
                console.log("fetching sub_sectors")
                getAllSubSectors()
                break;
            case "sectors":
                console.log("fetching sectors")
                getAllSectors();
                break;
            case "sdgs":
                getAllSDGS();
                break;
            case "employees":
                getAllPeople()
                break;
            default:
                setEditable(false)
                console.log("property not handled");
                break;
        }
    }

    const handleChange = (changes: any) => {
        setSelectedValues(changes)
    }

    const handleCancel = () => {
        setEditable(false)
    }

    const handleSave = () => {
        switch (colInfo?.key) {
            case "sub_sectors":
                console.log("saving subsectors....")
                saveData("sub_sectors")
                break;
            case "sectors":
                console.log("saving sectors....")
                saveData("sectors")
                break;
            case "technologies":
                saveData("technologies")
                break;
            case "sdgs":
                console.log("saving sdgs.....")
                saveData("sdgs")
                break;
            default:
                console.log("uknown query")
                break;
        }
    }

    const saveData = (property: string) => {
        const payload = selectedValues?.map((it) => it.id)
        try {
            update({
                variables: {
                    input: {
                        id: initialData.id,
                        entity: "organization",
                        payload: payload,
                        property: property,
                        type: "list"
                    }
                },
            }).then((res: any) => {
                setEditable(false)
                applyFilters()
                flash("entity updated successfully", "success")
            })
        } catch (err) {
            flash("failed to update entity", "error")
        }
    };

    useEffect(() => {
    }, [editable])


    useEffect(() => {
        // @ts-ignore
        const initialValues = value.map((v) => {
            // @ts-ignore
            return {
                // @ts-ignore
                id: v.id,

                // @ts-ignore
                value: v.name
            }
        });
        setSelectedValues(initialValues)
    }, [value])

    if(colInfo?.dataIndex === "employees") {
        console.log("employees",value)
    }

    return (
        <div
            style={{
                display: "flex",
                alignItems: editable ? "" : "center",
                width: "100%",
                height: "100%"
            }}
            onMouseEnter={() => setState({ isHovering: true })}
            onMouseLeave={() => { setState({ isHovering: false }) }}
            onDoubleClick={handleDoubleClick} >

            {editable && (
                <EditableCell
                    type={colInfo === undefined ? "text" : colInfo.type}
                    onCancel={handleCancel}
                    onSave={handleSave}
                    defaultValue={selectedValues}
                    options={opts}
                    onChange={handleChange}
                />
            )
            }

            {!editable && (<CellWrapper isHovering={state.isHovering} values={value} displayNumber={displayNumber} key={colInfo?.key} />)}
        </div>
    )
}


export interface CellWrapperProps {
    isHovering: boolean
    values?: any
    displayNumber: any
    key?: string
}

export const CellWrapper = ({ ...props }: CellWrapperProps) => {
    const { isHovering, values, displayNumber, key } = props
    return (
        <div>
            {(!isHovering || values.length < 2)
                ?
                <div className="relative px-1.5">
                    {values.map((it: any) => it.name).slice(0, displayNumber).join(", ")} {
                        values.length > displayNumber &&
                        <span
                            className="absolute bg-bs-light-gray text-white px-2 py-1 ml-2 text-xs rounded-md">
                            +{values.length - displayNumber}
                        </span>
                    }
                </div>
                :
                <div className="relative bg-white px-1.5 border border-r-0"
                    style={{ boxShadow: "-5px 0px 5px -3px rgba(0,0,0,0.41)" }}>
                    {values.map((it: any) => it.name).slice(0, displayNumber).join(", ")},
                    <div
                        className="absolute inline-block whitespace-nowrap ml-1 -mt-[0.05rem] bg-white px-1  border border-l-0"
                        style={{ boxShadow: "5px 0px 5px -3px rgba(0,0,0,0.41)" }}>
                        {values.map((it: any) => it.name).slice(displayNumber, values.length).join(", ")}
                    </div>
                </div>
            }
        </div>
    )
}

export interface CellContainerProps {
    colInfo: any
    editable: boolean
    onMouseEnter: any
    onMouseLeave: any
    onDoubleClick: any
    options: any
    onCancel: any
    onSave: any
    onChange: any
    selectedValue: any
    value: any
}

export const CellContainer = ({ ...props }: CellContainerProps) => {
    const { colInfo,editable,onMouseEnter,onMouseLeave,onDoubleClick,options,onCancel,onSave ,onChange,selectedValue,value} = props

    const [state,setState] = useState({isHovering: false})

    return (
        <div>
            <div
                style={{
                    display: "flex",
                    alignItems: editable ? "" : "center",
                    width: "100%",
                    height: "100%"
                }}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                onDoubleClick={onDoubleClick} >

                {editable && (
                    <EditableCell
                        type={colInfo === undefined ? "text" : colInfo.type}
                        onCancel={onCancel}
                        onSave={onSave}
                        defaultValue={selectedValue}
                        options={options}
                        onChange={onChange}
                    />
                )
                }

                {!editable && (<CellWrapper isHovering={state.isHovering} values={value} displayNumber={value} key={colInfo?.key} />)}
            </div>
        </div>
    )
}
