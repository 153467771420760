import {
    DataContainerProvider,
} from "../../interface/DataContainer/context/DataContainerContext";
import {Tabs} from "../../interface/Tabs/Tabs";
import {filters} from "./filters";
import {BulkUploadContainer} from "./fragments/BulkUploadContainer";
import {BulkApprovalContainer} from "./fragments/BulkApprovalContainer";
import {MissingDatapointsContainer } from "./fragments/MissingDatapointsContainer";
import {LastUpdatedFields} from "./fragments/DatapointsHistoryContainer";

// todo: create a better data grid which supports editable cells

export default function LaundromatOverviewContainer() {

    const nav = {
        "missing-datapoints": {
            title: 'Missing Datapoints',
            key: 'missing-datapoints',
            pane: <MissingDatapointsContainer/>
        },
        "last-updated-fields": {
            title: 'Last Updated Fields',
            key: 'last-updated-fields',
            pane: <LastUpdatedFields/>
        },
        "bulk-upload": {
            title: 'Bulk upload',
            key: 'bulk-upload',
            pane: <BulkUploadContainer />
        },
    }

    return (
        <DataContainerProvider filters={filters}>
            <div className="flex-grow flex flex-row">
                <div className="flex-grow">
                    <Tabs
                        index={'missing-datapoints'}
                        tabs={nav}
                    />
                </div>
            </div>
        </DataContainerProvider>
    )
}




