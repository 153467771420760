import React from "react";
import classNames from "classnames";

type TFieldsetDirection = "horizontal" | "vertical";

interface IFieldset {
    children: React.ReactNode;
    direction: TFieldsetDirection;
    title?: string;
    status?: boolean;
    collapsable?: boolean;
    style?: React.CSSProperties
}

export function Fieldset({...props}: IFieldset) {
    const {direction, title, children, collapsable, style} = props;
    const cls = classNames({
        "flex flex-grow gap-5": true,
        "flex-row": direction === "horizontal",
        "flex-col": direction === "vertical",
        "pb-5": title == undefined
    })
    return (
        <div className="flex-grow mb-2.5 p-2.5" style={{...style}}>
            <div className="flex flex-grow">
                {title && (
                    <>
                        <a href={`#${title}`} aria-hidden="true"/>
                        <p className="text-md uppercase text-bs-light-black pb-5 flex-grow font-semibold">{title}</p>
                    </>
                )}
                {collapsable && (
                    <span>

                    </span>
                )}
            </div>
            <fieldset className={cls}>
                {children}
            </fieldset>
        </div>
    )
}