import React, {useEffect, useMemo, useState} from "react"
import {Controller, FormProvider, useForm} from "react-hook-form";
import {CheckboxInput, DateInput, TextInput} from "../../interface/Form/Input/Inputs";
import {MultiSelectComboBox} from "../../interface/Form/Select/Select";
import {forEach, get, has} from "lodash";
import {gql, useMutation, useQuery} from "@apollo/client";
import {WYSIWYGAreaEditorInput} from "../../interface/Form/WYSIWYGAreaEditorInput";
import {RESOURCE_ARTICLE_BY_ID, RESOURCE_ARTICLE_CREATE, RESOURCE_ARTICLE_UPDATE,} from "../../queries";
import slugify from "slugify";
import {FiPlus} from "react-icons/fi";
import {useNavigate, useParams} from "react-router";
import {useFlash} from "../../context/FlashContext";
import {formatISO, isValid} from "date-fns";
import {CreateResourceAuthorContainerWidget} from "./CreateResourceAuthorContainer";
import {Portal} from "./CreateResourceUploadContainer";
import {InputImage} from "../../interface/Form/Input";
import {CreateResourceTagsWidget} from "./CreateResourceTagsContainer";

const FETCH_DROPDOWN_OPTIONS_FORM = gql`
    query fetchDropDownOptions {
        sectors(limit: 10000) {
            data {
                id
                value: name
            }
        }
        getResourceTypes {
            value: name
            id
        }
        getResourceAuthors {
            value: display_name
            id
        }
        agbase_categories(limit: 10000) {
            data {
                id
                value: name
            }
        }
        agbase_visibility(limit: 10000) {
            data {
                id
                value: name
            }
        }
        agbase_valuechains(limit: 10000) {
            data {
                id
                value: name
            }
        }
        business_models(limit: 10000) {
            data {
                id
                value: name
            }
        }
        sub_sectors(limit: 10000) {
            data {
                id
                value: name
            }
        }
        countries(limit: 1000) {
            data {
                id
                value: name
            }
        }
        cities(limit: 1000) {
            data {
                id
                value: name
            }
        }
        sdgs(limit: 1000) {
            data {
                id
                value: name
            }
        }
        technologies(limit: 1000) {
            data {
                id
                value: name
            }
        }
        getResourceTags {
            id
            value: name
        }
    }
`


const CreateArticleForm = ({article = {}, onSubmit, onSubmitLoading = false}: any) => {
    const {data: opts, loading, refetch} = useQuery(FETCH_DROPDOWN_OPTIONS_FORM, {
        fetchPolicy: "network-only",
    })
    const [isEditingSlug, setIsEditingSlug] = useState<boolean>(false)
    const [isAddingTags, setIsAddingTags] = useState<boolean>(false)
    const methods = useForm({
        defaultValues: useMemo(() => {
            return {
                image_preview: "",
                ...article
            };
        }, [article])
    })
    const [isAdding, setIsAdding] = useState<boolean>(false)

    useEffect(() => {
        if (has(article, "id")) {
            methods.reset(article);
        }
    }, [article]);

    useEffect(() => {
        const subscription = methods.watch((value, {name, type}) => {
            if (name === "title" && type === "change" && get(value, 'id', undefined) === undefined) {
                methods.setValue("slug", slugify(methods.watch("title") ?? "", {
                    trim: true, lower: true, strict: true
                }))
            }
        });
        return () => subscription.unsubscribe();
    }, [methods.watch]);

    const status = methods.watch("status")

    if (loading) {
        return <p>Loading...</p>
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="flex items-center gap-4 flex-grow pb-4">
                    <div className="w-2/3">
                    {
                        article.id && (
                            <p className="grow uppercase font-medium inline-flex flex-col">
                                <span>{article.title}</span>
                            </p>
                        )
                    }
                    {
                        !article?.id && (
                            <p className="grow uppercase font-medium text-lg">Create a new article</p>
                        )
                    }
                    </div>
                    <div className="flex justify-end w-1/3 gap-8">
                        {
                            // !article.id && (
                            //     <button
                            //         type="submit"
                            //         disabled={onSubmitLoading}
                            //         className="rounded-md text-bs-light-black border border-bs-light-gray font-medium px-4 py-0.5">
                            //         Save in draft
                            //     </button>
                            // )
                        }
                        <button
                            type="submit"
                            disabled={onSubmitLoading}
                            className="rounded-md bg-bs-light-primary border border-bs-light-border-primary px-4 py-0.5">
                            {article.id ? "Update" : "Publish"}
                        </button>
                    </div>
                </div>
                <div className="flex gap-12 flex-grow">
                    <div className="flex gap-4 flex-col py-4 flex-grow w-2/3">
                        <TextInput
                            placeholder={"Enter the title of the resource"}
                            type={"title"}
                            register={methods.register}
                            name={"title"}
                            label={""}/>
                        {
                            !isEditingSlug && (
                                <p>
                                    <span className="text-sm px-1 rounded-md">URL</span> <span
                                    className="text-gray-500">ex: https://briterbridges.com/</span>
                                    <span className="font-medium">{methods.watch("slug")}</span>
                                    <button type="button"
                                            className="mx-4 px-2 rounded-md py-0.5 text-xs bg-bs-light-primary"
                                            onClick={(ev) => {
                                                ev.preventDefault()
                                                setIsEditingSlug(true)
                                            }}
                                    >edit
                                    </button>
                                    <button type="button"
                                            className="mx-4 px-2 rounded-md py-0.5 text-xs bg-bs-light-black text-white"
                                            onClick={(ev) => {
                                                if (confirm("Are you sure you want to override the URL?")) {
                                                    methods.setValue("slug", slugify(methods.watch("title") ?? "", {
                                                        trim: true, lower: true, strict: true
                                                    }))
                                                }
                                            }}
                                    >regenerate
                                    </button>
                                </p>
                            )
                        }
                        <TextInput
                            placeholder={"Enter the title of the resource"}
                            type={isEditingSlug ? "text" : "hidden"}
                            register={methods.register}
                            name={"slug"}
                            label={""}/>
                        <div className="flex items-end gap-4 relative">
                            <div className="flex-grow">
                                <MultiSelectComboBox
                                    {...methods.register("authors")}
                                    name={"authors"}
                                    label={"Author(s)"} control={methods.control}
                                    options={get(opts, "getResourceAuthors", [])}
                                />
                            </div>
                            <button
                                className="py-3 bg-bs-light-primary border border-bs-light-border-primary font-semibold border-solid px-4 flex gap-2 items-center rounded-md"
                                type="button"
                                onClick={(ev) => {
                                    ev.preventDefault()
                                    setIsAdding(true)
                                }}
                            >
                                <FiPlus/>
                            </button>
                            {
                                isAdding && (
                                    //@ts-ignore
                                    <Portal>
                                        <CreateResourceAuthorContainerWidget
                                            onSuccess={() => {
                                                setIsAdding(false)
                                                refetch()
                                            }}
                                            onCancel={() => setIsAdding(false)}
                                        />
                                    </Portal>
                                )
                            }
                        </div>
                        <div className="flex items-end gap-4 relative">
                            <div className="flex-grow">
                                <MultiSelectComboBox
                                    {...methods.register("tags")}
                                    name={"tags"}
                                    label={"Tags"} control={methods.control}
                                    options={get(opts, "getResourceTags", [])}/>
                            </div>
                            <button
                                className="py-3 bg-bs-light-primary border border-bs-light-border-primary font-semibold border-solid px-4 flex gap-2 items-center rounded-md"
                                type="button"
                                onClick={(ev) => {
                                    ev.preventDefault()
                                    setIsAddingTags(true)
                                }}
                            >
                                <FiPlus/>
                            </button>
                            {
                                isAddingTags && (
                                    //@ts-ignore
                                    <Portal>
                                        <CreateResourceTagsWidget
                                            onSuccess={() => {
                                                setIsAddingTags(false)
                                                refetch()
                                            }}
                                            onCancel={() => setIsAddingTags(false)}
                                        />
                                    </Portal>
                                )
                            }
                        </div>
                        <div className="flex flex-col gap-1 flex-grow">
                            <Controller
                                name="body"
                                control={methods.control}
                                render={({field}) => {
                                    return (
                                        <WYSIWYGAreaEditorInput
                                            value={field.value} onChange={field.onChange}
                                            classNameContentEditable="h-full shadow-md border border-gray-100 border-solid"
                                        />
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-col grow w-1/3 gap-4">
                        {
                            isValid(new Date(methods.watch("published_at"))) && status === 1 && (
                                <>
                                    <div className="flex flex-col gap-1">
                                        <p className="text-sm">Status: <span
                                            className="font-medium bg-green-300 rounded-md px-2 py-0.5">Published</span></p>
                                        <p className="text-sm">Published: <span
                                            className="font-medium">{formatISO(new Date(methods.getValues("published_at")))}</span>
                                        </p>
                                    </div>
                                    <hr/>
                                </>
                            )
                        }
                        {
                            status !== 1 && (
                                <DateInput
                                    type={"date"}
                                    register={methods.register}
                                    name={"published_at"}
                                    label={"Publishing Date"}
                                    methods={methods}
                                />
                            )
                        }
                        <CheckboxInput
                            register={methods.register}
                            label={"Only premium users"}
                            name={"is_premium"}
                        />
                        <MultiSelectComboBox
                            {...methods.register("resource_type")}
                            name={"resource_type"}
                            label={"Resource Type"} control={methods.control}
                            options={get(opts, "getResourceTypes", [])}/>

                        <InputImage
                            label="Cover Image (JPEG/PNG)"
                            getValues={methods.getValues}
                            setValue={(v: string) => methods.setValue("image_preview", v)}
                            {...methods.register("image_preview", {
                                required: {value: true, message: "Image preview is mandatory."}
                            })}
                            name="image_preview"
                            path="images/articles"
                            type={"file"} required={true}
                        />

                        <hr className="border-gray-300"/>

                        <MultiSelectComboBox {...methods.register("agbase_category")}
                                             name={"agbase_category"}
                                             label={"AgBase Category"} control={methods.control}
                                             options={get(opts, "agbase_categories.data", [])}/>

                        <MultiSelectComboBox {...methods.register("agbase_value_chain")}
                                             name={"agbase_value_chain"}
                                             label={"AgBase Value Chain"} control={methods.control}
                                             options={get(opts, "agbase_valuechains.data", [])}/>

                        <MultiSelectComboBox {...methods.register("agbase_visibility")}
                                             name={"agbase_visibility"}
                                             label={"AgBase Visibility"} control={methods.control}
                                             options={get(opts, "agbase_visibility.data", [])}/>

                        <MultiSelectComboBox {...methods.register("sectors")} name={"sectors"}
                                             label={"Sectors"} control={methods.control}
                                             options={get(opts, "sectors.data", [])}/>

                        <MultiSelectComboBox {...methods.register("sub_sectors")} name={"sub_sectors"}
                                             label={"Sub Sectors"} control={methods.control}
                                             options={get(opts, "sub_sectors.data", [])}/>

                        <MultiSelectComboBox {...methods.register("technologies")} name={"technologies"}
                                             label={"Technologies"} control={methods.control}
                                             options={get(opts, "technologies.data", [])}/>

                        <MultiSelectComboBox {...methods.register("ops_countries")}
                                             name={"ops_countries"}
                                             label={"Operating Countries"} control={methods.control}
                                             options={get(opts, "countries.data", [])}/>
                        <MultiSelectComboBox {...methods.register("sdgs")}
                                             name={"sdgs"}
                                             label={"SDGs"} control={methods.control}
                                             options={get(opts, "sdgs.data", [])}/>
                    </div>
                </div>
            </form>
        </FormProvider>
    )
}

export const CreateResourceArticleContainer = () => {

    const [create, {error}] = useMutation(RESOURCE_ARTICLE_CREATE)
    const flash = useFlash()
    const navigate = useNavigate()

    const onSubmit = (data: any) => {
        delete data?.id
        delete data?.history
        data.status = 1

        data.published_at = formatISO(new Date(data.published_at))
        const remaps = [
            'resource_type', 'tags', 'authors', 'sectors', 'sub_sectors', 'technologies', 'ops_countries',
            'sdgs', 'agbase_category', 'agbase_visibility', 'agbase_value_chain'
        ]
        forEach(remaps, (remap: any) => {
            if (get(data, remap, []) != null) {
                data[remap] = get(data, remap, []).map((t: any) => t.id)
            }
        })

        create({
            variables: {
                resource: {...data}
            }
        }).then(r => {
            flash(`${data.title} created successfully`, "success")
            setTimeout(() => {
                navigate("/contents")
            }, 500)
        })
    }

    return (
        <div className="w-full px-10 py-8">
            <div className="flex-grow">
                <CreateArticleForm onSubmit={onSubmit}/>
            </div>
        </div>
    )
}

export const UpdateResourceArticleContainer = () => {
    const params = useParams()
    const flash = useFlash()
    const {data, loading, error} = useQuery(RESOURCE_ARTICLE_BY_ID, {
        variables: {
            id: params.id
        },
        onError: err => flash(err.message, "error"),
        fetchPolicy: "network-only"
    })
    const [updateResourceArticle] = useMutation(RESOURCE_ARTICLE_UPDATE)

    const onSubmit = (data: any) => {
        delete data?.id
        delete data?.history
        data.status = 1

        data.published_at = formatISO(new Date(data.published_at))
        const remaps = [
            'resource_type', 'tags', 'authors', 'sectors', 'sub_sectors', 'technologies', 'ops_countries',
            'sdgs', 'agbase_category', 'agbase_visibility', 'agbase_value_chain'
        ]
        forEach(remaps, (remap: any) => {
            if (get(data, remap, []) != null) {
                data[remap] = get(data, remap, []).map((t: any) => t.id)
            }
        })

        updateResourceArticle({
            variables: {
                id: params.id,
                resource: data
            }
        })
            .then(r => {
                flash(`${data.title} successfully updated`, "success")
            })
            .catch((err) => flash(err.message, "error"))
    }

    return (
        <div className="w-full px-10 py-8">
            <div className="flex-grow">
                {
                    !loading && (
                        <CreateArticleForm
                            article={get(data, 'getResourceArticleByID', {})}
                            onSubmit={onSubmit}
                        />
                    )
                }
            </div>
        </div>
    )
}