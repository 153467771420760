import React from "react";
import {DataExportProvider, useDataExportContext} from "./context/DataExportContext";
import {DropdownPopup} from "./elements/DropdownPopup";

const columnsAvailable = [
    { key: "id", value: "ID" },
    { key: "name", value: "Name" },
    { key: "description", value: "Description" },
    { key: "sectors.name", value: "Sector Name" },
    { key: "sectors.id", value: "Sector ID#" },
]

export default function DataExportSyncContainer() {
    return (
        <main className="px-4 py-4 w-full">
            <DataExportProvider>
                <DataExportNavBar />
                <DataExportMainContainer />
            </DataExportProvider>
        </main>
    )
}

const DataExportMainContainer = () => {
    const { bucket, columns, filters } = useDataExportContext()
    return (
        <div className="w-full grow justify-center items-center flex h-full">
            {
                !bucket && (
                    <p>Select a bucket</p>
                )
            }
            {
                bucket && columns.length < 1 && (
                    <p>Select now few columns to export from the {bucket} bucket</p>
                )
            }
            {
                columns.map(c => c.value).join(", ")
            }
        </div>
    )
}

const DataExportNavBar = () => {
    const { setBucket, bucket, upsertColumn } = useDataExportContext()
    return (
        <div className="w-full flex">
            <div className="grow flex gap-2 flex-col">
                <div className="flex gap-4">
                    Bucket:
                    <button onClick={() => setBucket("organizations")}>Organizations</button>
                    /
                    <button onClick={() => setBucket("deals")}>Deals</button>
                </div>
                {
                    bucket && (
                        <>
                            <DropdownPopup text="Add columns +">
                                <div className="flex flex-col items-start gap-2">
                                {
                                    columnsAvailable.map((i) => {
                                        return <button onClick={(ev) => {
                                            ev.preventDefault();
                                            upsertColumn(i)
                                        }} key={i.key}>{i.value}</button>
                                    })
                                }
                                </div>
                            </DropdownPopup>
                            <DropdownPopup text="Add filters +">
                                <p>Popup</p>
                            </DropdownPopup>
                        </>
                    )
                }
            </div>
            <div className="flex items-start">
                <button>Export</button>
            </div>
        </div>
    )
}

