import React from "react";
import { DataTableGraphQL } from "../../interface/DataTable/DataTable";
import { FETCH_SEARCH } from "../../queries";
import _, { get, join } from "lodash";
import { StatusOfflineIcon, StatusOnlineIcon } from "@heroicons/react/outline";
import { MDSection } from "../../interface/Typography";
import { Pillola } from "../../interface/Pillole";
import {
  ActionFiltersDateRange,
  ActionFiltersRefresh,
} from "../../interface/ActionFilters";
import {
  DataContainerProvider,
  useDataContainerContext,
} from "../../interface/DataContainer/context/DataContainerContext";
import { Button } from "../../interface/Button/Button";

export default function MediaMentionsOverviewContainer() {
  return (
    <div className="w-full px-10 py-8">
      <div className="flex-grow">
        <DataContainerProvider filters={[]}>
          <MediaMentionsTable />
        </DataContainerProvider>
      </div>
    </div>
  );
}

export function MediaMentionsTable() {
  const { filtersQuery, filtersActive, addFilter, removeFilter, applyFilters } =
    useDataContainerContext();
  return (
    <MDSection
      title={"Latest news from the wild"}
      subtitle={
        "These are all the latest news from disrupt-africa.com, techcrunch.com, ventureburn.com. Maximum display is 50 news."
      }
      actions={
        <div className="flex flex-grow items-center justify-end gap-5 self-end">
          <ActionFiltersDateRange
            type="range"
            addFilter={addFilter}
            removeFilter={removeFilter}
            defaultValues={_.find(filtersActive, { key: "last_modified" })}
            id={"last_modified"}
            title={""}
          />
          <ActionFiltersRefresh onClick={applyFilters} />
        </div>
      }
    >
      <DataTableGraphQL
        query={FETCH_SEARCH}
        root={"search"}
        property={"items"}
        data={[]}
        filters={filtersQuery}
        variables={{
          search: {
            bucket: "media_mentions",
            size: 5,
            query: {},
            sort: [{ key: "last_modified", dir: "desc" }],
          },
        }}
        headers={[
          { dataIndex: "last_modified", title: "Detection Time" },
          {
            dataIndex: "has_entity_analysis",
            title: "Analysed",
            render: (e: any) => {
              return e.has_entity_analysis ? (
                <StatusOnlineIcon className="h-6 w-6 text-green-500" />
              ) : (
                <StatusOfflineIcon className="h-6 w-6 text-neutral-500" />
              );
            },
          },
          {
            dataIndex: "title",
            title: "Title",
            render: (e: any) => {
              return (
                <div>
                  <p className="whitespace-nowrap pb-1">{e.title}</p>
                  <p className="flex flex-wrap gap-2">
                    {e.organizations?.map((o: any) => {
                      return <Pillola text={o.name} variant={"positive"} />;
                    })}
                  </p>
                </div>
              );
            },
          },
          {
            dataIndex: "source",
            title: "Source",
            render: (e: any) => {
              return <p className="whitespace-nowrap">{e.source}</p>;
            },
          },
          {
            dataIndex: "organizations",
            title: "Other organizations found",
            render: (e: any) => {
              return (
                <div>
                  <ShowPayload media={e} />
                </div>
              );
            },
          },
        ]}
      />
    </MDSection>
  );
}

export const ShowPayload = ({ media }: { media: any }) => {
  const entities: string[] = [];

  if (
    media.has_entity_analysis === true &&
    get(media, "analysis.openai") !== null &&
    get(media, "analysis.openai", []).length > 0
  ) {
    const org = get(
      _.first(get(media, "analysis.openai", [])),
      "ORGANIZATION",
      "",
    );
    const investors = get(
      get(media, "analysis.openai", [])[0],
      "INVESTORS",
      [],
    );
    entities.push(...investors);
    entities.push(org);
  }

  if (
    media.has_entity_analysis === true &&
    get(media, "analysis.qwen") !== null &&
    get(media, "analysis.qwen", []).length > 0
  ) {
    const org = get(
      _.first(get(media, "analysis.qwen", [])),
      "ORGANIZATION",
      "",
    );
    const investors = get(get(media, "analysis.qwen", [])[0], "INVESTORS", []);
    entities.push(...investors);
    entities.push(org);
  }

  // todo(nate): also push the entities under qwen

  return (
    <div>
      <pre>{JSON.stringify(media)}</pre>
      <p className="text-sm">
        {join(_.uniq(entities.map((entity: string) => entity)), ", ")}
      </p>
    </div>
  );
};
