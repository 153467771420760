import React from "react";
import {SimpleTimeChart} from "../../../interface/Charts/SimpleTimeChart";
import {eachDayOfInterval, format} from "date-fns";
import {MDSection} from "../../../interface/Typography";

// const data = eachDayOfInterval({
//     start: new Date('01-01-2022'), end: new Date()
// }).map((s) => [format(s, 'Y-M-d'), Math.floor(Math.random() * 100), Math.floor(Math.random() * 10)]);

const SettingsRow = ({title, row}: { title: string, row: string }) => {
    return (
        <div className="pb-2.5">
            <p className="uppercase text-sm font-semibold">{title}</p>
            <p className="text-bs-light-gray-800">{row}</p>
        </div>
    )
}

const data = eachDayOfInterval({
    start: new Date('06-01-2022'), end: new Date()
}).map((s) => ({label: format(s, 'Y-M-d'), value: Math.floor(Math.random() * 100), showValue: 0}));

export function ProfilePane({user}: { user: any }) {
    return (
        <div className="p-10 flex gap-10">
            <div className="flex-grow flex flex-col gap-10">
                <div>
                    <MDSection
                        title="Recent Activity"
                        variant="success"
                        badge="Very Active"
                        subtitle="Sum of all activities by day."
                    />
                    <SimpleTimeChart data={data}/>
                </div>
                <div className="flex-grow flex gap-10">
                    <div className="flex-grow w-1/2">
                        <MDSection title="User Preferences" variant="warning"
                                   subtitle="Most of the content in the user's feed will reflect the following interests"
                        />
                        <SettingsRow title={"Sectors"} row={"Agriculture, ESG, Climate"}/>
                        <SettingsRow title={"Geographies"} row={"No geographies has been selected"}/>
                        <SettingsRow title={"Interests"} row={"No interests has been selected"}/>
                    </div>
                    <div className="flex-grow w-1/2">
                        <MDSection title="Subscription status" variant="warning"/>
                        <div
                            className="px-5 py-2 text-sm text-yellow-700 bg-yellow-50 rounded-md border-yellow-700 border">
                            <p>Subscription will expire in 18 days</p>
                        </div>
                    </div>
                </div>
                <div className="flex gap-10">
                    <div className="flex-grow">
                        <MDSection title="Security checklist" variant="success"
                                   subtitle="List of security features enabled for this account"
                        />
                        <SettingsRow title={"Email verification status"}
                                     row={user.email_verified ? "Enabled" : "Not Verified"}/>
                        <SettingsRow title={"Multi Factor Authentication"} row={"Enabled"}/>
                        <SettingsRow title={"Last Password update"} row={"Enabled"}/>
                        <SettingsRow title={"Last authentication"} row={user.last_login}/>
                    </div>
                    <div className="flex-grow gap-4 flex flex-col">
                        <p className="text-sm uppercase text-bs-light-gray-800">Active sessions</p>
                        {
                            // @ts-ignore
                            user.sessions?.map((s: any) => {
                                return (
                                    <div className="border rounded-md px-8 py-4 text-sm">
                                        <ul>
                                            <li>Location: Nairobi, Kenya</li>
                                            <li>Device Type: Microsoft Edge, MacOS 10.11.4</li>
                                            <li>IP Address: {s.ip_address}</li>
                                            <li>Last Login: {s.updated_at}</li>
                                        </ul>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}