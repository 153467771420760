import React from "react";
import classNames from "classnames";
import _ from "lodash";

const DIMENSIONS = {
    DEFAULT: "w-10 h-10"
}

function strToHSLColor(str: string) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const h = (hash + str.length) % 360;
    return 'hsl(' + h + ',  40%, 60%, 0.8)';
}

export function createInitials(name: string) {
    return name?.split(" ").map(str => str[0]).slice(0, 2).join("");
}

export function createAvatarFromInitals(name: string, size?: string): JSX.Element {
    const cls = classNames({
        "text-white flex self-center justify-center rounded-md": true,
        "before:bg-gradient-to-b before:from-white before:to-transparent before:opacity-30 before:content-[''] before:absolute": true,
        "w-10 h-10 before:w-10 before:h-10 text-sm": _.isUndefined(size) || size == "md",
        "w-48 h-48 text-xl": size === "lg",
        "w-16 h-16 text-xl": size === "xl",
        "w-6 h-6 text-xs": size === "xs"
    })
    return (
        <div
            style={{backgroundColor: strToHSLColor(name)}}
            className={cls}>
            <span className="p-2 self-center">{createInitials(name)}</span>
        </div>
    )
}

export function Avatar({
                           imageURL,
                           text,
                           size
                       }: { imageURL?: string, text: string, size?: "xs" | "sm" | "md" | "lg" | "xl" | "2xl" }) {
    const cls = classNames({
        "rounded-md bg-cover": true,
        "before:bg-gradient-to-b before:from-white before:to-transparent before:opacity-30 before:content-[''] before:absolute": true,
        "w-10 h-10 before:w-10 before:h-10": _.isUndefined(size) || size == "md",
        "w-48 h-48": size === "lg",
        "w-16 h-16": size === "xl",
        "w-6 h-6": size === "xs"
    })
    return imageURL
        ? <div className={cls} style={{backgroundImage: `url(${imageURL})`}}/>
        : createAvatarFromInitals(text, size);
}