import {StyledElementContainer, StyledInput, StyledLabel} from "../StyledElements";
import format from "../../utils/date/format";
import {useFormContext} from "react-hook-form";

type DateInputProps = {
    label: string;
    register: any;
    required: boolean;
    name: string;
    setValue: any;
    getValues: any;
    type: string;
    formatLayout: string;
}

export const DateInput = ({label, name, required, getValues, register, formatLayout, type}: DateInputProps) => {
    const {onBlur, ref, onChange} = register(name, {required});
    const value = getValues(name) ? format(new Date(getValues(name)), formatLayout) : ""
    const {watch} = useFormContext();
    watch(name)
    return (
        <StyledElementContainer>
            <StyledLabel>{label}</StyledLabel>
            <StyledInput ref={ref} onBlur={onBlur} type={type} name={name} value={value}
                         onChange={onChange}/>
        </StyledElementContainer>
    )
}
