import { useEffect } from "react"
import { gql, useMutation, useQuery } from "@apollo/client";
import { format } from "date-fns";
import { get } from "lodash";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useFlash } from "../../../context/FlashContext";
import { Button } from "../../../interface/Button/Button";
import { DataTable } from "../../../interface/DataTable/DataTable";
import { Input, InputImage } from "../../../interface/Form/Input";
import { CheckboxInput, DateInput, TextAreaInput, TextInput, } from "../../../interface/Form/Input/Inputs";
import { MultiSelectComboBox, SelectComboBox, } from "../../../interface/Form/Select/Select";
import { Pillola } from "../../../interface/Pillole";
import { Tabs } from "../../../interface/Tabs/Tabs";
import { FETCH_DROPDOWN_OPTIONS_FORM, GET_JOB, PROCESS_JOB, UPDATE_JOB, FETCH_DEALS_DROPDOWN_OPTIONS_FORM} from "../../../queries/";
import { DELETE_ORGANIZATION_BY_ID } from "../../companies/queries";
import { AnnotationGrid } from "../articles/AnnotationGrid";
import { ChangesHistory } from "./ChangesHistory"

export interface ProcessOrgJobContainerProps {
    job: any;
    onProcessJob: any;
	handleFormState?: any
}

export const ProcessOrgJobContainer = ({ ...props }: ProcessOrgJobContainerProps) => {
    const { job,handleFormState } = props;

    const { data: opts} = useQuery(FETCH_DROPDOWN_OPTIONS_FORM);

    const [upsertJob] = useMutation(UPDATE_JOB);
    const [processJob] = useMutation(PROCESS_JOB);

    const flash = useFlash();
    const navigate = useNavigate();

    const buildImpacts = (data: any) => {
        const valueMap: Record<string, string> = {
            female_cofounder: "Female CoFounder",
            assistive_tech: "Assistive Tech",
            bop: "BoP",
            elderly_people: "Elderly People",
            employment_creation: "Employment Creation",
            female_focused: "Female Focoused",
            fifty_local: "Fifty Local",
            informal_sector: "Informal Sector",
            rural_focused: "Rulal Focused",
            youth_focused: "Youth Focused",
            zero_emissions: "Zero Emissions",
        };

        const selectedImpacts = [];
        for (const key in data) {
            if (
                Object.prototype.hasOwnProperty.call(data, key) &&
                data[key] === true
            ) {
                selectedImpacts.push({ id: key, value: valueMap[key] });
            }
        }
        return selectedImpacts;
    };

    const methods = useForm({
        defaultValues: get(job, "payload.msg"),
        values: {
            ...get(job, "payload.msg"),
            impact: buildImpacts(get(job, "payload.msg.impact", {})),
            operating_status: get(job, "payload.msg.operating_status.id", "c"),
        },
    });

    // todo(nate): move all this options to be built from the backend
    const optsImpact = [
        { id: "assistive_tech", value: "Assistive Tech" },
        { id: "bop", value: "BoP" },
        { id: "elderly_people", value: "Elderly People" },
        { id: "female_cofounder", value: "Female CoFounder" },
        { id: "employment_creation", value: "Employment Creation" },
        { id: "female_focused", value: "Female Focoused" },
        { id: "fifty_local", value: "Fifty Local" },
        { id: "informal_sector", value: "Informal Sector" },
        { id: "rural_focused", value: "Rural Focused" },
        { id: "youth_focused", value: "Youth Focused" },
        { id: "zero_emissions", value: "Zero Emissions" },
    ];

    const handleSubmit = (data: any) => {
        const normalizedImpact: any = {
            assistive_tech: false,
            bop: false,
            elderly_people: false,
            female_cofounder: false,
            employment_creation: false,
            female_focused: false,
            fifty_local: false,
            informal_sector: false,
            rural_focused: false,
            youth_focused: false,
            zero_emissions: false,
        };

        const impact = get(data, "impact", []);
        for (let i = 0; i < impact.length; i++) {
            normalizedImpact[impact[i].id] = true;
        }

        const normalized = {
            ...data,
            type: data.type ? data.type.id : "startup",
			operating_status: data.operating_status ? data.operating_status.id : "",
            impact: normalizedImpact,
            number_employees: data.number_employees
                ? data.number_employees.value
                : "",
            founded: new Date(get(data, "founded", 2006)),
        };

        upsertJob({
            variables: {
                id: job.id,
                req: {
                    id: job.id,
                    type: get(data, "getJobByID.type"),
                    stage: get(data, "getJobByID.stage", "created"),
                    payload: {
                        msg: normalized,
                    },
                },
            },
			refetchQueries: [{query: GET_JOB},{query: FETCH_DEALS_DROPDOWN_OPTIONS_FORM}],
        })
            .then((res) => {
                console.log("res", res);
                processJob({
                    variables: {
                        id: job.id,
                    },
                })
                    .then((res: any) => {
                        console.log("res", res);
                        flash(
                            "job updated and approved successfully",
                            "success",
                        );
                        navigate(-2);
                    })
                    .catch((err: any) => {
                        console.log("err", err);
                    });
            })
            .catch((err) => {
                console.log("err", err);
                flash("failed to update", "error");
            });
    };

	useEffect(() => {
		handleFormState && handleFormState(methods.formState.isDirty)
	},[methods.formState.isDirty])

    return (
        <>
            <EditJobForm
                optsImpact={optsImpact}
                opts={opts}
                methods={methods}
                onSubmit={handleSubmit}
            />
        </>
    );
};

export interface EditJobFormProps {
    methods: any;
    onSubmit: any;
    optsImpact: any;
    opts: any;
}

export const EditJobForm = ({ ...props }: EditJobFormProps) => {
    const { methods, onSubmit, optsImpact, opts } = props;

    return (
        <div
            style={{
                overflowY: "scroll",
                height: `calc(100vh - 200px)`,
                width: "100%",
            }}
        >
            <FormProvider {...methods}>
                <form
                    onSubmit={methods.handleSubmit(onSubmit)}
                    id="edit-job-form"
                >
                    <div className="grid gap-8 md:grid-cols-3 lg:grid-cols-2">
                        <div style={{ width: "100%" }}>
                            <div className="mb-6 w-full">
                                <Input
                                    errors={methods.formState.errors}
                                    outlined={true}
                                    label="Company name"
                                    type={"text"}
                                    {...methods.register("name")}
                                />
                            </div>
                            <div className="mb-6">
                                <TextAreaInput
                                    placeholder="Enter the description"
                                    data-testid="form-field-description"
                                    label={"Description"}
                                    register={methods.register}
                                    name={"description"}
                                />
                            </div>

                            <div className="mb-6 w-full">
                                <InputImage
                                    label="Profile Image/Logo (image)"
                                    getValues={methods.getValues}
                                    setValue={(v: string) =>
                                        methods.setValue("profile_image_url", v)
                                    }
                                    {...methods.register("profile_image_url", {
                                        required: {
                                            value: false,
                                            message:
                                                "Banner/cover is required.",
                                        },
                                    })}
                                    path="images/logos"
                                    description="Add the organization profile image here"
                                    type={"image"}
                                    required={true}
                                />
                            </div>
                            <div className="mb-6">
                                <TextInput
                                    placeholder={"Primary Email"}
                                    type={"email"}
                                    register={methods.register}
                                    name={"primary_email_contact"}
                                    label={"Primary Email"}
                                />
                            </div>
                            <div className="mb-6">
                                <TextInput
                                    placeholder={"Primary Phone Number"}
                                    type={"tel"}
                                    register={methods.register}
                                    name={"primary_phone_number"}
                                    label={"Primary Phone Number"}
                                />
                            </div>
                            <div className="mb-6">
                                <DateInput
                                    {...methods.register("founded")}
                                    name={"founded"}
                                    label={"Founded"}
                                    control={methods.control}
                                />
                            </div>
                            <div className="mb-6">
                                <SelectComboBox
                                    {...methods.register("type")}
                                    name={"type"}
                                    label={"Category"}
                                    control={methods.control}
                                    options={[
                                        { id: "startup", value: "Startup" },
                                        {
                                            id: "investor",
                                            value: "Investor",
                                        },
                                        {
                                            id: "ngo_charity",
                                            value: "NGO/Charity",
                                        },
                                        {
                                            id: "hub",
                                            value: "Hub/Accelerator",
                                        },
                                    ]}
                                />
                            </div>
                            <div className="mb-6">
                                <SelectComboBox
                                    {...methods.register("operating_status")}
                                    name={"operating_status"}
                                    label={"Operating Status"}
                                    control={methods.control}
                                    isMulti={false}
                                    options={[
                                        { id: "y", value: "Operational" },
                                        {
                                            id: "c",
                                            value: "No Recent Activity",
                                        },
                                        { id: "n", value: "Shut Down" },
                                    ]}
                                />
                            </div>
                            <div className="mb-6">
                                <SelectComboBox
                                    {...methods.register("number_employees")}
                                    name={"number_employees"}
                                    label={"Number of Employees"}
                                    control={methods.control}
                                    options={[
                                        { id: 0, value: "Self-employed" },
                                        { id: 1, value: "1-10" },
                                        { id: 11, value: "11-50" },
                                        { id: 51, value: "51-200" },
                                        { id: 201, value: "201-500" },
                                        { id: 501, value: "501-1000" },
                                        { id: 1001, value: "1001-5000" },
                                        { id: 5001, value: "5001-10,000" },
                                        { id: 10001, value: "10,000+" },
                                    ]}
                                />
                            </div>

                            <div className="mb-6">
                                <TextInput
                                    label={"Ownership Structure"}
                                    register={methods.register}
                                    name={"ownership_structure"}
                                />
                            </div>
                            <div className="mb-6">
                                <TextInput
                                    label={"Incorporation Number"}
                                    register={methods.register}
                                    name={"incorporation_number"}
                                />
                            </div>

                            <div className="mb-6">
                                <CheckboxInput
                                    register={methods.register}
                                    label={"Active"}
                                    name={"enabled"}
                                />
                            </div>
                        </div>

                        <div style={{ width: "100%" }}>
                            <div className="mb-6">
                                <MultiSelectComboBox
                                    {...methods.register("sectors")}
                                    name={"sectors"}
                                    label={"Sectors"}
                                    control={methods.control}
                                    options={get(opts, "sectors.data", [])}
                                />
                            </div>
                            <div className="mb-6">
                                <MultiSelectComboBox
                                    {...methods.register("sub_sectors")}
                                    name={"sub_sectors"}
                                    label={"Sub Sectors"}
                                    control={methods.control}
                                    options={get(opts, "sub_sectors.data", [])}
                                />
                            </div>
                            <div className="mb-6">
                                <MultiSelectComboBox
                                    {...methods.register("technologies")}
                                    name={"technologies"}
                                    label={"Technologies"}
                                    control={methods.control}
                                    options={get(opts, "technologies.data", [])}
                                />
                            </div>
                            <div className="mb-6">
                                <MultiSelectComboBox
                                    {...methods.register("business_models")}
                                    name={"business_models"}
                                    label={"Business Models"}
                                    control={methods.control}
                                    options={get(
                                        opts,
                                        "business_models.data",
                                        [],
                                    )}
                                />
                            </div>
                            <div className="mb-6">
                                <MultiSelectComboBox
                                    {...methods.register("impact")}
                                    name={"impact"}
                                    label={"Impact"}
                                    control={methods.control}
                                    options={optsImpact}
                                />
                            </div>
                            <div className="mb-6">
                                <MultiSelectComboBox
                                    {...methods.register("sdgs")}
                                    name={"sdgs"}
                                    label={"SDGs"}
                                    control={methods.control}
                                    options={get(opts, "sdgs.data", [])}
                                />
                            </div>
                            <div className="mb-6">
                                <MultiSelectComboBox
                                    {...methods.register("ops_countries")}
                                    name={"ops_countries"}
                                    label={"Operating Countries"}
                                    control={methods.control}
                                    options={get(opts, "countries.data", [])}
                                />
                            </div>
                            <div className="mb-6">
                                <MultiSelectComboBox
                                    {...methods.register("ops_cities")}
                                    name={"ops_cities"}
                                    label={"Operating Cities"}
                                    control={methods.control}
                                    options={get(opts, "cities.data", [])}
                                />
                            </div>
                            <div className="mb-6 w-full">
                                <TextInput
                                    placeholder="http://example.com"
                                    data-testid="form-field-website"
                                    label={"Website"}
                                    register={methods.register}
                                    name={"socials.website"}
                                />
                            </div>
                            <div className="mb-6">
                                <TextInput
                                    placeholder="http://x.com/username"
                                    data-testid="form-field-twitter"
                                    label={"Twitter"}
                                    register={methods.register}
                                    name={"socials.twitter"}
                                />
                            </div>
                            <div className="mb-6">
                                <TextInput
                                    placeholder="http://facebook.com/username"
                                    data-testid="form-field-facebook"
                                    label={"Facebook"}
                                    register={methods.register}
                                    name={"socials.facebook"}
                                />
                            </div>
                            <div className="mb-6">
                                <TextInput
                                    placeholder="http://angellist.com/username"
                                    data-testid="form-field-angellist"
                                    label={"Angellist"}
                                    register={methods.register}
                                    name={"socials.angellist"}
                                />
                            </div>
                            <div className="mb-6">
                                <TextInput
                                    placeholder="http://instagram.com/username"
                                    data-testid="form-field-instagram"
                                    label={"Instagram"}
                                    register={methods.register}
                                    name={"socials.instagram"}
                                />
                            </div>
                            <div className="mb-6">
                                <TextInput
                                    placeholder="http://linkedin.com/in/username"
                                    data-testid="form-field-linkedin"
                                    label={"Linked In"}
                                    register={methods.register}
                                    name={"socials.linkedin"}
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </FormProvider>
        </div>
    );
};

const OrgJobSideBar = ({ content, job }: { content: string; job: any }) => {
    const nav = {
        "job-history": {
            title: "Job Changes History",
            key: "job-history",
            pane: <ChangesHistory job={job} />,
        },
        "article-content": {
            title: "Article Contents",
            key: "article-content",
            pane: (
                <AnnotationGrid
                    job={job}
                    style={{
                        display: "flex",
                        width: "100%",
                        flexWrap: "wrap",
                        overflowY: "scroll",
                        height: `calc(100vh - 200px)`,
                    }}
                />
            ),
        },
        "other-similar-orgs": {
            title: "Similar Organizations",
            key: "other-similar-orgs",
            pane: <SimmilarOrganizations name={get(job, "payload.msg.name")} />,
        },
    };
    return (
        <div>
            <Tabs index={"article-content"} tabs={nav} />
        </div>
    );
};

const GET_ORGS_BY_SIMILARITY = gql`
    query getOrganizationByNameBySimilarity($name: String) {
        searchOrganizationNameBySimilarity(name: $name) {
            name
            id
            created_at
            type
            founded
        }
    }
`;

export const SimmilarOrganizations = ({ name }: { name: string }) => {
    const flash = useFlash();
    const { data, error, loading } = useQuery(GET_ORGS_BY_SIMILARITY, {
        variables: { name: name },
    });

    const [deleteOrganization] = useMutation(DELETE_ORGANIZATION_BY_ID);

    const navigate = useNavigate();

    const handleDeleteOrgByID = (id: string) => {
        deleteOrganization({
            variables: { id },
            refetchQueries: [
                {
                    query: GET_ORGS_BY_SIMILARITY,
                    variables: { name: name },
                },
            ],
        })
            .then((res) => {
                console.log("res", res);
                flash("Organization deleted successfully", "warning");
            })
            .catch((err) => {
                console.log("err", err);
                flash("Failed to delete organization", "error");
            });
    };

    const headers = [
        {
            key: "name",
            dataIndex: "name",
            title: "Name",
        },
        {
            key: "type",
            dataIndex: "type",
            title: "Type",
            render: (org: any) => {
                console.log("org", org);
                switch (org.type) {
                    default:
                        return (
                            <Pillola
                                size="sm"
                                text={org.type ? org.type : `Unknown`}
                                variant={"cold"}
                            />
                        );
                }
            },
        },
        {
            key: "created_at",
            dataIndex: "created_at",
            title: "Date Created",
            render: (org: any) => {
                return format(new Date(org.created_at), "Pp");
            },
        },
        {
            key: "actions",
            dataIndex: "actions",
            title: "Actions",
            render: (org: any) => {
                return (
                    <div className="flex gap-1">
                        <Button
                            title="View"
                            size="sm"
                            variant="primary"
                            onClick={() =>
                                navigate(
                                    `/organizations/directory/orgs/${org.id}`,
                                )
                            }
                        />
                        <Button
                            title="Delete"
                            size="sm"
                            variant="danger"
                            onClick={() => handleDeleteOrgByID(org.id)}
                        />
                    </div>
                );
            },
        },
    ];

    if (loading) return <p>Loading....</p>;
    if (error) return <p>There are no similar organizations</p>;
    return (
        <div className="w-full">
            {get(data, "searchOrganizationNameBySimilarity", []).length ===
            0 ? (
                <h1>
                    There are not other similar organizations to
                    <span className="font-bold">{name}</span>
                </h1>
            ) : (
                <DataTable
                    headers={headers}
                    data={get(data, "searchOrganizationNameBySimilarity", [])}
                />
            )}
        </div>
    );
};
