import React, {useState} from "react"
import classNames from "classnames";
import {FiChevronDown, FiChevronUp} from "react-icons/fi";

export const SectionCollapsible = ({ label, defaultOpen = false, children } : any) => {
    const [isOpen, setIsOpen] = useState<boolean>(defaultOpen)
    return (
        <section>
            <button
            onClick={(ev) => {
                ev.preventDefault();
                setIsOpen(!isOpen);
            }}
                className="font-semibold uppercase flex items-center gap-2 pb-2.5 w-full border-b border-solid border-b-gray-300 mb-4">
                <span className="flex-grow text-left">{label}</span> { isOpen ? <FiChevronDown /> : <FiChevronUp /> }
            </button>
            <div className={classNames({
                "pb-4": true,
                "hidden": !isOpen
            })}>{children}</div>
        </section>
    )
}