import React, {useEffect} from "react";
import {gql, useMutation} from "@apollo/client";

export const LOGOUT_MUTATION = gql`
mutation logout {
    logout {
        id
        ip_address
    }
}
`

export default function UserContainer() {
    const [logout, {data, loading}] = useMutation(LOGOUT_MUTATION)
    useEffect(() => {
        logout()
    }, [])
    if (loading) return <p>Logging out..</p>

    return (
        <p>Logged out</p>
    )
}