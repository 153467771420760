import React from "react";
import {gql, useMutation, useQuery} from "@apollo/client";
import {useParams} from "react-router";
import {Avatar} from "../../interface/Avatar";
import _, {get} from "lodash";
import {Tabs} from "../../interface/Tabs/Tabs";
import {PencilIcon} from "@heroicons/react/solid";
import {Socials} from "../../interface/Socials";
import {format} from "date-fns";
import {ProfilePane} from "./panes/ProfilePane";
import {usePlatformContext} from "../../context/PlatformContext";
import EditUserFragment from "./fragments/EditUserFragment";
import {UserAnalyticsPane} from "./panes/UserAnalyticsPane";
import {UserPolicyPermsPane} from "./panes/UserPolicyPermsPane";
import {UserSubscriptionPane} from "./panes/UserSubscriptionPane";
import classNames from "classnames";
import {FiCheckCircle, FiXCircle, FiAlertTriangle} from "react-icons/fi";

export const FETCH_USER = gql`
    query user($id: ID) {
        user(id: $id) {
            id
            first_name
            other_names
            email
            profile_image_url
            last_login
            enabled
            is_freemium
            claims {
                id
                status
                organization {
                    id
                    name
                }
            }
            sessions {
                id
                ip_address
                created_at
                updated_at
            }
            socials {
                website
                facebook
                instagram
                youtube
                linkedin
                angellist
            }
        }
    }
`

export default function IAMUserContainer() {
    const params = useParams();
    const {addPane} = usePlatformContext();
    const [setEnabledFlag] = useMutation(gql`
        mutation setEnabledFlag($user_id: ID!, $flag: Boolean!) {
            setUserEnabled(user_id: $user_id, flag: $flag) {
                user {
                    id
                }
            }
        }
    `, {
        refetchQueries: [FETCH_USER]
    })
    const {data, loading} = useQuery(FETCH_USER, {
        variables: {
            id: params.id
        }
    })
    const user = _.get(data, "user", {});
    const full_name = [
        _.get(data, "user.first_name", ""),
        _.get(data, "user.other_names", "")
    ].join(" ")

    return (
        <div className="flex flex-grow">
            <div className="h-full bg-bs-light-gray-50 px-4 flex-grow max-w-[25rem] min-w-[25rem]">
                <div className="flex flex-col gap-y-5 pt-5">
                    <div className="flex items-center">
                        <p className="text-2xl font-medium flex-grow">{user.first_name} {user.other_names}</p>
                        <PencilIcon className="w-5 h-5 cursor-pointer" onClick={() => {
                            addPane({
                                title: `${user.first_name} ${user.other_names} (user)`,
                                content: <EditUserFragment id={user.id}/>,
                                id: `edit-user-${user.id}`,
                                type: "edit"
                            })
                        }}/>
                    </div>
                    <div className="justify-center items-center ">
                        <div className="flex items-center">
                            <Avatar size="md" text={full_name} imageURL={user.profile_image_url}/>
                            <div className="flex-grow flex-row ml-4">
                                <p className="text-sm font-semibold">{user.email}</p>
                                <p className="text-sm">{user.geography ?? "Nairobi, Kenya"}</p>
                            </div>
                        </div>
                    </div>
                    {
                        user.description && (
                            <p className="text-sm">{user.description}</p>
                        )
                    }
                    <div className="flex flex-col gap-2">
                        <p className="text-sm">
                            <span className="font-semibold mr-2">Last access</span>
                            <span className="font-normal">
                            {
                                user.last_login ? format(new Date(user?.last_login), 'PPP ppp') : 'never'
                            }
                        </span>
                        </p>
                        <p className="text-sm flex items-center gap-2">
                            <span className="font-semibold mr-2">Access</span>
                            {
                                user.enabled
                                    ? <FiCheckCircle className="w-5 h-5"/>
                                    : <FiXCircle className="w-5 h-5"/>
                            }
                            {
                                user.enabled ? "Has access": "Has been disabled"
                            }
                            <div className="flex-grow flex justify-end">
                            <button
                                className={classNames({
                                    "px-2 py-0.5 mx-2.5 rounded-md": true,
                                    "bg-red-600 text-white": user.enabled,
                                    "bg-bs-light-primary": !user.enabled,
                                })}
                                onClick={(ev) => {
                                    ev.preventDefault()
                                    setEnabledFlag({
                                        variables: {
                                            user_id: user.id,
                                            flag: !user.enabled
                                        }
                                    })
                                }}
                            >
                                {
                                    user.enabled
                                        ? <span className="flex gap-2 items-center">Disable access <FiAlertTriangle /></span>
                                        : "Restore access"}
                            </button>
                            </div>
                        </p>
                    </div>
                    <hr/>
                    <div className="text-sm flex gap-1 flex-wrap items-center">
                        <span className="font-semibold mr-2">Claims</span>
                        {
                            get(user, 'claims', [])
                                .filter((claim: any) => claim.status >= 0).length < 1 && (
                                    <p>No claims</p>
                            )
                        }
                        {
                            get(user, 'claims', [])
                                .filter((claim: any) => claim.status >= 0)
                                .map((claim: any) => {
                                return (
                                    <span
                                        key={claim.id}
                                        className={classNames({
                                            "rounded-md px-2 py-1 mr-2": true,
                                            "bg-gray-300 text-black": claim.status == 0,
                                            "bg-green-700 text-white": claim.status == 1
                                        })}
                                    >
                                        {claim.organization.name} { claim.status === 0 && <>(pending)</>}
                                    </span>
                                )
                            })
                        }
                    </div>
                    <hr/>
                    <Socials type={"list"} hasLabel={true} allowAll socials={user.socials}/>
                </div>
            </div>
            <div className="px-2 flex-grow">
                <Tabs index="profile" tabs={{
                    "profile": {
                        title: 'Profile',
                        key: 'profile',
                        pane: <ProfilePane user={user}/>
                    },
                    "subscriptions": {
                        title: 'Subscription History',
                        key: 'subscriptions',
                        pane: <UserSubscriptionPane user={user}/>
                    },
                    "analytics": {
                        title: 'Analytics',
                        key: 'analytics',
                        pane: <UserAnalyticsPane user={user}/>
                    },
                    "permissions": {
                        title: "Policies & Permissions",
                        key: "permissions",
                        pane: <UserPolicyPermsPane user={user}/>
                    }
                }}/>
            </div>
        </div>
    )
}
