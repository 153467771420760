import React from "react";
import {
    DataContainerProvider,
    useDataContainerContext
} from "../../interface/DataContainer/context/DataContainerContext";
import {Tabs} from "../../interface/Tabs/Tabs";
import {DataGridGraphQL} from "../../interface/DataGrid/DataGridGraphQL";
import {Link} from "react-router-dom";
import {Persona} from "../../interface/Persona";
import {Socials} from "../../interface/Socials";
import classNames from "classnames";
import {format} from "date-fns";
import {Pillola} from "../../interface/Pillole";
import {DotsVerticalIcon, PencilIcon} from "@heroicons/react/outline";
import {get} from "lodash";
import {DataGridArrayCell} from "../../interface/DataGrid";
import {usePlatformContext} from "../../context/PlatformContext";
import {gql} from "@apollo/client";
import UpsertPeople from "../fragments/UpsertPeople";

export function PeopleDirectoryContainer() {
    const {filtersQuery} = useDataContainerContext();
    const {addPane, removePaneByID} = usePlatformContext()
    return (
        <section className="px-10">
            <div>
                <DataGridGraphQL
                    loading={false}
                    query={gql`
                    query fetchPeople($search: SearchRequest!) {
                        search(search: $search) {
                            items
                            total
                            took
                            cursor
                        }
                    }
                    `}
                    root="search"
                    property="items"
                    filters={filtersQuery}
                    variables={{
                        "search": {
                            "bucket": "people",
                            "sort": [{key: "name.keyword", dir: "asc"}],
                            "query": {
                                "filters": [{"term": {"enabled": true}}]
                            }
                        }
                    }}
                    entityName="people"
                    headers={[
                        {
                            title: "People Name",
                            dataIndex: "name",
                            key: "name",
                            render: (value) => {
                                return (
                                    <Link to={['/people/directory/person', value.id].join("/")}>
                                        <Persona text={[value.first_name, value.other_names].join(" ")} size="xs"
                                                 imageURL={value?.img_profile_url}/>
                                    </Link>
                                )
                            }
                        },
                        {
                            title: "Type",
                            dataIndex: "type",
                            key: "type",
                            render: (value) => <Pillola text={value.type} variant={"ghost"}/>
                        },
                        {
                            title: "Sectors",
                            dataIndex: "sectors",
                            key: "sectors",
                            render: (value) => {
                                const values = get(value, 'sectors', []).map((s: any) => ({value: s.name}));
                                return <DataGridArrayCell value={values}/>
                            }
                        },
                        {
                            title: "HQ",
                            dataIndex: "hq_country",
                            key: "hq_country"
                        },
                        {
                            title: "Operating Status",
                            dataIndex: "operating_status",
                            key: "operating_status",
                            render: (value) => {
                                const cls = classNames({
                                    "self-center px-2 py-1 text-xs rounded-md": true
                                })
                                switch (value.operating_status) {
                                    case 'y':
                                        return (
                                            <div className="flex-grow justify-center items-center flex">
                                                <Pillola text="ACTIVE" variant="positive"/>
                                            </div>
                                        )
                                    case 'n':
                                        return (
                                            <div className="flex-grow justify-center items-center flex">
                                                <Pillola text="SHUT DOWN" variant="negative"/>
                                            </div>
                                        )
                                    case 'c':
                                        return (
                                            <div className="flex-grow justify-center items-center flex">
                                                <Pillola text="UNDER REVIEW" variant="neutral"/>
                                            </div>
                                        )
                                    default:
                                        return <span
                                            className={cls}>{value.operating_status}</span>
                                }
                            }
                        },
                        {
                            title: "Contacts",
                            dataIndex: "contacts",
                            key: "contacts",
                            render: (value) => {
                                return <Socials size="sm" type={"horizontal"} socials={{
                                    angellist: get(value, 'angellist'),
                                    website: get(value, 'website'),
                                    // linkedin: get(value, 'linkedin'),
                                    // facebook: get(value, 'facebook')
                                }}/>
                            }
                        },
                        {
                            title: "Last Update",
                            dataIndex: "updated_at",
                            key: "updated_at",
                            render: (value) => {
                                return format(new Date(value.updated_at), 'Pp')
                            }
                        },
                        {
                            title: "",
                            dataIndex: "actions",
                            key: "actions",
                            render: (value) => {
                                const id = `edit-${value.id}`
                                return (
                                    <div className="flex gap-2.5">
                                        <PencilIcon className="w-5 h-5 cursor-pointer" onClick={() => {
                                            addPane({
                                                title: `${value.name} (person)`,
                                                content: <UpsertPeople id={value.id}
                                                                       onCancel={() => removePaneByID(id)}/>,
                                                id,
                                                type: "edit"
                                            })
                                        }}/>
                                        <DotsVerticalIcon
                                            className="w-5 h-5 text-bs-light-gray hover:text-bs-light-black hover:cursor-pointer"
                                        />
                                    </div>
                                )
                            }
                        }
                    ]}
                    data={[]}
                />
            </div>
        </section>
    )
}

export default function PeopleContainer() {
    const nav = {
        "directory": {
            title: 'Directory',
            key: 'directory',
            pane: <PeopleDirectoryContainer/>
        }
    }


    return (
        <DataContainerProvider filters={[]}>
            <div className="flex-grow flex flex-row">
                <div className="flex-grow">
                    <Tabs
                        index={'directory'}
                        tabs={nav}
                    />
                </div>
            </div>
        </DataContainerProvider>
    )
}