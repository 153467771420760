import React, {ChangeEvent, useEffect, useState} from "react";
import {IFilter} from "./FilterFactory";
import {FilterTitle} from "./fragments/FilterTitle";
import {XIcon} from "@heroicons/react/solid";
import _ from "lodash";

interface IFilterFreeSearch extends IFilter {

}

export default function FilterFreeSearch({...props}: IFilterFreeSearch) {
    const {title, type, id, addFilter, removeFilter, defaultValues} = props
    const value = _.get(defaultValues, 'value', '');
    const [state, setState] = useState({
        query: value
    });
    const update = (ev: ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            query: ev.target.value
        });
    }
    const reset = () => {
        setState({
            ...state,
            query: ''
        });
    }

    useEffect(() => {
        setState({...state, query: value})
    }, [value])

    useEffect(() => {
        if (state.query.length < 1) {
            removeFilter({
                type, key: id, value: ''
            })
        } else {
            addFilter({
                type, key: id, value: state.query
            })
        }
    }, [state.query]);

    return (
        <div className="w-full relative">
            <FilterTitle title={title}/>
            {
                state.query.length > 0 && (
                    <span
                        className="absolute right-0 py-2 cursor-pointer"
                        onClick={reset}
                    >
                        <XIcon className="w-3 h-3"/>
                    </span>
                )
            }
            <input type="text"
                   className="w-full border-b border-b-bs-gray bg-transparent py-1.5 outline-none focus:border-r-bs-light-black"
                   placeholder="All"
                   value={state.query}
                   onChange={update}
            />
        </div>
    )
}