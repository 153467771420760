import {gql} from "@apollo/client";

export const FETCH_SUBDIVISIONS = gql`
    query fetchCountries {
        subdivisions(limit: 5000) {
            data {
                id
                name
                iso_31662
                key: id
            }
        }
    }
`
export const FETCH_CITIES = gql`
    query fetchCountries {
        cities(limit: 1000) {
            data {
                id
                value: name
                key: id
            }
        }
    }
`

export const FETCH_COUNTRIES = gql`
    query fetchCountries {
        countries(limit: 1000) {
            data {
                id
                alpha2
                value: name
                key: id
            }
        }
    }
`

export const FETCH_AGGREGATION_STAT = gql`
query ($aggs: AggregationRequest!) {
    aggregate(aggregations: $aggs) {
        aggregations
        took
    }
}
`

export const FETCH_SEARCH = gql`
query search($search: SearchRequest!) {
    search(search: $search) {
        items
        total
        took
        cursor
    }
}
`

export const RESOURCE_ARTICLE_BY_ID = gql`
    query getResourceArticleById($id: ID!) {
        getResourceArticleByID(id: $id) {
            id
            body
            title
            is_premium
            is_spotlight
            published_at
            history
            slug
            status
            tags {
                id
                value: name
            }
            ops_countries {
                id
                value: name
            }
            resource_type {
                id
                value: name
                name
            }
            authors {
                id
                value: display_name
                display_name
            }
            agbase_visibility {
                id
                value: name
            }
            agbase_value_chain {
                id
                value: name
            }
            agbase_category {
                id
                value: name
            }
            sectors {
                id
                value: name
            }
            sub_sectors {
                id
                value: name
            }
            sdgs {
                id
                value: name
            }
            technologies {
                id
                value: name
            }
            image_preview
        }
    }
`

export const RESOURCE_UPLOAD_GET_BY_ID = gql`
    query getResourceUploadByID($id: ID!) {
        getResourceUploadByID(id: $id) {
            id
            description
            title
            is_premium
            history
            path
            is_spotlight
            tags {
                id
                value: name
            }
            ops_countries {
                id
                value: name
            }
            resource_type {
                id
                value: name
                name
            }
            authors {
                id
                value: display_name
                display_name
            }
            agbase_visibility {
                id
                value: name
            }
            agbase_value_chain {
                id
                value: name
            }
            agbase_category {
                id
                value: name
            }
            sectors {
                id
                value: name
            }
            sub_sectors {
                id
                value: name
            }
            sdgs {
                id
                value: name
            }
            technologies {
                id
                value: name
            }
            status
            slug
            image_preview
            published_at
        }
    }
`

export const RESOURCE_UPLOAD_CREATE = gql`
    mutation createResource($resource: UpsertResourceUploadRequest!) {
        createResourceUpload(resource: $resource) {
            id
        }
    }
`

export const RESOURCE_UPLOAD_UPDATE = gql`
    mutation updateResource($id: ID!, $resource: UpsertResourceUploadRequest!) {
        updateResourceUpload(id: $id, resource: $resource) {
            id
        }
    }
`

export const RESOURCE_ARTICLE_CREATE = gql`
    mutation createResourceArticle($resource: UpsertResourceArticleRequest!) {
        createResourceArticle(resource: $resource) {
            id
        }
    }
`

export const RESOURCE_ARTICLE_UPDATE = gql`
    mutation updateResourceArticle($id: ID!, $resource: UpsertResourceArticleRequest!) {
        updateResourceArticle(id: $id, resource: $resource) {
            id
        }
    }
`