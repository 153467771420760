import React, {useCallback, useEffect, useState} from "react"
import {DocumentNode, QueryHookOptions, useQuery} from "@apollo/client";
import {HiOutlineXMark} from "react-icons/hi2";
import {useCombobox} from "downshift";
import classNames from "classnames";

export interface ComboBoxProps {
    placeholder?: string
    loading?: boolean
    label?: string
    required?: boolean
    options?: {key: string, value: string}[]
    onSelect: (data: any) => void;
    value?: {key: string, value: string} | undefined
    values?: {key: string, value: string}[] | undefined
    creation?: boolean
}

export interface ComboBoxGQLProps extends ComboBoxProps {
    gql: {
        query: DocumentNode,
        options?: QueryHookOptions
    }
    getter: (data: any) => {key: string, value: string}[]
}

export const ComboBoxGQL = ({ ...props} : ComboBoxGQLProps) => {
    const {
        gql,
        getter,
        ...rest
    } = props

    const { data, loading, error } = useQuery(gql.query, gql.options )
    return (
        <div className="w-full">
            <SingleComboBox {...rest} loading={loading} options={getter(data)} />
        </div>
    )
}

function getItemsFilter(items : {key: string, value: string}[], query: string) {
    const lowerCasedInputValue = query.toLowerCase()
    return items.filter((item: {key: string, value: string}) => {
        return (
            !query ||
            item.key.toLowerCase().includes(lowerCasedInputValue) ||
            item.value.toLowerCase().includes(lowerCasedInputValue)
        )
    })
}


export const SingleComboBox = ({ ...props}: ComboBoxProps) => {
    const {
        placeholder, label, required, creation = false,
        loading , options, onSelect, value
    } = props

    const [items, setItems] = useState<{key: string, value: string}[]>(options || [])
    const [selectedItem, setSelectedItem] = useState<{key: string, value: string} | undefined>(value)
    const [isCreating, setIsCreating] = useState<boolean>(false)

    const stateReducer = useCallback((state, actionAndChanges) => {
        const {type, changes} = actionAndChanges
        // allowing to select a field with tab after searching
        switch (type) {
            case useCombobox.stateChangeTypes.InputBlur:
                const items = getItemsFilter(options || [], changes.inputValue || '')
                if(!creation) {
                    return {
                        ...changes,
                        inputValue: items.length === 1 ? items[0].value : '',
                        selectedItem: items.length === 1 ? items[0] : undefined
                    }
                }
                return changes
            default:
                return changes // otherwise business as usual.
        }
    }, [])

    const {
        isOpen,
        getMenuProps,
        getComboboxProps,
        getInputProps,
        highlightedIndex,
        inputValue,
        setInputValue,
        getItemProps,
    } = useCombobox({
        onInputValueChange({inputValue}) {
            const filteredItems = getItemsFilter(options || [], inputValue || '')
            setItems(filteredItems)
        },
        items,
        itemToString(item) {
            return item ? item.value : ''
        },
        selectedItem,
        stateReducer,
        onSelectedItemChange: ({selectedItem: newSelectedItem}) =>
            setSelectedItem(newSelectedItem || undefined),
    })

    useEffect(() => {
        console.log("items", items, items.length < 1, isCreating)
        if (items.length < 1 && !isCreating) {
            setIsCreating(true);
        }
        if (items.length > 0) {
            setIsCreating(false)
        }
    }, [items, setIsCreating, inputValue]);

    useEffect(() => {
        if (selectedItem !== undefined) {
            setIsCreating(false)
        }
        onSelect(selectedItem)
    }, [selectedItem]);

    return (
        <div className="w-full flex gap-2 flex-col">
            {
                label && (
                    <label className="text-bs-light-gray-800 text-sm">
                        {label} { required && (<span className="text-red-800">*</span>)}
                    </label>
                )
            }
            <div className={classNames({
                "w-full flex flex-wrap rounded-md gap-2 border py-1 px-1 items-center border-solid": true,
                "border-gray-300": !selectedItem,
                "border-bs-light-border-primary": selectedItem
            })}
                 {...getComboboxProps()}
            >
                {
                    loading && <p>Loading...</p>
                }
                {
                    !loading && (
                        <>
                            <input
                                placeholder={placeholder}
                                className="px-2 outline-0 grow"
                                {...getInputProps()}
                            />
                            {
                                selectedItem && selectedItem.value !== '' && (
                                    <HiOutlineXMark onClick={(ev) => {
                                        ev.preventDefault()
                                        setSelectedItem(undefined)
                                        setInputValue('')
                                    }} className="cursor-pointer" />
                                )
                            }
                        </>
                    )
                }
                <ul
                    className={`absolute w-72 bg-white mt-1 shadow-md max-h-80 overflow-scroll p-0 z-10 ${
                        !isOpen && 'hidden'
                    }`}
                    {...getMenuProps()}
                >
                    {
                        isOpen && items.map((item, index) => (
                            <li
                                className={classNames(
                                    highlightedIndex === index && 'bg-blue-300',
                                    selectedItem === item && 'font-bold',
                                    'py-2 px-3 shadow-sm flex flex-col',
                                )}
                                key={item.key}
                                {...getItemProps({item, index})}
                            >
                                <span>{item.value}</span>
                            </li>
                        ))
                    }
                    {
                        isCreating && (
                            <li
                                className={classNames(
                                    'py-2 px-3 shadow-sm flex flex-col cursor-pointer hover:bg-bs-light-border-primary',
                                )}
                                key={inputValue}
                            >
                                <span onClick={(e) => {
                                    e.preventDefault();
                                    setSelectedItem({
                                        key: "", value: inputValue
                                    })
                                }} className="cursor-pointer">Create <span className="cursor-pointer font-semibold">{inputValue}</span></span>
                            </li>
                        )
                    }
                </ul>
            </div>
        </div>
    )
}