import React from "react";
import {Fieldset} from "../../../interface/Form/Fieldset";
import {Input} from "../../../interface/Form/Input";

export default function EditUserFragment({id}: { id: string }) {
    return (
        <>
            <div className="p-5 flex-grow h-[calc(100vh-14rem)]">
                <form className="gap-5 flex max-h-full flex-col overflow-y-auto">
                    <p className="text-2xl border-b pb-5 border-dashed">Title</p>
                    <Fieldset direction="vertical" title="General information">
                        <Input type="text" value="edoardob@briterbridges.com" label="Email address"
                               required description="This is the primary email address of the user"
                        />
                        <Input type="text" value="Edoardo" label="First name" required/>
                        <Input type="text" value="Biraghi" label="Other names" required/>
                        <Input type="text" value="edoardo.biraghi@gmail.com" label="Recovery email address"
                               required/>
                    </Fieldset>
                    <Fieldset direction="horizontal" title="Socials">
                        <Fieldset direction="vertical">
                            <Input type="text" value="" label="Website"/>
                            <Input type="text" value="skedone" label="Instagram"/>
                            <Input type="text" value="" label="YouTube"/>
                            <Input type="text" value="" label="Twitter"/>
                        </Fieldset>
                        <Fieldset direction="vertical">
                            <Input type="text" value="" label="Angel List"/>
                            <Input type="text" value="" label="Facebook"/>
                            <Input type="text" value="" label="Linkedin"/>
                            <Input type="text" value="" label="Crunchbase"/>
                        </Fieldset>
                    </Fieldset>
                    <Fieldset direction="vertical" title="User Preferences">
                        <Input type="text" value="test"/>
                        <Input type="text" value="test"/>
                        <Input type="text" value="test"/>
                        <Input type="text" value="test"/>
                        <Input type="text" value="test"/>
                        <Input type="text" value="test"/>
                    </Fieldset>
                </form>
            </div>
            <div className="bg-bs-light-gray-100 min-w-48 py-5">
                <ul>
                    <li className="bg-white px-5 py-5">Profile fields</li>
                    <li className="px-5 py-5">Security Preferences</li>
                </ul>
            </div>
        </>
    )
}