import React from "react";
import {DocumentNode, TypedDocumentNode, useQuery} from "@apollo/client";
import _ from "lodash";

/**
 * example:
 * <StatsGroup stats={{
 *                                 options: {
 *                                     stretchLast: true
 *                                 },
 *                                 stats: [
 *                                     {title: 'Funding in 2022', value: '$ 560.4M', type: 'CARD'},
 *                                     {title: 'Operating companies', value: '1024', type: 'CARD'},
 *                                     {title: 'Sectors', value: '18', type: 'CARD'},
 *                                     {title: 'Products', value: '12400', type: 'CARD'},
 *                                     {
 *                                         title: 'Total death of startup since 2021',
 *                                         value: 'MOST OF THEM',
 *                                         type: 'PROGRESS',
 *                                         total: 2421,
 *                                         current: 2121,
 *                                         showPercentage: true,
 *                                         linkTitle: 'See total funding in previous year',
 *                                         linkTo: '/last-funding'
 *                                     },
 *                                     {
 *                                         title: 'Total disclosed funding since 2022',
 *                                         value: '$ 124.0M',
 *                                         type: 'PROGRESS',
 *                                         total: 24800,
 *                                         current: 13888,
 *                                         showPercentage: true,
 *                                         linkTitle: 'See total funding in previous year',
 *                                         linkTo: '/last-funding'
 *                                     },
 *                                 ]
 *                             }}/>
 */

export enum StatsType {
    CARD,
    PROGRESS
}

export interface Stat {
    type: string;
    value: string;
    title: string;
    variation?: string;
    prev?: string;
    linkTo?: string;
    linkTitle?: string;
}

export interface StatCard extends Stat {
    key?: string;
    prefix?: string;
    suffix?: string;
}

export interface StatProgress extends Stat {
    total: number,
    showPercentage?: boolean,
    current: number
}

export interface StatsGroup {
    stats: any,
    options: object;
    prefixCls?: string;
}

export interface IStatsGroupGraphQL {
    query: DocumentNode | TypedDocumentNode;
    property?: string;
    root: string;
}

export const StatsGraphQL = ({...props}) => {
    const {
        type,
        query,
        variables,
        filters,
        root = "aggregate.aggregations",
        property,
        title,
        render = (v: any) => v
    } = props;
    const {data, loading, error} = useQuery(query, {
        variables: {
            ...variables,
            query: {
                filter: filters
            },
        },
        fetchPolicy: 'cache-and-network'
    });
    const response = _.get(data, [root, property].join("."), 0);
    const stat = {title, value: render(response.value), type}
    return <StatsFactory stat={{...stat, type}}/>
}

export const StatsGroupGraphQL = ({...props}: IStatsGroupGraphQL) => {
    const {query, root, property} = props;
    const {data} = useQuery(query)
    const stats = _.get(data, [root, property].join("."), []).map((c: any) => c);
    return <StatsGroup stats={{stats: stats, options: {stretchLast: true}}}/>
}

export const StatsGroup = ({stats}: { stats: any }) => {
    return (
        <div className="flex gap-16 w-full">
            {stats.stats.map((stat: any) => (
                <StatsFactory key={stat.title} stat={stat}/>
            ))}
        </div>
    )
}

export const StatsFactory = ({stat}: { stat: any }) => {
    switch (stat.type) {
        case StatsType.CARD:
            return <StatsCard stat={stat}/>
        case StatsType.PROGRESS:
            return <StatsProgress stat={stat}/>
        default:
            return <StatsCard stat={stat}/>
    }
}

export const StatsProgress = ({stat}: { stat: StatProgress }) => {
    const percentage = Math.round(stat.current / stat.total * 100);
    return (
        <div className="flex-grow">
            <p className="text-bs-light-gray mb-2">{stat.title}</p>
            <div className="w-full bg-bs-light-gray-100 rounded-md h-8 relative align-middle self-center">
                <p className="absolute right-2 text-bs-light-gray leading-8 text-sm font-semibold">{stat.total}</p>
                <div
                    style={{width: [percentage, '%'].join("")}}
                    className={`text-sm left-0 bg-bs-light-black rounded-md h-8 text-right text-bs-light-white`}>
                    <p className="pr-4 leading-8 font-semibold">
                        {stat.value}
                        {stat.showPercentage && (
                            <span
                                className="pl-1 font-light">({percentage}%)</span>
                        )}
                    </p>
                </div>
            </div>
            <div className="h-8 w-full">
                {
                    stat.linkTo && (
                        <p className="text-sm py-2">
                            {stat.linkTitle}
                            <span className={"pl-4"}>
                        <svg xmlns="http://www.w3.org/2000/svg" className="inline h-3 w-3" fill="none"
                             viewBox="0 0 24 24"
                             stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7"/>
                        </svg>
                            </span>
                        </p>
                    )
                }
            </div>
        </div>
    )
}

export const StatsCard = ({stat}: { stat: Stat }) => {
    return (
        <div className="flex-grow">
            <p className="text-bs-light-gray mb-2">{stat.title}</p>
            <p className="text-bs-light-black text-3xl font-semibold">
                {stat.value} {
                parseFloat(stat.variation ?? "0") > 0 && <span className="text-normal text-green-500">+{stat.variation}</span>
            }{
                parseFloat(stat.variation ?? "0") < 0 && <span className="text-normal text-red-500">{stat.variation}</span>
            }
            </p>
        </div>
    )
}