import React, {createContext, useContext, useState} from "react";
import {xorBy} from "lodash";

export interface IDataExportState {
    bucket: string | undefined;
    filters: any[];
    columns: any[];
}
export interface IDataExportContext extends IDataExportState{
    setBucket: (bucket: string) => void;
    upsertColumn: (column: {  key: string, value: string }) => void;
}

const DataExportContext = createContext<IDataExportContext>({} as IDataExportContext)
export const DataExportProvider = ({children}: any) => {
    const [state, setState] = useState<IDataExportState>({
        bucket: undefined,
        filters: [],
        columns: []
    })

    const setBucket = (bucket: string) => {
        setState({
            ...state,
            bucket: bucket,
            filters: [],
            columns: []
        })
    }

    const upsertColumn = (column: { key: string, value: string }) => {
        const columns = xorBy(state.columns, [column], 'key')
        setState({
            ...state,
            columns
        })
    }
    const removeColumn = () => {}
    const addFilter = () => {}
    const removeFilter = () => {}
    const clearFilters = () => {}

    return (
        <DataExportContext.Provider value={{...state, setBucket, upsertColumn}}>
            {children}
        </DataExportContext.Provider>
    )
}

export function useDataExportContext() {
    return useContext(DataExportContext);
}